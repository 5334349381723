import React, { useEffect, useRef, useState } from 'react';
import { material } from '../../../library/material';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { getInvoiceByTreatmentPlanRequestId, getInvoiceTreatmentList, sendInvoiceByTreatmentPlanRequestId } from '../../../services/InvoiceManagementService';
import DiscountDialog from '../../dialog/DiscountDialog';
import Snackbar from '../../toastrmessage/Snackbar';
import Navbar from '../../navbar/Navbar';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import ViewCurrentOfferList from '../clinic-config/corrent-offers/ViewCurrentOfferList';
import { connect } from 'react-redux';
import { useTheme } from '@mui/material';


var allTreatmentListData;

function InvoiceManagement(props) {

    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [patientData, setPatientData] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [isLoading, setisLoading] = useState(true);
    const [openDiscountDialog, setOpenDiscountDialog] = useState({ "action": false, "data": "" });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [selectValue, setSelectValue] = useState("NOT_GENERATED");
    const [openViewCurrentOfferList, setOpenViewCurrentOfferList] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const iframeRef = useRef(null);
    const theme = useTheme();

    localStorage.setItem("menuName", "Treatment Invoice")

    useEffect(() => {
        getTreatmentList()
    }, [selectValue, openDiscountDialog.action === false]);

    useEffect(() => {
        if (iframeRef.current && selectedInvoice) {
            const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
            iframeDoc.open();
            iframeDoc.write(selectedInvoice);
            iframeDoc.close();
        }
    }, [selectedInvoice]);

    const changeInvoice = (e, value) => {
        setSelectValue(value)
    };

    const getTreatmentList = async () => {
        if (selectValue === "NOT_GENERATED") {
            setisLoading(true)
            await getInvoiceTreatmentList(selectValue)
                .then((res) => {
                    allTreatmentListData = res.data.data;
                    setPatientData(res.data.data);
                    setisLoading(false);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.data.info,
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.messages,
                        })
                    }
                })
        } else if (selectValue === "GENERATED") {
            setisLoading(true)
            await getInvoiceTreatmentList(selectValue)
                .then((res) => {
                    allTreatmentListData = res.data.data;
                    setPatientData(res.data.data);
                    setisLoading(false);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.data.info,
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.messages,
                        })
                    }
                })
        };
    };

    const filterByPatientId = (value) => {
        const filteredRows = patientData.filter((row) => {
            return row.patientName
                .toString()
                .toLowerCase()
                .trim()
                .includes(value.toString().toLowerCase().trim())
        })
        if (value.trim().toString().length < 1) {
            setPatientData(allTreatmentListData);
        } else {
            setPatientData(filteredRows);
        };
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const invoiceGenerate = (rowData) => {
        setOpenDiscountDialog({ action: true, data: rowData })
    };

    const showInvoice = async (rowData) => {
        await getInvoiceByTreatmentPlanRequestId(rowData.treatmentPlanRequestId)
            .then((resp) => {
                // const newTab = window.open("", "_blank")
                // newTab.document.write(resp.data)
                setSelectedInvoice(resp.data.data);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const sendInvoice = async (rowData) => {
        await sendInvoiceByTreatmentPlanRequestId({ treatmentPlanRequestId: rowData.treatmentPlanRequestId })
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const viewCurrentOffer = () => {
        setOpenViewCurrentOfferList(true)
    };

    const handlePrintClick = () => {
        if (iframeRef.current) {
            // Get the iframe document and print it
            iframeRef.current.contentWindow.focus();
            iframeRef.current.contentWindow.print();
        }
    };

    return (
        <material.Grid spacing={2} className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 10
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 10
                },
            }}
        >
            <Navbar />
            <div>
                <div className='row'>
                    <div className='col-4'>
                        <span style={{ marginLeft: 5 }}>
                            <material.TextField
                                fullWidth
                                variant="standard"
                                label="Filter by Patient Name"
                                onChange={(e) => filterByPatientId(e.target.value)}
                            />
                        </span>
                    </div>
                    <div className='col-8'>
                        <span className='float-end me-2'>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} className='me-2' onClick={viewCurrentOffer} startIcon={<material.VisibilityIcon />}>View Current Offer</material.Button>
                            <material.ToggleButtonGroup
                                color="info"
                                value={selectValue}
                                exclusive
                                onChange={changeInvoice}
                                aria-label="Platform"
                            >
                                <material.ToggleButton sx={{ textTransform: "none" }} value="NOT_GENERATED">Invoice is't Generated</material.ToggleButton>
                                <material.ToggleButton sx={{ textTransform: "none" }} value="GENERATED">Generated</material.ToggleButton>
                            </material.ToggleButtonGroup>
                        </span>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className={selectValue == "GENERATED" ? "col-6" : "col-12"}>
                        <material.Paper sx={{ p: 3 }} elevation={3}>
                            <material.TableContainer sx={{ maxHeight: 585 }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead >
                                        <material.TableRow>
                                            <StyledTableCell>Patient Name</StyledTableCell>
                                            <StyledTableCell>Injector Name</StyledTableCell>
                                            <StyledTableCell>Treatment Plan Request ID</StyledTableCell>
                                            <StyledTableCell>Approver Name</StyledTableCell>
                                            <StyledTableCell>Status</StyledTableCell>
                                            <StyledTableCell>Action</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {isLoading ? (
                                            <material.TableRow >
                                                <material.TableCell colSpan={10}>
                                                    <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                        <Skeleton count={10} />
                                                    </SkeletonTheme>
                                                </material.TableCell>
                                            </material.TableRow>
                                        ) : (
                                            <>
                                                {patientData.length ? patientData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                    <material.TableRow
                                                        key={i}
                                                        sx={{
                                                            '&:last-child td, &:last-child th': { border: 0 }
                                                        }}
                                                    >
                                                        <material.TableCell sx={{ pt: 2, pb: 2 }} size='small' scope="row">{row.patientName}</material.TableCell>
                                                        <material.TableCell size='small' scope="row">{row.injectorName}</material.TableCell>
                                                        <material.TableCell size='small' scope="row">{row.treatmentPlanRequestId}</material.TableCell>
                                                        <material.TableCell size='small' scope="row">{row?.approverName}</material.TableCell>
                                                        <material.TableCell size='small'>
                                                            {row.status === "USED" ? (
                                                                <span className="badge bg-success">
                                                                    USED
                                                                </span>
                                                            ) : null}
                                                        </material.TableCell>
                                                        <material.TableCell>
                                                            <span className='d-flex flex-column'>
                                                                {row.invoiceGenerated ? (
                                                                    <>
                                                                        <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='secondary' size="small" onClick={() => showInvoice(row)}>Show Invoice</material.Button>
                                                                        <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='success' size="small" onClick={() => sendInvoice(row)}>Send Invoice</material.Button>
                                                                    </>
                                                                ) : (
                                                                    <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='info' size="small" onClick={() => invoiceGenerate(row)}>Generate Invoice</material.Button>
                                                                )}
                                                            </span>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={10}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </>)}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                            <hr />
                            <material.TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                component="div"
                                count={patientData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </material.Paper>
                    </div>
                    {selectValue == "GENERATED" ? (
                        <div className="col-6">
                            <material.Paper sx={{ p: 3 }} elevation={3}>
                                {selectedInvoice ? (
                                    <div>
                                        <iframe
                                            ref={iframeRef}
                                            title="Invoice Viewer"
                                            style={{
                                                width: "100%",
                                                height: "70vh",
                                                border: "1px solid #ccc",
                                                borderRadius: "8px",
                                                backgroundColor: "#f9f9f9",
                                            }}
                                        ></iframe>
                                        <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handlePrintClick}>
                                            Print
                                        </material.Button>
                                    </div>
                                ) : (
                                    <p>Select an invoice to view its details.</p>
                                )}
                            </material.Paper>
                        </div>
                    ) : null}
                </div>
            </div>
            <DiscountDialog
                openDiscountDialog={openDiscountDialog}
                setOpenDiscountDialog={setOpenDiscountDialog}
                getTreatmentList={getTreatmentList}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <ViewCurrentOfferList
                userDetails={userDetails}
                clinicDetails={clinicDetails}
                openViewCurrentOfferList={openViewCurrentOfferList}
                setOpenViewCurrentOfferList={setOpenViewCurrentOfferList}
            />
        </material.Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state,
    };
};

export default connect(mapStateToProps)(InvoiceManagement);