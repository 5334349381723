import React, { useState, useEffect } from 'react';
import { material } from '../../library/material';
import { useForm } from 'react-hook-form';
import { generateInvoice, generateInvoicePreview, getTotalPayableAmount } from '../../services/InvoiceManagementService';
import Snackbar from '../toastrmessage/Snackbar';
import { getUsersList } from '../../services/UserManagementService';
import { getPatientCreditList } from '../../services/PatientService';
import { connect } from 'react-redux';

var generatedBy;

function DiscountDialog(props) {

    const { openDiscountDialog, setOpenDiscountDialog, getTreatmentList, userData, clinicData } = props;
    const { register, handleSubmit, resetField, watch, getValues, setValue, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [hide, setHide] = useState(true);
    const [userList, setSUserList] = useState([]);
    const [patientCreditList, setPatientCreditList] = useState(null);
    const [totalPayableAmount, setTotalPayableAmount] = useState(null);
    const [getCreditValue, setCreditValue] = useState(null);

    const onChangeDiscount = watch('discount');
    const onChangeEft = watch('eft');
    const onChangeGiftCard = watch('giftCard');
    const onChangeTotalCreditAmount = watch('patientCredit');
    const onChangeCash = watch('cash');
    const onChangeTotalAmount = watch('totalAmount');

    useEffect(() => {
        calculateRemainingValue();
    }, [onChangeEft, onChangeGiftCard, onChangeCash, onChangeTotalAmount, onChangeTotalCreditAmount, hide === false]);

    useEffect(() => {
        if (openDiscountDialog.action) {
            getAllUserList();
            setValue("discount", 0);
            getPatientCreditListData();
            setValue("patientCredit", 0);
        }
    }, [openDiscountDialog.action === true]);

    useEffect(() => {
        getDiscountValue();
    }, [onChangeDiscount]);

    const calculateRemainingValue = () => {
        let eftValue = getValues('eft');
        let giftCardValue = getValues('giftCard');
        let cashValue = getValues('cash');
        let totalAmount = watch('totalAmount');
        let totalCreditAmount = getValues('patientCredit');
        let totalValue = (Number(totalAmount) - (Number(eftValue) + Number(giftCardValue) + Number(cashValue) + Number(totalCreditAmount)));
        setCreditValue(totalCreditAmount);
        setValue("remainingAmount", Number(totalValue));
    };

    const getAllUserList = () => {
        const payload = {
            orgId: userDetails.orgId,
            clinicId: clinicDetails.clinicId
        }
        getUsersList(payload)
            .then((resp) => {
                const activeUser = resp.data.data.filter((user) => user.active === true);
                setSUserList(activeUser)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getDiscountValue = async () => {
        let discountAmount = getValues('discount');
        if (openDiscountDialog.action) {
            await getTotalPayableAmount({ discount: Number(discountAmount), treatmentPlanRequestId: openDiscountDialog.data.treatmentPlanRequestId })
                .then((resp) => {
                    setHide(false);
                    setTotalPayableAmount(resp.data.data.amount);
                    setValue("totalAmount", resp.data.data.amount);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.data.info,
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.messages,
                        })
                    }
                })
        };
    };

    const getPatientCreditListData = () => {
        let payload = {
            patientId: openDiscountDialog.data.patientId,
            // onlyNotUsed: false
        }
        getPatientCreditList(payload)
            .then((resp) => {
                // const amounts = resp.data?.map((ele) => ele.amount)
                if (resp.data.data[0].balance < 0) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": "Customer has an outstanding balance. Please check customer credit.",
                    })
                    setPatientCreditList(null)
                } else {
                    setPatientCreditList(resp.data.data[0].balance)
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const selectUser = (e, value) => {
        generatedBy = value.userId
    };

    const save = async (data) => {
        let obj = {
            treatmentPlanRequestId: openDiscountDialog.data.treatmentPlanRequestId,
            cash: data.cash,
            eft: data.eft,
            giftCard: data.giftCard,
            discount: data.discount,
            generatedBy: generatedBy,
            patientCredit: data.patientCredit,
            totalPayableAmount: data.totalAmount,
        }
        await generateInvoice(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                setHide(true);
                reset({ discount: "", cash: 0, giftCard: 0, eft: 0, remainingAmount: "" });
                getTreatmentList();
                setOpenDiscountDialog({ action: false });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const preview = async (data) => {
        let obj = {
            treatmentPlanRequestId: openDiscountDialog.data.treatmentPlanRequestId,
            cash: data.cash,
            eft: data.eft,
            giftCard: data.giftCard,
            discount: data.discount,
            generatedBy: generatedBy,
            patientCredit: data.patientCredit,
            totalPayableAmount: data.totalAmount,
        }
        await generateInvoicePreview(obj)
            .then((resp) => {
                const newTab = window.open("", "_blank")
                newTab.document.write(resp.data.data)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const cancel = () => {
        setHide(true)
        reset({ discount: "", cash: 0, giftCard: 0, eft: 0, remainingAmount: "" });
        setOpenDiscountDialog({ action: false })
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="md" open={openDiscountDialog.action} hideBackdrop >
                <material.DialogTitle>Add Discount</material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div className="col-6">
                            <material.FormControl fullWidth variant="standard">
                                <material.InputLabel htmlFor="standard-adornment-amount">Discount</material.InputLabel>
                                <material.Input
                                    type="number"
                                    error={errors.discount?.type === "required"}
                                    id="standard-adornment-amount"
                                    startAdornment={<material.InputAdornment position="start" sx={{ mb: 2, fontSize: 30 }}>$</material.InputAdornment>}
                                    {...register("discount", { required: true })}
                                />
                            </material.FormControl>
                        </div>
                        <div className="col-6">
                            <material.Autocomplete
                                id="generatedBy"
                                margin="dense"
                                onChange={selectUser}
                                options={userList}
                                getOptionLabel={(option) => option.firstName + " " + option.lastName}
                                renderInput={(params) => <material.TextField {...params} required variant="standard" label="Sales Account"
                                    {...register("generatedBy")}
                                />}
                            />
                        </div>
                    </div>
                    <div className='row' hidden={hide}>
                        <div className='col-4'>
                            <material.TextField
                                {...register("giftCard", { required: true })}
                                margin="dense"
                                required
                                fullWidth
                                type='number'
                                label="Gift Card"
                                variant="standard"
                                defaultValue={0}
                            />
                        </div>
                        <div className='col-4'>
                            <material.TextField
                                {...register("eft", { required: true })}
                                margin="dense"
                                required
                                fullWidth
                                type='number'
                                label="EFT"
                                variant="standard"
                                defaultValue={0}
                            />
                        </div>
                        <div className='col-4'>
                            <material.TextField
                                {...register("cash", { required: true })}
                                margin="dense"
                                required
                                fullWidth
                                type='number'
                                label="Cash"
                                variant="standard"
                                defaultValue={0}
                            />
                        </div>
                        <div className='col-4'>
                            <material.TextField
                                {...register("patientCredit", { required: true })}
                                margin="dense"
                                fullWidth
                                type='number'
                                label="Credit"
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                                disabled={patientCreditList === null}
                            />
                            {(getCreditValue > patientCreditList) && (
                                <h6 className="text-danger">Maximum credit available {patientCreditList}</h6>
                            )}
                            <h6>Available Balance : {patientCreditList}</h6>
                        </div>
                        <div className='col-4'>
                            <material.TextField
                                {...register("totalAmount", { required: true })}
                                margin="dense"
                                fullWidth
                                type='number'
                                label="Total Amount"
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                            />
                        </div>
                        <div className='col-4'>
                            <material.TextField
                                {...register("remainingAmount", { required: true })}
                                margin="dense"
                                fullWidth
                                type='number'
                                label="Remaining Amount"
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                            />
                        </div>
                    </div>
                    <div className='mt-3'>
                        <span className='float-end'>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" className='me-2' color='error' onClick={cancel}>
                                Cancel
                            </material.Button>
                            <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size="medium" onClick={handleSubmit(save)} disabled={!isValid}>
                                Save
                            </material.Button>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={handleSubmit(preview)}>
                                Preview
                            </material.Button>
                        </span>
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(DiscountDialog);