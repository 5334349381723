import axios from "axios"
import { rootApiUrl } from "../environment/Environment"



export const createOrUpdateTreatmentList = async (treatmentData) => {
    return axios.post(`${rootApiUrl}/api/v0.2/cosmetic-treatment/create-or-update-treatment-list`, treatmentData)
};

export const getTreatmentList = async () => {
    return axios.get(`${rootApiUrl}/api/v0.2/cosmetic-treatment/get-treatment-list-admin`)
};

export const getTreatmentListByClinicId = async (clinicId, orgId) => {
    return axios.get(`${rootApiUrl}/api/v0.2/cosmetic-treatment/get-treatment-list`, { params: { clinicId: clinicId, orgId: orgId } })
};

export const getSkinTreatmentList = async (clinicId, onlyTreatmentName, treatmentName) => {
    return axios.get(`${rootApiUrl}/api/v0.2/skin-treatment/get-skin-treatment-list`, { params: { clinicId: clinicId, onlyTreatmentName: onlyTreatmentName, treatmentName: treatmentName } })
};

export const saveSkinTreatment = async (treatmentData) => {
    return axios.post(`${rootApiUrl}/api/v0.2/skin-treatment/save-skin-treatment`, treatmentData)
};

export const getProductTreatment = async (clinicId, orgId) => {
    return axios.get(`${rootApiUrl}/api/v0.2/cosmetic-treatment/get-product-treatment-list`, { params: { clinicId: clinicId, orgId: orgId } })
};

export const createProductTreatmentMap = async (productData) => {
    return axios.post(`${rootApiUrl}/api/v0.2/cosmetic-treatment/create-product-treatment-map`, productData)
};

export const saveTreatmentPlanDetails = async (treatmentPlanData) => {
    return axios.post(`${rootApiUrl}/api/v0.2/cosmetic-treatment/save-treatment-plan-details`, treatmentPlanData)
};

export const getTreatmentDetailsByTreatmentPlanRequestId = async (treatmentPlanRequestId) => {
    return axios.get(`${rootApiUrl}/api/v0.2/cosmetic-treatment/get-treatment-plan-details-by-treatment-plan-id`, { params: { treatmentPlanRequestId: treatmentPlanRequestId } })
};

export const deleteTreatmentPlanDetails = async (treatmentPlanId) => {
    return axios.put(`${rootApiUrl}/api/v0.2/cosmetic-treatment/delete-treatment-plan-details-by-id`, null, { params: { id: treatmentPlanId } })
};

export const deleteSkinTreatmentList = async (skinTreatmentData) => {
    return axios.put(`${rootApiUrl}/api/v0.2/skin-treatment/delete-skin-treatment`, skinTreatmentData)
};