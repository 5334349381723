import axios from "axios";
import { rootApiUrl } from "../environment/Environment";


export const getBddFormRefData = async (inviteCode) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/bdd/get-bdd-form-ref-data/${inviteCode}`);
};

export const saveBddFormData = async (formData) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/bdd/save-bdd-form`, formData);
};

export const getBddFormDataByFormId = async (formId) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/bdd/get-bdd-form-data/${formId}`);
};

export const sendBddForm = async (formData) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/bdd/send-bdd-form`, formData);
};

export const sendBddFormAssessment = async (formData) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/cosmetic-treatment/send-bdd-form-assessment`, formData);
};

export const saveBddFormDataDirect = async (formData) => {
    return await axios.post(`${rootApiUrl}/save-bdd-form-direct`, formData);
};