import axios from "axios";
import { rootApiUrl } from "../environment/Environment";


export const inviteUser = async (inviteDetails) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/admin/invite`, inviteDetails);
};

export const getOrgList = async () => {
    return await axios.get(`${rootApiUrl}/api/v0.2/admin/org-list`);
};

export const getClinicList = async () => {
    return await axios.get(`${rootApiUrl}/api/v0.2/clinics/clinic-list`);
};

export const userRegisterBySuperAdmin = async (userData) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/admin/register`, userData);
};

export const userRegisterByAdmin = async (userData) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/clinics/register-org-user`, userData);
};

export const getUsersList = async (payload) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/users/get-all`, { params: { orgId: payload.orgId, clinicId: payload.clinicId } });
};

export const activeOrDeActiveUser = async (userId) => {
    return await axios.put(`${rootApiUrl}/api/v0.2/users/toggle-user-active-status/${userId}`);
};

export const updateInjector = async (injectorDetails) => {
    return await axios.post(`${rootApiUrl}/save-or-update-injector-details`, injectorDetails);
};

export const updateUser = async (userDetails) => {
    return await axios.put(`${rootApiUrl}/api/v0.2/users/update-user`, userDetails);
};