import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { deleteSkinTreatmentProduct, getSkinTreatmentPriceListByClinicId } from '../../../services/ProductPriceManagment';
import { connect } from 'react-redux';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import AddSkinTreatmentPrice from '../../dialog/AddSkinTreatmentPrice';
import Snackbar from '../../toastrmessage/Snackbar';
import Navbar from '../../navbar/Navbar';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';


var allTreatmentData;

function SkinTreatmentPriceManagement(props) {

    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [isLoading, setisLoading] = useState(true);
    const [treatmentList, setTreatmentList] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [openAddSkinTreatmentPrice, setOpenAddSkinTreatmentPrice] = useState({ "action": false, "priceData": null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    localStorage.setItem("menuName", "Skin Treatment");

    useEffect(() => {
        getSkinTreatmentPriceData()
    }, []);

    const getSkinTreatmentPriceData = async () => {
        await getSkinTreatmentPriceListByClinicId(clinicDetails.clinicId)
            .then((resp) => {
                allTreatmentData = resp.data.data
                setTreatmentList(resp.data.data)
                setisLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const addPrice = () => {
        setOpenAddSkinTreatmentPrice({ "action": true, "priceData": null })
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const editPrice = (rowData) => {
        setOpenAddSkinTreatmentPrice({ "action": true, "priceData": rowData })
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const filterByPrice = (value) => {
        setPage(0);
        const filterData = treatmentList.filter((ele) => {
            return ele.treatmentName
                .toString()
                .toLowerCase()
                .trim()
                .includes(value.toString().toLowerCase().trim())
        })
        if (value.trim().toString().length < 1) {
            setTreatmentList(allTreatmentData)
        } else {
            setTreatmentList(filterData)
        }
    };

    const deleteProduct = async (rowData) => {
        await deleteSkinTreatmentProduct(rowData)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                getSkinTreatmentPriceData()
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <div className='row'>
                    <div className="col-lg-6">
                        <span>
                            <material.TextField
                                fullWidth
                                variant="standard"
                                label="Filter by Treatment Name"
                                type='text'
                                onChange={(e) => filterByPrice(e.target.value)}
                                sx={{ width: "40ch" }}
                            />
                        </span>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <span className='float-end'>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addPrice}> Add-Price</material.Button>
                        </span>
                    </div>
                </div>
                <div className='mt-3'>
                    <material.Paper sx={{ width: "100%", overflow: 'hidden', mt: 3 }}>
                        <material.TableContainer sx={{ maxHeight: 460 }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell>Treatment Name</StyledTableCell>
                                        <StyledTableCell>Treatment Area</StyledTableCell>
                                        <StyledTableCell>Number of Session</StyledTableCell>
                                        <StyledTableCell>Price</StyledTableCell>
                                        <StyledTableCell>Action</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={10}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={10} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {treatmentList?.length ? treatmentList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                <material.TableRow
                                                    key={i}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': { border: 0 },
                                                    }}
                                                >
                                                    <material.TableCell sx={{ pt: 2, pb: 2 }} size='small'>{row.treatmentName}</material.TableCell>
                                                    <material.TableCell size='small'>{row.treatmentArea}</material.TableCell>
                                                    <material.TableCell size='small'>{row.numberOfSessions}</material.TableCell>
                                                    <material.TableCell size='small'>{row.price}</material.TableCell>
                                                    <material.TableCell size='small'>
                                                        <material.IconButton title='Edit Price' aria-label="create" size="large" onClick={() => editPrice(row)}>
                                                            <material.CreateIcon color='primary' />
                                                        </material.IconButton>
                                                        <material.IconButton title="Delete Product" aria-label="create" size="large" onClick={() => deleteProduct(row)}>
                                                            <material.DeleteIcon color='warning' />
                                                        </material.IconButton>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={10}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>
                                    )}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                        <hr />
                        <material.TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={treatmentList?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </material.Paper>
                </div>
            </div>
            <AddSkinTreatmentPrice
                getSkinTreatmentPriceData={getSkinTreatmentPriceData}
                openAddSkinTreatmentPrice={openAddSkinTreatmentPrice}
                setOpenAddSkinTreatmentPrice={setOpenAddSkinTreatmentPrice}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(SkinTreatmentPriceManagement);