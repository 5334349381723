import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { material } from '../../../library/material';
import { Controller, useForm } from 'react-hook-form';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import { getClinicTimeZone, saveClinicTimeZone } from '../../../services/ClinicService';
import Snackbar from '../../toastrmessage/Snackbar';

function TimeZone(props) {

    const { userDetails, clinicDetails } = props;
    const [openAddTimeZone, setOpenAddTimeZone] = useState({ action: false, timeZoneData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [getTimeZoneData, setTimeZoneData] = useState(null);

    useEffect(() => {
        getClinicTimeZoneData()
    }, []);

    const getClinicTimeZoneData = () => {
        const payload = {
            orgId: userDetails.orgId,
            clinicId: clinicDetails.clinicId
        }
        getClinicTimeZone(payload)
            .then((resp) => {
                setTimeZoneData(resp.data.data)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const addTimeZone = () => {
        setOpenAddTimeZone({ action: true, timeZoneData: null });
    };

    const editTimeZone = (timeZoneData) => {
        setOpenAddTimeZone({ action: true, timeZoneData: timeZoneData });
    }

    return (
        <div>
            <material.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <span className="float-end" style={{ marginTop: -40 }}>
                    <material.Button
                        variant="contained"
                        size="small"
                        sx={{ textTransform: "none" }}
                        onClick={addTimeZone}
                        disabled={getTimeZoneData}
                    >
                        Add Time Zone
                    </material.Button>
                </span>
            </material.Grid>
            <material.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <material.TableContainer>
                    <material.Table>
                        <material.TableHead>
                            <material.TableRow>
                                <StyledTableCell align='center'>Time Zone Name</StyledTableCell>
                            </material.TableRow>
                        </material.TableHead>
                        <material.TableBody>
                            <material.TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } }}
                                onClick={() => editTimeZone(getTimeZoneData)}
                            >
                                <material.TableCell align='center'>{getTimeZoneData?.timeZone}</material.TableCell>
                            </material.TableRow>
                        </material.TableBody>
                    </material.Table>
                </material.TableContainer>
            </material.Grid>
            <AddTimeZoneDialog
                openAddTimeZone={openAddTimeZone}
                setOpenAddTimeZone={setOpenAddTimeZone}
                userDetails={userDetails}
                clinicDetails={clinicDetails}
                getClinicTimeZoneData={getClinicTimeZoneData}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
};

const AddTimeZoneDialog = (props) => {

    const { openAddTimeZone, setOpenAddTimeZone, clinicDetails, userDetails, getClinicTimeZoneData } = props;
    const { handleSubmit, reset, control, setValue, watch, getValues, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (openAddTimeZone.action) {
            reset(openAddTimeZone.timeZoneData)
        }
    }, [openAddTimeZone.action])

    const cancel = () => {
        setOpenAddTimeZone({ action: false, timeZoneData: null });
    };

    const saveTimeZone = (formData) => {
        const payload = {
            "clinicId": clinicDetails.clinicId,
            "orgId": userDetails.orgId,
            ...formData
        }
        saveClinicTimeZone(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                getClinicTimeZoneData();
                reset({ timeZone: "" });
                setOpenAddTimeZone({ action: false, timeZoneData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="sm" open={openAddTimeZone.action}>
                <material.DialogTitle>Add Time Zone</material.DialogTitle>
                <material.DialogContent>
                    <Controller
                        name="timeZone"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field }) => (
                            <material.Autocomplete
                                {...field}
                                options={moment.tz.names()}
                                renderInput={(params) => (
                                    <material.TextField
                                        required
                                        margin="dense"
                                        {...params}
                                        variant="standard"
                                        label="Select Time Zone"
                                        fullWidth
                                    />
                                )}
                                onChange={(event, data) => {
                                    field.onChange(data);
                                }}
                            />
                        )}
                    />
                </material.DialogContent>
                <material.DialogActions>
                    <span>
                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size="medium" color='error' startIcon={<material.CloseIcon />} onClick={cancel}>Cancel</material.Button>
                        <material.Button variant="contained" sx={{ textTransform: "none", }} size="medium" color='success' startIcon={<material.DoneIcon />} onClick={handleSubmit(saveTimeZone)}>Save</material.Button>
                    </span>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
};

export default TimeZone;