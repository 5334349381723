import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { connect } from 'react-redux';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import AddEmailConfig from '../../dialog/AddEmailConfig';
import { getEmailText } from '../../../services/EmailConfigService';
import Navbar from '../../navbar/Navbar';
import Snackbar from '../../toastrmessage/Snackbar';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';


function EmailConfig(props) {

    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [isLoading, setisLoading] = useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [emailConfigData, setEmailConfigData] = useState([]);
    const [open, setOpen] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });

    localStorage.setItem("menuName", "Email Configure");

    useEffect(() => {
        getAllEmailTextData()
    }, []);

    const getAllEmailTextData = async () => {
        await getEmailText(userDetails.orgId, clinicDetails.clinicId)
            .then((resp) => {
                setEmailConfigData(resp.data.data)
                setisLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const addEmailConfig = () => {
        setOpen(true);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <div className='row'>
                    <div className='col-12'>
                        <span className="float-end">
                            <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={addEmailConfig}>Add Email Config</material.Button>
                        </span>
                    </div>
                </div>
                <material.Paper elevation={3} sx={{ pb: 3, pl: 3, pr: 3, mt: 3 }}>
                    <div className="row">
                        <div className="col-12 mt-3">
                            <material.TableContainer sx={{ maxHeight: 500 }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead >
                                        <material.TableRow>
                                            <StyledTableCell>Content</StyledTableCell>
                                            <StyledTableCell>Purpose</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {isLoading ? (
                                            <material.TableRow >
                                                <material.TableCell colSpan={2}>
                                                    <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                        <Skeleton count={2} />
                                                    </SkeletonTheme>
                                                </material.TableCell>
                                            </material.TableRow>
                                        ) : (
                                            <>
                                                {emailConfigData.length ? emailConfigData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                    <material.TableRow
                                                        key={i}
                                                    >
                                                        <material.TableCell sx={{ pt: 2, pb: 2 }} size='small'>{row.content}</material.TableCell>
                                                        <material.TableCell size='small'>{row.purpose}</material.TableCell>
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={2}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </>
                                        )}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                            <material.TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                component="div"
                                count={emailConfigData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                </material.Paper>
            </div>
            <AddEmailConfig
                open={open}
                setOpen={setOpen}
                getAllEmailTextData={getAllEmailTextData}
            />
            <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(EmailConfig) 