import React, { useState } from 'react';
import { material } from '../../library/material';
import { useForm } from 'react-hook-form';
import { addPatientHistory } from '../../services/PatientService';
import moment from 'moment';
import Snackbar from '../toastrmessage/Snackbar';
import { connect } from 'react-redux';

function AddNotes(props) {

    const { openAddNotes, setOpenAddNotes, patientData, getInjectorNotesByPatientId, getDermalTherapistsNotesByPatientId, userData } = props;
    let userDetails = userData.authReducer.data;
    const { register, handleSubmit, resetField, reset, formState: { errors, isValid }, setValue } = useForm({
        mode: "onTouched",
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const cancel = () => {
        reset({ "notes": "" })
        setOpenAddNotes({ action: false, callFrom: null })
    };
    const save = async (data) => {
        if (openAddNotes.callFrom === "DermalTherapistsNotes") {
            let obj = {
                typeOfNotes: "DERMAL_THERAPISTS_NOTES",
                patientId: patientData.patientId,
                dateOfEntry: moment().format("YYYY-MM-DDTHH:mm:ss"),
                user: userDetails.name,
                history: { ...data, name: userDetails.name, userId: userDetails.userId }
            }
            await addPatientHistory(obj)
                .then((resp) => {
                    setOpenAddNotes({ action: false, callFrom: null })
                    reset({ "notes": "" })
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data.messages,
                    })
                    getDermalTherapistsNotesByPatientId()
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.data.info,
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.messages,
                        })
                    }
                })
        } else if (openAddNotes.callFrom === "InjectorNotes") {
            let obj = {
                typeOfNotes: "INJECTOR_NOTES",
                patientId: patientData.patientId,
                dateOfEntry: moment().format("YYYY-MM-DDTHH:mm:ss"),
                user: userDetails.name,
                history: { ...data, name: userDetails.name, userId: userDetails.userId }
            }
            await addPatientHistory(obj)
                .then((resp) => {
                    setOpenAddNotes({ action: false, callFrom: null })
                    reset({ "notes": "" })
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data.messages,
                    })
                    getInjectorNotesByPatientId()
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.data.info,
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.messages,
                        })
                    }
                })
        }
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="md" open={openAddNotes.action} hideBackdrop >
                <material.DialogTitle>Add Notes</material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <material.TextField
                            id="standard-error"
                            variant="standard"
                            type="text"
                            label="Notes"
                            size="small"
                            multiline
                            fullWidth
                            {...register("notes")}
                        />
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <span className='float-end'>
                        <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" className='me-2' color='error' onClick={cancel}>
                            Cancel
                        </material.Button>
                        <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={handleSubmit(save)} disabled={!isValid}>
                            Save
                        </material.Button>
                    </span>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(AddNotes);