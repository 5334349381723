import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { useForm } from 'react-hook-form';
import { changePlanStatus } from '../../services/PrescriberService';
import Snackbar from '../toastrmessage/Snackbar';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PreSelectNotes from './PreSelectNotes';

function StatusChangeReasonDialog(props) {
    const navigate = useNavigate();
    const { openStatusChangeDialog, setOpenStatusChangeDialog, getTreatmentPlan, pendingTreatmantRequest, userData, getStatusChangeData } = props;
    let userDetails = userData.authReducer.data;
    const { register, handleSubmit, reset, setValue, getValues, watch, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [hideShow, setHideShow] = useState(true);
    const [openPreSelectNote, setOpenPreSelectNote] = useState({ action: false, treatmentData: null });
    const [selectNotes, setSelectNotes] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const onChangeNotes = watch('reason');

    useEffect(() => {
        getReasonValue();
    }, [onChangeNotes]);

    const getReasonValue = () => {
        let val1 = getValues('reason');
        setSelectNotes(val1)
    };

    useEffect(() => {
        if (openStatusChangeDialog.action) {
            // getPreWrittenNotes()
        }
    }, [openStatusChangeDialog.action === true]);

    const addPreWrittenNote = () => {
        setOpenPreSelectNote({ action: true, treatmentData: openStatusChangeDialog.data.action === "approve" ? openStatusChangeDialog?.data.treatmentPlan : openStatusChangeDialog?.data.treatmentPlanData })
    };

    const getNoteData = (data) => {
        let notesString = "";
        data?.map((ele) => {
            notesString = notesString + '\n' + ele.treatmentName + "__" + ele.treatmentArea + " :- " + " " + ele.notes + '\n \n \n';
        })
        if (selectNotes !== "" && data !== null) {
            setSelectNotes(selectNotes + '\n' + notesString + '\n')
            setValue("reason", selectNotes + '\n' + notesString + '\n');
        } else if (selectNotes === "") {
            setSelectNotes(notesString + '\n')
            setValue("reason", notesString + '\n');
        };
    };

    const changeStatus = async (data) => {
        reset({ "reason": "", treatmentNote: "" })
        setHideShow(true)
        if (openStatusChangeDialog.data.action === "delete") {
            let obj = {
                "treatmentPlanRequestId": openStatusChangeDialog.data.treatmentPlanRequestId,
                "status": "REJECTED",
                "reason": data.reason
            }
            await changePlanStatus(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data.messages,
                    })
                    setOpenStatusChangeDialog({ action: false, data: {} });
                    // if (role === "INJECTOR" && openStatusChangeDialog.data.parentComponent === "approvalWaiting") {
                    //     getTreatmentPlan();
                    // } else if (role === "PRESCRIBER" && openStatusChangeDialog.data.component === "waitingRoom") {
                    //     pendingTreatmantRequest();
                    // };
                    // if (role === "INJECTOR") {
                    //     navigate("/treatment-queue")
                    // } else if (role === "PRESCRIBER") {
                    //     navigate("/waiting-list")
                    // };
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.data.info,
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.messages,
                        })
                    }
                })
        } else if (openStatusChangeDialog.data.action === "approve") {
            let obj = {
                "treatmentPlanRequestId": openStatusChangeDialog.data.treatmentPlanRequestId,
                "status": "APPROVED",
                "reason": data.reason
            }
            await changePlanStatus(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data.messages,
                    })
                    setOpenStatusChangeDialog({ action: false, data: {} })
                    // if (role === "PRESCRIBER" && openStatusChangeDialog.data.component === "waitingRoom") {
                    //     pendingTreatmantRequest()
                    // }
                    navigate("/waiting-list")
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.data.info,
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.messages,
                        })
                    }
                })
        } else if (openStatusChangeDialog.data.action === "pending") {
            let obj = {
                "treatmentPlanRequestId": openStatusChangeDialog.data.treatmentPlanRequestId,
                "status": "PENDING",
                "reason": data.reason
            }
            await changePlanStatus(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data.messages,
                    })
                    setOpenStatusChangeDialog({ action: false, data: {} })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.data.info,
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.messages,
                        })
                    }
                })
        } else if (openStatusChangeDialog.data.action === "cancel") {
            let obj = {
                "treatmentPlanRequestId": openStatusChangeDialog.data.treatmentPlanRequestId,
                "status": "CANCEL",
                "reason": data.reason
            }
            await changePlanStatus(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data.messages,
                    })
                    setOpenStatusChangeDialog({ action: false, data: {} })
                    if (openStatusChangeDialog.data.parentComponent === "approvalWaiting") {
                        getTreatmentPlan()
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.data.info,
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.messages,
                        })
                    }
                })
        } else if (openStatusChangeDialog.data.action === "used") {
            let obj = {
                "treatmentPlanRequestId": openStatusChangeDialog.data.treatmentPlanRequestId,
                "status": "USED",
                "reason": data.reason
            }
            await changePlanStatus(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data.messages,
                    })
                    setOpenStatusChangeDialog({ action: false, data: {} })
                    if (openStatusChangeDialog.data.parentComponent === "approvalWaiting") {
                        getTreatmentPlan()
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.data.info,
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.messages,
                        })
                    }
                })
        } else if (openStatusChangeDialog.data.parentComponent === "treatmentPlan") {
            getStatusChangeData(data.reason);
            reset({ "reason": "", treatmentNote: "" });
            setOpenStatusChangeDialog({ action: false, data: {} });
        };
    };

    const close = () => {
        setOpenStatusChangeDialog({ action: false, data: {} });
        reset({ "reason": "", treatmentNote: "" });
        setHideShow(true);
        setSelectNotes("");
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="lg" open={openStatusChangeDialog.action} hideBackdrop >
                <material.DialogTitle>{openStatusChangeDialog.data.action === "used" ? "Add Treatment Notes" : openStatusChangeDialog.data.action === "approve" ? "Add Prescriber Notes" : "Add Notes"}
                    <material.Fab sx={{ ml: 2 }} color="primary" size='small' aria-label="add" title='Add Notes' onClick={addPreWrittenNote}>
                        <material.AddIcon />
                    </material.Fab>
                </material.DialogTitle>
                <material.DialogContent>
                    <hr />
                    <div className='row'>
                        <div className="col-lg-12 col-md-12 col-sm-12 mb-2 mt-2">
                            <material.TextField
                                id="standard-error"
                                label={openStatusChangeDialog.data.action === "used" ? "Treatment Notes" : (openStatusChangeDialog.data.action === "approve" ? "Prescriber Notes" : "Reason")}
                                variant="standard"
                                type="text"
                                size="small"
                                multiline
                                fullWidth
                                {...register("reason", { required: true, minLength: 5 })}
                                error={errors.reason?.type === "required"}
                                rows={10}
                                InputLabelProps={{ shrink: true }}
                            />
                            <p className='form-text text-danger'>{errors.reason?.type === "required" && 'This field is required'}</p>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <span className='float-end'>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" className='me-2' color='error' onClick={close}>
                                Cancel
                            </material.Button>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={handleSubmit(changeStatus)}>
                                Submit
                            </material.Button>
                        </span>
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <PreSelectNotes
                openPreSelectNote={openPreSelectNote}
                setOpenPreSelectNote={setOpenPreSelectNote}
                userDetails={userDetails}
                getNoteData={getNoteData}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userData: state,
    };
};

export default connect(mapStateToProps)(StatusChangeReasonDialog);