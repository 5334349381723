import React, { useState } from 'react';
import { material } from '../../library/material';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { saveRoomsData } from '../../services/ConfigureService';
import Snackbar from '../toastrmessage/Snackbar';

function AddRoom(props) {

    const { openAddRoom, setOpenAddRoom, userData, clinicData, getRoomListByClinicId } = props;

    const { register, handleSubmit, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    let userDetails = userData.authReducer.data;
    let clinicDetails = clinicData.clinicReducer.data;
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const save = async (data) => {
        let obj = {
            ...data,
            clinicId: clinicDetails.clinicId
        }
        await saveRoomsData(obj)
            .then((res) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": res.data.messages,
                })
                setOpenAddRoom(false)
                getRoomListByClinicId()
                reset({ "roomName": "", "roomNumber": "", "roomDescription": "" })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const handleClose = () => {
        reset({ "roomName": "", "roomNumber": "", "roomDescription": "" })
        setOpenAddRoom(false)
    };

    return (
        <div>
            <material.Dialog maxWidth="sm" fullWidth hideBackdrop open={openAddRoom}>
                <material.DialogTitle>Add Room</material.DialogTitle>
                <material.DialogContent>
                    <div>
                        <material.TextField
                            error={errors.roomName?.type === "required"}
                            {...register("roomName", { required: true, minLength: 3 })}
                            margin="dense"
                            id="name"
                            label="Room Name"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                        <p className='form-text text-danger'>{errors.roomName?.type === "required" && 'This field is required'}</p>
                        {(errors?.roomName?.type === "minLength") && (
                            <p className='text-danger'>This field contain minimum 3 digits</p>
                        )}
                    </div>
                    <div>
                        <material.TextField
                            error={errors.roomNumber?.type === "required"}
                            {...register("roomNumber", { required: true })}
                            margin="dense"
                            id="name"
                            label="Room Number"
                            type="number"
                            fullWidth
                            variant="standard"
                        />
                        <p className='form-text text-danger'>{errors.roomNumber?.type === "required" && 'This field is required'}</p>
                    </div>
                    <div>
                        <material.TextField
                            error={errors.roomDescription?.type === "required"}
                            {...register("roomDescription", { required: true, minLength: 3 })}
                            margin="dense"
                            id="name"
                            label="Description"
                            type="text"
                            fullWidth
                            variant="standard"
                            multiline
                        />
                        <p className='form-text text-danger'>{errors.firstName?.type === "required" && 'This field is required'}</p>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button onClick={handleClose} variant="contained" sx={{ textTransform: "none" }} color="error">Cancel</material.Button>
                    <material.Button onClick={handleSubmit(save)} variant="contained" sx={{ textTransform: "none" }} disabled={!isValid}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(AddRoom);