import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { getAllSupplierList } from '../../../services/SupplierProductConfigService';
import { Controller, useForm } from 'react-hook-form';
import { getBlankSupplierForm, uploadSupplierForm } from '../../../services/SupplierConfigService';
import Snackbar from '../../toastrmessage/Snackbar';
import { rootApiUrl } from '../../../environment/Environment';

function AddOrEditSupplierForm(props) {

    const { openAddOrEditSupplierForm, setOpenAddOrEditSupplierForm, clinicDetails, userDetails, getSupplierFormData } = props;
    const { register, handleSubmit, reset, setValue, control, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [supplierName, setSupplierName] = useState([]);
    const [supplierDetails, setSupplierDetails] = useState([]);
    const [supplierId, setSupplierId] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [blankSupplierFormData, setBlankSupplierFormData] = useState(null);
    const [currentForm, setCurrentForm] = useState(null);
    const [currentSupportingDocs, setCurrentSupportingDocs] = useState(null);
    const [isSaveAsDraft, setIsSaveAsDraft] = useState(false);

    useEffect(() => {
        if (openAddOrEditSupplierForm.action) {
            getSupplierList()
        }
    }, [openAddOrEditSupplierForm.action])

    const getSupplierList = () => {
        getAllSupplierList()
            .then((resp) => {
                setSupplierDetails(resp.data.data);
                const supplierName = resp.data?.data?.map((supplier) => supplier.supplierName);
                setSupplierName(supplierName);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getBlankSupplierFormData = (supplierId) => {
        const payload = {
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId,
            supplierId: supplierId
        }
        getBlankSupplierForm(payload)
            .then((resp) => {
                setBlankSupplierFormData(resp.data.data);
                setCurrentForm(resp.data?.data?.formLink)
                setCurrentSupportingDocs(resp.data?.data?.supportingDocumentLinks);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const handleClose = () => {
        reset({ supplierName: "", form: "", supportingDocument: "" });
        setBlankSupplierFormData(null);
        setOpenAddOrEditSupplierForm({ action: false, rowData: null });
    };

    const saveSupplierForm = (data) => {
        const formData = new FormData();
        for (const file of data.form) {
            formData.append('form', file);
        }
        for (const file of data.supportingDocument) {
            formData.append('supportingDocs', file);
        }
        formData.append('requestId', blankSupplierFormData.requestId);
        formData.append('submit', true);
        uploadSupplierForm(formData)
            .then((resp) => {
                getSupplierFormData();
                reset({ supplierName: "", form: "", supportingDocument: "" });
                setBlankSupplierFormData(null);
                setOpenAddOrEditSupplierForm({ action: false, rowData: null });
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const saveAsDraftSupplierForm = (data) => {
        const formData = new FormData();
        for (const file of data.form) {
            formData.append('form', file);
        }
        for (const file of data.supportingDocument) {
            formData.append('supportingDocs', file);
        }
        formData.append('requestId', blankSupplierFormData.requestId);
        formData.append('submit', false);
        uploadSupplierForm(formData)
            .then((resp) => {
                reset({ supplierName: "", form: "", supportingDocument: "" });
                setBlankSupplierFormData(null);
                setOpenAddOrEditSupplierForm({ action: false, rowData: null });
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const validateFile = (files) => {
        if (files && files.length > 0 && typeof (files) == 'object') {
            for (let file of files) {
                if (file.type !== "application/pdf") {
                    return `Invalid file type: ${file.type}. Only PDF file allowed.`;
                }
            }
        }
        return true;
    };

    const downloadForm = (downloadLink) => {
        window.open(`${rootApiUrl}/agl-link?key=${downloadLink}`, "_blank")
    };

    const fieldValidation = (field) => {
        return isSaveAsDraft ? {} : { required: (currentForm && currentSupportingDocs) == null ? `${field} is required` : false };
    };

    return (
        <div>
            <material.Dialog open={openAddOrEditSupplierForm.action} fullWidth maxWidth="sm">
                <material.DialogTitle>{openAddOrEditSupplierForm.rowData === null ? "Add Supplier Form" : "Edit Supplier Form"}</material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                        <Controller
                            name="supplierName"
                            control={control}
                            rules={fieldValidation("Supplier Name")}
                            defaultValue=""
                            render={({ field }) => (
                                <material.Autocomplete
                                    {...field}
                                    value={field.value}
                                    options={supplierName}
                                    renderInput={(params) => (
                                        <material.TextField
                                            required
                                            margin="dense"
                                            {...params}
                                            variant="standard"
                                            label="Supplier Name"
                                            fullWidth
                                            error={!!errors.supplierName}
                                            helperText={errors.supplierName?.message}
                                        />
                                    )}
                                    onChange={(event, data) => {
                                        field.onChange(data);
                                        const id = supplierDetails?.filter((supplier) => supplier.supplierName == data).map((x) => x.supplierId)[0];
                                        getBlankSupplierFormData(id)
                                        setSupplierId(id);
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className='row' hidden={blankSupplierFormData == null}>
                        <span className='mt-3'>
                            <material.Link component="button" variant="body2" title='Click to download' onClick={() => downloadForm(blankSupplierFormData.supplierSpecificForm)}>{blankSupplierFormData?.supplierSpecificForm.split("/").pop()}</material.Link>
                        </span>
                        <span className='mt-3'>
                            <material.InputLabel>Form</material.InputLabel>
                            <input
                                type="file"
                                {...register("form", fieldValidation("Form"), {
                                    validate: validateFile,
                                })}
                            />
                            {currentForm && (
                                <p style={{ marginTop: "5px" }}>
                                    Current file: <strong>{currentForm.split("/").pop()}</strong>
                                </p>
                            )}
                            {errors.form && (
                                <p style={{ color: "red" }}>{errors.form.message}</p>
                            )}
                        </span>
                        <span className='mt-3'>
                            <material.InputLabel>Supporting Document</material.InputLabel>
                            <input
                                type="file"
                                multiple
                                {...register("supportingDocument", fieldValidation("Supporting Document"), {
                                    validate: validateFile,
                                })}
                            />
                            {currentSupportingDocs &&
                                currentSupportingDocs.map((ele, k) => (
                                    <p style={{ marginTop: "5px" }} key={k}>
                                        Current file: <strong>{ele.split("/").pop()}</strong>
                                    </p>
                                ))}
                            {errors.supportingDocument && (
                                <p style={{ color: "red" }}>{errors.supportingDocument.message}</p>
                            )}
                        </span>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} color='error' startIcon={<material.CloseIcon />} onClick={handleClose}>Cancel</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} color='success' startIcon={<material.SaveAsIcon />}
                        onClick={() => { setIsSaveAsDraft(true); handleSubmit(saveAsDraftSupplierForm)() }}>Save as Draft</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} color='success' startIcon={<material.Save />} onClick={() => { setIsSaveAsDraft(false); handleSubmit(saveSupplierForm)() }}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default AddOrEditSupplierForm;