import React, { useState, useEffect } from 'react';
import { material } from '../../../../library/material';
import { useForm } from 'react-hook-form';
import Snackbar from '../../../toastrmessage/Snackbar';
import { saveOrUpdateCashFloatData } from '../../../../services/ClinicConfigService';

function AddCashFloat(props) {

    const { openAddCashFloatDialog, setOpenAddCashFloatDialog, userDetails, clinicDetails, getCashFloatData } = props;
    const { register, handleSubmit, setValue, getValues, control, watch, reset, formState: { isValid }, } = useForm({ mode: "onTouched" });
    const [buttonDisable, setButtonDisable] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });

    const coins = watch([
        "coins_5_cents",
        "coins_10_cents",
        "coins_50_cents",
        "coins_1_dollar",
        "coins_2_dollar",
    ]);
    const notes = watch([
        "notes_5_dollar",
        "notes_10_dollar",
        "notes_20_dollar",
        "notes_50_dollar",
        "notes_100_dollar",
    ]);

    useEffect(() => {
        calculateSums()
    }, [coins, notes]);

    const calculateSums = () => {
        const sumOfCoins =
            Number(coins[0] || 0) * 0.05 +
            Number(coins[1] || 0) * 0.1 +
            Number(coins[2] || 0) * 0.5 +
            Number(coins[3] || 0) * 1 +
            Number(coins[4] || 0) * 2;

        const sumOfNotes =
            Number(notes[0] || 0) * 5 +
            Number(notes[1] || 0) * 10 +
            Number(notes[2] || 0) * 20 +
            Number(notes[3] || 0) * 50 +
            Number(notes[4] || 0) * 100;
        setValue("totalAmount", sumOfCoins + sumOfNotes);
    };

    useEffect(() => {
        if (openAddCashFloatDialog.action) {
            reset({
                coins_5_cents: 0,
                coins_10_cents: 0,
                coins_50_cents: 0,
                coins_1_dollar: 0,
                coins_2_dollar: 0,
                notes_5_dollar: 0,
                notes_10_dollar: 0,
                notes_20_dollar: 0,
                notes_50_dollar: 0,
                notes_100_dollar: 0,
                totalAmount: 0
            })
        }
    }, [openAddCashFloatDialog.action]);

    const handleClose = () => {
        reset();
        setOpenAddCashFloatDialog({ action: false, rowData: null });
    };

    const save = (formData) => {
        const numericData = Object.fromEntries(
            Object.entries(formData).map(([key, value]) => [key, Number(value)])
        );
        const payload = {
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId,
            ...numericData
        }
        saveOrUpdateCashFloatData(payload)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data.messages,
                });
                getCashFloatData();
                reset();
                setOpenAddCashFloatDialog({ action: false, rowData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openAddCashFloatDialog.action} fullWidth maxWidth="md">
                <material.DialogTitle>Add Cash Float</material.DialogTitle>
                <material.DialogContent>
                    <material.Card
                        style={{
                            borderWidth: "2px",
                            borderStyle: "solid",
                            height: "150px",
                            marginTop: "8px",
                        }}
                    >
                        <div className="row m-2">
                            <material.Typography className="fs-5 fw-bold">
                                COINS
                            </material.Typography>
                            <div className="col-2">
                                <material.TextField
                                    {...register("coins_5_cents", { required: true })}
                                    type="number"
                                    id="coins_5_cents"
                                    label="5 cents"
                                    variant="standard"
                                    fullWidth
                                    required
                                />
                            </div>
                            <div className="col-2">
                                <material.TextField
                                    {...register("coins_10_cents", { required: true })}
                                    type="number"
                                    id="coins_10_cents"
                                    label="10 cents"
                                    variant="standard"
                                    fullWidth
                                    required
                                />
                            </div>
                            <div className="col-2">
                                <material.TextField
                                    {...register("coins_50_cents", { required: true })}
                                    type="number"
                                    id="coins_50_cents"
                                    label="50 cents"
                                    variant="standard"
                                    fullWidth
                                    required
                                />
                            </div>
                            <div className="col-2">
                                <material.TextField
                                    {...register("coins_1_dollar", { required: true })}
                                    type="number"
                                    id="coins_1_dollar"
                                    label="1 dollar"
                                    variant="standard"
                                    fullWidth
                                    required
                                />
                            </div>
                            <div className="col-2">
                                <material.TextField
                                    {...register("coins_2_dollar", { required: true })}
                                    type="number"
                                    id="coins_2_dollar"
                                    label="2 dollar"
                                    variant="standard"
                                    fullWidth
                                    required
                                />
                            </div>
                        </div>
                    </material.Card>
                    <material.Card
                        style={{
                            borderWidth: "2px",
                            borderStyle: "solid",
                            height: "150px",
                            marginTop: "8px",
                        }}
                    >
                        <div className="row m-2">
                            <material.Typography className="fs-5 fw-bold">
                                Notes
                            </material.Typography>
                            <div className="col-2">
                                <material.TextField
                                    {...register("notes_5_dollar", { required: true })}
                                    type="number"
                                    id="notes_5_dollar"
                                    label="5 dollar"
                                    variant="standard"
                                    fullWidth
                                    required
                                />
                            </div>
                            <div className="col-2">
                                <material.TextField
                                    {...register("notes_10_dollar", { required: true })}
                                    type="number"
                                    id="notes_10_dollar"
                                    label="10 dollar"
                                    variant="standard"
                                    fullWidth
                                    required
                                />
                            </div>
                            <div className="col-2">
                                <material.TextField
                                    {...register("notes_20_dollar", { required: true })}
                                    type="number"
                                    id="notes_20_dollar"
                                    label="20 dollar"
                                    variant="standard"
                                    fullWidth
                                    required
                                />
                            </div>
                            <div className="col-2">
                                <material.TextField
                                    {...register("notes_50_dollar", { required: true })}
                                    type="number"
                                    id="notes_50_dollar"
                                    label="50 dollar"
                                    variant="standard"
                                    fullWidth
                                    required
                                />
                            </div>
                            <div className="col-2">
                                <material.TextField
                                    {...register("notes_100_dollar", { required: true })}
                                    type="number"
                                    id="notes_100_dollar"
                                    label="100 dollar"
                                    variant="standard"
                                    fullWidth
                                    required
                                />
                            </div>
                        </div>
                    </material.Card>
                    <material.TextField
                        {...register("totalAmount", { required: true })}
                        type="number"
                        id="totalAmount"
                        label="Total Amount"
                        variant="standard"
                        fullWidth
                        InputProps={{ readOnly: true }}
                        InputLabelProps={{ shrink: true }}
                        required
                        sx={{ width: "30ch", mt: 2 }}
                    />
                </material.DialogContent>
                <material.DialogActions sx={{ m: 2 }}>
                    <material.Button onClick={handleClose} variant="contained" color="error" sx={{ textTransform: "none", mr: 1 }} startIcon={<material.CloseIcon />}> Close </material.Button>
                    <material.Button onClick={handleSubmit(save)} disabled={buttonDisable} variant="contained" color="success" sx={{ textTransform: "none", mr: 1 }} startIcon={<material.Save />}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
        </div>
    );
}

export default AddCashFloat;