import axios from "axios";
import { rootApiUrl } from "../environment/Environment";

export const saveUpdatePlatformCrm = async (PlatformCrmData) => {
  return axios.post(`${rootApiUrl}/api/v0.2/admin/save-or-update-platform-crm-data`, PlatformCrmData);
};
export const getAllPlatformCrm = async () => {
  return await axios.get(`${rootApiUrl}/api/v0.2/admin/get-all-platform-crm-data`);
};
export const createHtmlTemplate = async (templateData) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/admin/save-crm-email-campaign-template`, templateData);
};
 
export const getAllHtmlTemplate = async (payload) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/admin/get-all-crm-template-html`, { params: { ...payload } })
}; 

export const updateHtmlTemplate = async (payload) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/admin/update-crm-existing-template`, payload)
};

// /update-crm-existing-template
 
export const uploadTemplateForCampaign = async (templateData) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/admin//upload-crm-template-for-campaign`, templateData);
};

// get-crm-template-list

export const getCrmTemplateList = async (payload) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/admin/get-crm-template-list`, { params: { ...payload } });
};

// /get-all-crm-custom-campaign-list
export const getAllCustomCampaignList = async (payload) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/admin/get-all-crm-custom-campaign-list`, { params: { ...payload } });
}; 

export const startCrmCampaign = async (campaignData) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/admin/crm-campaign-data`, campaignData);
};

// /get-crm-campaign-names
export const getCampaignNameByClinicId = async (orgId) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/admin/get-crm-campaign-names`, { params: { orgId: orgId } });
};

export const startSendingEmailByCampaignRefNo = async (campaignRefNo) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/admin/start-crm-sending-email-by-campaign-ref-no`, campaignRefNo);
};

export const getCampaignNameByCampaignRefNo = async (campaignRefNo) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/admin/get-crm-campaign-by-id`, { params: { campaignRefNo: campaignRefNo } });
};

//////////////////////////// CRM API /////////////

export const getAllCrmData = async (payload) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/admin/get-crm-data`, { params: { ...payload } });
};

export const saveCrmAllData = async (crmData) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/admin/save-crm-data`, crmData);
};

export const saveUpdateCrmAllData = async (data) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/admin/save-update-crm-data`, data);
};

export const getCrmEmailConfigurationData = async () => {
    return await axios.get(`${rootApiUrl}/api/v0.2/admin/get-crm-email-configuration-data`);
};
/////////////////////////////

export const getCrmEmailSentData = async () => {
    return await axios.get(`${rootApiUrl}/api/v0.2/admin/get-send-mail-data`);
};
export const getCrmEmailReceivedData = async () => {
    return await axios.get(`${rootApiUrl}/api/v0.2/admin/get-received-mail-data`);
};

export const getSendMailData = async (email) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/admin/get-email-wise-send-mail-data`, { params: { email: email } });
};
export const getReceiveMailData = async (email) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/admin/get-email-wise-receive-mail-data`, { params: { email: email } });
};

// /send-reply-with-attachment

export const sendReplyEmail = async (data) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/admin/send-reply-with-attachment`, data);
};

