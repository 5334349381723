import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import Navbar from '../../navbar/Navbar';
import { connect } from 'react-redux';
import Snackbar from '../../toastrmessage/Snackbar';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import { getCurrentOffers } from '../../../services/ConfigureService';
import { useTheme } from '@mui/material';

function ViewCurrentOffer(props) {

    const { userData, clinicData } = props;
    const clinicDetails = clinicData.clinicReducer.data;
    const userDetails = userData.authReducer.data;
    const theme = useTheme();
    const [getCurrentOfferData, setGetCurrentOfferData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });

    localStorage.setItem("menuName", "View Current Offers");

    useEffect(() => {
        getCurrentOffersData();
    }, []);

    const getCurrentOffersData = () => {
        const payload = {
            orgId: userDetails.orgId,
            clinicId: clinicDetails.clinicId
        }
        getCurrentOffers(payload)
            .then((resp) => {
                setGetCurrentOfferData(resp.data.data)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    return (
        <material.Grid spacing={2} className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 12
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 12
                },
            }}
        >
            <Navbar />
            <material.Paper elevation={3} sx={{ p: 3 }}>
                <div className="row">
                    <material.Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="col-12 mt-3">
                            <material.TableContainer>
                                <material.Table>
                                    <material.TableHead>
                                        <material.TableRow>
                                            <StyledTableCell>Treatment Name</StyledTableCell>
                                            <StyledTableCell>Amount</StyledTableCell>
                                            <StyledTableCell>Name</StyledTableCell>
                                            <StyledTableCell sx={{ width: "60%" }}>Description</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {getCurrentOfferData.length ? getCurrentOfferData.map((item, k) => (
                                            <material.TableRow key={k}>
                                                <material.TableCell>{item.forTreatment}</material.TableCell>
                                                <material.TableCell>{item.offerAmount}</material.TableCell>
                                                <material.TableCell>{item.offerName}</material.TableCell>
                                                <material.TableCell>{<div dangerouslySetInnerHTML={{ __html: item.offerDescription }} />}</material.TableCell>
                                            </material.TableRow>
                                        )) : (
                                            <material.TableRow>
                                                <material.TableCell colSpan={10}>
                                                    <h6 className="d-flex justify-content-center text-danger fw-bold">
                                                        No data found
                                                    </h6>
                                                </material.TableCell>
                                            </material.TableRow>
                                        )}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                        </div>
                    </material.Grid>
                </div>
            </material.Paper>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </material.Grid >
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state,
    };
};

export default connect(mapStateToProps)(ViewCurrentOffer);