import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { material } from '../../../library/material';
import { debounce } from 'lodash';
import { saveOrUpdatePackageDetails } from '../../../services/PackageConfigurationService';
import Snackbar from '../../toastrmessage/Snackbar';

const complementary = ["Yes", "No"];

function AddServicePackage(props) {

    const { openAddServicePackage, setOpenAddServicePackage, userDetails, clinicDetails, packageId, getSingleClinicPackages, setPackageId } = props;
    const { register, handleSubmit, resetField, control, watch, setValue, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: "service",
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });

    useEffect(() => {
        const calculateTotalCost = debounce(() => {
            fields.forEach((field, ind) => {
                const costPerSession = watch(`service.${ind}.perSessionCost`);
                const numberOfSessions = watch(`service.${ind}.numberOfSession_quantityOfProduct`);
                const discountPercentage = watch(`service.${ind}.discountPercentage`);
                const discount = watch(`service.${ind}.discount`);
                const totalCost = watch(`service.${ind}.total`);
                if (costPerSession && numberOfSessions) {
                    const totalCost = parseFloat(costPerSession) * parseInt(numberOfSessions);
                    setValue(`service.${ind}.total`, totalCost.toFixed(2));
                    console.log(totalCost)
                }
                if (totalCost && discountPercentage) {
                    const discount = parseFloat(totalCost) * parseInt(discountPercentage) / 100;
                    setValue(`service.${ind}.discount`, discount.toFixed(2));
                }
                if (discount && totalCost) {
                    const payableAmount = parseFloat(totalCost) - parseInt(discount)
                    setValue(`service.${ind}.totalPayableAmount`, payableAmount.toFixed(2));
                }
            });
        }, 300);

        calculateTotalCost();
        return () => {
            calculateTotalCost.cancel();
        };
    }, [
        fields,
        ...fields.map((field, ind) => watch(`service.${ind}.perSessionCost`)),
        ...fields.map((field, ind) => watch(`service.${ind}.numberOfSession_quantityOfProduct`)),
        ...fields.map((field, ind) => watch(`service.${ind}.total`)),
        ...fields.map((field, ind) => watch(`service.${ind}.discountPercentage`)),
        ...fields.map((field, ind) => watch(`service.${ind}.discount`)),
        ...fields.map((field, ind) => watch(`service.${ind}.totalPayableAmount`)),
    ]);

    useEffect(() => {
        if (openAddServicePackage.action) {
            append({ treatmentProductName: "", treatmentProductArea: "", numberOfSession_quantityOfProduct: "", perSessionCost: "", total: "", discountPercentage: "", discount: "", totalPayableAmount: "", isComplementary:"" })
        }
    }, [openAddServicePackage.action === true]);

    const addMultiple = () => {
        append({ treatmentProductName: "", treatmentProductArea: "", numberOfSession_quantityOfProduct: "", perSessionCost: "", total: "", discountPercentage: "", discount: "", totalPayableAmount: "", isComplementary:"" })
    };

    const saveService = (formData) => {
        const array = [];
        formData.service.forEach(ele => {
            const row = {
                clinicId: clinicDetails.clinicId,
                orgId: userDetails.orgId,
                treatment_product_service: "SERVICE",
                packageId: packageId,
                isComplementary: ele.isComplementary == "Yes" ? true : false,
                treatmentProductName: ele.treatmentProductName,
                treatmentProductArea: ele.treatmentProductArea,
                numberOfSession_quantityOfProduct: ele.numberOfSession_quantityOfProduct,
                perSessionCost: ele.perSessionCost,
                total: ele.total,
                discountPercentage: ele.discountPercentage,
                discount: ele.discount,
                totalPayableAmount: ele.totalPayableAmount
            }
            array.push(row)
        })
        saveOrUpdatePackageDetails(array)
            .then((resp) => {
                getSingleClinicPackages(resp.data.data.newAssignedId);
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data.messages,
                });
                remove();
                setOpenAddServicePackage({ action: false, data: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const cancel = () => {
        remove();
        setOpenAddServicePackage({ action: false, data: null });
    };

    return (
        <div>
            <material.Dialog open={openAddServicePackage.action} maxWidth="lg" fullWidth>
                <material.DialogTitle>Add Service Plan</material.DialogTitle>
                <material.DialogContent>
                    {fields.map((field, ind) => (
                        <div className="row border border-2 p-2 my-2 rounded-2 mt-3" key={field.id} style={{ boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" }}>
                            <div className="col-lg-4">
                                <Controller
                                    name={`service.${ind}.isComplementary`}
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <material.Autocomplete
                                            {...field}
                                            options={complementary}
                                            renderInput={(params) => (
                                                <material.TextField
                                                    required
                                                    margin="dense"
                                                    {...params}
                                                    variant="standard"
                                                    label="Is Complementary"
                                                    fullWidth
                                                />
                                            )}
                                            onChange={(event, data) => {
                                                field.onChange(data);
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-lg-4">
                                <material.TextField
                                    {...register(`service.${ind}.treatmentProductName`, { required: true })}
                                    required
                                    margin="dense"
                                    id="name"
                                    label="Service Name"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                            <div className="col-lg-4">
                                <material.TextField
                                    {...register(`service.${ind}.treatmentProductArea`)}
                                    margin="dense"
                                    id="name"
                                    label="Instruction"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                            <div className="col-lg-4">
                                <material.TextField
                                    {...register(`service.${ind}.numberOfSession_quantityOfProduct`, { required: true })}
                                    required
                                    margin="dense"
                                    id="name"
                                    label="Number of Session"
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                            <div className="col-lg-4">
                                <material.TextField
                                    {...register(`service.${ind}.perSessionCost`, { required: true })}
                                    required
                                    margin="dense"
                                    id="name"
                                    label="Per Session Cost"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                            <div className="col-lg-4">
                                <material.TextField
                                    {...register(`service.${ind}.total`, { required: true })}
                                    required
                                    margin="dense"
                                    id="name"
                                    label="Total Amount"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                />
                            </div>
                            <div className="col-lg-4">
                                <material.TextField
                                    {...register(`service.${ind}.discountPercentage`, { required: true })}
                                    required
                                    margin="dense"
                                    id="name"
                                    label="Discount Percentage"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                            <div className="col-lg-4">
                                <material.TextField
                                    {...register(`service.${ind}.discount`, { required: true })}
                                    required
                                    margin="dense"
                                    id="name"
                                    label="Discount"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                />
                            </div>
                            <div className="col-lg-4">
                                <material.TextField
                                    {...register(`service.${ind}.totalPayableAmount`, { required: true })}
                                    required
                                    margin="dense"
                                    id="name"
                                    label="Total Payable Amount"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                />
                            </div>
                            <div className="col-lg-4 mt-4">
                                <span>
                                    <material.Button sx={{ mr: 1, textTransform: "none" }} variant="contained" color='error' size="small" startIcon={<material.DeleteIcon />} onClick={() => remove(ind)}>Delete</material.Button>
                                </span>
                            </div>
                        </div>
                    ))}
                </material.DialogContent>
                <material.DialogActions sx={{ m: 1 }}>
                    <material.Button sx={{ textTransform: "none", mr: 1 }} variant="contained" color='info' size="medium" startIcon={<material.AddIcon />} onClick={addMultiple}>Add</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size="medium" color="error" onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" color="primary" onClick={handleSubmit(saveService)} startIcon={<material.DoneIcon />}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default AddServicePackage;