import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { useTheme } from '@mui/material';
import Navbar from '../navbar/Navbar';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import { useNavigate } from 'react-router-dom';
import { getSubmittedForms } from '../../services/on-boarding-service/OnBoardingService';
import Snackbar from '../toastrmessage/Snackbar';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import ViewDocument from './protocol-document/ViewDocument';

function ManageOnBoarding(props) {

    localStorage.setItem("menuName", "Manage On-Boarding")
    const theme = useTheme();
    const navigate = useNavigate();
    const [formListData, setFormListData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [openViewDocument, setViewDocument] = useState(false);

    useEffect(() => {
        getSubmittedFormList()
    }, []);

    const getSubmittedFormList = (payloadValue) => {
        setIsLoading(true);
        const payload = {
            page: payloadValue ? payloadValue.page : 0,
            limit: payloadValue ? payloadValue.limit : 10,
            search: payloadValue ? payloadValue.search : ""
        }
        getSubmittedForms(payload)
            .then((resp) => {
                setFormListData(resp.data.data.applicationForm);
                setPage(Number(resp.data.data.currentPage))
                setRowsPerPage(resp.data.data.limit)
                setTotalElements(resp.data.data.totalItems)
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const payload = {
            limit: rowsPerPage,
            page: newPage,
        }
        getSubmittedFormList(payload)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        const payload = {
            limit: event.target.value,
            page: page
        }
        getSubmittedFormList(payload)
    };

    const openChecklist = (rowData) => {
        navigate('/manage-onboard/checklist', { state: { rowData } })
    };

    const searchList = (value) => {
        const payload = {
            search: value
        }
        getSubmittedFormList(payload)
    };

    const viewDocumentList = () => {
        setViewDocument(true)
    };

    return (
        <material.Grid spacing={2} className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 12
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 12
                },
            }}
        >
            <Navbar />
            <div className="row">
                <div className="col-6">
                    <span style={{ marginLeft: 5 }}>
                        <material.TextField
                            fullWidth
                            variant="standard"
                            label="Search..."
                            onChange={(e) => searchList(e.target.value)}
                            sx={{ mt: -2, width: "40ch" }}
                        />
                    </span>
                </div>
                <div className="col-6">
                    <span className='float-end'>
                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} onClick={viewDocumentList}>Protocol Document List</material.Button>
                    </span>
                </div>
                <div className="col-12 mt-3">
                    <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <material.TableContainer sx={{ maxHeight: "70vh" }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell>Submitted By</StyledTableCell>
                                        <StyledTableCell>Email</StyledTableCell>
                                        <StyledTableCell>Role</StyledTableCell>
                                        <StyledTableCell>Submitted Date</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={10}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={10} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {formListData.length ? formListData.map((row, k) => (
                                                <material.TableRow key={k}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } }}
                                                    onClick={() => openChecklist(row)}
                                                >
                                                    <material.TableCell>{row.formSubmittedBy}</material.TableCell>
                                                    <material.TableCell>{row.emailAddress}</material.TableCell>
                                                    <material.TableCell>{row.applicantRole}</material.TableCell>
                                                    <material.TableCell>{row.applicationCreatedOn}</material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow>
                                                    <material.TableCell colSpan={10}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>
                                    )}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                        <material.TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={totalElements}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </material.Paper>
                </div>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <ViewDocument
                openViewDocument={openViewDocument}
                setViewDocument={setViewDocument}
            />
        </material.Grid>
    );
}

export default ManageOnBoarding;