import axios from "axios";
import { rootApiUrl } from "../environment/Environment";

export const saveOrUpdateSOP = async (data) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/clinic-configuration/save-update-sop`, data)
};

export const getSOPData = async (payload) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/clinic-configuration/get-sop`, { params: { ...payload } })
};

export const deleteSOPData = async (sopId) => {
    return await axios.put(`${rootApiUrl}/api/v0.2/clinic-configuration/delete-sop`, null, { params: { id: sopId } })
};