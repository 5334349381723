import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { material } from '../../../library/material';
import Navbar from '../../navbar/Navbar';
import { useTheme } from '@mui/material';
import Snackbar from '../../toastrmessage/Snackbar';
import { getReceiveMailData, sendReplyEmail } from '../../../services/PlatformCrmService'; 

function ViewReceiveMail() {
    const location = useLocation();
    // const { email } = location.state;
    const [searchParams] = useSearchParams();
    const email = searchParams.get("email");

    const navigate = useNavigate();
    const theme = useTheme();

    const [emailList, setEmailList] = useState([]); // List of all emails
    const [filteredEmailData, setFilteredEmailData] = useState([]); // Filtered emails based on search
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState(""); // Search input state
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [replyMessage, setReplyMessage] = useState("");
    const [attachment, setAttachment] = useState(null);

    useEffect(() => {
        fetchEmails();
    }, []);

    useEffect(() => {
        filterEmails();
    }, [searchQuery, emailList]);

    const fetchEmails = () => {
        setLoading(true);
        getReceiveMailData(email)
            .then((resp) => {
                if (resp.data?.data) {
                    const parsedEmails = resp.data?.data.map((htmlString) => {
                        const parser = new DOMParser();
                        const doc = parser.parseFromString(htmlString, "text/html");

                        return {
                            subject: doc.querySelector("h2")?.innerText || "No Subject",
                            from: doc.querySelector("p strong")?.nextSibling?.nodeValue.trim() || "Unknown Sender",
                            timestamp: doc.querySelector("p:nth-of-type(2) strong")?.nextSibling?.nodeValue.trim() || "Unknown Date",
                            content: doc.body.innerHTML,
                        };
                    });

                    setEmailList(parsedEmails);
                    setFilteredEmailData(parsedEmails);
                } else {
                    setEmailList([]);
                    setFilteredEmailData([]);
                }

                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data.message,
                });
            })
            .catch((error) => {
                console.error(error);
                setEmailList([]);
                setFilteredEmailData([]);
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.message,
                });
            })
            .finally(() => setLoading(false));
    };

    const filterEmails = () => {
        if (searchQuery.trim() === "") {
            setFilteredEmailData(emailList);
        } else {
            const filtered = emailList.filter((email) =>
                email.subject.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredEmailData(filtered);
        }
        setCurrentPage(0); // Reset to first page after filtering
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleChangePage = (_, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    // const backToList = () => setSelectedEmail(null);
    const backToList = () => {
        setSelectedEmail(null);
        setReplyMessage("");
        setAttachment(null);
    };
    const handleAttachmentChange = (event) => {
        setAttachment(event.target.files[0]);
    };

    const handleReplySubmit = async () => {
        if (!replyMessage.trim()) {
            setOpenSnackBar({ action: true, type: "error", message: "Reply message cannot be empty" });
            return;
        }
        const data = new FormData();
        data.append("to", selectedEmail.from);
        data.append("subject", `${selectedEmail.subject}`);
        data.append("message", replyMessage);
        // if (attachment) {
        data.append("attachment", attachment);
        // }

        try {
            await sendReplyEmail(data);
            setOpenSnackBar({ action: true, type: "success", message: "Reply sent successfully" });
            backToList();
        } catch (error) {
            setOpenSnackBar({ action: true, type: "error", message: "Failed to send reply" });
        }
    };

    return (
        <material.Grid className="container-fluid" sx={{ mt: { xs: 10, md: 12, lg: 12 } }}>
            <Navbar />
            <material.Grid container spacing={2}>
                <material.Grid item xs={12}>
                    <material.Stack direction="row" spacing={1} justifyContent="flex-end">
                        {selectedEmail ? (
                            <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size="small" onClick={backToList}>
                                Back to Receive Emails
                            </material.Button>
                        ) : (
                            <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size="small" onClick={() => navigate("/platform-crm")}>
                                Back
                            </material.Button>
                        )}
                    </material.Stack>
                </material.Grid>

                {/* Email List View */}
                {!selectedEmail ? (
                    <material.Grid item xs={12}>
                        <material.Paper sx={{ width: "100%", overflow: "hidden", p: 2 }}>
                            <material.Grid item xs={12} sm={2} md={2} lg={2}>
                                <material.TextField
                                    fullWidth
                                    label="Search by Subject"
                                    variant="standard"
                                    size="small"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    sx={{ mb: 2 }}
                                />
                            </material.Grid>

                            {loading ? (
                                <material.Box
                                    sx={{
                                        display: "grid",
                                        placeItems: "center",
                                        height: "100vh",
                                        width: "100vw",
                                        position: "fixed",
                                        top: 0,
                                        left: 0,
                                    }}
                                >
                                    <material.CircularProgress />
                                </material.Box>
                            ) : filteredEmailData.length === 0 ? (
                                <h3 style={{ color: "red", textAlign: "center" }}>No emails found</h3>
                            ) : (
                                <>
                                    <material.TableContainer>
                                        <material.Table>
                                            <material.TableHead>
                                                <material.TableRow>
                                                    <material.TableCell><strong>Sender</strong></material.TableCell>
                                                    <material.TableCell><strong>Subject</strong></material.TableCell>
                                                    <material.TableCell align="right"><strong>Received At</strong></material.TableCell>
                                                </material.TableRow>
                                            </material.TableHead>
                                            <material.TableBody>
                                                {filteredEmailData
                                                    .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                                                    .map((email, index) => (
                                                        <material.TableRow
                                                            key={index}
                                                            onClick={() => setSelectedEmail(email)}
                                                            sx={{ cursor: "pointer", "&:hover": { backgroundColor: "#f5f5f5" } }}
                                                        >
                                                            <material.TableCell sx={{ fontWeight: "bold" }}>{email.from}</material.TableCell>
                                                            <material.TableCell>
                                                                <span style={{ fontWeight: email.isImportant ? "bold" : "normal" }}>
                                                                    {email.subject}
                                                                </span>
                                                            </material.TableCell>
                                                            <material.TableCell align="right">{email.timestamp}</material.TableCell>
                                                        </material.TableRow>
                                                    ))}
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>

                                    {/* Pagination */}
                                    <material.TablePagination
                                        rowsPerPageOptions={[5, 10, 50]}
                                        component="div"
                                        count={filteredEmailData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={currentPage}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </>
                            )}
                        </material.Paper>
                    </material.Grid>
                ) : (
                    // Email Detail View
                    <material.Grid item xs={12}>
                        <material.Paper sx={{ width: "100%", overflow: "hidden", p: 2 }}>
                            <div dangerouslySetInnerHTML={{ __html: selectedEmail.content }} />
                            <material.TextField fullWidth label="Reply" multiline rows={4} value={replyMessage} onChange={(e) => setReplyMessage(e.target.value)} sx={{ mt: 2 }} />
                            <material.Input type="file" onChange={handleAttachmentChange} sx={{ mt: 2, my: 2 }} />
                            <material.Button variant="contained" onClick={handleReplySubmit} sx={{ mt: 2, my: 3 }}>Send Reply</material.Button>
                        </material.Paper>
                    </material.Grid>
                )}
            </material.Grid>

            <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
        </material.Grid>
    );
}


export default ViewReceiveMail;

// import React, { useEffect, useState } from 'react';
// import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// import { material } from '../../../library/material';
// import Navbar from '../../navbar/Navbar';
// import { useTheme } from '@mui/material';
// import Snackbar from '../../toastrmessage/Snackbar';
// import { getReceiveMailData } from '../../../services/PlatformCrmService';

// function ViewReceiveMail() {
//     const location = useLocation();
//     const [searchParams] = useSearchParams();
//     const email = searchParams.get("email");
//     const navigate = useNavigate();

//     const [emailList, setEmailList] = useState([]);
//     const [filteredEmailData, setFilteredEmailData] = useState([]);
//     const [selectedEmail, setSelectedEmail] = useState(null);
//     const [replyMessage, setReplyMessage] = useState("");
//     const [attachment, setAttachment] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [searchQuery, setSearchQuery] = useState("");
//     const [currentPage, setCurrentPage] = useState(0);
//     const [rowsPerPage, setRowsPerPage] = useState(10);
//     const [openSnackBar, setOpenSnackBar] = useState({
//         action: false,
//         type: "",
//         message: "",
//     });

//     useEffect(() => {
//         fetchEmails();
//     }, []);

//     useEffect(() => {
//         filterEmails();
//     }, [searchQuery, emailList]);

//     const fetchEmails = () => {
//         setLoading(true);
//         getReceiveMailData(email)
//             .then((resp) => {
//                 if (resp.data?.data) {
//                     const parsedEmails = resp.data?.data.map((htmlString) => {
//                         const parser = new DOMParser();
//                         const doc = parser.parseFromString(htmlString, "text/html");

//                         return {
//                             subject: doc.querySelector("h2")?.innerText || "No Subject",
//                             from: doc.querySelector("p strong")?.nextSibling?.nodeValue.trim() || "Unknown Sender",
//                             timestamp: doc.querySelector("p:nth-of-type(2) strong")?.nextSibling?.nodeValue.trim() || "Unknown Date",
//                             content: doc.body.innerHTML,
//                         };
//                     });

//                     setEmailList(parsedEmails);
//                     setFilteredEmailData(parsedEmails);
//                 } else {
//                     setEmailList([]);
//                     setFilteredEmailData([]);
//                 }

//                 setOpenSnackBar({
//                     action: true,
//                     type: "success",
//                     message: resp.data.message,
//                 });
//             })
//             .catch((error) => {
//                 console.error(error);
//                 setEmailList([]);
//                 setFilteredEmailData([]);
//                 setOpenSnackBar({
//                     action: true,
//                     type: "error",
//                     message: error.message,
//                 });
//             })
//             .finally(() => setLoading(false));
//     };

//     const filterEmails = () => {
//         if (searchQuery.trim() === "") {
//             setFilteredEmailData(emailList);
//         } else {
//             const filtered = emailList.filter((email) =>
//                 email.subject.toLowerCase().includes(searchQuery.toLowerCase())
//             );
//             setFilteredEmailData(filtered);
//         }
//         setCurrentPage(0);
//     };

//     const handleSearchChange = (event) => {
//         setSearchQuery(event.target.value);
//     };

//     const handleChangePage = (_, newPage) => {
//         setCurrentPage(newPage);
//     };

//     const handleChangeRowsPerPage = (event) => {
//         setRowsPerPage(parseInt(event.target.value, 10));
//         setCurrentPage(0);
//     };

//     const backToList = () => {
//         setSelectedEmail(null);
//         setReplyMessage("");
//         setAttachment(null);
//     };

//     const handleAttachmentChange = (event) => {
//         setAttachment(event.target.files[0]);
//     };

//     const handleReplySubmit = async () => {
//         if (!replyMessage.trim()) {
//             setOpenSnackBar({ action: true, type: "error", message: "Reply message cannot be empty" });
//             return;
//         }

//         const formData = new FormData();
//         formData.append("to", selectedEmail.from);
//         formData.append("subject", `Re: ${selectedEmail.subject}`);
//         formData.append("message", replyMessage);
//         if (attachment) {
//             formData.append("attachment", attachment);
//         }

//         try {
//             // await sendReplyEmail(formData);
//             setOpenSnackBar({ action: true, type: "success", message: "Reply sent successfully" });
//             backToList();
//         } catch (error) {
//             setOpenSnackBar({ action: true, type: "error", message: "Failed to send reply" });
//         }
//     };

//     return (
//         <material.Grid className="container-fluid" sx={{ mt: { xs: 10, md: 12, lg: 12 } }}>
//             <Navbar />
//             <material.Grid container spacing={2}>
//                 <material.Grid item xs={12}>
//                     <material.Stack direction="row" spacing={1} justifyContent="flex-end">
//                         {selectedEmail ? (
//                             <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size="small" onClick={backToList}>
//                                 Back to Receive Emails
//                             </material.Button>
//                         ) : (
//                             <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size="small" onClick={() => navigate("/platform-crm")}>
//                                 Back
//                             </material.Button>
//                         )}
//                     </material.Stack>
//                 </material.Grid>

//                 {!selectedEmail ? (
//                     <material.Grid item xs={12}>
//                         <material.Paper sx={{ width: "100%", overflow: "hidden", p: 2 }}>
//                             <material.TextField
//                                 fullWidth
//                                 label="Search by Subject"
//                                 variant="standard"
//                                 size="small"
//                                 value={searchQuery}
//                                 onChange={handleSearchChange}
//                                 sx={{ mb: 2 }}
//                             />

//                             {loading ? (
//                                 <material.Box sx={{ display: "grid", placeItems: "center", height: "100vh", width: "100vw", position: "fixed", top: 0, left: 0 }}>
//                                     <material.CircularProgress />
//                                 </material.Box>
//                             ) : filteredEmailData.length === 0 ? (
//                                 <h3 style={{ color: "red", textAlign: "center" }}>No emails found</h3>
//                             ) : (
//                                 <>
//                                     <material.TableContainer>
//                                         <material.Table>
//                                             <material.TableHead>
//                                                 <material.TableRow>
//                                                     <material.TableCell><strong>Sender</strong></material.TableCell>
//                                                     <material.TableCell><strong>Subject</strong></material.TableCell>
//                                                     <material.TableCell align="right"><strong>Received At</strong></material.TableCell>
//                                                 </material.TableRow>
//                                             </material.TableHead>
//                                             <material.TableBody>
//                                                 {filteredEmailData
//                                                     .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
//                                                     .map((email, index) => (
//                                                         <material.TableRow
//                                                             key={index}
//                                                             onClick={() => setSelectedEmail(email)}
//                                                             sx={{ cursor: "pointer", "&:hover": { backgroundColor: "#f5f5f5" } }}
//                                                         >
//                                                             <material.TableCell sx={{ fontWeight: "bold" }}>{email.from}</material.TableCell>
//                                                             <material.TableCell>{email.subject}</material.TableCell>
//                                                             <material.TableCell align="right">{email.timestamp}</material.TableCell>
//                                                         </material.TableRow>
//                                                     ))}
//                                             </material.TableBody>
//                                         </material.Table>
//                                     </material.TableContainer>
//                                 </>
//                             )}
//                         </material.Paper>
//                     </material.Grid>
//                 ) : (
//                     <material.Grid item xs={12}>
//                         <material.Paper sx={{ width: "100%", overflow: "hidden", p: 2 }}>
//                             <div dangerouslySetInnerHTML={{ __html: selectedEmail.content }} />
//                             <material.TextField fullWidth label="Reply" multiline rows={4} value={replyMessage} onChange={(e) => setReplyMessage(e.target.value)} sx={{ mt: 2 }} />
//                             <material.Input type="file" onChange={handleAttachmentChange} sx={{ mt: 2 }} />
//                             <material.Button variant="contained" onClick={handleReplySubmit} sx={{ mt: 2 }}>Send Reply</material.Button>
//                         </material.Paper>
//                     </material.Grid>
//                 )}
//             </material.Grid>

//             <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
//         </material.Grid>
//     );
// }

// export default ViewReceiveMail;
