import axios from "axios";
import { rootApiUrl } from "../environment/Environment";

export const getAllClinic = async () => {
  return await axios.get(`${rootApiUrl}/api/v0.2/clinics/get-all`);
};

export const createClinic = async (clinicInformation) => {
  return await axios.post(`${rootApiUrl}/api/v0.2/clinics/add-clinic`, clinicInformation);
};

export const updateClinic = async (updateClinicInformation) => {
  return await axios.put(
    `${rootApiUrl}/api/v0.2/clinics/update-clinic`,
    updateClinicInformation
  );
};

export const activeOrDeactiveClinic = async (clinicId) => {
  return await axios.put(
    `${rootApiUrl}/api/v0.2/clinics/toggle-clinic-by-clinic-id/${clinicId}`
  );
};

export const deleteClinicById = async (clinicId) => {
  return await axios.put(
    `${rootApiUrl}/api/v0.2/clinics/delete-clinic-by-clinic-id/${clinicId}`
  );
};

export const getClinicForInjector = async () => {
  return await axios.get(`${rootApiUrl}/api/v0.2/clinics/get-clinic-details-for-injector`);
};

export const getClinicByIdOpen = async (clinicId) => {
  return await axios.get(`${rootApiUrl}/get-clinic-by-id-open/${clinicId}`);
};

export const checkInjectorAvailabilityOpen = async (payload) => {
  return await axios.put(`${rootApiUrl}/check-injector-open`, payload);
};

export const getClinicRotationDetailsByOrgIdAndClinicId = async (params) => {
  return await axios.get(`${rootApiUrl}/api/v0.2/clinic-configuration/get-clinic-roster`, {
    params,
  });
};

export const saveClinicRotation = async (clinicRotationInformation) => {
  return await axios.post(
    `${rootApiUrl}/api/v0.2/clinic-configuration/save-clinic-roster`,
    clinicRotationInformation
  );
};

export const getClinicTimeZone = async (payload) => {
  return await axios.get(`${rootApiUrl}/api/v0.2/clinic-configuration/get-clinic-timezone`, { params: { clinicId: payload.clinicId, orgId: payload.orgId } });
};

export const saveClinicTimeZone = async (payload) => {
  return await axios.post(`${rootApiUrl}/api/v0.2/clinic-configuration/save-clinic-timezone`, payload);
};

export const uploadClinicLogo = async (payload) => {
  return await axios.post(`${rootApiUrl}/api/v0.2/clinic-configuration/upload-logo`, payload);
};

export const getClinicByOrgId = async (payload) => {
  return await axios.get(`${rootApiUrl}/api/v0.2/admin/get-clinics-by-org-id/${payload}`);
};