import axios from "axios";
import { rootApiUrl } from "../environment/Environment";


export const getPendingTreatmentRequest = async () => {
    return await axios.get(`${rootApiUrl}/api/v0.2/cosmetic-treatment/get-pending-treatment-request-prescriber`);
};

export const changePlanStatus = async (data) => {
    return await axios.put(`${rootApiUrl}/api/v0.2/cosmetic-treatment/change-plan-status`, data);
};

export const lockTreatmentPlan = async (planRequestId, payload) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/cosmetic-treatment/lock-treatment/${planRequestId}`, payload);
};

export const getLockTreatmentPlan = async () => {
    return await axios.get(`${rootApiUrl}/api/v0.2/cosmetic-treatment/get-locked-pending-treatment-request-prescriber`);
};