import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { material } from "../../../library/material";
import Snackbar from "../../toastrmessage/Snackbar";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import moment from "moment";
import { saveUpdateEmployeePayrollHours } from "../../../services/EmployeePayrollHoursServices";
import dayjs from "dayjs";

function AddEmployeePayrollHoursDetails(props) {
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "onTouched" });
  const {
    openEmployeePayrollHours,
    setOpenEmployeePayrollHours,
    getAllEmployeePayrollHours,
    userDetails,
    clinicDetails,
  } = props;

  const [selectBreakTime, setSelectBreakTime] = useState("");
  const [selectType, setSelectType] = useState("");

  const [openSnackBar, setOpenSnackBar] = useState({
    action: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    if (openEmployeePayrollHours.action && openEmployeePayrollHours.employeePayrollHoursData) {
      const mapBreakTimeToSelectValue = (time) => {
        const timeMapping = {
          "00:00:00": "0 min", "00:15:00": "15 min", "00:30:00": "30 min", "00:45:00": "45 min", "01:00:00": "1 hour",
          "01:15:00": "1 hour 15 min", "01:30:00": "1 hour 30 min", "01:45:00": "1 hour 45 min", "02:00:00": "2 hour",
        };
        return timeMapping[time] || "";
      };

      const mapTimeToDayjs = (time) => {
        return dayjs(`1970-01-01T${time}`);
      };
      const employeePayrollData = openEmployeePayrollHours.employeePayrollHoursData;
      const formattedBreakTime = mapBreakTimeToSelectValue(employeePayrollData.breakTime);
      const formattedEntryTime = mapTimeToDayjs(employeePayrollData.entryTime);
      const formattedExitTime = mapTimeToDayjs(employeePayrollData.exitTime);
      setSelectBreakTime(formattedBreakTime);
      setSelectType(employeePayrollData.type);
      reset({
        ...employeePayrollData,
        entryTime: formattedEntryTime,
        exitTime: formattedExitTime,
      });
    }
  }, [openEmployeePayrollHours.action]);

  useEffect(() => {
    if (openEmployeePayrollHours.action) {
      if (openEmployeePayrollHours.employeePayrollHoursData) {
        setValue("employeeName", openEmployeePayrollHours.employeePayrollHoursData.employeeName);
      } else {
        setValue("employeeName", userDetails.name);
      }
    }
  }, [openEmployeePayrollHours.action, openEmployeePayrollHours.employeePayrollHoursData, userDetails.name, setValue,]);


  const handleChangeBreakTime = (event) => {
    setSelectBreakTime(event.target.value);
  };

  const cancel = () => {
    setOpenEmployeePayrollHours({
      action: false,
      EmployeePayrollHoursData: null,
    });
    setSelectType("");
    setSelectBreakTime("");
    reset({
      employeeName: "",
      date: "",
      entryTime: "",
      exitTime: "",
      comments: "",
    });
  };

  const save = (data) => {
    const formattedData = {
      clinicId: clinicDetails.clinicId,
      orgId: userDetails.orgId,
      employeeName: data.employeeName,
      date: data.date ? data.date.format("YYYY-MM-DD") : null,
      entryTime: data.entryTime ? data.entryTime.format("HH:mm") : null,
      exitTime: data.exitTime ? data.exitTime.format("HH:mm") : null,
      comments: data.comments,
      breakTime: selectBreakTime,
      type: selectType,
    };
    saveUpdateEmployeePayrollHours(formattedData)
      .then((resp) => {
        setOpenSnackBar({
          action: true,
          type: "success",
          message: resp.data.messages,
        });
        getAllEmployeePayrollHours();
        cancel();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response.data.data.info,
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response.data.messages,
          })
        }
      });
  };

  const update = (data) => {
    const formattedData = {
      id: data.id,
      clinicId: data.clinicId,
      orgId: data.orgId,
      employeeName: data.employeeName,
      entryTime: data.entryTime ? data.entryTime.format("HH:mm") : null,
      exitTime: data.exitTime ? data.exitTime.format("HH:mm") : null,
      comments: data.comments,
      breakTime: selectBreakTime,
      type: selectType,
      date: dayjs(data.date).isValid() ? dayjs(data.date).format('YYYY-MM-DD') : null,
      isApprove: data.isApprove,
    };
    saveUpdateEmployeePayrollHours(formattedData)
      .then((resp) => {
        setOpenSnackBar({
          action: true,
          type: "success",
          message: resp.data.messages,
        });
        getAllEmployeePayrollHours();
        cancel();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response.data.data.info,
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response.data.messages,
          })
        }
      });
  };

  const handleChangeType = (event) => {
    const selectedValue = event.target.value;
    setSelectType(selectedValue);
  };

  return (
    <div>
      <material.Dialog
        fullWidth
        maxWidth="md"
        open={openEmployeePayrollHours.action}
        hideBackdrop
      >
        <material.DialogTitle className="fw-bold">
          {openEmployeePayrollHours.employeePayrollHoursData
            ? "Edit Employee Payroll Hours"
            : "Add Employee Payroll Hours"}
        </material.DialogTitle>
        <material.DialogContent>
          <div
            style={{
              width: "100%",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "16px",
              marginTop: "2px",
              boxShadow:
                "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
            }}
          >
            <div className="row">
              <div className="col-6 mt-2">
                <material.TextField
                  multiline
                  variant="outlined"
                  label="Employee Name"
                  type="text"
                  fullWidth
                  {...register("employeeName", { required: true })}
                />
              </div>
              <div className="col-6 mt-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={["DatePicker", "DatePicker"]}
                    sx={{ mt: -1 }}
                  >
                    <Controller
                      name="date"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={null}
                      render={({ field }) => (
                        <DatePicker
                          label="Date"
                          {...field}
                          value={dayjs(field.value)}
                          onChange={(newValue) => field.onChange(newValue)}
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div className="col-6 mt-2">
                <material.FormControl variant="standard" fullWidth>
                  <material.InputLabel
                    id="demo-simple-select-standard-label"
                    required
                  >
                    Type
                  </material.InputLabel>
                  <material.Select
                    {...register("type", { required: true })}
                    required
                    label="Type"
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    fullWidth
                    value={selectType}
                    onChange={handleChangeType}
                  >
                    <material.MenuItem value={"Normal Day"}>
                      Normal Day
                    </material.MenuItem>
                    <material.MenuItem value={"Leave Day"}>
                      Leave Day
                    </material.MenuItem>
                  </material.Select>
                </material.FormControl>
              </div>

              <div className="col-6 mt-2">
                <Controller
                  name={`entryTime`}
                  control={control}
                  defaultValue={null}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Entry Time"
                        {...field}
                        onChange={(newValue) => field.onChange(newValue)}
                        sx={{ width: "100%" }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
              <div className="col-6 mt-2">
                <Controller
                  name={`exitTime`}
                  control={control}
                  rules={{ required: true }}
                  defaultValue={null}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Exit Time"
                        {...field}
                        onChange={(newValue) => field.onChange(newValue)}
                        sx={{ width: "100%" }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>

              <div className="col-6 mt-2">
                <material.TextField
                  multiline
                  variant="outlined"
                  label="Comments"
                  type="text"
                  fullWidth
                  {...register("comments", { required: true })}
                />
              </div>

              <div className="col-6 mt-2">
                <material.FormControl variant="standard" fullWidth>
                  <material.InputLabel
                    id="demo-simple-select-standard-label"
                    required
                  >
                    Break Time
                  </material.InputLabel>
                  <material.Select
                    {...register("breakTime", { required: true })}
                    required
                    label="Break"
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    fullWidth
                    value={selectBreakTime}
                    onChange={handleChangeBreakTime}
                  >
                    <material.MenuItem value={"0 min"}>0 min</material.MenuItem>
                    <material.MenuItem value={"15 min"}>
                      15 min
                    </material.MenuItem>
                    <material.MenuItem value={"30 min"}>
                      30 min
                    </material.MenuItem>
                    <material.MenuItem value={"45 min"}>
                      45 min
                    </material.MenuItem>
                    <material.MenuItem value={"1 hour"}>
                      1 hour
                    </material.MenuItem>
                    <material.MenuItem value={"1 hour 15 min"}>
                      {" "}
                      1 hour 15 min{" "}
                    </material.MenuItem>
                    <material.MenuItem value={"1 hour 30 min"}>
                      {" "}
                      1 hour 30 min{" "}
                    </material.MenuItem>
                    <material.MenuItem value={"1 hour 45 min"}>
                      {" "}
                      1 hour 45 min
                    </material.MenuItem>
                    <material.MenuItem value={"2 hour"}>
                      2 hour
                    </material.MenuItem>
                  </material.Select>
                </material.FormControl>
              </div>
            </div>
          </div>
        </material.DialogContent>
        <material.DialogActions>
          <div className="mt-3">
            <span className="float-end py-3">
              <material.Button
                size="medium"
                className="me-2"
                onClick={cancel}
                variant="contained"
                sx={{ textTransform: "none" }}
                color="error"
              >
                Cancel
              </material.Button>
              {openEmployeePayrollHours.employeePayrollHoursData ? (
                <material.Button
                  size="medium"
                  className="me-2"
                  onClick={handleSubmit(update)}
                  variant="contained"
                  sx={{ textTransform: "none" }}
                >
                  Update
                </material.Button>
              ) : (
                <material.Button
                  size="medium"
                  className="me-2"
                  onClick={handleSubmit(save)}
                  variant="contained"
                  sx={{ textTransform: "none" }}
                >
                  Save
                </material.Button>
              )}
            </span>
          </div>
        </material.DialogActions>
      </material.Dialog>
      <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
    </div>
  );
}

export default AddEmployeePayrollHoursDetails;
