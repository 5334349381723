import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import Navbar from '../navbar/Navbar';
import { useTheme } from '@mui/material';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import { getAllSupplierClinicConfigRequest, uploadSupplierForm } from '../../services/SupplierConfigService';
import Snackbar from '../toastrmessage/Snackbar';
import { rootApiUrl } from '../../environment/Environment';
import RejectionNoteDialog from './RejectionNoteDialog';

function SupplierClinicConfigurationRequests(props) {

    localStorage.setItem("menuName", "Supplier Clinic Configuration Requests");
    const theme = useTheme();
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [supplierClinicConfigData, setSupplierClinicConfigData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [openRejectionNoteDialog, setOpenRejectionNoteDialog] = useState({ action: false, rowData: null });

    useEffect(() => {
        getSupplierClinicConfigReqData();
    }, []);

    const getSupplierClinicConfigReqData = (paginationData) => {
        setIsLoading(true);
        const payload = {
            limit: paginationData ? paginationData.limit : 10,
            page: paginationData ? paginationData.page : 0,
            search: paginationData ? paginationData.supplierName : "",
        }
        getAllSupplierClinicConfigRequest(payload)
            .then((resp) => {
                setSupplierClinicConfigData(resp.data.data.results);
                setPage(Number(resp.data.data.currentPage));
                setRowsPerPage(Number(resp.data.data.limit));
                setTotalElements(resp.data.data.totalItems);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const payload = {
            limit: rowsPerPage,
            page: newPage,
        }
        getSupplierClinicConfigReqData(payload)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        const payload = {
            limit: event.target.value,
            page: page
        }
        getSupplierClinicConfigReqData(payload)
    };

    const searchSupplier = (value) => {
        const payload = {
            supplierName: value
        }
        getSupplierClinicConfigReqData(payload)
    };

    const downloadForm = (downloadLink) => {
        window.open(`${rootApiUrl}/agl-link?key=${downloadLink}`, "_blank")
    };

    const downloadSupportingDoc = (downloadLink) => {
        window.open(`${rootApiUrl}/agl-link?key=${downloadLink}`, "_blank")
    };

    const statusChange = (rowData) => {
        if (rowData.action === "Approved") {
            const formData = new FormData();
            formData.append("approved", true)
            formData.append("requestId", rowData.requestId)
            uploadSupplierForm(formData)
                .then((resp) => {
                    getSupplierClinicConfigReqData();
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data.messages,
                    })
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.data.info,
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.messages,
                        })
                    }
                })
        } else if (rowData.action === "Reject") {
            setOpenRejectionNoteDialog({ action: true, rowData: rowData })
        }
    };

    const viewRejectionNote = (rowData) => {
        setOpenRejectionNoteDialog({ action: true, rowData: rowData })
    };

    return (
        <material.Grid className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 12
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 12
                },
            }}
        >
            <Navbar />
            <div className="row">
                <div className="col-12">
                    <span style={{ marginLeft: 5 }}>
                        <material.TextField
                            fullWidth
                            variant="standard"
                            label="Search Supplier"
                            onChange={(e) => searchSupplier(e.target.value)}
                            sx={{ mt: -2, width: "30ch" }}
                        />
                    </span>
                </div>
                <div className="col-12 mt-3">
                    <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <material.TableContainer sx={{ maxHeight: 460 }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell>Supplier Name</StyledTableCell>
                                        <StyledTableCell>Form Link</StyledTableCell>
                                        <StyledTableCell>Supporting Docs Link</StyledTableCell>
                                        <StyledTableCell>Submission Date</StyledTableCell>
                                        <StyledTableCell align='center'>Action</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={15}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={15} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {supplierClinicConfigData.length ? supplierClinicConfigData.map((rowData, k) => (
                                                <material.TableRow key={k}>
                                                    <material.TableCell>{rowData.supplierName}</material.TableCell>
                                                    <material.TableCell>
                                                        <material.Link component="button" variant="body2" title='Click to download' onClick={() => downloadForm(rowData.formLink)}>{rowData.formLink.split("/").pop()}</material.Link>
                                                    </material.TableCell>
                                                    <material.TableCell>{rowData.supportingDocumentLinks.map((ele, k) => (
                                                        <div key={k}>
                                                            <material.Link component="button" variant="body2" title='Click to download' onClick={() => downloadSupportingDoc(ele)}>{ele.split("/").pop()}</material.Link>
                                                        </div>
                                                    ))}</material.TableCell>
                                                    <material.TableCell>{rowData.applicationSubmissionDate}</material.TableCell>
                                                    <material.TableCell align='center'>{!rowData.isApproved && !rowData.isRejected ? (
                                                        <span className='d-flex flex-column'>
                                                            <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='success' size="small" startIcon={<material.DoneIcon />} onClick={() => statusChange({ "action": "Approved", ...rowData })}>Approve</material.Button>
                                                            <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='error' size="small" startIcon={<material.PriorityHighIcon />} onClick={() => statusChange({ "action": "Reject", ...rowData })}>Reject</material.Button>
                                                        </span>
                                                    ) : rowData.isApproved ? (
                                                        <span className="badge bg-success">
                                                            APPROVED
                                                        </span>
                                                    ) : rowData.isRejected ? (
                                                        <material.Link component="button" variant="body2" onClick={() => viewRejectionNote(rowData)}>View Rejection Note</material.Link>
                                                    ) : null}</material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={15}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>
                                    )}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                        <material.TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={totalElements}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </material.Paper>
                </div>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <RejectionNoteDialog
                openRejectionNoteDialog={openRejectionNoteDialog}
                setOpenRejectionNoteDialog={setOpenRejectionNoteDialog}
                getSupplierClinicConfigReqData={getSupplierClinicConfigReqData}
            />
        </material.Grid>
    );
}

export default SupplierClinicConfigurationRequests;