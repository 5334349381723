import React, { useState, useEffect } from "react";
import { material } from "../../../library/material";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { getAllInjectors } from "../../../services/InjectorService";
import { connect } from "react-redux";
import { StyledTableCell } from "../../../shared/TableHeaderStyle";
import Navbar from "../../navbar/Navbar";
import { webRootApiUrl } from "../../../environment/Environment";
import Snackbar from "../../toastrmessage/Snackbar";
import { useForm } from "react-hook-form";
import AddUserRotation from "../../dialog/AddUserRotation";
import { useTheme } from "@mui/material";

var allInjectorData;

function InjectorList(props) {
  const { userData, clinicData } = props;
  const userDetails = userData.authReducer.data;
  const clinicDetails = clinicData.clinicReducer.data;
  const theme = useTheme();
  const [injectorsData, setInjectorsData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isLoading, setisLoading] = useState(true);
  const [openSnackBar, setOpenSnackBar] = useState({
    action: false,
    type: "",
    message: "",
  });
  const [openDialog, setOpenDialog] = useState({
    action: false,
    rowData: null,
  });
  const [openRotationDialog, setOpenRotationDialog] = useState({
    action: false,
    rowData: null,
  });

  const navigate = useNavigate();

  localStorage.setItem("menuName", "Injector List");

  useEffect(() => {
    getInjectorList();
  }, []);

  const getInjectorList = async () => {
    let clinicId = clinicDetails.clinicId;
    await getAllInjectors(clinicId)
      .then((resp) => {
        allInjectorData = resp.data.data;
        setInjectorsData(resp.data.data);
        setisLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response.data.data.info,
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response.data.messages,
          })
        }
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const editInjector = (userData) => {
    navigate("/user_management/injector-list/edit-injector", {
      state: { userData },
    });
  };

  const filterByPrice = (value) => {
    setPage(0);
    const filterData = injectorsData.filter((ele) => {
      return ele.fullName
        .toString()
        .toLowerCase()
        .trim()
        .includes(value.toString().toLowerCase().trim());
    });
    if (value.trim().toString().length < 1) {
      setInjectorsData(allInjectorData);
    } else {
      setInjectorsData(filterData);
    }
  };

  const copyUrl = (rowData) => {
    setOpenDialog({ action: true, rowData: rowData });
  };

  const getDialogData = (dialogData) => {
    if (dialogData.selectValue === "YES") {
      const urlContent = `${webRootApiUrl}/create-appointment/${userDetails.orgId}/${clinicDetails.clinicId}/${dialogData.rowData.injectorId}/${dialogData.campaignTag}`;
      navigator.clipboard
        .writeText(urlContent)
        .then(() => {
          setOpenSnackBar({
            action: true,
            type: "success",
            message: "Appointment booking URL copied to clipboard!",
          });
        })
        .catch((err) => {
          setOpenSnackBar({
            action: true,
            type: "error",
            message: `Failed to copy text: ${err}`,
          });
        });
    } else if (dialogData.selectValue === "NO") {
      const urlContent = `${webRootApiUrl}/create-appointment/${userDetails.orgId}/${clinicDetails.clinicId}/${dialogData.rowData.injectorId}/Consulting`;
      navigator.clipboard
        .writeText(urlContent)
        .then(() => {
          setOpenSnackBar({
            action: true,
            type: "success",
            message: "Appointment booking URL copied to clipboard!",
          });
        })
        .catch((err) => {
          setOpenSnackBar({
            action: true,
            type: "error",
            message: `Failed to copy text: ${err}`,
          });
        });
    }
  };

  const openAddRotationDialog = (userId) => {
    setOpenRotationDialog({ action: true, userId: userId });
  };

  const handleAddHoliday = (userData) => {
    navigate("/user_management/injector-list/injector-add-holiday", {
      state: userData,
    });
  };

  return (
    <material.Grid className='container-fluid'
      sx={{
        [theme.breakpoints.down('sm')]: {
          mt: 10
        },
        [theme.breakpoints.up('sm')]: {
          mt: 10
        },
        [theme.breakpoints.up('md')]: {
          mt: 12
        },
        [theme.breakpoints.up('lg')]: {
          mt: 12
        },
      }}
    >
      <Navbar />
      <div className="row">
        <div className="col-12">
          <span>
            <material.TextField
              fullWidth
              variant="standard"
              label="Filter by Injector Name"
              type="text"
              onChange={(e) => filterByPrice(e.target.value)}
              sx={{ width: "40ch" }}
            />
          </span>
        </div>
        <div className="col-12 mt-3">
          <material.Paper sx={{ width: "100%", overflow: "hidden" }}>
            <material.TableContainer sx={{ maxHeight: 460 }}>
              <material.Table stickyHeader aria-label="sticky table">
                <material.TableHead>
                  <material.TableRow>
                    <StyledTableCell>Injector Name</StyledTableCell>
                    <StyledTableCell>Email ID</StyledTableCell>
                    <StyledTableCell>Role</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </material.TableRow>
                </material.TableHead>
                <material.TableBody>
                  {isLoading ? (
                    <material.TableRow>
                      <material.TableCell colSpan={6}>
                        <SkeletonTheme
                          baseColor="#bbdefb"
                          highlightColor="#c6ff00"
                          enableAnimation="true"
                          inline="true"
                          width="100% "
                          height="30px"
                        >
                          <Skeleton count={10} />
                        </SkeletonTheme>
                      </material.TableCell>
                    </material.TableRow>
                  ) : (
                    <>
                      {injectorsData.length ? (
                        injectorsData
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, i) => (
                            <material.TableRow
                              key={i}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <material.TableCell
                                sx={{ textTransform: "capitalize" }}
                                size="small"
                                component="th"
                                scope="row"
                              >
                                {row.fullName}
                              </material.TableCell>
                              <material.TableCell size="small">
                                {row.email}
                              </material.TableCell>
                              <material.TableCell size="small">
                                {row.role?.join(", ")}
                              </material.TableCell>
                              <material.TableCell align="center">
                                <material.IconButton
                                  title="Edit Injector Details"
                                  aria-label="create"
                                  size="large"
                                  onClick={() => editInjector(row)}
                                >
                                  <material.CreateIcon color="primary" />
                                </material.IconButton>
                                <material.IconButton
                                  title="Copy url"
                                  aria-label="visibility"
                                  size="large"
                                  onClick={() => copyUrl(row)}
                                >
                                  <material.ContentCopyIcon color="primary" />
                                </material.IconButton>
                                <material.Button size="small"
                                  startIcon={<material.AddIcon />}
                                  variant="contained"
                                  onClick={() =>
                                    openAddRotationDialog(row.userId)
                                  }
                                  sx={{ textTransform: "none" }}
                                >
                                  Add Hours
                                </material.Button>
                                <material.Button size="small"
                                  startIcon={<material.AddIcon />}
                                  variant="contained"
                                  onClick={() =>
                                    handleAddHoliday(row)
                                  }
                                  sx={{ textTransform: "none", ml: 2 }}
                                >
                                  Add Holiday
                                </material.Button>
                              </material.TableCell>
                            </material.TableRow>
                          ))
                      ) : (
                        <material.TableRow>
                          <material.TableCell colSpan={6}>
                            <h6 className="d-flex justify-content-center text-danger fw-bold">
                              No data found
                            </h6>
                          </material.TableCell>
                        </material.TableRow>
                      )}
                    </>
                  )}
                </material.TableBody>
              </material.Table>
            </material.TableContainer>
            <hr />
            <material.TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={injectorsData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </material.Paper>
        </div>
      </div>
      <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
      <PermissionDialog
        getDialogData={getDialogData}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
      <AddUserRotation
        setOpenRotationDialog={setOpenRotationDialog}
        openRotationDialog={openRotationDialog}
      />
    </material.Grid>
  );
}

const PermissionDialog = (props) => {
  const { getDialogData, openDialog, setOpenDialog } = props;
  const {
    register,
    handleSubmit,
    reset,
    resetField,
    formState: { errors, isValid },
  } = useForm({
    mode: "onTouched",
  });
  const [selectValue, setSelectValue] = useState("");

  const handleChange = (e) => {
    setSelectValue(e.target.value);
    if (e.target.value === "NO") {
      const payload = {
        selectValue: e.target.value,
        campaignTag: null,
        rowData: openDialog.rowData,
      };
      setSelectValue("");
      getDialogData(payload);
      setOpenDialog({ action: false, rowData: null });
    }
  };

  const handleClose = () => {
    setOpenDialog({ action: false, rowData: null });
    reset({ campaignTag: "" });
    setSelectValue("");
  };

  const save = (data) => {
    const payload = {
      selectValue: "YES",
      campaignTag: data.campaignTag,
      rowData: openDialog.rowData,
    };
    getDialogData(payload);
    reset({ campaignTag: "" });
    setOpenDialog({ action: false, rowData: null });
    setSelectValue("");
  };

  return (
    <div>
      <material.Dialog
        open={openDialog.action}
        maxWidth="sm"
        fullWidth
        hideBackdrop
      >
        <material.DialogContent>
          <span>Add Custom Title</span>
          <span>
            <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
              <material.RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={handleChange}
                value={selectValue}
              >
                <material.FormControlLabel
                  value="YES"
                  control={<material.Radio color="secondary" />}
                  label="YES"
                />
                <material.FormControlLabel
                  value="NO"
                  control={<material.Radio color="secondary" />}
                  label="NO"
                />
              </material.RadioGroup>
            </material.FormControl>
          </span>
          {selectValue === "YES" ? (
            <div>
              <material.TextField
                {...register("campaignTag", { required: true })}
                margin="dense"
                id="name"
                label="Treatment/Campaign Name"
                type="text"
                fullWidth
                variant="standard"
              />
            </div>
          ) : null}
        </material.DialogContent>
        {selectValue === "YES" ? (
          <material.DialogActions>
            <material.Button
              onClick={handleClose}
              variant="contained"
              sx={{ textTransform: "none" }}
              color="error"
            >
              Cancel
            </material.Button>
            <material.Button
              onClick={handleSubmit(save)}
              variant="contained"
              sx={{ textTransform: "none" }}
              disabled={!isValid}
            >
              Save
            </material.Button>
          </material.DialogActions>
        ) : null}
      </material.Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state,
    clinicData: state,
  };
};

export default connect(mapStateToProps)(InjectorList);
