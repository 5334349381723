import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { Controller, useForm } from 'react-hook-form';
import moment from "moment";
import { getClinicRotationDetailsByOrgIdAndClinicId, saveClinicRotation } from "../../../services/ClinicService";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from '@mui/x-date-pickers';
import { changeTimeFormate, changeTimeFormateInDay } from '../../../date-and-time-format/DateAndTimeFormat';
import Snackbar from '../../toastrmessage/Snackbar';

const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

function ClinicRoaster(props) {

    const { userDetails, clinicDetails } = props;
    const { register, handleSubmit, reset, control, setValue, formState: { errors, isValid } } = useForm({ mode: "onTouched" });

    const [clinicStatus, setClinicStatus] = useState(
        days.reduce((acc, day) => ({ ...acc, [day]: false }), {})
    );
    const [isLoading, setIsLoading] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });

    useEffect(() => {
        getRotationDetails();
    }, []);

    const getRotationDetails = () => {
        setIsLoading(true);
        let object = {
            orgId: userDetails.orgId,
            clinicId: clinicDetails.clinicId,
        };
        getClinicRotationDetailsByOrgIdAndClinicId(object)
            .then((res) => {
                showFormFieldsData(res.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            });
    };

    const showFormFieldsData = (data) => {
        data.forEach((item) => {
            const day = days[item.dayOfWeek];
            setValue(`clinicStatus.${day}`, item.open === 1);
            setValue(`openingTime.${day}`, dayjs(item.openingTime, "HH:mm:ss"));
            setValue(`closingTime.${day}`, dayjs(item.closingTime, "HH:mm:ss"));
            setClinicStatus((prev) => ({ ...prev, [day]: item.open === 1 }));
        });
    };

    const handleSwitchChange = (day) => (event) => {
        setClinicStatus((prevStatus) => ({
            ...prevStatus,
            [day]: event.target.checked,
        }));
    };

    const onSubmit = (data) => {
        let requestBody = [
            {
                dayOfWeek: "0",
                open: data.clinicStatus.Sunday,
                openingTime: data.openingTime.Sunday ? changeTimeFormateInDay(data.openingTime.Sunday.$d) : null,
                closingTime: data.closingTime.Sunday ? changeTimeFormateInDay(data.closingTime.Sunday.$d) : null,
                orgId: userDetails.orgId,
                clinicId: clinicDetails.clinicId,
            },
            {
                dayOfWeek: "1",
                open: data.clinicStatus.Monday,
                openingTime: data.openingTime.Monday ? changeTimeFormateInDay(data.openingTime.Monday.$d) : null,
                closingTime: data.closingTime.Monday ? changeTimeFormateInDay(data.closingTime.Monday.$d) : null,
                orgId: userDetails.orgId,
                clinicId: clinicDetails.clinicId,
            },
            {
                dayOfWeek: "2",
                open: data.clinicStatus.Tuesday,
                openingTime: data.openingTime.Tuesday ? changeTimeFormateInDay(data.openingTime.Tuesday.$d) : null,
                closingTime: data.closingTime.Tuesday ? changeTimeFormateInDay(data.closingTime.Tuesday.$d) : null,
                orgId: userDetails.orgId,
                clinicId: clinicDetails.clinicId,
            },
            {
                dayOfWeek: "3",
                open: data.clinicStatus.Wednesday,
                openingTime: data.openingTime.Wednesday ? changeTimeFormateInDay(data.openingTime.Wednesday.$d) : null,
                closingTime: data.closingTime.Wednesday ? changeTimeFormateInDay(data.closingTime.Wednesday.$d) : null,
                orgId: userDetails.orgId,
                clinicId: clinicDetails.clinicId,
            },
            {
                dayOfWeek: "4",
                open: data.clinicStatus.Thursday,
                openingTime: data.openingTime.Thursday ? changeTimeFormateInDay(data.openingTime.Thursday.$d) : null,
                closingTime: data.closingTime.Thursday ? changeTimeFormateInDay(data.closingTime.Thursday.$d) : null,
                orgId: userDetails.orgId,
                clinicId: clinicDetails.clinicId,
            },
            {
                dayOfWeek: "5",
                open: data.clinicStatus.Friday,
                openingTime: data.openingTime.Friday ? changeTimeFormateInDay(data.openingTime.Friday.$d) : null,
                closingTime: data.closingTime.Friday ? changeTimeFormateInDay(data.closingTime.Friday.$d) : null,
                orgId: userDetails.orgId,
                clinicId: clinicDetails.clinicId,
            },
            {
                dayOfWeek: "6",
                open: data.clinicStatus.Saturday,
                openingTime: data.openingTime.Saturday ? changeTimeFormateInDay(data.openingTime.Saturday.$d) : null,
                closingTime: data.closingTime.Saturday ? changeTimeFormateInDay(data.closingTime.Saturday.$d) : null,
                orgId: userDetails.orgId,
                clinicId: clinicDetails.clinicId,
            },
        ];
        saveClinicRotation(requestBody)
            .then((res) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: res.data.messages,
                });
                return new Promise((resolve) => {
                    setTimeout(() => {
                        getRotationDetails();
                        resolve(true);
                    }, 1000);
                });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            });
    };

    return (
        <div>
            {isLoading ? (
                <SkeletonTheme
                    baseColor="#bbdefb"
                    highlightColor="#c6ff00"
                    enableAnimation="true"
                    inline="true"
                    width="100% "
                    height="30px"
                >
                    <Skeleton count={10} />
                </SkeletonTheme>
            ) : (
                <div
                    style={{
                        marginTop: "1%",
                        alignContent: "center",
                        justifyContent: "center",
                    }}
                >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {days.map((day, i) => (
                            <material.Box
                                key={i}
                                sx={{
                                    width: '100%',
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    border: "1px solid #ccc",
                                    borderRadius: "8px",
                                    padding: "16px",
                                    marginBottom: "8px",
                                    boxShadow:
                                        "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                                }}
                            >
                                <div style={{ width: '25%' }}>
                                    <material.Typography variant="h6">
                                        {day}
                                    </material.Typography>
                                </div>
                                <div style={{ width: '25%' }}>
                                    <material.Stack
                                        direction="row"
                                        spacing={1}
                                        alignItems="center"
                                    >
                                        <span>Close</span>
                                        <Controller
                                            name={`clinicStatus.${day}`}
                                            control={control}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <material.Switch
                                                    {...field}
                                                    color="secondary"
                                                    checked={clinicStatus[day]}
                                                    onChange={(event) => {
                                                        field.onChange(event.target.checked);
                                                        handleSwitchChange(day)(event);
                                                    }}
                                                />
                                            )}
                                        />
                                        <span>Open</span>
                                    </material.Stack>
                                </div>
                                <div style={{ width: '25%' }}>
                                    <Controller
                                        name={`openingTime.${day}`}
                                        control={control}
                                        defaultValue={null}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                            >
                                                <TimePicker
                                                    label="Opening Time"
                                                    {...field}
                                                    onChange={(newValue) =>
                                                        field.onChange(newValue)
                                                    }
                                                    disabled={!clinicStatus[day]}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    />
                                </div>
                                <div style={{ width: '25%' }}>
                                    <Controller
                                        name={`closingTime.${day}`}
                                        control={control}
                                        defaultValue={null}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                            >
                                                <TimePicker
                                                    label="Closing Time"
                                                    {...field}
                                                    onChange={(newValue) =>
                                                        field.onChange(newValue)
                                                    }
                                                    disabled={!clinicStatus[day]}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    />
                                </div>
                            </material.Box>
                        ))}
                        <span className="float-end py-3">
                            <material.Button
                                type="submit"
                                variant="contained"
                                sx={{ textTransform: "none" }}
                                disabled={!isValid}
                            >
                                Save
                            </material.Button>
                        </span>
                    </form>
                </div>
            )}
            <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
        </div>
    );
}

export default ClinicRoaster;