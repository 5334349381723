import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { Controller, useForm } from 'react-hook-form';
import { getOrgList } from '../../services/UserManagementService';
import { getClinicByOrgId } from '../../services/ClinicService';
import Snackbar from '../toastrmessage/Snackbar';
import { createOrUpdateTierData } from '../../services/TierConfigService';

function AddOrUpdateTierConfig(props) {

    const { openAddOrUpdateTierDialog, setOpenAddOrUpdateTierDialog, getTierConfigList } = props;
    const { register, handleSubmit, reset, control, setValue, watch, getValues, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [organizationName, setOrganizationName] = useState([]);
    const [clinicName, setClinicName] = useState([]);
    const [organizationDetails, setOrganizationDetails] = useState([]);
    const [clinicDetails, setClinicDetails] = useState([]);
    const [selectTierType, setSelectTierType] = useState([]);
    const [orgId, setOrgId] = useState(null);
    const [clinicId, setClinicId] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (openAddOrUpdateTierDialog.action && openAddOrUpdateTierDialog.rowData == null) {
            getAllOrganization();
        } else if (openAddOrUpdateTierDialog.action && openAddOrUpdateTierDialog.rowData != null) {
            reset(openAddOrUpdateTierDialog.rowData);
            setValue("tierType", openAddOrUpdateTierDialog.rowData.tierType);
            setOrgId(openAddOrUpdateTierDialog.rowData.orgId);
            setClinicId(openAddOrUpdateTierDialog.rowData.clinicId);
            setSelectTierType(openAddOrUpdateTierDialog.rowData.tierType);
        }
    }, [openAddOrUpdateTierDialog.action]);

    const getAllOrganization = () => {
        getOrgList()
            .then((resp) => {
                setOrganizationDetails(resp.data.data);
                const orgName = resp.data.data?.map((org) => org.orgName);
                setOrganizationName(orgName);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getAllClinic = (orgId) => {
        getClinicByOrgId(orgId)
            .then((resp) => {
                setClinicDetails(resp.data.data);
                const clinicName = resp.data.data?.map((clinic) => clinic.clinicName);
                setClinicName(clinicName);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const handleChange = (event) => {
        setSelectTierType(event.target.value);
    };

    const handleClose = () => {
        reset({ orgName: "", clinicName: "", tierType: "" });
        setOrgId(null);
        setClinicId(null);
        setSelectTierType("");
        setOpenAddOrUpdateTierDialog({ action: false, rowData: null });
    };

    const saveTierData = (data) => {
        const payload = {
            tierId: data.tierId ? data.tierId : "",
            orgName: data.orgName,
            clinicName: data.clinicName,
            tierType: data.tierType,
            orgId: orgId,
            clinicId: clinicId,
        }
        createOrUpdateTierData(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                getTierConfigList();
                reset({ orgName: "", clinicName: "", tierType: "" });
                setOrgId(null);
                setClinicId(null);
                setSelectTierType("");
                setOpenAddOrUpdateTierDialog({ action: false, rowData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openAddOrUpdateTierDialog.action} maxWidth="sm" fullWidth>
                <material.DialogTitle>Add Tier Config</material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                        {openAddOrUpdateTierDialog.rowData != null ? (
                            <div className="col-12">
                                <material.TextField
                                    required
                                    fullWidth
                                    variant="standard"
                                    label="Organization Name"
                                    id='orgName'
                                    disabled
                                    {...register("orgName", { required: true })}
                                />
                            </div>
                        ) : (
                            <div className="col-12">
                                <Controller
                                    name="orgName"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <material.Autocomplete
                                            {...field}
                                            value={field.value}
                                            options={organizationName}
                                            renderInput={(params) => (
                                                <material.TextField
                                                    required
                                                    margin="dense"
                                                    {...params}
                                                    variant="standard"
                                                    label="Organization Name"
                                                    fullWidth
                                                />
                                            )}
                                            onChange={(event, data) => {
                                                field.onChange(data);
                                                if (data) {
                                                    const orgId = organizationDetails.filter((org) => org.orgName === data).map((x) => x.orgId)[0];
                                                    setOrgId(orgId);
                                                    getAllClinic(orgId);
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        )}
                        {openAddOrUpdateTierDialog.rowData != null ? (
                            <div className="col-12">
                                <material.TextField
                                    required
                                    fullWidth
                                    variant="standard"
                                    label="Clinic Name"
                                    id='clinicName'
                                    disabled
                                    {...register("clinicName", { required: true })}
                                />
                            </div>
                        ) : (
                            <div className="col-12">
                                <Controller
                                    name="clinicName"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <material.Autocomplete
                                            {...field}
                                            value={field.value}
                                            options={clinicName}
                                            renderInput={(params) => (
                                                <material.TextField
                                                    required
                                                    margin="dense"
                                                    {...params}
                                                    variant="standard"
                                                    label="Clinic Name"
                                                    fullWidth
                                                />
                                            )}
                                            onChange={(event, data) => {
                                                field.onChange(data);
                                                if (data) {
                                                    const clinicId = clinicDetails.filter((clinic) => clinic.clinicName === data).map((x) => x.clinicId)[0];
                                                    setClinicId(clinicId);
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        )}
                        <div className="col-12 mt-2">
                            <material.FormControl variant="standard" required fullWidth>
                                <material.InputLabel id="demo-simple-select-standard-label">Tier Type</material.InputLabel>
                                <material.Select
                                    {...register("tierType", { required: true })}
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectTierType}
                                    onChange={handleChange}
                                >
                                    <material.MenuItem value="Free">Free</material.MenuItem>
                                    <material.MenuItem value="Starter">Starter</material.MenuItem>
                                    <material.MenuItem value="Pro">Pro</material.MenuItem>
                                    <material.MenuItem value="Enterprise">Enterprise</material.MenuItem>
                                </material.Select>
                            </material.FormControl>
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} color='error' onClick={handleClose} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} color='success' onClick={handleSubmit(saveTierData)} startIcon={<material.DoneIcon />}>{openAddOrUpdateTierDialog.rowData != null ? "Update" : "Save"}</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default AddOrUpdateTierConfig;