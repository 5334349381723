import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { useForm } from 'react-hook-form';
import { uploadTreatmentDocument } from '../../services/DocumentManagement';
import { connect } from 'react-redux';
import Snackbar from '../toastrmessage/Snackbar';

const treatmentName = [
    "Chemical Peel Treatment",
    "IPL Treatment",
    "PDT Light Therapy Treatments",
    "Laser Hair Removal",
    "Microneedling Treatment",
    "Aqua-Infusion Skin Therapy",
    "Dermal Filler",
    "Anti-Wrinkle",
    "Skin Booster",
    "Threads",
    "Exomide Scalp",
    "CO2 Laser Treatment",
    "PICO Laser Treatment",
    "Tattoo Removal Treatment",
    "Skin Tags Removal Treatment"
];


function FileUpload(props) {

    const { open, setOpen, clinicData, userData, getAllDocumentList } = props;
    let userDetails = userData.authReducer.data;
    let clinicDetails = clinicData.clinicReducer.data;
    const { register, handleSubmit, resetField, formState: { errors, isValid }, setValue } = useForm({
        mode: "onTouched",
    });
    const [selectFolder, setSelectFolder] = useState();
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const selectTreatment = () => {

    };

    const chooseFile = (e) => {
        let fileName = e.target.files[0];
        setSelectFolder(fileName);
    };

    const cancel = () => {
        resetField("file")
        setOpen(false)
    };

    const save = async (formData) => {
        let data = new FormData();
        data.append('file', selectFolder);
        data.append('orgId', userDetails.orgId);
        data.append('treatmentName', formData.treatmentName);
        data.append('clinicId', clinicDetails.clinicId);
        await uploadTreatmentDocument(data)
            .then((res) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "File uploaded successfully",
                })
                resetField("file")
                setOpen(false)
                getAllDocumentList()
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="sm" open={open} hideBackdrop >
                <material.DialogTitle>Upload File
                </material.DialogTitle>
                <material.DialogContent>
                    <material.Autocomplete
                        id="area"
                        onChange={selectTreatment}
                        options={treatmentName}
                        renderInput={(params) => <material.TextField fullWidth {...params} required variant="standard" label="Select Treatment"
                            {...register("treatmentName", { required: true })}
                        // sx={{ width: 350 }}
                        />}
                    />
                    <material.TextField
                        margin="dense"
                        id="number"
                        label="Choose File"
                        type="file"
                        fullWidth
                        variant="standard"
                        onChange={chooseFile}
                    />
                </material.DialogContent>
                <material.DialogActions>
                    <span>
                        <material.Button sx={{ textTransform: "none", mr: 2 }} variant="contained" color='error' size="small" onClick={cancel}>Cancel</material.Button>
                        <material.Button sx={{ textTransform: "none" }} variant="contained" color='info' size="small" onClick={handleSubmit(save)}>Save</material.Button>
                    </span>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(FileUpload);