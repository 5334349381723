import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { Controller, useForm } from 'react-hook-form';
import Snackbar from '../toastrmessage/Snackbar';
import { getAllSupplierList, saveOrUpdateSupplierProductConfig } from '../../services/SupplierProductConfigService';


function AddSupplierProductConfig(props) {

    const { addSupplierProductConfig, setAddSupplierProductConfig, getSupplierProductConfigData } = props;

    const { register, handleSubmit, reset, control, getValues, setValue, watch, formState: { errors, isValid }, } = useForm({ mode: "onTouched" });
    const [supplierNameData, setSupplierNameData] = useState([]);
    const [selectedSupplierId, setSelectedSupplierId] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [uploadedImage, setUploadedImage] = useState(null);
    const [existingImage, setExistingImage] = useState(null);

    const onChangeListPriceExcludingGst = watch("listPriceExcludingGst");
    const onChangeListPriceIncludingGst = watch("listPriceIncludingGst");
    const onChangeMinimumOrderQuantity = watch("minimumOrderQuantity");
    const onChangeGst = watch("gst");


    useEffect(() => {
        if (addSupplierProductConfig.supplierProductConfigData) {
            const data = addSupplierProductConfig.supplierProductConfigData;
            Object.keys(data).forEach((key) => {
                setValue(key, data[key]);
            });
            setSelectedSupplierId({
                supplierId: data.supplierId,
                supplierName: data.supplierName
            });
            setExistingImage(data.productImage);
        } else if (addSupplierProductConfig.action) {
            getGetAllSupplierData();
            reset({
                totalGstAmount: 0, totalOfMinimumOrderQuantityIncludingGst: 0, totalOfMinimumOrderQuantityExcludingGst: 0, minimumOrderQuantity: 1, gst: 0, listPriceIncludingGst: 0,
                listPriceExcludingGst: 0
            });
        }
    }, [addSupplierProductConfig.action]);

    useEffect(() => {
        if (addSupplierProductConfig.action) {
            calculation()
        }
    }, [onChangeListPriceExcludingGst, onChangeListPriceIncludingGst, onChangeMinimumOrderQuantity, onChangeGst, addSupplierProductConfig.action]);

    const calculation = () => {
        const listPriceExcludingGst = getValues("listPriceExcludingGst");
        const listPriceIncludingGst = getValues("listPriceIncludingGst");
        const minimumOrderQuantity = getValues("minimumOrderQuantity");
        const gst = getValues("gst");
        setValue("gst", Number(listPriceIncludingGst) - Number(listPriceExcludingGst));
        setValue("totalGstAmount", Number(minimumOrderQuantity) * Number(gst));
        setValue("totalOfMinimumOrderQuantityIncludingGst", Number(listPriceIncludingGst) * Number(minimumOrderQuantity));
        setValue("totalOfMinimumOrderQuantityExcludingGst", Number(listPriceExcludingGst) * Number(minimumOrderQuantity));
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const validFileTypes = ["image/jpeg", "image/png", "image/jpg", "image/svg+xml"];
            if (!validFileTypes.includes(file.type)) {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: "Invalid file type. Only JPG, JPEG, PNG, and SVG are allowed.",
                });
                return;
            }
            setUploadedImage(file);
            setExistingImage(null);
        }
    };

    const getGetAllSupplierData = () => {
        getAllSupplierList()
            .then((resp) => {
                setSupplierNameData(resp.data.data)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const cancel = () => {
        reset({
            totalGstAmount: "", totalOfMinimumOrderQuantityIncludingGst: "", totalOfMinimumOrderQuantityExcludingGst: "", minimumOrderQuantity: "", gst: "", listPriceIncludingGst: "",
            listPriceExcludingGst: "", productDescription: "", productName: "", productCode: "",
        });
        setUploadedImage(null);
        setExistingImage(null);
        setSelectedSupplierId(null);
        setAddSupplierProductConfig({ action: false, supplierProductConfigData: null, });
    };

    const saveUpdateSupplierProductConfig = (data) => {
        const formData = new FormData();
        if (addSupplierProductConfig.supplierProductConfigData !== null) {
            formData.append("productConfigId", data.productConfigId);
        }
        formData.append("supplierId", selectedSupplierId?.supplierId);
        formData.append("supplierName", selectedSupplierId?.supplierName);
        formData.append("productCode", data.productCode);
        formData.append("productName", data.productName);
        formData.append("productDescription", data.productDescription);
        formData.append("listPriceExcludingGst", data.listPriceExcludingGst);
        formData.append("listPriceIncludingGst", data.listPriceIncludingGst);
        formData.append("gst", data.gst);
        formData.append("minimumOrderQuantity", data.minimumOrderQuantity);
        formData.append("totalOfMinimumOrderQuantityExcludingGst", data.totalOfMinimumOrderQuantityExcludingGst);
        formData.append("totalOfMinimumOrderQuantityIncludingGst", data.totalOfMinimumOrderQuantityIncludingGst);
        formData.append("totalGstAmount", data.totalGstAmount);

        if (uploadedImage) {
            formData.append("file", uploadedImage);
        } else if (existingImage) {
            formData.append("productImage", existingImage);
        }

        saveOrUpdateSupplierProductConfig(formData)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data.messages,
                });
                setAddSupplierProductConfig({ action: false, supplierProductConfigData: null, });
                getSupplierProductConfigData();
                cancel();
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            });
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="lg" open={addSupplierProductConfig.action} hideBackdrop >
                <material.DialogTitle className="fw-bold"> {addSupplierProductConfig.supplierProductConfigData ? "Edit Supplier Product Config" : "Add Supplier Product Config"} </material.DialogTitle>
                <material.DialogContent>
                    <div style={{
                        width: "100%", border: "1px solid #ccc", borderRadius: "8px", padding: "16px", marginTop: "2px",
                        boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                    }}
                    >
                        <div className="row">
                            <div className="col-lg-4 mt-2">
                                <material.Autocomplete
                                    options={supplierNameData}
                                    getOptionLabel={(option) => option.supplierName}
                                    onChange={(event, value) => setSelectedSupplierId(value)}
                                    value={selectedSupplierId}
                                    renderInput={(params) => (
                                        <material.TextField {...params} label="Supplier Name" variant="standard" />
                                    )}
                                />
                            </div>
                            <div className="col-lg-4 mt-2">
                                <material.TextField
                                    label="Supplier ID"
                                    value={selectedSupplierId?.supplierId || ""}
                                    variant="standard"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth
                                />
                            </div>
                            <div className="col-lg-4 mt-2">
                                <material.TextField
                                    multiline
                                    variant="standard"
                                    label="Product Code"
                                    type="text"
                                    fullWidth
                                    {...register("productCode", { required: true })}
                                />
                            </div>
                            <div className="col-lg-4 mt-2">
                                <material.TextField
                                    required
                                    multiline
                                    variant="standard"
                                    label="Product Name"
                                    type="text"
                                    fullWidth
                                    {...register("productName", { required: true })}
                                />
                            </div>
                            <div className="col-lg-4 mt-2">
                                <material.TextField
                                    required
                                    multiline
                                    variant="standard"
                                    label="Product Description"
                                    type="text"
                                    fullWidth
                                    {...register("productDescription", { required: true })}
                                />
                            </div>
                            <div className="col-lg-4 mt-2">
                                <span>
                                    <material.InputLabel>{"Product Image"}</material.InputLabel>
                                    <input
                                        type="file"
                                        accept=".jpg,.jpeg,.png,.svg"
                                        onChange={handleImageUpload}
                                    />
                                    {existingImage && (
                                        <p>
                                            current Image: {existingImage.split("/").pop()}
                                        </p>
                                    )}
                                </span>
                            </div>
                            <div className="col-lg-4 mt-2">
                                <material.TextField
                                    required
                                    variant="standard"
                                    label="List Price Including GST"
                                    type="number"
                                    fullWidth
                                    {...register("listPriceIncludingGst", { required: true })}
                                />
                            </div>
                            <div className="col-lg-4 mt-2">
                                <material.TextField
                                    required
                                    variant="standard"
                                    label="List Price Excluding GST"
                                    type="number"
                                    fullWidth
                                    {...register("listPriceExcludingGst", { required: true })}
                                />
                            </div>
                            <div className="col-lg-4 mt-2">
                                <material.TextField
                                    required
                                    variant="standard"
                                    label="Minimum Order Quantity"
                                    type="number"
                                    fullWidth
                                    {...register("minimumOrderQuantity", { required: true })}
                                />
                            </div>
                            <div className="col-lg-4 mt-2">
                                <material.TextField
                                    required
                                    variant="standard"
                                    label="GST"
                                    type="number"
                                    fullWidth
                                    InputProps={{ readOnly: true }}
                                    {...register("gst", { required: true })}
                                />
                            </div>
                            <div className="col-lg-4 mt-2">
                                <material.TextField
                                    required
                                    variant="standard"
                                    label="Total for Minimum Order Quantity Including Gst"
                                    type="number"
                                    fullWidth
                                    InputProps={{ readOnly: true }}
                                    {...register("totalOfMinimumOrderQuantityIncludingGst", { required: true })}
                                />
                            </div>
                            <div className="col-lg-4 mt-2">
                                <material.TextField
                                    required
                                    variant="standard"
                                    label="Total for Minimum Order Quantity Excluding Gst"
                                    type="number"
                                    fullWidth
                                    InputProps={{ readOnly: true }}
                                    {...register("totalOfMinimumOrderQuantityExcludingGst", { required: true })}
                                />
                            </div>
                            <div className="col-lg-4 mt-2">
                                <material.TextField
                                    required
                                    variant="standard"
                                    label="Total Gst Amount"
                                    type="number"
                                    fullWidth
                                    InputProps={{ readOnly: true }}
                                    {...register("totalGstAmount", { required: true })}
                                />
                            </div>
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <div className="mt-3"> <span className="float-end py-3">
                        <material.Button size="medium" className="me-2" onClick={cancel} variant="contained" sx={{ textTransform: "none" }} color="error" startIcon={<material.CloseIcon />}>Cancel</material.Button>
                        {addSupplierProductConfig.supplierProductConfigData ? (
                            <material.Button size="medium" className="me-2" onClick={handleSubmit(saveUpdateSupplierProductConfig)} variant="contained" sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />} color='success'>Update</material.Button>
                        ) : (
                            <material.Button size="medium" className="me-2" onClick={handleSubmit(saveUpdateSupplierProductConfig)} variant="contained" sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />} color='success'>Save</material.Button>)}
                    </span>
                    </div>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}

            />
        </div>
    )
}

export default AddSupplierProductConfig