import React, { useState } from "react";
import { material } from "../../library/material";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller, useForm } from "react-hook-form";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import { saveUserHoliday } from "../../services/InjectorService";
import Snackbar from "../toastrmessage/Snackbar";
import { saveClinicHoliday } from "../../services/ClinicConfigService";

const AddHolidayDate = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm({ mode: "onTouched" });
  const { setOpenAddHolidayDateDialog, openAddHolidayDateDialog, getUsersHolidayData, callFrom, userDetails, clinicDetails, getClinicHolidayData } = props;

  const [openSnackBar, setOpenSnackBar] = useState({
    action: false,
    type: "",
    message: "",
  });

  const cancel = () => {
    reset({ data: "", startTime: "", endTime: "", purpose: "" });
    setOpenAddHolidayDateDialog({ action: false, data: null, userData: null });
  };

  const onsubmit = (data) => {
    if (callFrom === "clinic-config") {
      const payload = {
        "clinicId": clinicDetails.clinicId,
        "orgId": userDetails.orgId,
        "startDate": moment(data.startDate.$d).format("YYYY-MM-DD"),
        "endDate": moment(data.endDate.$d).format("YYYY-MM-DD"),
        "startTime": moment(data.startTime.$d).format("HH:mm:ss"),
        "endTime": moment(data.endTime.$d).format("HH:mm:ss"),
        "purpose": data.purpose,
      }
      saveClinicHoliday(payload)
        .then((resp) => {
          setOpenSnackBar({
            action: true,
            type: "success",
            message: resp.data.messages,
          });
          getClinicHolidayData();
          reset({ data: "", startTime: "", endTime: "", purpose: "" });
          setOpenAddHolidayDateDialog({ action: false, data: null, userData: null });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response.data.data.info,
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response.data.messages,
            })
          }
        })
    } else {
      const payload = {
        "clinicId": openAddHolidayDateDialog.userData.clinicId,
        "orgId": openAddHolidayDateDialog.userData.orgId,
        "startDate": moment(data.startDate.$d).format("YYYY-MM-DD"),
        "endDate": moment(data.endDate.$d).format("YYYY-MM-DD"),
        "startTime": moment(data.startTime.$d).format("HH:mm:ss"),
        "endTime": moment(data.endTime.$d).format("HH:mm:ss"),
        "purpose": data.purpose,
        userId: openAddHolidayDateDialog.userData.userId
      }
      saveUserHoliday(payload)
        .then((resp) => {
          setOpenSnackBar({
            action: true,
            type: "success",
            message: resp.data.messages,
          });
          getUsersHolidayData();
          reset({ data: "", startTime: "", endTime: "", purpose: "" });
          setOpenAddHolidayDateDialog({ action: false, data: null, userData: null });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response.data.data.info,
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response.data.messages,
            })
          }
        })
    }
  };

  return (
    <div>
      <material.Dialog
        fullWidth
        maxWidth="sm"
        open={openAddHolidayDateDialog.action}
        hideBackdrop
      >
        <material.DialogTitle className="fw-bold">
          Add Holiday
        </material.DialogTitle>
        <material.DialogContent>
          <div
            style={{
              width: "100%",
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "space-between",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "16px",
              marginTop: "2px",
              boxShadow:
                "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
            }}
          >
            <div className="row">
              <div className="col-12">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker", "DatePicker"]} sx={{ mt: -1 }}>
                    <Controller
                      name="startDate"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={null}
                      render={({ field }) => (
                        <DatePicker
                          label="Start Date"
                          {...field}
                          onChange={(newValue) => field.onChange(newValue)}
                          sx={{ width: '100%' }}
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div className="col-12 mt-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker", "DatePicker"]} sx={{ mt: -1 }}>
                    <Controller
                      name="endDate"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={null}
                      render={({ field }) => (
                        <DatePicker
                          label="End Date"
                          {...field}
                          onChange={(newValue) => field.onChange(newValue)}
                          sx={{ width: '100%' }}
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div className="col-12 mt-2">
                <Controller
                  name={`startTime`}
                  control={control}
                  defaultValue={null}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Start Time"
                        {...field}
                        onChange={(newValue) => field.onChange(newValue)}
                        sx={{ width: '100%' }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
              <div className="col-12 mt-2">
                <Controller
                  name={`endTime`}
                  control={control}
                  rules={{ required: true }}
                  defaultValue={null}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="End Time"
                        {...field}
                        onChange={(newValue) => field.onChange(newValue)}
                        sx={{ width: '100%' }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
              <div className="col-12 mt-2">
                <material.TextField
                  multiline
                  variant="outlined"
                  label="Purpose"
                  type="text"
                  fullWidth
                  {...register("purpose", { required: true })}
                />
              </div>
            </div>
          </div>
        </material.DialogContent>
        <material.DialogActions>
          <div className="mt-3">
            <span className="float-end py-3">
              <material.Button
                variant="contained"
                sx={{ textTransform: "none" }}
                size="medium"
                className="me-2"
                color="error"
                onClick={cancel}
              >
                Close
              </material.Button>
              <material.Button
                variant="contained"
                sx={{ textTransform: "none" }}
                size="medium"
                className="me-2"
                onClick={handleSubmit(onsubmit)}
              >
                Save
              </material.Button>
            </span>
          </div>
        </material.DialogActions>
      </material.Dialog>
      <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
    </div>
  );
};

export default AddHolidayDate;
