import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import Navbar from '../../navbar/Navbar';
import { useTheme } from '@mui/material';
import Snackbar from '../../toastrmessage/Snackbar';
import { addProductToCart, getPlatformProductList } from '../../../services/ProductCatalogueService';
import { rootApiUrl } from '../../../environment/Environment';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';



function ProductCatalogue(props) {

    const theme = useTheme();
    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const { register, handleSubmit, reset, setValue, control, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [platformProductListAllData, setPlatformProductListAllData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [totalElements, setTotalElements] = useState(0);

    useEffect(() => {
        getPlatformProductListData();
    }, []);

    const getPlatformProductListData = async (paginationData) => {
        setIsLoading(true);
        const payload = {
            limit: paginationData ? paginationData.limit : 10,
            page: paginationData ? paginationData.page : 0,
            supplierName: paginationData ? paginationData.supplierName : "",
            productName: paginationData ? paginationData.productName : "",
            productCode: paginationData ? paginationData.productCode : ""
        }
        await getPlatformProductList(payload)
            .then((resp) => {
                setPlatformProductListAllData(resp.data.data.results);
                setPage(Number(resp.data.data.currentPage));
                setRowsPerPage(Number(resp.data.data.limit));
                setTotalElements(resp.data.data.totalItems);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            });
    };

    const handleAddToCart = async (product) => {
        const payload = {
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId,
            productConfigId: product.productConfigId,
            quantity: product.minimumOrderQuantity
        };
        await addProductToCart(payload)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data.messages,
                });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const payload = {
            limit: rowsPerPage,
            page: newPage,
        }
        getPlatformProductListData(payload)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        const payload = {
            limit: event.target.value,
            page: page
        }
        getPlatformProductListData(payload)
    };

    const applyFilter = (formData) => {
        getPlatformProductListData(formData)
    };

    const handleRefresh = () => {
        getPlatformProductListData();
        reset({ productName: "", productCode: "", supplierName: "" });
    };

    return (
        <material.Grid className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: { mt: 10 },
                [theme.breakpoints.up('sm')]: { mt: 10 },
                [theme.breakpoints.up('md')]: { mt: 12 },
                [theme.breakpoints.up('lg')]: { mt: 12 },
            }}>
            <Navbar />
            {isLoading ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                    <material.CircularProgress color='secondary' />
                </div>
            ) : (
                <div className="row">
                    <div className="col-12" style={{ marginTop: "-20px" }}>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    margin="dense"
                                    fullWidth
                                    variant="standard"
                                    label="Search Product"
                                    type="text"
                                    {...register("productName")}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    margin="dense"
                                    fullWidth
                                    variant="standard"
                                    label="Search Supplier"
                                    type="text"
                                    {...register("supplierName")}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    margin="dense"
                                    fullWidth
                                    variant="standard"
                                    label="Search Product Code"
                                    type="text"
                                    {...register("productCode")}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mt-3">
                                <span>
                                    <material.IconButton title='Apply Filter' onClick={handleSubmit(applyFilter)}>
                                        <material.FilterListIcon color='info' />
                                    </material.IconButton>
                                    <material.IconButton title='Refresh' onClick={handleRefresh}>
                                        <material.RefreshIcon color='info' />
                                    </material.IconButton>
                                </span>
                            </div>
                        </div>
                    </div>
                    {platformProductListAllData?.length ? platformProductListAllData.map((product, k) => (
                        <div className="col-lg-6 col-md-12 col-sm-12 mt-3" key={k}>
                            <material.Card sx={{ display: 'flex' }}>
                                <material.CardMedia
                                    component="img"
                                    sx={{ width: "40%", objectFit: "contain", p: 1 }}
                                    image={`${rootApiUrl}/agl-link?key=${product.productImage}`}
                                    alt="Live from space album cover"
                                />
                                <material.Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <material.CardContent sx={{ flex: '1 0 auto' }}>
                                        <material.Typography sx={{ fontWeight: "bold", fontSize: 20 }}>{product.productName}</material.Typography>
                                        <material.Typography>Product Description: <material.Tooltip title={product.productDescription}>
                                            <span className='textContainer fw-bold'>{product.productDescription}</span>
                                        </material.Tooltip></material.Typography>
                                        <material.Typography>Product Code: <span className='fw-bold'>{product.productCode}</span></material.Typography>
                                        <material.Typography>Supplier Name: <span className='fw-bold'>{product.supplierName}</span></material.Typography>
                                        <material.Typography>List Price Including GST: <span className='fw-bold'>${product.listPriceIncludingGst}</span></material.Typography>
                                        <material.Typography>List Price Excluding GST: <span className='fw-bold'>${product.listPriceExcludingGst}</span></material.Typography>
                                        <material.Typography>GST: <span className='fw-bold'>${product.gst}</span></material.Typography>
                                        <material.Typography>Quantity: <span className='fw-bold'>${product.minimumOrderQuantity}</span></material.Typography>
                                    </material.CardContent>
                                    <material.Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                        <material.Button
                                            variant="outlined"
                                            color="primary"
                                            fullWidth
                                            onClick={() => handleAddToCart(product)}
                                            startIcon={<material.ShoppingCartIcon />}
                                        >
                                            Add to Cart
                                        </material.Button>
                                    </material.Box>
                                </material.Box>
                            </material.Card>
                        </div>
                    )) : null}
                    <div className="col-12 mt-3">
                        <hr />
                        <span className='d-flex justify-content-center align-item-center'>
                            <material.TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                // component="div"
                                count={totalElements}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </span>
                    </div>
                </div>
            )}
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </material.Grid>
    )
};

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state,
    };
};

export default connect(mapStateToProps)(ProductCatalogue);
