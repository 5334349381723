import axios from "axios";
import { rootApiUrl } from "../environment/Environment";


export const getConsentFormIdByTreatmentPlanId = async (treatmentPlanRequestId) => {
    return await axios.get(`${rootApiUrl}/get-consent-form-id-by-treatment-plan/${treatmentPlanRequestId}`);
};

// export const saveConsentFormSignature = async (consentFormData) => {
//     return await axios.post(`${rootApiUrl}/save-consent-form-signature`, consentFormData);
// };

export const sendConsentForm = async (patientData) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/cosmetic-treatment/send-concent-email`, patientData)
};

export const saveConsentFormOpen = async (formData) => {
    return await axios.post(`${rootApiUrl}/save-concent-form-open`, formData)
};

export const saveConsentForm = async (formData) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/cosmetic-treatment/save-concent-form`, formData)
};

export const saveSkinConsentForm = async (formData) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/skin-treatment/save-consent-skin`, formData)
};