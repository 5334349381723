import React, { useState } from 'react';
import { material } from '../../../../library/material';
import { StyledTableCell } from '../../../../shared/TableHeaderStyle';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import EditSkinAndCosmeticTreatment from './EditSkinAndCosmeticTreatment';
import { getSkinTreatmentPriceListForBookingByClinicId } from '../../../../services/SkinTreatmentService';
import { getTreatmentListByClinicId } from '../../../../services/TreatmentListService';
import DeletePermissionDialog from './DeletePermissionDialog';
import { updatePlanDetails } from '../../../../services/TreatmentPlanService';
import Snackbar from '../../../toastrmessage/Snackbar';

function ViewTreatmentList(props) {

    const { isLoading, getTreatmentPlanData, clinicDetails, userDetails, getProposedTreatmentPlan } = props;
    const [openEditSkinAndCosmeticTreatment, setOpenEditSkinAndCosmeticTreatment] = useState({ action: false, rowData: null });
    const [treatmentList, setTreatmentList] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [openDeletePermissionDialog, setOpenDeletePermissionDialog] = useState({ action: false, rowData: null });

    const editSkinAndCosmeticTreatment = (rowData) => {
        if (rowData.recordType === "Cosmetic_Injectable") {
            getCosmeticTreatmentList()
        } else if (rowData.recordType === "Skin_Treatment") {
            getSkinTreatmentList()
        }
        setOpenEditSkinAndCosmeticTreatment({ action: true, rowData: rowData });
    };

    const deleteTreatment = (rowData) => {
        setOpenDeletePermissionDialog({ action: true, rowData: rowData })
    };

    const getDeletePermissionData = (data) => {
        const payload = {
            id: data.id,
            sessionNumber: data.sessionNumber,
            slNo: data.slNo,
            treatmentOrProductName: data.treatmentOrProductName,
            treatmentAreaOrProductInstructions: data.treatmentAreaOrProductInstructions,
            totalNumberOfSessionsRequired: data.totalNumberOfSessionsRequired,
            costPerSession: data.costPerSession,
            totalCost: data.totalCost,
            dateFormat: data.dateFormat,
            days: data.days,
            active: false
        }
        updatePlanDetails(payload)
            .then((resp) => {
                getProposedTreatmentPlan(data.proposedPlanId)
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data.messages,
                });
                setOpenDeletePermissionDialog({ action: false, rowData: null })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getCosmeticTreatmentList = () => {
        getTreatmentListByClinicId(clinicDetails.clinicId, userDetails.orgId)
            .then((resp) => {
                setTreatmentList(resp.data.data);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getSkinTreatmentList = () => {
        getSkinTreatmentPriceListForBookingByClinicId(clinicDetails.clinicId)
            .then((resp) => {
                const skinTreatment = resp.data.data.map((x) => {
                    return {
                        treatmentName: x.treatmentName,
                        treatmentArea: x.treatmentDetails.map((x) => x.treatmentArea),
                    };
                });
                setTreatmentList(skinTreatment);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    return (
        <div>
            <material.TableContainer>
                <material.Table>
                    <material.TableHead >
                        <material.TableRow>
                            <StyledTableCell>Session No.</StyledTableCell>
                            <StyledTableCell>Serial / Treatment Number</StyledTableCell>
                            <StyledTableCell>Treatment / Product Name</StyledTableCell>
                            <StyledTableCell>Treatment Area / Product Instruction</StyledTableCell>
                            <StyledTableCell>Total Session Required</StyledTableCell>
                            <StyledTableCell>Routine</StyledTableCell>
                            <StyledTableCell>Frequency</StyledTableCell>
                            <StyledTableCell>Action</StyledTableCell>
                        </material.TableRow>
                    </material.TableHead>
                    <material.TableBody>
                        {isLoading ? (
                            <material.TableRow >
                                <material.TableCell colSpan={12}>
                                    <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                        <Skeleton count={12} />
                                    </SkeletonTheme>
                                </material.TableCell>
                            </material.TableRow>
                        ) : (
                            <>
                                {getTreatmentPlanData?.length ? getTreatmentPlanData?.map((row, i) => (
                                    <material.TableRow
                                        key={i}
                                    >
                                        <material.TableCell>{row.sessionNumber}</material.TableCell>
                                        <material.TableCell>{row.slNo}</material.TableCell>
                                        <material.TableCell>{row.treatmentOrProductName}</material.TableCell>
                                        <material.TableCell>{row.treatmentAreaOrProductInstructions}</material.TableCell>
                                        <material.TableCell>{row.totalNumberOfSessionsRequired}</material.TableCell>
                                        <material.TableCell>{row.routine ? row.routine : "N/A"}</material.TableCell>
                                        <material.TableCell>{row.days + " " + row.dateFormat}</material.TableCell>
                                        <material.TableCell>
                                            <material.IconButton title='Edit Treatment' onClick={() => editSkinAndCosmeticTreatment(row)}>
                                                <material.CreateIcon color='info' />
                                            </material.IconButton>
                                            <material.IconButton title='Delete Treatment' onClick={() => deleteTreatment(row)}>
                                                <material.DeleteIcon color='error' />
                                            </material.IconButton>
                                        </material.TableCell>
                                    </material.TableRow>
                                )) : (
                                    <material.TableRow >
                                        <material.TableCell colSpan={12}>
                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                        </material.TableCell>
                                    </material.TableRow>
                                )}
                            </>)}
                    </material.TableBody>
                </material.Table>
            </material.TableContainer>
            <EditSkinAndCosmeticTreatment
                setOpenEditSkinAndCosmeticTreatment={setOpenEditSkinAndCosmeticTreatment}
                openEditSkinAndCosmeticTreatment={openEditSkinAndCosmeticTreatment}
                clinicDetails={clinicDetails}
                userDetails={userDetails}
                treatmentList={treatmentList}
                getProposedTreatmentPlan={getProposedTreatmentPlan}
            />
            <DeletePermissionDialog
                getDeletePermissionData={getDeletePermissionData}
                openDeletePermissionDialog={openDeletePermissionDialog}
                setOpenDeletePermissionDialog={setOpenDeletePermissionDialog}
                title="Treatment"
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default ViewTreatmentList;