import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import Snackbar from '../../toastrmessage/Snackbar';
import { saveCrmAllData } from '../../../services/PlatformCrmService';



function AddPlatFormCrmDetails(props) {
    const { openAddCrm, setOpenAddCrm, getCrmData, clinicDetails, userDetails } = props;

    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm({
        mode: "onTouched",
    });


    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [selectFolder, setSelectFolder] = useState();
    // const [selectTemplate, setSelectTemplate] = useState("");
    // const [templateList, setTemplateList] = useState([]);
    // const [customCampaignList, setCustomCampaignList] = useState([]);


    const chooseFile = (e) => {
        let fileName = e.target.files[0];
        setSelectFolder(fileName);
    };

    const cancel = () => {
        reset({crmName:""});
        // reset({ campaignName: "", emailSubject: "" });
        // setSelectTemplate("");
        setOpenAddCrm(false);
    };

    const save = async (formData) => {
        let data = new FormData();

        data.append('excelFile', selectFolder);
        data.append('orgId', userDetails.orgId);
        data.append('clinicId', clinicDetails.clinicId);
        data.append('crmName', formData.crmName);
        // data.append('campaignName', formData.campaignName);
        // data.append('emailSubject', formData.emailSubject);
        // data.append('emailTemplateId', selectTemplate);

        await saveCrmAllData(data)
            .then((resp) => {
                console.log(resp)
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    // message: resp?.data?.messages,
                    message: "Data save successful",
                });
                reset({crmName:""});
                // reset({ campaignName: "", emailSubject: "", smsText: "" });
                // setSelectTemplate("");
                getCrmData();
                setOpenAddCrm(false);
                // cancel();

            })
            .catch((error) => {
                console.log(error)
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error?.response?.data,
                });
            });
    };

    // const handleChangeTemplate = (event) => {
    //     setSelectTemplate(event.target.value);
    // };

    return (
        <div>
            <material.Dialog open={openAddCrm} fullWidth maxWidth="sm" hideBackdrop>
                <material.DialogTitle>Save CRM</material.DialogTitle>
                <material.DialogContent>
                    {/* <material.TextField
                        required
                        margin="dense"
                        id="subject"
                        label="Email Subject"
                        type="text"
                        fullWidth
                        variant="standard"
                        {...register("emailSubject", { required: true })}
                    /> */}
                    <material.TextField
                        required
                        margin="dense"
                        id="number"
                        label="CRM Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        {...register("crmName", { required: true })}
                    />
                    <div className='py-2'>
                        <label for="excelFile">Choose Excel File</label><br />
                        <input
                            type="file"
                            accept=".xlsx"
                            margin="dense"
                            onChange={chooseFile}
                        />
                    </div>

                    {/* <material.FormControl variant="standard" fullWidth margin="dense">
                        <material.InputLabel id="demo-simple-select-standard-label" required>Select Template</material.InputLabel>
                        <material.Select
                            {...register("emailTemplateId", { required: true })}
                            required
                            margin="dense"
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={selectTemplate}
                            onChange={handleChangeTemplate}
                        >
                            {templateList.map((ele, i) =>
                                <material.MenuItem key={i} value={ele.emailTemplateId}>{ele.emailTemplateName}</material.MenuItem>
                            )};
                        </material.Select>
                    </material.FormControl> */}


                </material.DialogContent>
                <material.DialogActions>
                    <span className='m-2'>
                        <material.Button sx={{ textTransform: "none", mr: 2 }} variant="outlined" color='error' onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                        <material.Button sx={{ textTransform: "none" }} variant="outlined" onClick={handleSubmit(save)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    </span>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};
export default connect(mapStateToProps)(AddPlatFormCrmDetails);

// export default AddPlatFormCrmDetails