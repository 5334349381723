import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { changeDateFormate, changeTimeFormate } from "../../../date-and-time-format/DateAndTimeFormat";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import { getClinicHoliday } from '../../../services/ClinicConfigService';
import AddHolidayDate from '../../dialog/AddHolidayDate';

function ClinicHoliday(props) {

    const { userDetails, clinicDetails } = props;

    const [clinicHolidayData, setClinicHolidayData] = useState([]);
    const [openAddHolidayDateDialog, setOpenAddHolidayDateDialog] = useState({
        action: false,
        data: null,
        userData: null
    });
    const [isLoading, setIsLoading] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });

    useEffect(() => {
        getClinicHolidayData();
    }, []);

    const getClinicHolidayData = () => {
        setIsLoading(true);
        const payload = {
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId
        }
        getClinicHoliday(payload)
            .then((resp) => {
                setClinicHolidayData(resp.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const openAddHolidayDialog = () => {
        setOpenAddHolidayDateDialog({ action: true, data: null, userData: null });
    };

    return (
        <div style={{ marginTop: "-25px" }}>
            <div className="row">
                <div className="col-12">
                    <span className="float-end">
                        <material.Button
                            startIcon={<material.AddIcon />}
                            variant="contained"
                            sx={{ textTransform: "none" }}
                            onClick={openAddHolidayDialog}
                        >
                            Add Holiday
                        </material.Button>
                    </span>
                </div>
                <div className="col-12 mt-2">
                    <material.Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <material.TableContainer>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead>
                                    <material.TableRow>
                                        <StyledTableCell>Date</StyledTableCell>
                                        <StyledTableCell>Start Time</StyledTableCell>
                                        <StyledTableCell>End Time</StyledTableCell>
                                        <StyledTableCell>Purpose</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow>
                                            <material.TableCell colSpan={10}>
                                                <SkeletonTheme
                                                    baseColor="#bbdefb"
                                                    highlightColor="#c6ff00"
                                                    enableAnimation="true"
                                                    inline="true"
                                                    width="100% "
                                                    height="30px"
                                                >
                                                    <Skeleton count={10} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {clinicHolidayData.length ? clinicHolidayData.map((holiday, i) => (
                                                <material.TableRow key={i}>
                                                    <material.TableCell>{changeDateFormate(holiday.date)}</material.TableCell>
                                                    <material.TableCell>{changeTimeFormate(holiday.startTime)}</material.TableCell>
                                                    <material.TableCell>{changeTimeFormate(holiday.endTime)}</material.TableCell>
                                                    <material.TableCell>{holiday.purpose}</material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow>
                                                    <material.TableCell colSpan={10}>
                                                        <h6 className="d-flex justify-content-center text-danger fw-bold">
                                                            No data found
                                                        </h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>
                                    )}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                    </material.Paper>
                </div>
            </div>
            <AddHolidayDate
                userDetails={userDetails}
                clinicDetails={clinicDetails}
                callFrom="clinic-config"
                openAddHolidayDateDialog={openAddHolidayDateDialog}
                setOpenAddHolidayDateDialog={setOpenAddHolidayDateDialog}
                getClinicHolidayData={getClinicHolidayData}
            />
        </div>
    );
}

export default ClinicHoliday;