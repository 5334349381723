import React, { useEffect, useState } from 'react';
import { material } from '../../../../library/material';
import { StyledTableCell } from '../../../../shared/TableHeaderStyle';
import { getSOPData } from '../../../../services/ProtocolService';
import Snackbar from '../../../toastrmessage/Snackbar';
import { Slide } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function ViewProtocolList(props) {

    const { userDetails, clinicDetails, openViewProtocolList, setOpenViewProtocolList } = props;
    const [getProtocolData, setProtocolData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [openViewProtocolDetails, setOpenViewProtocolDetails] = useState({ action: false, data: null });

    useEffect(() => {
        if (openViewProtocolList) {
            getProtocol()
        }
    }, [openViewProtocolList === true]);

    const getProtocol = (searchProtocol) => {
        const payload = {
            orgId: userDetails.orgId,
            clinicId: clinicDetails.clinicId,
            search: searchProtocol ? searchProtocol.search : ""
        }
        getSOPData(payload)
            .then((resp) => {
                setProtocolData(resp.data.data.results)
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data.messages,
                });
            })
    };

    const viewProtocolDetails = (rowData) => {
        // setOpenViewProtocolDetails({ action: true, data: rowData })
        const newTab = window.open("", "_blank")
        newTab.document.write(rowData.sopDetails)
    };

    const handleClose = () => {
        setOpenViewProtocolList(false);
    };

    const searchProtocol = (value) => {
        const payload = {
            search: value
        }
        getProtocol(payload)
    };

    return (
        <div>
            <material.Dialog open={openViewProtocolList}
                fullScreen
                PaperProps={{
                    sx: {
                        backgroundColor: '#fff',
                    },
                }}
                TransitionComponent={Transition}
            >
                <material.AppBar sx={{
                    position: 'relative',
                }}>
                    <material.Toolbar>
                        <material.IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <material.CloseIcon />
                        </material.IconButton>
                        <material.Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Protocol List
                        </material.Typography>
                    </material.Toolbar>
                </material.AppBar>
                <material.DialogContent>
                    <div className="row">
                        <div className="col-12">
                            <span style={{ marginLeft: 5 }}>
                                <material.TextField
                                    fullWidth
                                    variant="standard"
                                    label="Search Protocol"
                                    onChange={(e) => searchProtocol(e.target.value)}
                                />
                            </span>
                        </div>
                        <div className="col-12 mt-3">
                            <material.TableContainer>
                                <material.Table>
                                    <material.TableHead>
                                        <material.TableRow>
                                            <StyledTableCell>Treatment Name</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {getProtocolData.length ? getProtocolData.map((item, k) => (
                                            <material.TableRow key={k}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } }}
                                                onClick={() => viewProtocolDetails(item)}
                                            >
                                                <material.TableCell>{item.forTreatment}</material.TableCell>
                                            </material.TableRow>
                                        )) : (
                                            <material.TableRow>
                                                <material.TableCell colSpan={10}>
                                                    <h6 className="d-flex justify-content-center text-danger fw-bold">
                                                        No data found
                                                    </h6>
                                                </material.TableCell>
                                            </material.TableRow>
                                        )}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                        </div>
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <ViewProtocolDetails
                openViewProtocolDetails={openViewProtocolDetails}
                setOpenViewProtocolDetails={setOpenViewProtocolDetails}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
};

const ViewProtocolDetails = (props) => {

    const { openViewProtocolDetails, setOpenViewProtocolDetails } = props;

    const handleClose = () => {
        setOpenViewProtocolDetails({ action: false, data: null });
    };

    return (
        <div>
            <material.Dialog open={openViewProtocolDetails.action} fullWidth maxWidth="md">
                <material.DialogTitle className='fw-bold'>Protocol Details
                    <span className='float-end'>
                        <material.IconButton title='Close' color='error' onClick={handleClose}>
                            <material.CloseIcon sx={{ cursor: "pointer" }} />
                        </material.IconButton>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                        <div className="col-12">
                            <span>{<div dangerouslySetInnerHTML={{ __html: openViewProtocolDetails.data?.sopDetails }} />}</span>
                        </div>
                    </div>
                </material.DialogContent>
            </material.Dialog>
        </div>
    )
}

export default ViewProtocolList;