import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPrescription, getTreatmentPlanInjector } from '../../../services/ApprovalWatingService';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { changeDateAndTimeFormate } from '../../../date-and-time-format/DateAndTimeFormat';
import Snackbar from '../../toastrmessage/Snackbar';
import StatusChangeReasonDialog from '../../dialog/StatusChangeReasonDialog';
import { socket } from '../../../socket/Socket';
import FinaliseDialog from '../../dialog/FinaliseDialog';
import ViewPreWrittenNote from '../../dialog/ViewPreWrittenNote';
import Navbar from '../../navbar/Navbar';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#4e73df",
        color: theme.palette.common.white,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

var allPatientData;

function ApprovalWating(props) {

    const [patientData, setPatientData] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [isLoading, setisLoading] = useState(true);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openStatusChangeDialog, setOpenStatusChangeDialog] = useState({
        "action": false, "data": ""
    });
    const [queueSelector, setQueueSelector] = useState("Pending")
    const [treatmentQueueUpdated, setTreatmentQueueUpdated] = useState();
    const [openFinaliseDialog, setOpenFinaliseDialog] = useState({ action: false, treatmentData: null });
    const [openViewNotesDialog, setOpenViewNotesDialog] = useState({ action: false, notes: null });
    const navigate = useNavigate();
    const location = useLocation();

    let planRequestId = location.state?.planRequestId;
    localStorage.setItem("menuName", "Treatment Quere");

    socket.on("treatment-queue-updated", (res) => {
        setTreatmentQueueUpdated(res);
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Queue Updated",
        })
    });

    useEffect(() => {
        getTreatmentPlan();
    }, [planRequestId, queueSelector, treatmentQueueUpdated]);

    const queueChange = (e, value) => {
        setQueueSelector(value)
        setisLoading(true)
    };

    const getTreatmentPlan = async () => {
        let status;
        if (queueSelector === "Pending") {
            status = "PENDING"
        } else if (queueSelector === "Draft") {
            status = "DRAFT"
        } else if (queueSelector === "Approved") {
            status = "APPROVED"
        } else if (queueSelector === "Finalis") {
            status = "USED"
        } else if (queueSelector === "Rejected") {
            status = "REJECTED"
        } else if (queueSelector === "Cancel") {
            status = "CANCEL"
        };
        await getTreatmentPlanInjector(status)
            .then((resp) => {
                if (planRequestId) {
                    setisLoading(true)
                    let data = resp.data?.data?.filter((ele) => ele.treatmentPlanRequestId === planRequestId)
                    setPatientData(data)
                    setisLoading(false)
                } else {
                    setPatientData(resp.data.data)
                    setisLoading(false)
                    allPatientData = resp.data.data
                }
            })
            .catch((error) => {
                setisLoading(false)
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const filterByPatientId = (value) => {
        setPage(0);
        const filteredRows = patientData.filter((row) => {
            return row.patientName
                .toString()
                .toLowerCase()
                .trim()
                .includes(value.toString().toLowerCase().trim())
        })
        if (value.trim().toString().length < 1) {
            setPatientData(allPatientData);
        } else {
            setPatientData(filteredRows);
        }
    };

    const showTreatmentPlan = (treatmentPlanDetails) => {
        navigate("/treatment-queue/treatment-plan-details", { state: { treatmentPlanDetails } })
    };

    const statusChange = async (value) => {
        setOpenStatusChangeDialog({ action: true, data: value });
    };

    const startVideoChat = (treatmentPlanDetails) => {
        navigate("/treatment-queue/treatment-plan-details", { state: { treatmentPlanDetails } })
    };

    const generatePrescription = async (rowData) => {
        await getPrescription(rowData.treatmentPlanRequestId)
            .then((resp) => {
                const newTab = window.open("", "_blank")
                newTab.document.write(resp.data)
            })
            .catch((error) => {

            })
    };

    const finaliseTreatment = (data) => {
        setOpenFinaliseDialog({ action: true, treatmentData: data })
    };

    const viewNotes = (notes) => {
        setOpenViewNotesDialog({ action: true, notes: notes });
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                {/* <div className='row'>
                    <div className='col-6'>
                        <span><material.Typography variant="h5">Treatment Quere</material.Typography></span>
                    </div>
                    <div className='col-6'></div>
                </div> */}
                <div className='row'>
                    <div className='col-6'>
                        <span style={{ marginLeft: 5 }}>
                            <material.TextField
                                sx={{ width: "30ch" }}
                                variant="standard"
                                label="Filter by Patient Name"
                                onChange={(e) => filterByPatientId(e.target.value)}
                            />
                        </span>
                    </div>
                    <div className='col-6'>
                        <span className='float-end me-2'>
                            <material.ToggleButtonGroup
                                color="info"
                                value={queueSelector}
                                exclusive
                                onChange={queueChange}
                                aria-label="Platform"
                            >
                                <material.ToggleButton sx={{ textTransform: "none" }} value="Pending">Pending</material.ToggleButton>
                                <material.ToggleButton sx={{ textTransform: "none" }} value="Draft">Draft</material.ToggleButton>
                                <material.ToggleButton sx={{ textTransform: "none" }} value="Approved">Approved</material.ToggleButton>
                                <material.ToggleButton sx={{ textTransform: "none" }} value="Finalis">Finalised</material.ToggleButton>
                                <material.ToggleButton sx={{ textTransform: "none" }} value="Rejected">Rejected</material.ToggleButton>
                                <material.ToggleButton sx={{ textTransform: "none" }} value="Cancel">Canceled</material.ToggleButton>
                            </material.ToggleButtonGroup>
                        </span>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <material.TableContainer sx={{ maxHeight: 460 }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead >
                                        <material.TableRow>
                                            <StyledTableCell>Patient Name</StyledTableCell>
                                            <StyledTableCell >Treatment</StyledTableCell>
                                            <StyledTableCell>Created At</StyledTableCell>
                                            {queueSelector === "Approved" || queueSelector === "Pending" ? (
                                                <>
                                                    <StyledTableCell>Notes</StyledTableCell>
                                                    <StyledTableCell>Prescriber Notes</StyledTableCell>
                                                </>
                                            ) : queueSelector === "Finalis" ? (
                                                <>
                                                    <StyledTableCell>Prescriber Notes</StyledTableCell>
                                                    <StyledTableCell>Treatment Notes</StyledTableCell>
                                                </>
                                            ) : queueSelector === "Rejected" ? (
                                                <>
                                                    <StyledTableCell>Prescriber Notes</StyledTableCell>
                                                    <StyledTableCell>Rejection Notes</StyledTableCell>
                                                </>
                                            ) : null}
                                            <StyledTableCell>Status</StyledTableCell>
                                            <StyledTableCell>Action</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {isLoading ? (
                                            <material.TableRow >
                                                <material.TableCell colSpan={10}>
                                                    <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                        <Skeleton count={10} />
                                                    </SkeletonTheme>
                                                </material.TableCell>
                                            </material.TableRow>
                                        ) : (
                                            <>
                                                {patientData.length ? patientData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                    <material.TableRow
                                                        key={i}
                                                        sx={{
                                                            '&:last-child td, &:last-child th': { border: 0 }
                                                        }}
                                                    >
                                                        <material.TableCell sx={{ pt: 2, pb: 2 }} size='small' scope="row">{row.patientName} </material.TableCell>
                                                        <material.TableCell size='small'>
                                                            {row.treatmentPlan.map((treatment, k) => (
                                                                <span className='d-flex flex-wrap' key={k}>
                                                                    {treatment.treatmentName}, {treatment.treatmentArea}, {treatment.product}, {treatment.qty}
                                                                </span>
                                                            ))}
                                                        </material.TableCell>
                                                        <material.TableCell size='small'>{changeDateAndTimeFormate(row.timeStamp)}</material.TableCell>
                                                        {queueSelector === "Approved" || queueSelector === "Pending" ? (
                                                            <>
                                                                <material.TableCell size='small'>{row.reason}</material.TableCell>
                                                                <material.TableCell size='small' title={row.prescribesNote}><div className='textContainer'>{row.prescribesNote}</div>{row.prescribesNote?.length > 50 ? <material.Link component="button" variant="body2" onClick={() => viewNotes(row.prescribesNote)}>Read More</material.Link> : null}</material.TableCell>
                                                            </>
                                                        ) : queueSelector === "Finalis" ? (
                                                            <>
                                                                <material.TableCell size='small' title={row.prescribesNote}><div className='textContainer'>{row.prescribesNote}</div>{row.prescribesNote?.length > 50 ? <material.Link component="button" variant="body2" onClick={() => viewNotes(row.prescribesNote)}>Read More</material.Link> : null}</material.TableCell>
                                                                <material.TableCell size='small' title={row.treatmentNote}><div className='textContainer'>{row.treatmentNote}</div>{row.treatmentNote?.length > 50 ? <material.Link component="button" variant="body2" onClick={() => viewNotes(row.treatmentNote)}>Read More</material.Link> : null}</material.TableCell>
                                                            </>
                                                        ) : queueSelector === "Rejected" ? (
                                                            <>
                                                                <material.TableCell size='small' title={row.prescribesNote}><div className='textContainer'>{row.prescribesNote}</div>{row.prescribesNote?.length > 50 ? <material.Link component="button" variant="body2" onClick={() => viewNotes(row.prescribesNote)}>Read More</material.Link> : null}</material.TableCell>
                                                                <material.TableCell size='small' title={row.rejectionNote}><div className='textContainer'>{row.rejectionNote}</div>{row.rejectionNote?.length > 50 ? <material.Link component="button" variant="body2" onClick={() => viewNotes(row.rejectionNote)}>Read More</material.Link> : null}</material.TableCell>
                                                            </>
                                                        ) : null}
                                                        <material.TableCell size='small'>
                                                            {row.status === "PENDING" ? (
                                                                <span className="badge" style={{ backgroundColor: "yellowgreen" }}>
                                                                    PENDING
                                                                </span>
                                                            ) : null}
                                                            {row.status === "REJECTED" ? (
                                                                <span className="badge bg-danger">
                                                                    REJECTED
                                                                </span>
                                                            ) : null}
                                                            {row.status === "APPROVED" ? (
                                                                <span className="badge bg-success">
                                                                    APPROVED
                                                                </span>
                                                            ) : null}
                                                            {row.status === "DRAFT" ? (
                                                                <span className="badge" style={{ backgroundColor: "gray" }}>
                                                                    DRAFT
                                                                </span>
                                                            ) : null}
                                                            {row.status === "CANCEL" ? (
                                                                <span className="badge" style={{ backgroundColor: "gray" }}>
                                                                    CANCEL
                                                                </span>
                                                            ) : row.status === "USED" ? (
                                                                <span className="badge bg-success">
                                                                    FINALISED
                                                                </span>
                                                            ) : null}
                                                        </material.TableCell>
                                                        <material.TableCell>
                                                            <span className='d-flex flex-column'>
                                                                <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='secondary' size="small" startIcon={<material.VisibilityIcon />} onClick={() => showTreatmentPlan({ ...row, "component": "approvalWaiting" })}>View</material.Button>
                                                                {row.status === "DRAFT" ? (
                                                                    <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='error' size="small" startIcon={<material.PriorityHighIcon />} onClick={() => statusChange({ "action": "delete", ...row, "parentComponent": "approvalWaiting" })}>Reject</material.Button>
                                                                ) : row.status === "PENDING" ? (
                                                                    <>
                                                                        <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='error' size="small" startIcon={<material.CancelIcon />} onClick={() => statusChange({ "action": "cancel", ...row, "parentComponent": "approvalWaiting" })}>Cancel</material.Button>
                                                                    </>
                                                                ) : row.status === "APPROVED" ? (
                                                                    <>
                                                                        <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='info' size="small" onClick={() => generatePrescription(row)}>Prescription</material.Button>
                                                                        <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='success' size="small" startIcon={<material.DoneIcon />} onClick={() => finaliseTreatment(row)}>Finalise</material.Button>
                                                                    </>
                                                                ) : row.status === "USED" ? (
                                                                    <material.Button sx={{ mb: 1, textTransform: "none" }} variant="contained" color='info' size="small" onClick={() => generatePrescription(row)}>Prescription</material.Button>
                                                                ) : null}
                                                            </span>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={10}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </>)}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                            <hr />
                            <material.TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                component="div"
                                count={patientData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </material.Paper>
                    </div>
                </div>
            </div>
            <StatusChangeReasonDialog
                openStatusChangeDialog={openStatusChangeDialog}
                setOpenStatusChangeDialog={setOpenStatusChangeDialog}
                getTreatmentPlan={getTreatmentPlan}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <FinaliseDialog
                openFinaliseDialog={openFinaliseDialog}
                setOpenFinaliseDialog={setOpenFinaliseDialog}
                getTreatmentPlan={getTreatmentPlan}
            />
            <ViewPreWrittenNote
                openViewNotesDialog={openViewNotesDialog}
                setOpenViewNotesDialog={setOpenViewNotesDialog}
            />
        </div>
    );
}

export default ApprovalWating;