import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import AddVendor from '../../dialog/AddVendor';
import { getVendorByClinicId, getVendorByOrgId } from '../../../services/VendorManagementService';
import { connect } from 'react-redux';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Navbar from '../../navbar/Navbar';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import Snackbar from '../../toastrmessage/Snackbar';
import { useTheme } from '@mui/material';


function VendorManagement(props) {

    const { userData, clinicData } = props;
    const clinicDetails = clinicData.clinicReducer.data;
    const theme = useTheme();
    const [openAddVendor, setOpenAddVendor] = useState({ action: false, supplierData: null });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [isLoading, setisLoading] = useState(true);
    const [vendorsData, setVendorsData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const userDetails = userData.authReducer.data;

    localStorage.setItem("menuName", "Supplier List");

    useEffect(() => {
        getVendors()
    }, []);

    const getVendors = async () => {
        await getVendorByClinicId(clinicDetails.clinicId)
            .then((res) => {
                setVendorsData(res.data.data)
                setisLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const addVendor = () => {
        setOpenAddVendor({ action: true, supplierData: null })
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const editSupplier = (rowData) => {
        setOpenAddVendor({ action: true, supplierData: rowData })
    };

    return (
        <material.Grid spacing={2} className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 10
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 10
                },
            }}
        >
            <Navbar />
            <div className='row'>
                <div className='col-lg-12'>
                    <span className='float-end'>
                        <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addVendor}> Add-Supplier</material.Button>
                    </span>
                </div>
                <div className='col-12 mt-3'>
                    <material.Paper sx={{ width: "100%", overflow: 'hidden', p: 3 }}>
                        <material.TableContainer sx={{ maxHeight: 500 }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell>Company Name</StyledTableCell>
                                        <StyledTableCell>Manager</StyledTableCell>
                                        <StyledTableCell>Owner</StyledTableCell>
                                        <StyledTableCell>Contact Name</StyledTableCell>
                                        <StyledTableCell>Contact Number</StyledTableCell>
                                        <StyledTableCell>Email</StyledTableCell>
                                        <StyledTableCell>Registered Office</StyledTableCell>
                                        <StyledTableCell>Status</StyledTableCell>
                                        <StyledTableCell align="right">Action</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={10}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={10} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {vendorsData.length ? vendorsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                <material.TableRow
                                                    key={i}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': { border: 0 },
                                                    }}
                                                >
                                                    <material.TableCell sx={{ pt: 2, pb: 2 }} size='small'>{row.companyName}</material.TableCell>
                                                    <material.TableCell size='small'>{row.manager}</material.TableCell>
                                                    <material.TableCell size='small'>{row.owner}</material.TableCell>
                                                    <material.TableCell size='small'>{row.contactName}</material.TableCell>
                                                    <material.TableCell size='small'>{row.contactNumber}</material.TableCell>
                                                    <material.TableCell size='small'>{row.email}</material.TableCell>
                                                    <material.TableCell size='small'>{row.registeredOffice}</material.TableCell>
                                                    <material.TableCell size='small'>
                                                        {row.active ? (<p style={{ color: "green", fontWeight: "bold" }}>active</p>) : (<p style={{ color: "red", fontWeight: "bold" }}>De-active</p>)}
                                                    </material.TableCell>
                                                    <material.TableCell size='small' align="right">
                                                        <material.IconButton title='Edit Supplier' aria-label="create" size="large" onClick={() => editSupplier(row)}>
                                                            <material.CreateIcon color='primary' />
                                                        </material.IconButton>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={10}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>)}
                                </material.TableBody>
                            </material.Table>
                            <hr />
                        </material.TableContainer>
                        <material.TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={vendorsData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </material.Paper>
                </div>
            </div>
            <AddVendor
                open={openAddVendor}
                setOpen={setOpenAddVendor}
                getVendors={getVendors}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </material.Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(VendorManagement);