import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { Controller, useForm } from 'react-hook-form';
import { getOrgList } from '../../services/UserManagementService';
import { getClinicByOrgId } from '../../services/ClinicService';
import Snackbar from '../toastrmessage/Snackbar';
import { getAllSupplierList } from '../../services/SupplierProductConfigService';
import { saveOrUpdateSupplierClinicConfiguration } from '../../services/SupplierConfigService';

function AddOrEditSupplierClinicConfiguration(props) {

    const { openAddOrEditSupplierClinicConfig, setOpenAddOrEditSupplierClinicConfig, getSupplierClinicConfigData } = props;
    const { register, handleSubmit, reset, setValue, control, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [organizationName, setOrganizationName] = useState([]);
    const [organizationDetails, setOrganizationDetails] = useState([]);
    const [clinicDetails, setClinicDetails] = useState([]);
    const [clinicName, setClinicName] = useState([]);
    const [supplierDetails, setSupplierDetails] = useState([]);
    const [supplierName, setSupplierName] = useState([]);
    const [orgId, setOrgId] = useState(null);
    const [clinicId, setClinicId] = useState(null);
    const [supplierId, setSupplierId] = useState(null);

    useEffect(() => {
        if (openAddOrEditSupplierClinicConfig.action) {
            getAllOrganization();
            getSupplierList();
        }
    }, [openAddOrEditSupplierClinicConfig])

    const getAllOrganization = () => {
        getOrgList()
            .then((resp) => {
                const orgName = resp.data.data?.map((org) => org.orgName);
                setOrganizationName(orgName);
                setOrganizationDetails(resp.data.data);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getAllClinic = (orgId) => {
        getClinicByOrgId(orgId)
            .then((resp) => {
                setClinicDetails(resp.data.data);
                const clinicName = resp.data.data?.map((clinic) => clinic.clinicName);
                setClinicName(clinicName);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getSupplierList = () => {
        getAllSupplierList()
            .then((resp) => {
                setSupplierDetails(resp.data.data);
                const supplierName = resp.data.data?.map((supplier) => supplier.supplierName);
                setSupplierName(supplierName);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const saveConfiguration = (formData) => {
        const payload = {
            orgId: orgId,
            clinicId: clinicId,
            supplierId: supplierId,
            active: true,
            ...formData
        }
        saveOrUpdateSupplierClinicConfiguration(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                reset({ organizationName: "", clinicName: "", supplierName: "", clinicClientId: "" });
                setOrgId(null);
                setClinicId(null);
                setSupplierId(null);
                getSupplierClinicConfigData();
                setOpenAddOrEditSupplierClinicConfig({ action: false, rowData: null })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const handleClose = () => {
        reset({ organizationName: "", clinicName: "", supplierName: "", clinicClientId: "" });
        setOrgId(null);
        setClinicId(null);
        setSupplierId(null);
        setOpenAddOrEditSupplierClinicConfig({ action: false, rowData: null });
    };

    return (
        <div>
            <material.Dialog open={openAddOrEditSupplierClinicConfig.action} fullWidth maxWidth="sm">
                <material.DialogTitle>{openAddOrEditSupplierClinicConfig.rowData !== null ? "Edit Supplier Clinic Configuration" : "Add Supplier Clinic Configuration"}</material.DialogTitle>
                <material.DialogContent>
                    <div>
                        <Controller
                            name="organizationName"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field }) => (
                                <material.Autocomplete
                                    {...field}
                                    value={field.value}
                                    options={organizationName}
                                    renderInput={(params) => (
                                        <material.TextField
                                            required
                                            margin="dense"
                                            {...params}
                                            variant="standard"
                                            label="Organization Name"
                                            fullWidth
                                        />
                                    )}
                                    onChange={(event, data) => {
                                        field.onChange(data);
                                        if (data) {
                                            const orgId = organizationDetails.filter((org) => org.orgName === data).map((x) => x.orgId)[0];
                                            setOrgId(orgId);
                                            getAllClinic(orgId);
                                        }
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div>
                        <Controller
                            name="clinicName"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field }) => (
                                <material.Autocomplete
                                    {...field}
                                    value={field.value}
                                    options={clinicName}
                                    renderInput={(params) => (
                                        <material.TextField
                                            required
                                            margin="dense"
                                            {...params}
                                            variant="standard"
                                            label="Clinic Name"
                                            fullWidth
                                        />
                                    )}
                                    onChange={(event, data) => {
                                        field.onChange(data);
                                        if (data) {
                                            const clinicId = clinicDetails.filter((clinic) => clinic.clinicName === data).map((x) => x.clinicId)[0];
                                            setClinicId(clinicId)
                                        }
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div>
                        <Controller
                            name="supplierName"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field }) => (
                                <material.Autocomplete
                                    {...field}
                                    value={field.value}
                                    options={supplierName}
                                    renderInput={(params) => (
                                        <material.TextField
                                            required
                                            margin="dense"
                                            {...params}
                                            variant="standard"
                                            label="Supplier Name"
                                            fullWidth
                                        />
                                    )}
                                    onChange={(event, data) => {
                                        field.onChange(data);
                                        if (data) {
                                            const supplierId = supplierDetails.filter((supplier) => supplier.supplierName === data).map((x) => x.supplierId)[0];
                                            setSupplierId(supplierId);
                                        }
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div>
                        <material.TextField
                            required
                            label="Clinic Client ID"
                            variant="standard"
                            fullWidth
                            id='clinicClientId'
                            {...register("clinicClientId", { required: true })}
                        />
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} color='error' startIcon={<material.CloseIcon />} onClick={handleClose}>Cancel</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />} onClick={handleSubmit(saveConfiguration)}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default AddOrEditSupplierClinicConfiguration;