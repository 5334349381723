import React, { useEffect, useRef, useState } from "react";
import { material } from "../../../library/material";
import { useForm, Controller } from "react-hook-form";
// import { createHtmlTemplate } from "../../../services/CampaignService";
import { connect } from "react-redux";
import Navbar from "../../navbar/Navbar";
import { useNavigate } from "react-router-dom";
import Snackbar from "../../toastrmessage/Snackbar";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { SketchPicker, } from "react-color";
import { createHtmlTemplate } from "../../../services/PlatformCrmService";
// import { getInjectorListByClinicId } from "../../../services/AppointmentService";


var injectorDetails = [];
var injectorId;

function CreateCrmTemplateDialog(props) {
    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const navigate = useNavigate();
    const iframeRef = useRef(null);
    localStorage.setItem("menuName", "Create Campaign Template");
    const {
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        control,
        formState: { errors, isValid },
    } = useForm({
        mode: "onTouched",
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    // const [checked, setChecked] = useState(null);
    // const [injectorName, setInjectorName] = useState([]);
    // const [openPreviewHtmlTemplate, setOpenPreviewHtmlTemplate] = useState({
    //     action: false,
    //     data: null,
    // });
    // const [templateContent, setTemplateContent] = useState(null);
    const [displayHeaderColorPicker, setDisplayHeaderColorPicker] =
        useState(false);
    const [herderColor, setHeaderColor] = useState("#fff");
    const [displayFooterColorPicker, setDisplayFooterColorPicker] =
        useState(false);
    const [footerColor, setFooterColor] = useState("#fff");
    const [displayBodyColorPicker, setDisplayBodyColorPicker] = useState(false);
    const [bodyColor, setBodyColor] = useState("#fff");
    const [previewTemplate, setPreviewTemplate] = useState(null);

    // useEffect(() => {
    //     getInjectorList();
    // }, []);

    useEffect(() => {
        if (iframeRef.current && previewTemplate) {
            const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
            iframeDoc.open();
            iframeDoc.write(previewTemplate);
            iframeDoc.close();
        }
    }, [previewTemplate]); 

    const handleHeaderColorChange = (headerColor) => {
        setHeaderColor(headerColor.hex);
        setValue("headerColor", headerColor.hex);
    };
    const handleFooterColorChange = (footerColor) => {
        setFooterColor(footerColor.hex);
        setValue("footerColor", footerColor.hex);
    };
    const handleBodyColorChange = (bodyColor) => {
        setBodyColor(bodyColor.hex);
        setValue("bodyColor", bodyColor.hex);
    };

    // const getInjectorList = () => {
    //     getInjectorListByClinicId(clinicDetails.clinicId)
    //         .then((res) => {
    //             console.log(res)
    //             injectorDetails = res.data.data;
    //             const injectorName = res.data?.data?.map((ele) => ele.fullName);
    //             setInjectorName(injectorName);
    //         })
    //         .catch((error) => {
    //             setOpenSnackBar({
    //                 action: true,
    //                 type: "error",
    //                 message: error.response.data.messages,
    //             });
    //         });
    // };

    const commonFunctionForBoth = (formData) => {
        setOpenSnackBar({
            action: true,
            type: "info",
            message: "Please wait...",
        });
        let data = new FormData();
        const logoFile = formData.logo instanceof FileList ? formData.logo[0] : formData.logo;
        const paragraphOneImg = formData.paragraphOneImg instanceof FileList ? formData.paragraphOneImg[0] : formData.logo;
        const paragraphTwoImg = formData.paragraphTwoImg instanceof FileList ? formData.paragraphTwoImg[0] : formData.paragraphTwoImg;
        data.append("logo", logoFile);
        data.append("templateName", formData.templateName);
        data.append("headerColor", formData.headerColor);
        data.append("headingOne", formData.headingOne);
        data.append("headingTwo", formData.headingTwo);
        data.append("headingThree", formData.headingThree);
        data.append("bodyColor", formData.bodyColor);
        data.append("paragraphOneImg", paragraphOneImg);
        data.append("paragraphOne", formData.paragraphOne);
        data.append("paragraphTwoImg", paragraphTwoImg);
        data.append("paragraphTwo", formData.paragraphTwo);
        data.append("paragraphThree", formData.paragraphThree);
        data.append("termsAndConditions", formData.termsAndConditions);
        data.append("visitUs", formData.visitUs);
        data.append("bestRegards", formData.bestRegards);
        data.append("footerColor", formData.footerColor);
        data.append("address", formData.address);
        data.append("clinicId", clinicDetails?.clinicId);
        data.append("orgId", userDetails?.orgId);
        data.append("forSaved", formData.forSaved);
        data.append("contactNo", formData.contactNo);
        // if (checked) {
        //     data.append("injectorId", injectorId);
        //     data.append("campaignTag", formData.campaignTag);
        // }
        createHtmlTemplate(data)
            .then((res) => {
                if (formData.forSaved) {
                    navigate(-1);
                    reset({
                        logo: "",
                        templateName: "",
                        headerColor: "",
                        headingOne: "",
                        headingTwo: "",
                        headingThree: "",
                        bodyColor: "",
                        paragraphOneImg: "",
                        paragraphOne: "",
                        paragraphTwoImg: "",
                        paragraphTwo: "",
                        paragraphThree: "",
                        termsAndConditions: "",
                        visitUs: "",
                        bestRegards: "",
                        footerColor: "",
                        address: "",
                        forSaved: "",
                        injectorName: "",
                        campaignTag: "",
                    });
                    injectorId = [];
                } else {
                    // setTemplateContent(res.data);
                    // setOpenPreviewHtmlTemplate({ action: true, data: res.data });
                    // const newTab = window.open("", "_blank");
                    // newTab.document.write(res.data);
                    // console.log(res.data)
                    setPreviewTemplate(res.data)
                }
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data.messages,
                });
            });
    };

    const createTemplate = (formData) => {
        commonFunctionForBoth({ ...formData, forSaved: true });
    };

    const templatePreview = (formData) => {
        commonFunctionForBoth({ ...formData, forSaved: false });
    };

    const goBack = () => {
        navigate(-1);
    };

    // const handleChange = (e) => {
    //     setChecked(e.target.checked);
    // };

    // const selectInjector = (e, newVal) => {
    //     setValue("injectorName", newVal);
    //     injectorId = injectorDetails
    //         ?.filter((ele) => ele.fullName === newVal)
    //         .map((res) => res.injectorId)[0];
    // };

    return (
        <div>
            <Navbar />
            <div className="mt-3 p-4 mt-5">
                <div className="row">
                    <div className="col-12">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <h4>Create HTML Template</h4>
                            <span className="float-end">
                                <material.Button
                                    variant="contained"
                                    size="small"
                                    sx={{ textTransform: "none" }}
                                    onClick={goBack}
                                    startIcon={<material.ReplyIcon />}
                                >
                                    Back
                                </material.Button>
                            </span>
                        </div>
                    </div>
                    <div className="col-6">
                        <material.Paper elevation={7} sx={{ p: 2, mt: 1 }}>
                            <div
                                className="container-fluid"
                                style={{
                                    marginTop: "1%",
                                    alignContent: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <div
                                    className="row border border-2 p-2 rounded-2"
                                    style={{
                                        boxShadow:
                                            "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                                    }}
                                >
                                    <material.TextField
                                        // required
                                        margin="dense"
                                        id="templateName"
                                        label="Template Name"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        {...register("templateName")}
                                    />
                                </div>
                                <div
                                    className="row border border-2 p-2 py-4 my-2 rounded-2 mt-2"
                                    style={{
                                        boxShadow:
                                            "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                                    }}
                                >
                                    <div
                                        className="col-10"
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <h6>Logo Upload : </h6>
                                        <input
                                            type="file"
                                            accept=".jpg, .jpeg, .png, .svg"
                                            margin="dense"
                                            {...register("logo", )}
                                            style={{ marginLeft: "2%" }}
                                        />
                                    </div>
                                    <div className="col-2">
                                        <Controller
                                            name="headerColor"
                                            control={control}
                                            defaultValue={herderColor}
                                            render={({ field }) => (
                                                <>
                                                    <material.TextField
                                                        {...field}
                                                        // value={herderColor}
                                                        {...register("headerColor")}
                                                        onClick={() => setDisplayHeaderColorPicker(true)}
                                                        readOnly
                                                        // required
                                                        margin="dense"
                                                        label="Choose Header Color"
                                                        type="text"
                                                        fullWidth
                                                        variant="standard"
                                                    />
                                                    {displayHeaderColorPicker && (
                                                        <div style={{ position: "absolute", zIndex: 2 }}>
                                                            <div
                                                                style={{
                                                                    position: "fixed",
                                                                    top: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    left: 0,
                                                                }}
                                                                onClick={() => setDisplayHeaderColorPicker(false)}
                                                            />
                                                            <SketchPicker
                                                                color={herderColor}
                                                                onChange={handleHeaderColorChange}
                                                                width="200px"
                                                                styles={{
                                                                    default: {
                                                                        picker: {
                                                                            boxShadow: "none",
                                                                            borderRadius: "4px",
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <material.TextField
                                            multiline
                                            // required
                                            margin="dense"
                                            id="subject"
                                            label="Heading One"
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                            {...register("headingOne")}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <material.TextField
                                            multiline
                                            // required
                                            margin="dense"
                                            id="subject"
                                            label="Heading Two"
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                            {...register("headingTwo")}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <material.TextField
                                            multiline
                                            // required
                                            margin="dense"
                                            id="subject"
                                            label="Heading Three"
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                            {...register("headingThree")}
                                        />
                                    </div>
                                </div>

                                <div
                                    className="row border border-2 p-2 py-4 my-2 rounded-2 mt-2"
                                    style={{
                                        boxShadow:
                                            "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                                    }}
                                >
                                    <div className="col-4 mt-3">
                                        <material.InputLabel>Body Image 1</material.InputLabel>
                                        <input
                                            type="file"
                                            label="Body Image 1"
                                            accept=".jpg, .jpeg, .png, .svg"
                                            margin="dense"
                                            {...register("paragraphOneImg")}
                                        // style={{ marginTop: '5%' }}
                                        />
                                    </div>
                                    <div className="col-8">
                                        <material.TextField
                                            multiline
                                            // required
                                            margin="dense"
                                            id="subject"
                                            label="Paragraph One"
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                            {...register("paragraphOne")}
                                            // {...register("paragraphOne", { required: true })}
                                        />
                                    </div>

                                    <div className="col-4 mt-2">
                                        <material.InputLabel>Body Image 2</material.InputLabel>
                                        <input
                                            type="file"
                                            label="Body Image 2"
                                            accept=".jpg, .jpeg, .png, .svg"
                                            margin="dense"
                                            {...register("paragraphTwoImg")}
                                            // {...register("paragraphTwoImg", { required: true })}
                                        // style={{ marginTop: '5%' }}
                                        />
                                    </div>
                                    <div className="col-8 mt-2">
                                        <material.TextField
                                            multiline
                                            // required
                                            margin="dense"
                                            id="subject"
                                            label="Paragraph Two"
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                            {...register("paragraphTwo")}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <Controller
                                            name="bodyColor"
                                            control={control}
                                            defaultValue={bodyColor}
                                            render={({ field }) => (
                                                <>
                                                    <material.TextField
                                                        {...field}
                                                        // value={bodyColor}
                                                        {...register("bodyColor")}
                                                        onClick={() => setDisplayBodyColorPicker(true)}
                                                        readOnly
                                                        // required
                                                        margin="dense"
                                                        label="Choose Body Color"
                                                        type="text"
                                                        fullWidth
                                                        variant="standard"
                                                    />
                                                    {displayBodyColorPicker && (
                                                        <div style={{ position: "absolute", zIndex: 2 }}>
                                                            <div
                                                                style={{
                                                                    position: "fixed",
                                                                    top: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    left: 0,
                                                                }}
                                                                onClick={() => setDisplayBodyColorPicker(false)}
                                                            />
                                                            <SketchPicker
                                                                color={bodyColor}
                                                                onChange={handleBodyColorChange}
                                                                width="200px"
                                                                styles={{
                                                                    default: {
                                                                        picker: {
                                                                            boxShadow: "none",
                                                                            borderRadius: "4px",
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="col-8">
                                        <material.TextField
                                            // required
                                            multiline
                                            margin="dense"
                                            id="subject"
                                            label="Paragraph Three"
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                            // {...register("paragraphThree", { required: true })}
                                            {...register("paragraphThree")}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <material.TextField
                                            // required
                                            multiline
                                            margin="dense"
                                            id="subject"
                                            label="Terms And Conditions"
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                            {...register("termsAndConditions")}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <material.TextField
                                            // required
                                            multiline
                                            margin="dense"
                                            id="subject"
                                            label="Best Regards"
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                            {...register("bestRegards")}
                                        />
                                    </div>
                                </div>

                                <div
                                    className="row border border-2 p-2 py-4 my-2 rounded-2 mt-2"
                                    style={{
                                        boxShadow:
                                            "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                                    }}
                                >
                                    <h5>Contact Us at : </h5>
                                    <div className="col-3">
                                        <material.TextField
                                            // required
                                            margin="dense"
                                            id="subject"
                                            label="Contact No"
                                            type="number"
                                            fullWidth
                                            variant="standard"
                                            {...register("contactNo")}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <material.TextField
                                            // required
                                            multiline
                                            margin="dense"
                                            id="subject"
                                            label="Address"
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                            {...register("address")}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <material.TextField
                                            // required
                                            multiline
                                            margin="dense"
                                            id="subject"
                                            label="Visit Us Online"
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                            {...register("visitUs")}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <Controller
                                            name="footerColor"
                                            control={control}
                                            defaultValue={footerColor}
                                            render={({ field }) => (
                                                <>
                                                    <material.TextField
                                                        {...field}
                                                        // value={footerColor}
                                                        {...register("footerColor")}
                                                        onClick={() => setDisplayFooterColorPicker(true)}
                                                        readOnly
                                                        // required
                                                        margin="dense"
                                                        label="Choose Footer Color"
                                                        type="text"
                                                        fullWidth
                                                        variant="standard"
                                                    />
                                                    {displayFooterColorPicker && (
                                                        <div style={{ position: "absolute", zIndex: 2 }}>
                                                            <div
                                                                style={{
                                                                    position: "fixed",
                                                                    top: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    left: 0,
                                                                }}
                                                                onClick={() => setDisplayFooterColorPicker(false)}
                                                            />
                                                            <SketchPicker
                                                                color={footerColor}
                                                                onChange={handleFooterColorChange}
                                                                width="200px"
                                                                styles={{
                                                                    default: {
                                                                        picker: {
                                                                            boxShadow: "none",
                                                                            borderRadius: "4px",
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                                {/* <div
                                    className="row border border-2 p-2 py-4 my-2 rounded-2 mt-2"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        boxShadow:
                                            "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                                    }}
                                >
                                    <div className="col-4">
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox color="secondary" />}
                                                label="Add Appointment Link"
                                                onChange={handleChange}
                                            />
                                        </FormGroup>
                                    </div>
                                    {checked ? (
                                        <div className="col-4">
                                            <material.Autocomplete
                                                id="injectorName"
                                                fullWidth
                                                onChange={selectInjector}
                                                options={injectorName}
                                                renderInput={(params) => (
                                                    <material.TextField
                                                        {...params}
                                                        required
                                                        variant="standard"
                                                        label="Select Injector"
                                                        {...register("injectorName")}
                                                    />
                                                )}
                                            />
                                            <p className="form-text text-danger">
                                                {errors.injectorName?.type === "required" &&
                                                    "This field is required"}
                                            </p>
                                        </div>
                                    ) : null}
                                    {checked ? (
                                        <div className="col-4" style={{ marginTop: "-2  %" }}>
                                            <material.TextField
                                                multiline
                                                margin="dense"
                                                id="campaignName  "
                                                label="TreatmentName/Campaign"
                                                type="text"
                                                fullWidth
                                                variant="standard"
                                                {...register("campaignTag")}
                                            />
                                        </div>
                                    ) : null}
                                </div> */}

                                <span className="float-end py-1">
                                    <material.Button
                                        variant="contained"
                                        // size="small"
                                        sx={{ textTransform: "none", mb: 1 }}
                                        className="me-2"
                                        onClick={handleSubmit(createTemplate)}
                                        disabled={!isValid}
                                        startIcon={<material.DoneIcon />}
                                    >
                                        Save
                                    </material.Button>
                                    <material.Button
                                        variant="contained"
                                        color="secondary"
                                        sx={{ textTransform: "none", mb: 1 }}
                                        className="me-2"
                                        onClick={handleSubmit(templatePreview)}
                                        disabled={!isValid}
                                        startIcon={<material.VisibilityIcon />}
                                    >
                                        Preview
                                    </material.Button>
                                </span>
                            </div>
                        </material.Paper>
                    </div>
                    <div className="col-6">
                        <material.Paper elevation={7} sx={{ p: 2, mt: 1 }}>
                            {previewTemplate ? (
                                <div>
                                    <iframe
                                        ref={iframeRef}
                                        title="Invoice Viewer"
                                        style={{
                                            width: "100%",
                                            height: "80vh",
                                            border: "1px solid #ccc",
                                            borderRadius: "8px",
                                            backgroundColor: "#f9f9f9",
                                        }}
                                    ></iframe>
                                </div>
                            ) : (
                                <p>View Template.</p>
                            )}
                        </material.Paper>
                    </div>
                </div>
            </div>
            <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state,
    };
};

export default connect(mapStateToProps)(CreateCrmTemplateDialog);