import React, { useState } from 'react';
import { material } from '../../library/material';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

const SelectTreatmentTypeDialog = (props) => {

    const { setOpenSelectTreatmentDialog, openSelectTreatmentDialog, patientData, userData } = props;
    const navigate = useNavigate();
    const userDetails = userData.authReducer.data;
    const [openPermissionDialog, setOpenPermissionDialog] = useState(false)

    const selectTreatment = (data) => {
        if (data === "Skin Treatment") {
            navigate("/patient-list/view-patient-details/patient_skin_treatment_list", { state: { patientData } });
            setOpenSelectTreatmentDialog(false);
        } else {
            navigate("/patient-list/view-patient-details/treatment-plan", { state: { patientData } });
            setOpenSelectTreatmentDialog(false);
        }
    };

    const cancel = () => {
        navigate("/patient-list/view-patient-details", { state: { patientData } });
        setOpenSelectTreatmentDialog(false);
    };

    return (
        <div>
            <material.Dialog open={openSelectTreatmentDialog} hideBackdrop fullWidth maxWidth="sm">
                <material.DialogContent>
                    Would you like to choose between a skin treatment or cosmetic injectables?
                    <div className='mt-3'>
                        <span>
                            <material.Button variant="outlined" sx={{ textTransform: "none", ml: 1 }} size="medium" onClick={() => selectTreatment("Skin Treatment")}>Skin Treatment</material.Button>
                            <material.Button variant="outlined" sx={{ textTransform: "none", ml: 1 }} size="medium" onClick={() => selectTreatment("Cosmetic Injectable")}>Cosmetic Injectable</material.Button>
                            <material.Button variant="outlined" sx={{ textTransform: "none", ml: 1 }} color='error' size="medium" onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                        </span>
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <SkinTreatmentPermissionDialog
                openPermissionDialog={openPermissionDialog}
                setOpenPermissionDialog={setOpenPermissionDialog}
                patientData={patientData}
            />
        </div>
    )
}

const SkinTreatmentPermissionDialog = (props) => {

    const { openPermissionDialog, setOpenPermissionDialog, patientData } = props;
    const navigate = useNavigate();

    const selectSkinTreatment = (data) => {
        if (data === "New Skin Treatment") {
            navigate("/patient-list/view-patient-details/patient_skin_treatment_list/skin-treatment-booking", { state: { patientData: { ...patientData, callFrom: "patientSkinTreatmentList" } } });
            setOpenPermissionDialog(false);
        } else {
            navigate("/patient-list/view-patient-details/patient_skin_treatment_list", { state: { patientData } });
            setOpenPermissionDialog(false);
        };
    };

    return (
        <div>
            <material.Dialog open={openPermissionDialog} hideBackdrop fullWidth maxWidth="sm">
                <material.DialogContent>
                    Would you like to choose between a new skin treatment or existing skin treatment?
                    <div className='mt-3'>
                        <span>
                            <material.Button variant="outlined" sx={{ textTransform: "none", ml: 1 }} size="medium" onClick={() => selectSkinTreatment("New Skin Treatment")}>New Skin Treatment</material.Button>
                            <material.Button variant="outlined" sx={{ textTransform: "none", ml: 1 }} size="medium" onClick={() => selectSkinTreatment("Existing Skin Treatment")}>Existing Skin Treatment</material.Button>
                        </span>
                    </div>
                </material.DialogContent>
            </material.Dialog>
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(SelectTreatmentTypeDialog);