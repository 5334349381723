import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { saveOrUpdateSupplierConfiguration } from '../../services/SupplierConfigService';
import Snackbar from '../toastrmessage/Snackbar';

function AddOrEditSupplierConfig(props) {

    const { openAddOrEditSupplierConfig, setOPenAddOrEditSupplierConfig, getAllSupplierConfigData } = props;
    const { register, handleSubmit, reset, watch, control, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [currentFileName, setCurrentFileName] = useState("");

    useEffect(() => {
        if (openAddOrEditSupplierConfig.action && openAddOrEditSupplierConfig.rowData !== null) {
            reset(openAddOrEditSupplierConfig.rowData);
            setCurrentFileName(openAddOrEditSupplierConfig.rowData.nurseAccountSetupForm.split("/").pop() || "No file uploaded");
        }
    }, [openAddOrEditSupplierConfig.action]);

    const handleClose = () => {
        reset({ supplierName: "", contactName: "", position: "", contactEmail: "", contactPhoneNumber: "", nurseAccountSetupEmail: "", setupSupportContact: "", productPurchaseContactName: "", productPurchaseContactNumber: "", productPurchaseEmail: "", shippingCost: "", freeShippingThreshold: "" });
        setCurrentFileName("");
        setOPenAddOrEditSupplierConfig({ action: false, rowData: null });
    };

    const saveSupplierConfig = (data) => {
        let formData = new FormData();
        if (openAddOrEditSupplierConfig.rowData !== null) {
            if (typeof data.nurseAccountSetupForm === 'object' && data.nurseAccountSetupForm.length > 0) {
                formData.append('file', data.nurseAccountSetupForm[0]);
            } else {
                formData.append('nurseAccountSetupForm', data.nurseAccountSetupForm);
            }
            formData.append('supplierName', data.supplierName);
            formData.append('contactName', data.contactName);
            formData.append('position', data.position);
            formData.append('contactEmail', data.contactEmail);
            formData.append('contactPhoneNumber', data.contactPhoneNumber);
            formData.append('nurseAccountSetupEmail', data.nurseAccountSetupEmail);
            formData.append('setupSupportContact', data.setupSupportContact);
            formData.append('setupSupportName', data.setupSupportName);
            formData.append('productPurchaseContactName', data.productPurchaseContactName);
            formData.append('productPurchaseContactNumber', data.productPurchaseContactNumber);
            formData.append('productPurchaseEmail', data.productPurchaseEmail);
            formData.append('shippingCost', data.shippingCost);
            formData.append('freeShippingThreshold', data.freeShippingThreshold);
            formData.append('supplierId', data.supplierId);
        } else {
            if (typeof data.nurseAccountSetupForm === 'object' && data.nurseAccountSetupForm.length > 0) {
                formData.append('file', data.nurseAccountSetupForm[0]);
                formData.append('supplierName', data.supplierName);
                formData.append('contactName', data.contactName);
                formData.append('position', data.position);
                formData.append('contactEmail', data.contactEmail);
                formData.append('contactPhoneNumber', data.contactPhoneNumber);
                formData.append('nurseAccountSetupEmail', data.nurseAccountSetupEmail);
                formData.append('setupSupportContact', data.setupSupportContact);
                formData.append('setupSupportName', data.setupSupportName);
                formData.append('productPurchaseContactName', data.productPurchaseContactName);
                formData.append('productPurchaseContactNumber', data.productPurchaseContactNumber);
                formData.append('productPurchaseEmail', data.productPurchaseEmail);
                formData.append('shippingCost', data.shippingCost);
                formData.append('freeShippingThreshold', data.freeShippingThreshold);
            } else {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": "Please upload Nurse account Setup Form",
                });
                return;
            }
        }
        saveOrUpdateSupplierConfiguration(formData)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                getAllSupplierConfigData();
                setCurrentFileName("");
                reset({ supplierName: "", contactName: "", position: "", contactEmail: "", contactPhoneNumber: "", nurseAccountSetupEmail: "", setupSupportContact: "", productPurchaseContactName: "", productPurchaseContactNumber: "", productPurchaseEmail: "", shippingCost: "", freeShippingThreshold: "" });
                setOPenAddOrEditSupplierConfig({ action: false, rowData: null })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const validateFile = (files) => {
        if (files && files.length > 0 && typeof (files) == 'object') {
            for (let file of files) {
                if (file.type !== "application/pdf") {
                    return `Invalid file type: ${file.type}. Only PDF file allowed.`;
                }
            }
        }
        return true;
    };

    return (
        <div>
            <material.Dialog open={openAddOrEditSupplierConfig.action} fullWidth maxWidth="lg">
                <material.DialogTitle>{openAddOrEditSupplierConfig?.rowData == null ? "Add Supplier Configuration" : "Edit Supplier Configuration"}</material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <material.TextField
                                margin="dense"
                                required
                                label="Supplier Name"
                                id='supplierName'
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                {...register("supplierName", { required: true })}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <material.TextField
                                margin="dense"
                                required
                                label="Contact Name"
                                id='contactName'
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                {...register("contactName", { required: true })}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <material.TextField
                                margin="dense"
                                required
                                label="Position"
                                id='position'
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                {...register("position", { required: true })}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <material.TextField
                                margin="dense"
                                required
                                label="Contact Email"
                                id='contactEmail'
                                variant="standard"
                                type="email"
                                size="small"
                                fullWidth
                                {...register("contactEmail", { required: true })}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-2">
                            <material.InputLabel>Contact Phone Number</material.InputLabel>
                            <Controller
                                name="contactPhoneNumber"
                                control={control}
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <PhoneInput
                                        {...field}
                                        required
                                        placeholder="Contact Phone Number"
                                        defaultCountry="AU"
                                        enableSearch={true}
                                        value={field.value || ""}
                                        international
                                        onChange={(newValue) => {
                                            field.onChange(newValue);
                                        }}
                                        error={field.value ? (isValidPhoneNumber(field.value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-3">
                            <span>
                                <material.InputLabel>Nurse account Setup Form (allow only PDF)</material.InputLabel>
                                <input
                                    type="file"
                                    {...register("nurseAccountSetupForm", {
                                        validate: validateFile,
                                    })}
                                />
                                {currentFileName && (
                                    <p style={{ marginTop: "5px" }}>
                                        Current file: <strong>{currentFileName}</strong>
                                    </p>
                                )}
                                {errors.nurseAccountSetupForm && (
                                    <p style={{ color: "red" }}>{errors.nurseAccountSetupForm.message}</p>
                                )}
                            </span>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <material.TextField
                                margin="dense"
                                required
                                label="Nurse Account Setup Email"
                                id='nurseAccountSetupEmail'
                                variant="standard"
                                type="email"
                                size="small"
                                fullWidth
                                {...register("nurseAccountSetupEmail", { required: true })}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-2">
                            <material.InputLabel>Setup Support Contact</material.InputLabel>
                            <Controller
                                name="setupSupportContact"
                                control={control}
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <PhoneInput
                                        {...field}
                                        required
                                        placeholder="Setup Support Contact"
                                        defaultCountry="AU"
                                        enableSearch={true}
                                        value={field.value || ""}
                                        international
                                        onChange={(newValue) => {
                                            field.onChange(newValue);
                                        }}
                                        error={field.value ? (isValidPhoneNumber(field.value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <material.TextField
                                margin="dense"
                                required
                                label="Setup Support Name"
                                id='setupSupportName'
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                {...register("setupSupportName", { required: true })}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <material.TextField
                                margin="dense"
                                required
                                label="Product Purchase Contact Name"
                                id='productPurchaseContactName'
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                {...register("productPurchaseContactName", { required: true })}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mt-2">
                            <material.InputLabel>Product Purchase Contact Number</material.InputLabel>
                            <Controller
                                name="productPurchaseContactNumber"
                                control={control}
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <PhoneInput
                                        {...field}
                                        required
                                        placeholder="Product Purchase Contact Number"
                                        defaultCountry="AU"
                                        enableSearch={true}
                                        value={field.value || ""}
                                        international
                                        onChange={(newValue) => {
                                            field.onChange(newValue);
                                        }}
                                        error={field.value ? (isValidPhoneNumber(field.value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <material.TextField
                                margin="dense"
                                required
                                label="Product Purchase Email"
                                id='productPurchaseEmail'
                                variant="standard"
                                type="email"
                                size="small"
                                fullWidth
                                {...register("productPurchaseEmail", { required: true })}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <material.TextField
                                margin="dense"
                                required
                                label="Shipping Cost"
                                id='shippingCost'
                                variant="standard"
                                type="number"
                                size="small"
                                fullWidth
                                {...register("shippingCost", { required: true })}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <material.TextField
                                margin="dense"
                                required
                                label="Free Shipping Threshold"
                                id='freeShippingThreshold'
                                variant="standard"
                                type="number"
                                size="small"
                                fullWidth
                                {...register("freeShippingThreshold", { required: true })}
                            />
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions sx={{ m: 2 }}>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} color='error' startIcon={<material.CloseIcon />} onClick={handleClose}>Cancel</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />} color='success' onClick={handleSubmit(saveSupplierConfig)}>{openAddOrEditSupplierConfig.rowData == null ? "Save" : "Update"}</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default AddOrEditSupplierConfig;

