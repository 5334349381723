import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { Slide } from '@mui/material';
import { getCart, paymentGateway, updateProductQuantity } from '../../../services/SupplierConfigService';
import Snackbar from '../../toastrmessage/Snackbar';
import { rootApiUrl } from '../../../environment/Environment';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function PurchaseCartDialog(props) {

    const { openPurchaseCart, setOpenPurchaseCart, userDetails, clinicDetails } = props;
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [allChartData, setAllChartData] = useState(null);

    useEffect(() => {
        if (openPurchaseCart) {
            getCartData()
        }
    }, [openPurchaseCart]);

    const getCartData = () => {
        const payload = {
            orgId: userDetails.orgId,
            clinicId: clinicDetails.clinicId
        }
        getCart(payload)
            .then((resp) => {
                setAllChartData(resp.data.data)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const close = () => {
        setOpenPurchaseCart(false)
    };

    const handleChangeProductQuantity = (rowData, quantity) => {
        const payload = {
            purchaseId: rowData.purchaseId,
            productConfigId: rowData.productConfigId,
            quantity: quantity == 1 ? Number(rowData.quantity) + 1 : Number(rowData.quantity) - 1
        }
        updateProductQuantity(payload)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data.messages,
                });
                getCartData();
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const paymentMethod = (cartDetails) => {
        const payload = {
            amount: cartDetails.orderTotalIncludingGst
        }
        paymentGateway(payload)
            .then((resp) => {
                const url = resp.data.data.redirectUrl;
                window.open(url, "_blank");
            })
            .catch((error) => {
                console.log(error)
            })
    };

    return (
        <div>
            <material.Dialog open={openPurchaseCart} fullScreen
                PaperProps={{
                    sx: {
                        backgroundColor: '#fff',
                    },
                }}
                TransitionComponent={Transition}
            >
                <material.AppBar sx={{
                    position: 'relative',
                }}>
                    <material.Toolbar>
                        <material.IconButton
                            edge="start"
                            color="inherit"
                            onClick={close}
                            aria-label="close"
                        >
                            <material.CloseIcon />
                        </material.IconButton>
                        <material.Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Purchase Details
                        </material.Typography>
                    </material.Toolbar>
                </material.AppBar>
                <material.DialogContent>
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <material.Paper elevation={1} sx={{ p: 4 }}>
                                <material.Typography variant='h6'>PRODUCT DETAILS</material.Typography>
                                <material.Divider sx={{ borderColor: "black", mb: 1 }} />
                                {allChartData?.orderDetails.length ? allChartData?.orderDetails.map((item, k) => (
                                    <>
                                        <div className="row" key={k}>
                                            <div className="col-lg-4 col-md-12 col-sm-12">
                                                <div className='d-flex justify-content-center align-item-center'>
                                                    <span>
                                                        <img
                                                            src={`${rootApiUrl}/agl-link?key=${item.productImage}`}
                                                            alt='product image'
                                                            height="100%"
                                                            width="100%"
                                                            style={{ objectFit: "contain" }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className='d-flex justify-content-center align-item-center mt-5'>
                                                    <material.Button color='error' variant="contained" sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DeleteIcon />}>Remove</material.Button>
                                                    <div className="d-flex align-items-center">
                                                        <material.Button
                                                            variant="outlined"
                                                            color="primary"
                                                            sx={{ minWidth: 40 }}
                                                            disabled={item.quantity === item.minimumOrderQuantity}
                                                            onClick={() => handleChangeProductQuantity(item, -1)}
                                                        >
                                                            -
                                                        </material.Button>
                                                        <span style={{ margin: "0 15px", fontWeight: "bold", fontSize: "16px" }}>
                                                            {item.quantity}
                                                        </span>
                                                        <material.Button
                                                            variant="outlined"
                                                            color="primary"
                                                            sx={{ minWidth: 40 }}
                                                            onClick={() => handleChangeProductQuantity(item, 1)}
                                                        >
                                                            +
                                                        </material.Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-sm-12">
                                                <material.Typography sx={{ fontWeight: "bold", fontSize: 20 }}>{item.productName}</material.Typography>
                                                <material.Typography sx={{ width: 600 }}>Product Description: <span className='fw-bold' style={{ wordBreak: "break-all" }}>{item.productDescription}</span></material.Typography>
                                                <material.Typography>Supplier Name: <span className='fw-bold'>{item.supplierName}</span></material.Typography>
                                                <material.Typography>List Price Including GST: <span className='fw-bold'>${item.listPriceIncludingGst}</span></material.Typography>
                                                <material.Typography>List Price Excluding GST: <span className='fw-bold'>${item.listPriceExcludingGst}</span></material.Typography>
                                                <material.Typography>GST: <span className='fw-bold'>${item.gst}</span></material.Typography>
                                                <material.Typography>Order GST: <span className='fw-bold'>${item.orderGst}</span></material.Typography>
                                                <material.Typography>Total of Minimum Order Quantity Including GST: <span className='fw-bold'>${item.totalOfMinimumOrderQuantityIncludingGst}</span></material.Typography>
                                                <material.Typography>Total of Minimum Order Quantity Excluding GST: <span className='fw-bold'>${item.totalOfMinimumOrderQuantityExcludingGst}</span></material.Typography>
                                                <material.Typography>Total Excluding GST: <span className='fw-bold'>${item.totalExcludingGst}</span></material.Typography>
                                            </div>
                                        </div>
                                        <material.Divider sx={{ borderColor: "black", mb: 1, mt: 1 }} />
                                    </>
                                )) : null}
                            </material.Paper>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <material.Paper elevation={1} sx={{ p: 4 }}>
                                <material.Typography variant='h6'>PRICE DETAILS</material.Typography>
                                <material.Divider sx={{ borderColor: "black" }} />
                                <div className="row">
                                    <div className="col-6">
                                        <material.Typography sx={{ fontWeight: "bold" }}>Price</material.Typography>
                                    </div>
                                    <div className="col-6">
                                        <material.Typography sx={{ fontWeight: "bold" }}>${allChartData?.orderTotalExcludingGst}</material.Typography>
                                    </div>
                                    <div className="col-6">
                                        <material.Typography>GST</material.Typography>
                                    </div>
                                    <div className="col-6">
                                        <material.Typography>${allChartData?.gst}</material.Typography>
                                    </div>
                                    <material.Divider sx={{ borderColor: "black" }} />
                                    <div className="col-6">
                                        <material.Typography sx={{ fontWeight: "bold" }}>Total Amount</material.Typography>
                                    </div>
                                    <div className="col-6">
                                        <material.Typography sx={{ fontWeight: "bold" }}>${allChartData?.orderTotalIncludingGst}</material.Typography>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <button type="button" style={{ width: "100%" }} className="btn btn-warning" onClick={() => paymentMethod(allChartData)}>Pay ${allChartData?.orderTotalIncludingGst}</button>
                                    </div>
                                </div>
                            </material.Paper>
                        </div>
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default PurchaseCartDialog;