import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { material } from '../../../library/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { getStocksByClinicId, getStocksByOrgId } from '../../../services/StocksService';
import { changeDateAndTimeFormate, changeDateFormate } from '../../../date-and-time-format/DateAndTimeFormat';
import Snackbar from '../../toastrmessage/Snackbar';
import { socket } from '../../../socket/Socket';
import { connect } from 'react-redux';
import AddStocksPurchased from '../../dialog/drug-book/AddStocksPurchased';
import Navbar from '../../navbar/Navbar';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#4e73df",
        color: theme.palette.common.white,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

var allProductData;

function StockPurchased(props) {

    const { userData, clinicData } = props;
    let userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const theme = useTheme();
    const [stocksData, setStocksData] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [isLoading, setisLoading] = useState(true);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [stockUpdated, setStockUpdated] = useState();
    const [openAddStock, setOpenAddStock] = useState({ action: false, stockData: null })

    socket.on("stock-updated", (res) => {
        setStockUpdated(res)
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Stock Updated",
        })
    });

    localStorage.setItem("menuName", "Injectable Purchased");

    useEffect(() => {
        getAllStocks()
    }, [stockUpdated]);

    const getAllStocks = async () => {
        // if (userDetails.role.includes("ADMIN")) {
        //     await getStocksByOrgId(userDetails.orgId)
        //         .then((resp) => {
        //             allProductData = resp.data;
        //             setStocksData(resp.data)
        //             setisLoading(false)
        //         })
        // } else if (userDetails.role.includes("MANAGER")) {
        //     await getStocksByClinicId(clinicDetails.clinicId)
        //         .then((resp) => {
        //             allProductData = resp.data.data;
        //             setStocksData(resp.data.data)
        //             setisLoading(false);
        //         })
        // };
        await getStocksByClinicId(clinicDetails.clinicId)
            .then((resp) => {
                allProductData = resp.data.data;
                setStocksData(resp.data.data)
                setisLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
        setStockUpdated("")
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const addStocks = () => {
        setOpenAddStock({ action: true, stockData: null })
    };

    const editStock = (stockData) => {
        setOpenAddStock({ action: true, stockData: stockData })
    };

    const filterByPrice = (value) => {
        setPage(0);
        const filterData = stocksData.filter((ele) => {
            return ele.product
                .toString()
                .toLowerCase()
                .trim()
                .includes(value.toString().toLowerCase().trim())
        })
        if (value.trim().toString().length < 1) {
            setStocksData(allProductData)
        } else {
            setStocksData(filterData)
        }
    };

    return (
        <material.Grid spacing={2} className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 10
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 10
                },
            }}
        >
            <Navbar />
            <div className='row'>
                <div className='col-6'>
                    <span>
                        <material.TextField
                            fullWidth
                            variant="standard"
                            label="Filter by Product Name"
                            type='text'
                            onChange={(e) => filterByPrice(e.target.value)}
                            sx={{ width: "40ch" }}
                        />
                    </span>
                </div>
                <div className='col-6'>
                    <span className="float-end">
                        <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={addStocks}>Add-Injectable</material.Button>
                    </span>
                </div>
                <div className="col-12 mt-3">
                    <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <material.TableContainer sx={{ maxHeight: 500 }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Supplier</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Clinic Id</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Product</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Product Type</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Box/Vial</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Number of Box/Vial</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Unit Per Box/Vial</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Cost Per Box/Vial</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Total Cost</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Date in</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Batch No</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Expiry Date</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Edited by</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Time stamp</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Comments</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Action</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={10}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={10} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {stocksData.length ? stocksData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                <material.TableRow
                                                    key={i}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': { border: 0 }
                                                    }}
                                                >
                                                    <material.TableCell sx={{ pt: 2, pb: 2 }} size='small'>{row.supplier}</material.TableCell>
                                                    <material.TableCell size='small'>{row.clinicId}</material.TableCell>
                                                    <material.TableCell size='small'>{row.product}</material.TableCell>
                                                    <material.TableCell size='small'>{row.productType}</material.TableCell>
                                                    <material.TableCell size='small'>{row.unitType}</material.TableCell>
                                                    <material.TableCell size='small'>{row.noOfBoxOrVial}</material.TableCell>
                                                    <material.TableCell size='small'>{row.unitsPerBoxOrVial}</material.TableCell>
                                                    <material.TableCell size='small'>{row.costPerBoxOrVial}</material.TableCell>
                                                    <material.TableCell size='small'>{row.totalCost}</material.TableCell>
                                                    <material.TableCell size='small'>{row.dateIn != null ? changeDateFormate(row.dateIn) : ""}</material.TableCell>
                                                    <material.TableCell size='small'>{row.batchNo}</material.TableCell>
                                                    <material.TableCell size='small'>{row.expDate != null ? changeDateFormate(row.expDate) : ""}</material.TableCell>
                                                    <material.TableCell size='small'>{row.enitedBy}</material.TableCell>
                                                    <material.TableCell size='small'>{row.timeStamp != null ? changeDateAndTimeFormate(row.timeStamp) : ""}</material.TableCell>
                                                    <material.TableCell size='small'>{row.comment}</material.TableCell>
                                                    <StyledTableCell size='small'>
                                                        <material.IconButton title='Edit Stocks' aria-label="create" size="large" onClick={() => editStock({ ...row, "action": "edit" })}>
                                                            <material.CreateIcon color='primary' />
                                                        </material.IconButton>
                                                    </StyledTableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={10}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>)}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                        <hr />
                        <material.TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={stocksData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </material.Paper>
                </div>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <AddStocksPurchased
                openAddStock={openAddStock}
                setOpenAddStock={setOpenAddStock}
                getAllStocks={getAllStocks}
            />
        </material.Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(StockPurchased);