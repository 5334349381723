import React, { useEffect, useState } from 'react';
import { material } from "../../../../library/material";
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { getTreatmentListByClinicId } from '../../../../services/TreatmentListService';
import Snackbar from '../../../toastrmessage/Snackbar';
import { getSkinTreatmentPriceListForBookingByClinicId } from '../../../../services/SkinTreatmentService';
import { saveProposedTreatmentPlans } from '../../../../services/TreatmentPlanService';
import { getOtherInHandStocksByClinic } from '../../../../services/OtherStockService';
import moment from 'moment';

const sessionFocusOptions = ["Skin", "Wrinkles", "Volume", "Morphology"];
const type = ["Product", "Treatment"];
const selectDateFormat = ['Day', 'Week', "Month", 'Year'];
const typeOfTreatment = ['Home Treatment', 'Skin Treatment', 'Cosmetic Injectable']

function AddTreatmentPlan(props) {
    const { setOpenAddTreatmentPlan, openAddTreatmentPlan, userData, clinicData, patientData, getProposedTreatmentPlansByClinicIdAndOrgId } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;

    const { register, handleSubmit, resetField, control, watch, getValues, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
        defaultValues: {
            treatmentPlans: []
        }
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'treatmentPlans',
    });
    const [startDate, setStartDate] = useState(dayjs());
    const [combinedTreatments, setCombinedTreatments] = useState([]);
    const [treatmentNameOptions, setTreatmentNameOptions] = useState([]);
    const [productNameOptions, setProductNameOptions] = useState([]);
    const days = [...Array(31).keys()].map((day) => (day + 1).toString());
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });


    // let curentDate = moment(new Date()).format("DD-MM-YYYY")
    // console.log(curentDate)

    useEffect(() => {
        if (openAddTreatmentPlan.action) {
            getTreatmentList();
            getProductName();
        }
    }, [openAddTreatmentPlan.action]);

    const getTreatmentList = async () => {
        try {
            const [treatments, skinTreatments] = await Promise.all([
                getTreatmentListByClinicId(clinicDetails.clinicId, userDetails.orgId),
                getSkinTreatmentPriceListForBookingByClinicId(clinicDetails.clinicId)
            ]);
            let skinTreatment = skinTreatments.data.data.map((x) => {
                return {
                    treatmentName: x.treatmentName,
                    treatmentArea: x.treatmentDetails.map((x) => x.treatmentArea),
                };
            });
            const allTreatments = [...treatments.data.data, ...skinTreatment];
            setCombinedTreatments(allTreatments);
            setTreatmentNameOptions(allTreatments.map(t => t.treatmentName));
        } catch (error) {
            setOpenSnackBar({
                action: true,
                type: "error",
                message: error.response?.data?.messages || "An error occurred while fetching treatments."
            });
        }
    };

    const getTreatmentAreaOptions = (treatmentName) => {
        const treatment = combinedTreatments.find(ele => ele.treatmentName === treatmentName);
        return treatment ? treatment.treatmentArea : [];
    };

    const getProductName = () => {
        getOtherInHandStocksByClinic(clinicDetails.clinicId)
            .then((res) => {
                let productName = res.data?.data?.map((ele) => ele.product);
                productName.push("Other Product")
                setProductNameOptions(productName);
            }).catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            });
    };

    const handleDateChange = (dayCount, dateFormat) => {
        // setStartDate(dayjs(new Date()));
        let newDate = dayjs();
        switch (dateFormat) {
            case 'Day':
                newDate = newDate.add(dayCount, 'day');
                break;
            case 'Week':
                newDate = newDate.add(dayCount, 'week');
                break;
            case 'Month':
                newDate = newDate.add(dayCount, 'month');
                break;
            case 'Year':
                newDate = newDate.add(dayCount, 'year');
                break;
            default:
                break;
        }
        return newDate
    };

    const saveTreatment = (data) => {
        const array = [];
        data.treatmentPlans.forEach(ele => {
            let obj = {
                clinicId: clinicDetails.clinicId,
                orgId: userDetails.orgId,
                patientId: patientData.patientId,
                injectorId: userDetails.userId,
                "treatmentSession": ele.treatmentSession,
                "sessionFocus": ele.sessionFocus,
                "rational": ele.rational,
                "type": ele.type,
                "treatment": ele.treatmentName,
                "treatmentArea": ele.treatmentArea?.length > 1 ? ele.treatmentArea.join(",") : ele.treatmentArea[0],
                "productName": ele.productName === "Other Product" ? ele.otherProduct : ele.productName,
                "productArea": ele.productArea,
                "cost": ele.cost,
                "days": ele.days,
                "dateFormat": ele.dateFormat,
                "timeApart": startDate.format("YYYY-MM-DD"),
                typeOfTreatment: ele.typeOfTreatment
            }
            array.push(obj);
        });
        const payload = {
            treatmentPlans: array
        };
        saveProposedTreatmentPlans(payload)
            .then((res) => {
                getProposedTreatmentPlansByClinicIdAndOrgId();
                reset({ typeOfTreatment: "", treatmentSession: "", sessionFocus: "", rational: "", treatment: "", treatmentArea: "", productName: "", productArea: "", otherProduct: "", cost: "", days: "", dateFormat: "", timeApart: "" });
                remove();
                setOpenAddTreatmentPlan({ action: false });
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": res.data,
                });
            }).catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                });
            });
    };

    const cancel = () => {
        reset({ typeOfTreatment: "", treatmentSession: "", sessionFocus: "", rational: "", treatment: "", treatmentArea: "", productName: "", productArea: "", otherProduct: "", cost: "", days: "", dateFormat: "", timeApart: "" });
        // resetField("treatmentSession");
        // resetField("sessionFocus");
        // resetField("rational");
        // resetField("type");
        // resetField("treatment");
        // resetField("treatmentArea");
        // resetField("productName");
        // resetField("productArea");
        // resetField("cost");
        // resetField("days");
        // resetField("dateFormat");
        // resetField("timeApart");
        remove();
        setOpenAddTreatmentPlan({ action: false });
    };

    return (
        <div>
            <material.Dialog
                fullWidth
                maxWidth="lg"
                open={openAddTreatmentPlan.action}
                hideBackdrop
            >
                <material.DialogTitle>
                    <span>
                        <material.Typography variant="h5">Add Proposed Treatment Plan</material.Typography>
                    </span>
                    <span>
                        <material.Fab
                            color="primary"
                            aria-label="add"
                            size='small'
                            title='Add Treatment'
                            sx={{ mt: 2 }}
                            onClick={() => append({ treatmentSession: "", sessionFocus: "", rational: "", type: "", treatmentName: "", treatmentArea: "", productName: "", productArea: "", days: "", dateFormat: "", timeApart: "", cost: "" })}
                        >
                            <material.AddIcon />
                        </material.Fab>
                    </span>
                </material.DialogTitle>

                <material.DialogContent>
                    {fields.map((field, index) => {
                        const typeName = watch(`treatmentPlans.${index}.type`);
                        const productName = watch(`treatmentPlans.${index}.productName`);
                        return (
                            <div key={field.id} className='border border-2 p-2 my-2 rounded-2 mt-2' style={{ boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" }}>
                                <div className='row'>
                                    <div className='col-3'>
                                        <Controller
                                            name={`treatmentPlans.${index}.typeOfTreatment`}
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <material.Autocomplete
                                                    {...field}
                                                    options={typeOfTreatment}
                                                    renderInput={(params) => (
                                                        <material.TextField
                                                            margin="dense"
                                                            {...params}
                                                            variant="standard"
                                                            label="Type of Treatment"
                                                            fullWidth
                                                            error={!!errors?.treatmentPlans?.[index]?.typeOfTreatment}
                                                        />
                                                    )}
                                                    onChange={(_, data) => field.onChange(data)}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Controller
                                            name={`treatmentPlans.${index}.treatmentSession`}
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <material.TextField
                                                    margin="dense"
                                                    {...field}
                                                    label="Treatment Session"
                                                    variant="standard"
                                                    type="number"
                                                    size="small"
                                                    fullWidth
                                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                                    error={!!errors?.treatmentPlans?.[index]?.treatmentSession}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Controller
                                            name={`treatmentPlans.${index}.sessionFocus`}
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <material.Autocomplete
                                                    {...field}
                                                    options={sessionFocusOptions}
                                                    renderInput={(params) => (
                                                        <material.TextField
                                                            margin="dense"
                                                            {...params}
                                                            variant="standard"
                                                            label="Session Focus"
                                                            fullWidth
                                                            error={!!errors?.treatmentPlans?.[index]?.sessionFocus}
                                                        />
                                                    )}
                                                    onChange={(_, data) => field.onChange(data)}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Controller
                                            name={`treatmentPlans.${index}.rational`}
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <material.TextField
                                                    margin="dense"
                                                    {...field}
                                                    label="Rational"
                                                    variant="standard"
                                                    type="text"
                                                    size="small"
                                                    multiline
                                                    fullWidth
                                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                                    error={!!errors?.treatmentPlans?.[index]?.rational}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Controller
                                            name={`treatmentPlans.${index}.type`}
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <material.Autocomplete
                                                    // margin="dense"
                                                    {...field}
                                                    options={type}
                                                    renderInput={(params) => (
                                                        <material.TextField
                                                            margin="dense"
                                                            {...params}
                                                            variant="standard"
                                                            label="Type"
                                                            fullWidth
                                                            error={!!errors?.treatmentPlans?.[index]?.type}
                                                        />
                                                    )}
                                                    onChange={(_, data) => {
                                                        field.onChange(data)
                                                        const typeName = getValues(`treatmentPlans.${index}.type`);
                                                        if (typeName === "Product") {
                                                            resetField(`treatmentPlans.${index}.treatmentName`)
                                                            resetField(`treatmentPlans.${index}.treatmentArea`)
                                                        } else if (typeName === "Treatment") {
                                                            resetField(`treatmentPlans.${index}.productName`)
                                                            resetField(`treatmentPlans.${index}.otherProduct`)
                                                            resetField(`treatmentPlans.${index}.productArea`)
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    {typeName === "Product" ? (
                                        <>
                                            <div className='col-3'>
                                                <Controller
                                                    name={`treatmentPlans.${index}.productName`}
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <material.Autocomplete
                                                            // margin="dense"
                                                            {...field}
                                                            options={productNameOptions}
                                                            renderInput={(params) => (
                                                                <material.TextField
                                                                    margin="dense"
                                                                    {...params}
                                                                    variant="standard"
                                                                    label="Product Name"
                                                                    fullWidth
                                                                    error={!!errors?.treatmentPlans?.[index]?.productName}
                                                                />
                                                            )}
                                                            onChange={(_, data) => field.onChange(data)}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            {productName === "Other Product" ? (
                                                <div className='col-3'>
                                                    <Controller
                                                        name={`treatmentPlans.${index}.otherProduct`}
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{ required: true }}
                                                        render={({ field }) => (
                                                            <material.TextField
                                                                margin="dense"
                                                                {...field}
                                                                label="Other Product"
                                                                variant="standard"
                                                                type="text"
                                                                size="small"
                                                                multiline
                                                                fullWidth
                                                                inputProps={{ style: { textTransform: 'capitalize' } }}
                                                                error={!!errors?.treatmentPlans?.[index]?.otherProduct}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            ) : null}
                                            <div className='col-3'>
                                                <Controller
                                                    name={`treatmentPlans.${index}.productArea`}
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <material.TextField
                                                            margin="dense"
                                                            {...field}
                                                            label="Product Area"
                                                            variant="standard"
                                                            type="text"
                                                            size="small"
                                                            multiline
                                                            fullWidth
                                                            inputProps={{ style: { textTransform: 'capitalize' } }}
                                                            error={!!errors?.treatmentPlans?.[index]?.productArea}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </>
                                    ) : typeName === "Treatment" ? (
                                        <>
                                            <div className='col-3'>
                                                <Controller
                                                    name={`treatmentPlans.${index}.treatmentName`}
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <material.Autocomplete
                                                            // margin="dense"
                                                            {...field}
                                                            options={treatmentNameOptions}
                                                            renderInput={(params) => (
                                                                <material.TextField
                                                                    margin="dense"
                                                                    {...params}
                                                                    variant="standard"
                                                                    label="Treatment Name"
                                                                    fullWidth
                                                                    error={!!errors?.treatmentPlans?.[index]?.treatmentName}
                                                                />
                                                            )}
                                                            onChange={(_, data) => field.onChange(data)}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className='col-3'>
                                                <Controller
                                                    name={`treatmentPlans.${index}.treatmentArea`}
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <material.Autocomplete
                                                            // margin="dense"
                                                            multiple
                                                            disableCloseOnSelect
                                                            {...field}
                                                            value={field.value || []}
                                                            options={getTreatmentAreaOptions(watch(`treatmentPlans.${index}.treatmentName`))}
                                                            renderInput={(params) => (
                                                                <material.TextField
                                                                    margin="dense"
                                                                    {...params}
                                                                    variant="standard"
                                                                    label="Treatment Area"
                                                                    fullWidth
                                                                    error={!!errors?.treatmentPlans?.[index]?.treatmentArea}
                                                                />
                                                            )}
                                                            onChange={(_, data) => field.onChange(data)}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </>
                                    ) : null}
                                    <div className='col-3'>
                                        <Controller
                                            name={`treatmentPlans.${index}.cost`}
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <material.TextField
                                                    margin="dense"
                                                    {...field}
                                                    label="Cost"
                                                    variant="standard"
                                                    type="text"
                                                    size="small"
                                                    multiline
                                                    fullWidth
                                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                                    error={!!errors?.treatmentPlans?.[index]?.cost}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Controller
                                            name={`treatmentPlans.${index}.days`}
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <material.Autocomplete
                                                    // margin="dense"
                                                    {...field}
                                                    options={days}
                                                    renderInput={(params) => (
                                                        <material.TextField
                                                            margin="dense"
                                                            {...params}
                                                            variant="standard"
                                                            label="Days"
                                                            fullWidth
                                                            error={!!errors?.treatmentPlans?.[index]?.days}
                                                        />
                                                    )}
                                                    onChange={(event, data) => {
                                                        field.onChange(data);
                                                        const dateFormat = getValues(`treatmentPlans.${index}.dateFormat`);
                                                        let updateDays = handleDateChange(data, dateFormat);
                                                        // setValue(`treatmentPlans.${index}.timeApart`, updateDays)
                                                        setStartDate(dayjs(updateDays))
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <Controller
                                            name={`treatmentPlans.${index}.dateFormat`}
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <material.Autocomplete
                                                    // margin="dense"
                                                    {...field}
                                                    options={selectDateFormat}
                                                    renderInput={(params) => (
                                                        <material.TextField
                                                            margin="dense"
                                                            {...params}
                                                            variant="standard"
                                                            label="Date Format"
                                                            fullWidth
                                                            error={!!errors?.treatmentPlans?.[index]?.dateFormat}
                                                        />
                                                    )}
                                                    onChange={(event, data) => {
                                                        field.onChange(data); // Update form state with selected value
                                                        const dayCount = getValues(`treatmentPlans.${index}.days`);
                                                        let updateDate = handleDateChange(dayCount, data); // Pass data to handleDateChange
                                                        // setValue(`treatmentPlans.${index}.timeApart`, updateDate)
                                                        setStartDate(dayjs(updateDate))
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='col-3 disabledColor mt-2' >
                                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                                            <Controller
                                                name={`treatmentPlans.${index}.timeApart`}
                                                control={control}
                                                defaultValue={startDate}
                                                render={({ field }) => (
                                                    <material.DatePicker
                                                        margin="dense"
                                                        {...field}
                                                        disabled
                                                        label="Time Apart"
                                                        format='DD-MM-YYYY'
                                                        value={handleDateChange(watch(`treatmentPlans.${index}.days`), watch(`treatmentPlans.${index}.dateFormat`))}
                                                        // onChange={(date) => {
                                                        //     field.onChange(date); // Update form state
                                                        //     setStartDate(date); // Update state
                                                        // }}
                                                        sx={{ width: "100%", }}
                                                        slotProps={{ textField: { variant: 'standard' } }}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </div>

                                    <span className='col-3'>
                                        <material.IconButton
                                            title='Delete Treatment'
                                            aria-label="delete"
                                            size="large"
                                            sx={{ marginTop: { xs: 3, sm: 3, md: 2, } }}
                                            onClick={() => remove(index)}
                                        >
                                            <material.DeleteIcon color='warning' />
                                        </material.IconButton>
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </material.DialogContent>

                <material.DialogActions>
                    <div className="mt-3">
                        <span className="float-end py-3">
                            <material.Button
                                variant="contained"
                                sx={{ textTransform: "none" }}
                                size="medium"
                                color="error"
                                onClick={cancel}
                            >
                                Close
                            </material.Button>
                        </span>
                    </div>
                    <div className="mt-3">
                        <span className="float-end py-3">
                            <material.Button
                                variant="contained"
                                sx={{ textTransform: "none" }}
                                size="medium"
                                color="primary"
                                onClick={handleSubmit(saveTreatment)}
                                disabled={!fields.length || !isValid}
                            >
                                Save
                            </material.Button>
                        </span>
                    </div>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    userData: state,
    clinicData: state,
});

export default connect(mapStateToProps)(AddTreatmentPlan);
