import React, { useEffect, useState } from 'react';
import { material } from '../../../../library/material';
import { Slide } from '@mui/material';
import { useForm } from 'react-hook-form';
import { createNewProposedTreatmentPlan, getTreatmentPlanHtml, getTreatmentPlans, saveAndDraftProposedTreatmentPlan, sendPlanEmail } from '../../../../services/TreatmentPlanService';
import Snackbar from '../../../toastrmessage/Snackbar';
import AddHomeTreatmentPlan from './AddHomeTreatmentPlan';
import AddSkinAndCosmeticTreatment from './AddSkinAndCosmeticTreatment';
import ViewTreatmentList from './ViewTreatmentList';
import PermissionDialog from './PermissionDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function AddProposedTreatmentPlan(props) {

    const { openAddProposedTreatmentPlan, setOpenAddProposedTreatmentPlan, clinicDetails, userDetails, getProposedTreatmentPlansByClinicIdAndOrgId } = props;
    const { register, handleSubmit, reset, setValue, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [openAddHomeTreatment, setOpenAddHomeTreatment] = useState({ action: false, patientData: null, planData: null });
    const [openAddCosmeticAndSkinTreatment, setOpenAddCosmeticAndSkinTreatment] = useState({ action: false, patientData: null, planData: null, openFor: null });
    const [planDetails, setPlanDetails] = useState(null);
    const [getTreatmentPlanData, setGetTreatmentPlanData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [openPermissionDialog, setOpenPermissionDialog] = useState(false)

    useEffect(() => {
        if (openAddProposedTreatmentPlan.action && openAddProposedTreatmentPlan.rowData === null) {
            setValue('name', openAddProposedTreatmentPlan.patientData.name);
            setValue('dateOfBirth', openAddProposedTreatmentPlan.patientData.dateOfBirth);
            createNewProposedTreatmentPlanDetails();
        } else if (openAddProposedTreatmentPlan.action && openAddProposedTreatmentPlan.rowData !== null) {
            reset({ ...openAddProposedTreatmentPlan.rowData, dateOfBirth: openAddProposedTreatmentPlan.patientData.dateOfBirth, name: openAddProposedTreatmentPlan.patientData.name });
            getProposedTreatmentPlan(openAddProposedTreatmentPlan.rowData.id);
        }
    }, [openAddProposedTreatmentPlan.action === true]);

    const createNewProposedTreatmentPlanDetails = () => {
        const payload = {
            patientId: openAddProposedTreatmentPlan.patientData.patientId,
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId
        }
        createNewProposedTreatmentPlan(payload)
            .then((resp) => {
                reset(resp.data.data.plan);
                setPlanDetails(resp.data.data.plan);
                if (resp.data.data) {
                    getProposedTreatmentPlan(resp.data.data.plan.id)
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getProposedTreatmentPlan = (proposedPlanId) => {
        setIsLoading(true);
        getTreatmentPlans(proposedPlanId)
            .then((resp) => {
                setGetTreatmentPlanData(resp.data.data);
                setIsLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const cosmeticInjectable = () => {
        setOpenAddCosmeticAndSkinTreatment({ action: true, patientData: openAddProposedTreatmentPlan.patientData, planData: openAddProposedTreatmentPlan.rowData !== null ? openAddProposedTreatmentPlan.rowData : planDetails, openFor: "Cosmetic" })
    };

    const skinTreatment = () => {
        setOpenAddCosmeticAndSkinTreatment({ action: true, patientData: openAddProposedTreatmentPlan.patientData, planData: openAddProposedTreatmentPlan.rowData !== null ? openAddProposedTreatmentPlan.rowData : planDetails, openFor: "Skin" })
    };

    const atHome = () => {
        setOpenAddHomeTreatment({ action: true, patientData: openAddProposedTreatmentPlan.patientData, planData: openAddProposedTreatmentPlan.rowData !== null ? openAddProposedTreatmentPlan.rowData : planDetails })
    };

    const close = () => {
        setOpenAddProposedTreatmentPlan({ action: false, patientData: null })
    };

    const saveProposedTreatment = (data) => {
        const payload = {
            planFileName: data.planFileName,
            planSummary: data.planSummary,
            notes: data.notes,
            patientConcerns: data.patientConcerns,
            progress: data.progress,
            isSaved: true,
            id: data.id,
        }
        saveAndDraftProposedTreatmentPlan(payload)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data.messages,
                });
                setOpenAddProposedTreatmentPlan({ action: false, patientData: null });
                getProposedTreatmentPlansByClinicIdAndOrgId();
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const saveAsDraftProposedTreatment = (data) => {
        const payload = {
            planFileName: data.planFileName,
            planSummary: data.planSummary,
            notes: data.notes,
            patientConcerns: data.patientConcerns,
            progress: data.progress,
            isSaved: false,
            id: data.id,
        }
        saveAndDraftProposedTreatmentPlan(payload)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data.messages,
                });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const sendProposedTreatmentPlan = () => {
        sendPlanEmail(openAddProposedTreatmentPlan.rowData.id)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data.messages,
                });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const printProposedTreatmentPlan = () => {
        setOpenPermissionDialog(true);
    };

    const getPrintTypeValue = (data) => {
        const payload = {
            proposedPlanId: openAddProposedTreatmentPlan.rowData.id,
            atHomeOnly: data
        }
        getTreatmentPlanHtml(payload)
            .then((resp) => {
                setOpenPermissionDialog(false);
                const newTab = window.open("", "_blank")
                newTab.document.write(resp.data.data)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog hideBackdrop TransitionComponent={Transition} fullScreen open={openAddProposedTreatmentPlan.action}>
                <material.AppBar sx={{ position: 'relative' }}>
                    <material.Toolbar>
                        <material.IconButton
                            edge="start"
                            color="inherit"
                            onClick={close}
                            aria-label="close"
                        >
                            <material.CloseIcon />
                        </material.IconButton>
                        <material.Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Proposed Treatment Plan
                        </material.Typography>
                        <material.Button autoFocus sx={{ textTransform: "none" }} color="inherit" onClick={handleSubmit(saveProposedTreatment)}>Save</material.Button>
                        <material.Button autoFocus sx={{ textTransform: "none" }} color="inherit" onClick={handleSubmit(saveAsDraftProposedTreatment)} hidden={openAddProposedTreatmentPlan.rowData?.isSaved}>Save as Draft</material.Button>
                    </material.Toolbar>
                </material.AppBar>
                <material.DialogContent>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <span className='float-end'>
                                <material.Button variant="outlined" sx={{ textTransform: "none", mr: 1 }} size="small" color="secondary" startIcon={<material.SendIcon />} onClick={sendProposedTreatmentPlan} hidden={!openAddProposedTreatmentPlan.rowData?.isSaved}>
                                    Send Proposed Treatment Plan
                                </material.Button>
                                <material.Button variant="outlined" sx={{ textTransform: "none", mr: 1 }} size="small" color="secondary" startIcon={<material.PrintIcon />} onClick={printProposedTreatmentPlan} hidden={!openAddProposedTreatmentPlan.rowData?.isSaved}>
                                    Print Proposed Treatment Plan
                                </material.Button>
                                <material.Button variant="outlined" size='small' sx={{ textTransform: "none", mr: 1 }} onClick={cosmeticInjectable}>Cosmetic Injectable</material.Button>
                                <material.Button variant="outlined" size='small' sx={{ textTransform: "none", mr: 1 }} onClick={skinTreatment}>Skin Treatment</material.Button>
                                <material.Button variant="outlined" size='small' sx={{ textTransform: "none", mr: 1 }} onClick={atHome}>At Home</material.Button>
                            </span>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <material.TextField
                                        {...register("name")}
                                        margin="dense"
                                        id="name"
                                        label="Patient Name"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        InputProps={{ readOnly: true }}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <material.TextField
                                        {...register("dateOfBirth")}
                                        margin="dense"
                                        id="name"
                                        label="Date of Birth"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        InputProps={{ readOnly: true }}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <material.TextField
                                        {...register("planFileName")}
                                        margin="dense"
                                        id="name"
                                        label="Plan Identification Name"
                                        type="text"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        variant="standard"
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <material.TextField
                                        {...register("id")}
                                        margin="dense"
                                        id="name"
                                        label="Plan Id"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ readOnly: true }}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <material.TextField
                                        {...register("createdBy")}
                                        margin="dense"
                                        id="name"
                                        label="Created By"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ readOnly: true }}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <material.TextField
                                        {...register("updatedBy")}
                                        margin="dense"
                                        id="name"
                                        label="Last Updated By"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ readOnly: true }}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <material.TextField
                                        {...register("progress")}
                                        margin="dense"
                                        id="name"
                                        label="Progress"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <material.TextField
                                        {...register("planStatus")}
                                        margin="dense"
                                        id="name"
                                        label="Plan Status"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ readOnly: true }}
                                    />
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <material.TextField
                                        {...register("patientConcerns")}
                                        margin="dense"
                                        id="name"
                                        label="Patient Concerns"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        multiline
                                        InputLabelProps={{ shrink: true }}
                                        rows={4}
                                    />
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <material.TextField
                                        {...register("notes")}
                                        margin="dense"
                                        id="name"
                                        label="Extra/Notes"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        multiline
                                        rows={4}
                                    />
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <material.TextField
                                        {...register("planSummary")}
                                        margin="dense"
                                        id="name"
                                        label="Plan Summary"
                                        type="text"
                                        fullWidth
                                        multiline
                                        InputLabelProps={{ shrink: true }}
                                        rows={4}
                                        variant="standard"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <ViewTreatmentList
                                getTreatmentPlanData={getTreatmentPlanData}
                                isLoading={isLoading}
                                clinicDetails={clinicDetails}
                                userDetails={userDetails}
                                getProposedTreatmentPlan={getProposedTreatmentPlan}
                            />
                        </div>
                    </div>
                </material.DialogContent>
                <AddHomeTreatmentPlan
                    openAddHomeTreatment={openAddHomeTreatment}
                    setOpenAddHomeTreatment={setOpenAddHomeTreatment}
                    clinicDetails={clinicDetails}
                    getProposedTreatmentPlan={getProposedTreatmentPlan}
                />
                <AddSkinAndCosmeticTreatment
                    openAddCosmeticAndSkinTreatment={openAddCosmeticAndSkinTreatment}
                    setOpenAddCosmeticAndSkinTreatment={setOpenAddCosmeticAndSkinTreatment}
                    getProposedTreatmentPlan={getProposedTreatmentPlan}
                    clinicDetails={clinicDetails}
                    userDetails={userDetails}
                />
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <PermissionDialog
                openPermissionDialog={openPermissionDialog}
                setOpenPermissionDialog={setOpenPermissionDialog}
                getPrintTypeValue={getPrintTypeValue}
            />
        </div>
    );
}

export default AddProposedTreatmentPlan;