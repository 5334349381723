import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { useTheme } from '@mui/material';
import Navbar from '../navbar/Navbar';
import Snackbar from '../toastrmessage/Snackbar';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import AddSupplierProductConfig from './AddSupplierProductConfig';
import { getSupplierProductConfig } from '../../services/SupplierProductConfigService';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';


function SupplierProductConfig(props) {

    localStorage.setItem("menuName", "Supplier Product Offering Configuration");

    const theme = useTheme();
    const [addSupplierProductConfig, setAddSupplierProductConfig] = useState({ action: false, supplierProductConfigData: null, });
    const [supplierProductConfigTableData, setSupplierProductConfigTableData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [totalElements, setTotalElements] = useState(0);

    useEffect(() => {
        getSupplierProductConfigData();
    }, []);

    const getSupplierProductConfigData = async (paginationData) => {
        setIsLoading(true);
        const payload = {
            limit: paginationData ? paginationData.limit : 10,
            page: paginationData ? paginationData.page : 0,
        }
        await getSupplierProductConfig(payload)
            .then((resp) => {
                setSupplierProductConfigTableData(resp.data.data.results);
                setPage(Number(resp.data.data.currentPage));
                setRowsPerPage(Number(resp.data.data.limit));
                setTotalElements(resp.data.data.totalItems);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            });
    };

    const addSupplierProduct = () => {
        setAddSupplierProductConfig({ action: true, supplierProductConfigData: null, });
    };

    const editSupplierProduct = (rowData) => {
        setAddSupplierProductConfig({ action: true, supplierProductConfigData: rowData, });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const payload = {
            limit: rowsPerPage,
            page: newPage,
        }
        getSupplierProductConfigData(payload)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        const payload = {
            limit: event.target.value,
            page: page
        }
        getSupplierProductConfigData(payload)
    };

    return (
        <material.Grid className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: { mt: 10 },
                [theme.breakpoints.up('sm')]: { mt: 10 },
                [theme.breakpoints.up('md')]: { mt: 12 },
                [theme.breakpoints.up('lg')]: { mt: 12 },
            }}>
            <Navbar />
            <div className="row">
                <div className="col-12">
                    <span className="float-end">
                        <material.Button onClick={addSupplierProduct} variant="contained" sx={{ textTransform: "none" }} startIcon={<material.AddIcon />}>Add Supplier Product Config</material.Button>
                    </span>
                </div>

                <div className="col-12 mt-3">
                    <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <material.TableContainer sx={{ maxHeight: 460 }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead>
                                    <material.TableRow>
                                        <StyledTableCell>Supplier Name</StyledTableCell>
                                        <StyledTableCell>Product Code</StyledTableCell>
                                        <StyledTableCell>Product Name</StyledTableCell>
                                        <StyledTableCell>Product Image</StyledTableCell>
                                        <StyledTableCell>Product Description</StyledTableCell>
                                        <StyledTableCell>List Price Excluding GST</StyledTableCell>
                                        <StyledTableCell>List Price Including GST</StyledTableCell>
                                        <StyledTableCell>GST</StyledTableCell>
                                        <StyledTableCell>Minimum Order Quantity</StyledTableCell>
                                        <StyledTableCell>Total for Minimum Order Quantity Excluding GST</StyledTableCell>
                                        <StyledTableCell>Total for Minimum Order Quantity Including GST</StyledTableCell>
                                        <StyledTableCell>Total GST Amount</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={15}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={15} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {supplierProductConfigTableData.length ?
                                                supplierProductConfigTableData.map((data, i) => (
                                                    <material.TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } }}
                                                        onClick={() => editSupplierProduct(data)}
                                                    >
                                                        <material.TableCell>{data.supplierName}</material.TableCell>
                                                        <material.TableCell>{data.productCode}</material.TableCell>
                                                        <material.TableCell>{data.productName}</material.TableCell>
                                                        <material.TableCell>{data.productImage?.split("/").pop()}</material.TableCell>
                                                        <material.TableCell sx={{ width: 200, wordBreak: "break-all" }}>{data.productDescription}</material.TableCell>
                                                        <material.TableCell>{data.listPriceExcludingGst}</material.TableCell>
                                                        <material.TableCell>{data.listPriceIncludingGst} </material.TableCell>
                                                        <material.TableCell>{data.gst}</material.TableCell>
                                                        <material.TableCell>{data.minimumOrderQuantity}</material.TableCell>
                                                        <material.TableCell>{data.totalOfMinimumOrderQuantityExcludingGst}</material.TableCell>
                                                        <material.TableCell>{data.totalOfMinimumOrderQuantityIncludingGst}</material.TableCell>
                                                        <material.TableCell>{data.totalGstAmount}</material.TableCell>

                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow>
                                                        <material.TableCell colSpan={15}>
                                                            <h6 className="d-flex justify-content-center text-danger fw-bold">
                                                                No data found
                                                            </h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                        </>
                                    )}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                        <material.TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={totalElements}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </material.Paper>
                </div>
            </div>
            <AddSupplierProductConfig
                addSupplierProductConfig={addSupplierProductConfig}
                setAddSupplierProductConfig={setAddSupplierProductConfig}
                getSupplierProductConfigData={getSupplierProductConfigData}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </material.Grid>
    );
}

export default SupplierProductConfig;