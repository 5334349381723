import React, { useState } from 'react';
import { material } from '../../library/material';
import { useForm } from 'react-hook-form';
import { addPatientHistory } from '../../services/PatientService';
import Snackbar from '../toastrmessage/Snackbar';
import moment from 'moment';
import { connect } from 'react-redux';

function AddCustomNote(props) {

    const { openCustomNote, setOpenCustomNote, getPatientHistoryByPatientId, patientData, userData } = props;
    let userDetails = userData.authReducer.data;
    const { register, handleSubmit, resetField, reset, formState: { errors, isValid }, setValue } = useForm({
        mode: "onTouched",
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const addCustomNote = async (data) => {
        let obj = {
            patientId: patientData.patientId,
            dateOfEntry: moment().format("YYYY-MM-DDTHH:mm:ss"),
            typeOfNotes: "GENERAL_HISTORY",
            noteDefinition: "CustomNotes",
            user: userDetails.name,
            history: { [data.key]: data.value }
        }
        await addPatientHistory(obj)
            .then((resp) => {
                getPatientHistoryByPatientId()
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                reset({ "key": "" })
                reset({ "value": "" })
                setOpenCustomNote(false)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="lg" open={openCustomNote} hideBackdrop >
                <material.DialogTitle className='d-flex flex-row' style={{ justifyContent: "space-between" }}>Add Custom Note
                </material.DialogTitle>
                <material.DialogContent>
                    <hr />
                    <div className='row'>
                        <div className="col-lg-2 col-md-4 col-sm-6 mb-2">
                            <material.Typography>Label</material.Typography>
                        </div>
                        <div className="col-lg-10 col-md-8 col-sm-6 mb-2 mt-2">
                            <material.TextField
                                id="standard-error"
                                variant="standard"
                                type="text"
                                size="small"
                                multiline
                                fullWidth
                                {...register("key")}
                            />
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6">
                            <material.Typography>Description</material.Typography>
                        </div>
                        <div className="col-lg-10 col-md-8 col-sm-6 mt-2">
                            <material.TextField
                                multiline
                                id="standard-error"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                {...register("value")}
                            />
                        </div>
                    </div>
                    <div className='mt-3'>
                        <span className='float-end'>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" className='me-2' color='error' onClick={() => setOpenCustomNote(false)}>
                                Cancel
                            </material.Button>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={handleSubmit(addCustomNote)} disabled={!isValid}>
                                Save
                            </material.Button>
                        </span>
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
    };
};

export default connect(mapStateToProps)(AddCustomNote);