import React, { useEffect, useState, useRef } from 'react';
import { material } from '../../../../library/material';
import './ChatBox.css';
import { useForm } from 'react-hook-form';
import { socket } from '../../../../socket/Socket';
import { getClinicMessages, saveClinicMessages } from '../../../../services/ClinicConfigService';
import { Slide } from '@mui/material';
import Snackbar from '../../../toastrmessage/Snackbar';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const prescriberPermission = ["AEST_PRESCRIBER"];

function ClinicChatBox(props) {

    const {
        openChatBoxComponent,
        setOpenChatBoxComponent,
        clinicDetails,
        userDetails,
        getMessageNotification,
        setNotification
    } = props;

    const { register, handleSubmit, reset } = useForm({
        mode: "onTouched"
    });

    const [messages, setMessages] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });

    const messagesEndRef = useRef(null);

    useEffect(() => {
        if (openChatBoxComponent) {
            fetchMessages();
        }
        if (userDetails.role.permissions.some(item => prescriberPermission.includes(item.permissionName))) {
            socket.emit('joinRoom', "Prescriber Chat Room");
        } else {
            socket.emit('joinRoom', clinicDetails.clinicId);
        }
        socket.on('newMessage', (message) => {
            setMessages((prevMessages) => [...prevMessages, message]);
            getMessageNotification(message);
        });

        return () => {
            socket.off('newMessage');
        };
    }, [openChatBoxComponent]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const fetchMessages = async () => {
        const payload = {
            roomName: userDetails.role.permissions.some(item => prescriberPermission.includes(item.permissionName)) ? "Prescriber Chat Room" : clinicDetails.clinicId
        };
        getClinicMessages(payload)
            .then((resp) => {
                setMessages(resp.data.data);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            });
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const close = () => {
        setOpenChatBoxComponent(false);
        setNotification(0);
    };

    const sendMessage = (formData) => {
        const payload = {
            roomName: userDetails.role.permissions.some(item => prescriberPermission.includes(item.permissionName)) ? "Prescriber Chat Room" : clinicDetails.clinicId,
            userId: userDetails.userId,
            content: formData.message
        };
        saveClinicMessages(payload)
            .then((resp) => {
                reset({ message: "" });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            });
    };

    return (
        <div>
            <material.Dialog open={openChatBoxComponent} fullScreen
                PaperProps={{
                    sx: {
                        backgroundColor: '#fff',
                    },
                }}
                TransitionComponent={Transition}
            >
                <material.AppBar sx={{
                    position: 'relative',
                }}>
                    <material.Toolbar>
                        <material.IconButton
                            edge="start"
                            color="inherit"
                            onClick={close}
                            aria-label="close"
                        >
                            <material.CloseIcon />
                        </material.IconButton>
                        <material.Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            In Clinic Chat
                        </material.Typography>
                    </material.Toolbar>
                </material.AppBar>
                <material.DialogContent>
                    <form>
                        <div className='body_chat'>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-md-12 col-xl-12 chat">
                                        <div className="card">
                                            <div className="card-body msg_card_body">
                                                {messages.map((msg, k) => (
                                                    <div
                                                        className={`d-flex mb-4 ${msg.userId === userDetails.userId ? "justify-content-end" : "justify-content-start"}`}
                                                        key={k}
                                                    >
                                                        <div className={msg.userId === userDetails.userId ? "msg_cotainer_send" : "msg_cotainer"}>
                                                            <strong>{msg.userId === userDetails.userId ? "" : `${msg.User?.username} : ` || 'User : '} </strong>
                                                            {msg.content}
                                                        </div>
                                                    </div>
                                                ))}
                                                <div ref={messagesEndRef}></div>
                                            </div>
                                            <div className="card-footer d-flex align-items-center">
                                                <material.TextField
                                                    type='text'
                                                    fullWidth
                                                    multiline
                                                    placeholder='Send a message'
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                border: 'none',
                                                            },
                                                        },
                                                    }}
                                                    {...register("message", { required: true })}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter' && !e.shiftKey) {
                                                            e.preventDefault();
                                                            handleSubmit(sendMessage)();
                                                        }
                                                    }}
                                                />
                                                <span className='me-2'>
                                                    <material.SendIcon
                                                        onClick={handleSubmit(sendMessage)}
                                                        sx={{ cursor: "pointer" }}
                                                        color='info'
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default ClinicChatBox;