import React from 'react';
import { material } from '../../library/material';

function UserTypeCheckDialog(props) {

const {openUserTypeCheckDialog, setOpenUserTypeCheckDialog} = props;

    return (
        <div>
            <material.Dialog open={openUserTypeCheckDialog.action} maxWidth="sm" fullWidth>
                <material.DialogContent>
                    <div className="d-flex flex-row space-between">
                        <material.Typography>What type of User will you create ?</material.Typography>
                        <span className='mt-2 ms-1'>
                            <material.Button variant="contained" color="primary" sx={{ textTransform: "none", mr: 1 }} onClick={() => setOpenUserTypeCheckDialog({ action: false, data: "Platform Level" })}>Platform Level</material.Button>
                            <material.Button variant="contained" color="secondary" sx={{ textTransform: "none" }} onClick={() => setOpenUserTypeCheckDialog({ action: false, data: "Clinic Level" })}>Clinic Level</material.Button>
                        </span>
                    </div>
                </material.DialogContent>
            </material.Dialog>
        </div>
    );
}

export default UserTypeCheckDialog;