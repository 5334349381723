import React, { useEffect, useState } from 'react'
import { material } from '../../../library/material';
import { useTheme } from '@mui/material';
import Navbar from '../../navbar/Navbar';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import { useNavigate } from 'react-router-dom';
import Snackbar from '../../toastrmessage/Snackbar';
import { getCampaignNameByCampaignRefNo, getCampaignNameByClinicId, startSendingEmailByCampaignRefNo } from '../../../services/PlatformCrmService';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import moment from 'moment';
import CrmCampaign from './CrmCampaign';
import { connect } from 'react-redux';
import ViewCrmTemplateList from './ViewCrmTemplateList';
import UploadCrmTemplate from './UploadCrmTemplate';



function PlatFormCrm(props) {
    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const theme = useTheme();
    const navigate = useNavigate();
    const [openStartCrmCampaignDialog, setOpenStartCrmCampaignDialog] = useState(false);
    const [allCampaignName, setAllCampaignName] = useState([]);
    const [campaignList, setCampaignList] = useState([]);
    const [campaignRefNo, setCampaignRefNo] = useState(null);
    const [openViewTemplateList, setOpenViewTemplateList] = useState(false);
    const [openUploadTemplate, setOpenUploadTemplate] = useState({ action: false, data: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        getCrmCampaignName();
    }, []);

    // console.log(campaignList)

    const getCrmCampaignName = () => {
        getCampaignNameByClinicId(userDetails.orgId)
            .then((resp) => {
                // console.log(resp.data)
                setAllCampaignName(resp.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const startEmailSending = async () => {
        await startSendingEmailByCampaignRefNo({ campaignRefNo })
            .then((resp) => {
                // console.log(resp)
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp?.data,
                });
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error?.data?.messages,
                });
            })

    };
    const editCrmTemplate = () => {
        setOpenViewTemplateList(true)
    };
    const createCrmTemplate = () => {
        navigate('/platform-crm/create-crm-template')
    };
    const uploadCrmTemplate = () => {
        setOpenUploadTemplate({ action: true, data: null })
    };

    const selectCampaign = (e, value) => {
        setCampaignRefNo(value.campaignRefNo)
        getCampaignListByCampaignRefNo(value.campaignRefNo)
    };

    const getCampaignListByCampaignRefNo = async (campaignRefNo) => {
        setIsLoading(true)
        await getCampaignNameByCampaignRefNo(campaignRefNo)
            .then((resp) => {
                console.log(resp.data)
                setCampaignList(resp.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error?.data?.messages,
                });
            })
    };


    const startCampaign = () => {
        setOpenStartCrmCampaignDialog(true);
    };

    return (
        <material.Grid className='container-fluid'
            sx={{ [theme.breakpoints.down('sm')]: { mt: 10 }, [theme.breakpoints.up('sm')]: { mt: 10 }, [theme.breakpoints.up('md')]: { mt: 12 }, [theme.breakpoints.up('lg')]: { mt: 12 }, }} >
            <Navbar />
            <material.Grid container spacing={2}>
                <material.Grid item xs={12} sm={6} md={6} lg={6}>
                    <material.Stack direction='row' spacing={1}>
                        <material.Autocomplete
                            id="campaignName"
                            onChange={selectCampaign}
                            options={allCampaignName}
                            getOptionLabel={(option) => option.campaignName}
                            renderInput={(params) => <material.TextField {...params} variant="standard" label="Select CRM Campaign"
                                sx={{
                                    mt: -2, [theme.breakpoints.down('sm')]: { width: "15ch" }, [theme.breakpoints.up('sm')]: { width: "15ch" },
                                    [theme.breakpoints.up('md')]: { width: "20ch" }, [theme.breakpoints.up('lg')]: { width: "30ch" },
                                }} />}
                        />
                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' onClick={startEmailSending}>Start Email Sending</material.Button>
                    </material.Stack>
                </material.Grid>
                <material.Grid item xs={12} sm={6} md={6} lg={6}>
                    <material.Stack direction='row' spacing={1} justifyContent='flex-end'>
                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' onClick={editCrmTemplate}>Edit Exiting Email Template</material.Button>
                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' onClick={createCrmTemplate}>Create Email Template</material.Button>
                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' onClick={uploadCrmTemplate}>Upload Email Template</material.Button>
                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' onClick={startCampaign}>Add CRM Campaign</material.Button>
                    </material.Stack>
                </material.Grid>
                <material.Grid item xs={12}>
                    <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <material.TableContainer sx={{ maxHeight: 500 }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell>Campaign Name</StyledTableCell>
                                        <StyledTableCell>Clinic Name</StyledTableCell>
                                        <StyledTableCell>Nurse Name</StyledTableCell>
                                        <StyledTableCell>Phone Number</StyledTableCell>
                                        <StyledTableCell>Email</StyledTableCell>
                                        <StyledTableCell>Unsubscribe/Subscribe</StyledTableCell>
                                        <StyledTableCell>Email Sent</StyledTableCell>
                                        <StyledTableCell>Email Opened</StyledTableCell>
                                        <StyledTableCell>Invalid Email</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={10}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={10} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {campaignList.length ? campaignList.map((row, i) => (
                                                <material.TableRow
                                                    key={i}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': { border: 0 }
                                                    }}
                                                >
                                                    <material.TableCell sx={{ pt: 2, pb: 2 }} size='small'>{row.campaignName}</material.TableCell>
                                                    <material.TableCell size='small'>{row.clinicName}</material.TableCell>
                                                    <material.TableCell size='small'>{row.nurseName}</material.TableCell>
                                                    <material.TableCell size='small'>{row.phoneNumber}</material.TableCell>
                                                    <material.TableCell size='small'>{row.email}</material.TableCell>
                                                    <material.TableCell size='small'>{row.unsubscribe}</material.TableCell>
                                                    <material.TableCell size='small'>{row.emailSent}</material.TableCell>
                                                    <material.TableCell size='small'>{row.emailOpened}</material.TableCell>
                                                    <material.TableCell size='small'>{row.invalidEmail}</material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={10}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>
                                    )}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                        {/* <material.TablePagination
                                           rowsPerPageOptions={[5, 10, 20]}
                                           component="div"
                                           count={campaignList.length}
                                           rowsPerPage={rowsPerPage}
                                           page={page}
                                           onPageChange={handleChangePage}
                                           onRowsPerPageChange={handleChangeRowsPerPage}
                                       /> */}
                    </material.Paper>
                </material.Grid>
            </material.Grid>
            <CrmCampaign
                openStartCrmCampaignDialog={openStartCrmCampaignDialog}
                setOpenStartCrmCampaignDialog={setOpenStartCrmCampaignDialog}
                getCrmCampaignName={getCrmCampaignName}
                clinicDetails={clinicDetails}
                userDetails={userDetails}
            />
            <ViewCrmTemplateList
                openViewTemplateList={openViewTemplateList}
                setOpenViewTemplateList={setOpenViewTemplateList}
                clinicDetails={clinicDetails}
                userDetails={userDetails}
            />
            <UploadCrmTemplate
                openUploadTemplate={openUploadTemplate}
                setOpenUploadTemplate={setOpenUploadTemplate}
            />

            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </material.Grid>
    )
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(PlatFormCrm);



