import React, { useState, useEffect } from "react";
import { material } from "../../../library/material";
import { useForm } from "react-hook-form";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import {
  createBlankCashFloat,
  downloadDocument,
  saveUpdateCashFloat,
} from "../../../services/CreateBankCashFloatService";
import { connect } from "react-redux";
import Snackbar from "../../toastrmessage/Snackbar";
import EditNoteIcon from "@mui/icons-material/EditNote";
import SaveAsIcon from '@mui/icons-material/SaveAs';
import SaveIcon from '@mui/icons-material/SaveAs';
import moment from "moment";
import AddAttachmentDialog from "./AddAttachmentDialog";
import { rootApiUrl } from "../../../environment/Environment";

const approveCashFloatPermission = [""];

function AddCreateBankCashFloat(props) {
  const { register, handleSubmit, setValue, getValues, control, watch, reset, formState: { isValid }, } = useForm({ mode: "onTouched" });
  const { openCreateBankCashFloat, setOpenCreateBankCashFloat, userData, clinicData, getAllCreateBankCashFloat, } = props;
  const userDetails = userData.authReducer.data;
  const clinicDetails = clinicData.clinicReducer.data;
  const [blankCashFloatData, setBlankCashFloatData] = useState(null);
  const [openSnackBar, setOpenSnackBar] = useState({
    action: false,
    type: "",
    message: "",
  });
  const [openAddAttachment, setOpenAddAttachment] = useState({ action: false, formData: null });
  const [openWarningDialog, setOpenWarningDialog] = useState({ action: false, click: null, formData: null })

  const coins = watch([
    "coins_5_cents",
    "coins_10_cents",
    "coins_50_cents",
    "coins_1_dollar",
    "coins_2_dollar",
  ]);
  const notes = watch([
    "notes_5_dollar",
    "notes_10_dollar",
    "notes_20_dollar",
    "notes_50_dollar",
    "notes_100_dollar",
  ]);
  const onChangeCashInHand = watch("cashInHandAmount");
  const onChangeClosingBalance = watch("closingBalanceAmount");
  const onChangeOpeningBalance = watch("openingBalanceAmount");
  const onChangeCashSale = watch("cashSale");
  const onChangeFloatLimit = watch("floatLimit");

  useEffect(() => {
    if (openCreateBankCashFloat.action) {
      const calculateSums = () => {
        const sumOfCoins =
          Number(coins[0] || 0) * 0.05 +
          Number(coins[1] || 0) * 0.1 +
          Number(coins[2] || 0) * 0.5 +
          Number(coins[3] || 0) * 1 +
          Number(coins[4] || 0) * 2;

        const sumOfNotes =
          Number(notes[0] || 0) * 5 +
          Number(notes[1] || 0) * 10 +
          Number(notes[2] || 0) * 20 +
          Number(notes[3] || 0) * 50 +
          Number(notes[4] || 0) * 100;

        const cashInHand = sumOfCoins + sumOfNotes;
        setValue("cashInHandAmount", cashInHand);

        const closingBalance = getValues("closingBalanceAmount");
        setValue("variance", Number(closingBalance) - cashInHand);

        const openingBalanceAmount = getValues("openingBalanceAmount");
        const cashSale = getValues("cashSale");
        setValue("closingBalanceAmount", Number(openingBalanceAmount) + Number(cashSale));
        const floatLimit = getValues("floatLimit")
        setValue("toHandOverBank", Number(closingBalance) - Number(floatLimit));
      };

      calculateSums();
    }
  }, [coins, notes, onChangeCashInHand, onChangeClosingBalance, openCreateBankCashFloat.action, onChangeOpeningBalance, onChangeCashSale, onChangeFloatLimit])

  const sumOfCoins =
    Number(coins[0] || 0) * 0.05 +
    Number(coins[1] || 0) * 0.1 +
    Number(coins[2] || 0) * 0.5 +
    Number(coins[3] || 0) * 1 +
    Number(coins[4] || 0) * 2;

  const sumOfNotes =
    Number(notes[0] || 0) * 5 +
    Number(notes[1] || 0) * 10 +
    Number(notes[2] || 0) * 20 +
    Number(notes[3] || 0) * 50 +
    Number(notes[4] || 0) * 100;

  useEffect(() => {
    if (openCreateBankCashFloat.action) {
      if (openCreateBankCashFloat.cashFloatData) {
        populateFormWithData(openCreateBankCashFloat.cashFloatData);
      } else {
        getBlankCashFloatData();
        setValue("date", moment().format("YYYY-MM-DD"));
      }
    }
  }, [openCreateBankCashFloat.action]);


  const getBlankCashFloatData = async () => {
    let payload = {
      clinicId: clinicDetails.clinicId,
      orgId: userDetails.orgId,
    };
    await createBlankCashFloat(payload)
      .then((resp) => {
        reset(resp.data?.data.data);
        setValue("dayClosedByUserName", userDetails.name)
        setBlankCashFloatData(resp.data.data.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response.data.data.info,
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response.data.messages,
          })
        }
      });
  };

  const populateFormWithData = (data) => {
    Object.keys(data).forEach((key) => {
      const value = data[key];
      setValue(key, value ?? "");
    });
  };

  const handleClose = () => {
    setOpenCreateBankCashFloat({ action: false, cashFloatData: null });
  };

  const handleSaveAsDraft = (data) => {
    if (Number(data.closingBalanceAmount) !== Number(data.cashInHandAmount)) {
      setOpenWarningDialog({ action: true, click: "saveAsDraft", formData: data });
    } else {
      saveAsDraft(data)
    }
  };

  const saveAsDraft = async (data) => {
    const payload = {
      ...blankCashFloatData,
      ...data,
      openingBalanceAmount: Number(data.openingBalanceAmount),
      cashSale: Number(data.cashSale),
      closingBalanceAmount: Number(data.closingBalanceAmount),
      coins_5_cents: Number(data.coins_5_cents),
      coins_10_cents: Number(data.coins_10_cents),
      coins_50_cents: Number(data.coins_50_cents),
      coins_1_dollar: Number(data.coins_1_dollar),
      coins_2_dollar: Number(data.coins_2_dollar),
      notes_5_dollar: Number(data.notes_5_dollar),
      notes_10_dollar: Number(data.notes_10_dollar),
      notes_20_dollar: Number(data.notes_20_dollar),
      notes_50_dollar: Number(data.notes_50_dollar),
      notes_100_dollar: Number(data.notes_100_dollar),
      cashInHandAmount: Number(data.cashInHandAmount),
      variance: Number(data.variance),
      floatLimit: Number(data.floatLimit),
      toHandOverBank: Number(data.toHandOverBank),
      date: data.date,
      isApprove: false,
      isSubmitted: false,
      dayClosedByUserId: data.dayClosedByUserId ? data.dayClosedByUserId : userDetails.userId,
    }
    try {
      await saveUpdateCashFloat(payload)
        .then((resp) => {
          setOpenSnackBar({
            action: true,
            type: "success",
            message: resp.data.messages,
          });
          getAllCreateBankCashFloat();
          handleClose();
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response.data.data.info,
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response.data.messages,
            })
          }
        })
    } catch (error) {
      if (error.response.status === 401) {
        setOpenSnackBar({
          "action": true,
          "type": "error",
          "message": error.response.data.data.info,
        })
      } else {
        setOpenSnackBar({
          action: true,
          type: "error",
          message: error.response?.data.messages || "Error saving as draft",
        });
      }
    }
  };

  const getWarningDialogData = (data, formData) => {
    if (data == "save") {
      save(formData)
    } else if (data == "saveAsDraft") {
      saveAsDraft(formData)
    }
  };

  const handleSave = (data) => {
    if (Number(data.closingBalanceAmount) !== Number(data.cashInHandAmount)) {
      setOpenWarningDialog({ action: true, click: "save", formData: data });
    } else {
      save(data)
    }
  };

  const save = async (data) => {
    const sourceData = blankCashFloatData || openCreateBankCashFloat.cashFloatData || {};
    const payload = {
      ...sourceData,
      ...data,
      date: data.date,
      openingBalanceAmount: Number(data.openingBalanceAmount),
      cashSale: Number(data.cashSale),
      closingBalanceAmount: Number(data.closingBalanceAmount),
      coins_5_cents: Number(data.coins_5_cents),
      coins_10_cents: Number(data.coins_10_cents),
      coins_50_cents: Number(data.coins_50_cents),
      coins_1_dollar: Number(data.coins_1_dollar),
      coins_2_dollar: Number(data.coins_2_dollar),
      notes_5_dollar: Number(data.notes_5_dollar),
      notes_10_dollar: Number(data.notes_10_dollar),
      notes_20_dollar: Number(data.notes_20_dollar),
      notes_50_dollar: Number(data.notes_50_dollar),
      notes_100_dollar: Number(data.notes_100_dollar),
      cashInHandAmount: Number(data.cashInHandAmount),
      variance: Number(data.variance),
      comments: data.comments,
      floatLimit: Number(data.floatLimit),
      toHandOverBank: Number(data.toHandOverBank),
      dayClosedBy: data.dayClosedBy,
      bankDepositByReceivedBy: data.bankDepositByReceivedBy,
      isApprove: false,
      isSubmitted: true,
      dayClosedByUserId: data.dayClosedByUserId ? data.dayClosedByUserId : userDetails.userId
    }
    try {
      await saveUpdateCashFloat(payload)
        .then((resp) => {
          setOpenSnackBar({
            action: true,
            type: "success",
            message: resp.data.messages,
          });
          getAllCreateBankCashFloat();
          handleClose();
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response.data.data.info,
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response.data.messages,
            })
          }
        })
    } catch (error) {
      if (error.response.status === 401) {
        setOpenSnackBar({
          "action": true,
          "type": "error",
          "message": error.response.data.data.info,
        })
      } else {
        setOpenSnackBar({
          action: true,
          type: "error",
          message: error.response?.data.messages || "Error updating cash float",
        });
      }
    }
  };

  const handleUpdate = async (data) => {
    const payload = {
      ...openCreateBankCashFloat.cashFloatData, // Use existing data as base
      ...data, // Override with form values
      date: data.date,
      openingBalanceAmount: Number(data.openingBalanceAmount),
      cashSale: Number(data.cashSale),
      closingBalanceAmount: Number(data.closingBalanceAmount),
      coins_5_cents: Number(data.coins_5_cents),
      coins_10_cents: Number(data.coins_10_cents),
      coins_50_cents: Number(data.coins_50_cents),
      coins_1_dollar: Number(data.coins_1_dollar),
      coins_2_dollar: Number(data.coins_2_dollar),
      notes_5_dollar: Number(data.notes_5_dollar),
      notes_10_dollar: Number(data.notes_10_dollar),
      notes_20_dollar: Number(data.notes_20_dollar),
      notes_50_dollar: Number(data.notes_50_dollar),
      notes_100_dollar: Number(data.notes_100_dollar),
      cashInHandAmount: Number(data.cashInHandAmount),
      variance: Number(data.variance),
      comments: data.comments,
      floatLimit: Number(data.floatLimit),
      toHandOverBank: Number(data.toHandOverBank),
      dayClosedBy: data.dayClosedBy,
      bankDepositByReceivedBy: data.bankDepositByReceivedBy,
      isApprove: false,
      isSubmitted: true,
      dayClosedByUserId: data.dayClosedByUserId ? data.dayClosedByUserId : userDetails.userId
    };

    try {
      await saveUpdateCashFloat(payload)
        .then((resp) => {
          setOpenSnackBar({
            action: true,
            type: "success",
            message: resp.data.messages,
          });
          getAllCreateBankCashFloat();
          handleClose();
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response.data.data.info,
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response.data.messages,
            })
          }
        })
    } catch (error) {
      setOpenSnackBar({
        action: true,
        type: "error",
        message: "Error updating cash float",
      });
    }
  };

  const handleApprove = async (data) => {
    const payload = {
      ...data,
      openingBalanceAmount: Number(data.openingBalanceAmount),
      cashSale: Number(data.cashSale),
      closingBalanceAmount: Number(data.closingBalanceAmount),
      coins_5_cents: Number(data.coins_5_cents),
      coins_10_cents: Number(data.coins_10_cents),
      coins_50_cents: Number(data.coins_50_cents),
      coins_1_dollar: Number(data.coins_1_dollar),
      coins_2_dollar: Number(data.coins_2_dollar),
      notes_5_dollar: Number(data.notes_5_dollar),
      notes_10_dollar: Number(data.notes_10_dollar),
      notes_20_dollar: Number(data.notes_20_dollar),
      notes_50_dollar: Number(data.notes_50_dollar),
      notes_100_dollar: Number(data.notes_100_dollar),
      cashInHandAmount: Number(data.cashInHandAmount),
      variance: Number(data.variance),
      floatLimit: Number(data.floatLimit),
      date: data.date,
      isApprove: true,
      isSubmitted: true,
      bankDepositByReceivedBy: data.bankDepositByReceivedBy,
      dayClosedByUserId: data.dayClosedByUserId ? data.dayClosedByUserId : userDetails.userId
    };

    try {
      await saveUpdateCashFloat(payload)
        .then((resp) => {
          setOpenSnackBar({
            action: true,
            type: "success",
            message: resp.data.messages,
          });
          getAllCreateBankCashFloat();
          handleClose();
        })
        .catch((error) => {
          setOpenSnackBar({
            action: true,
            type: "error",
            message: error.response.data?.messages,
          });
        })
    } catch (error) {
      setOpenSnackBar({
        action: true,
        type: "error",
        message: "Error approving cash float",
      });
    }
  };

  const addAttachment = () => {
    setOpenAddAttachment({ action: true, formData: blankCashFloatData })
  };

  const downloadAttachment = (attachmentLink) => {
    window.open(`${rootApiUrl}/agl-link?key=${attachmentLink}`, "_blank")
  };

  return (
    <div>
      <material.Dialog
        maxWidth="xl"
        open={openCreateBankCashFloat.action}
        fullWidth
      >
        <material.DialogTitle id="alert-dialog-title">Create Bank Cash Float</material.DialogTitle>
        <material.DialogContent>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <material.TextField
                {...register("date", { required: true })}
                type="text"
                id="date"
                label="Date"
                variant="standard"
                fullWidth
                required
                InputProps={{ readOnly: true }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-3">
              <material.TextField
                {...register("openingBalanceAmount", { required: true })}
                type="number"
                id="standard-basic"
                label="Opening Balance"
                variant="standard"
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-3">
              <material.TextField
                {...register("cashSale", { required: true })}
                type="number"
                id="standard-basic"
                label="Cash Sale"
                variant="standard"
                fullWidth
                required
                InputProps={{ readOnly: true }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-3">
              <material.TextField
                {...register("closingBalanceAmount", { required: true })}
                type="number"
                id="standard-basic"
                label="Closing Balance"
                variant="standard"
                InputProps={{ readOnly: true }}
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <material.Card
              style={{
                borderWidth: "2px",
                borderStyle: "solid",
                height: "150px",
                marginTop: "8px",
              }}
            >
              <material.Typography className="fs-5 fw-bold">
                COINS
              </material.Typography>
              <div className="row">
                <div className="col-2">
                  <material.TextField
                    {...register("coins_5_cents", { required: true })}
                    type="number"
                    id="standard-basic"
                    label="5 cents"
                    variant="standard"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-2">
                  <material.TextField
                    {...register("coins_10_cents", { required: true })}
                    type="number"
                    id="standard-basic"
                    label="10 cents"
                    variant="standard"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-2">
                  <material.TextField
                    {...register("coins_50_cents", { required: true })}
                    type="number"
                    id="standard-basic"
                    label="50 cents"
                    variant="standard"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-2">
                  <material.TextField
                    {...register("coins_1_dollar", { required: true })}
                    type="number"
                    id="standard-basic"
                    label="1 dollar"
                    variant="standard"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-2">
                  <material.TextField
                    {...register("coins_2_dollar", { required: true })}
                    type="number"
                    id="standard-basic"
                    label="2 dollar"
                    variant="standard"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
            </material.Card>
            <material.Card
              style={{
                borderWidth: "2px",
                borderStyle: "solid",
                height: "150px",
                marginTop: "8px",
              }}
            >
              <div className="row">
                <material.Typography className="fs-5 fw-bold">
                  Notes
                </material.Typography>
                <div className="col-2">
                  <material.TextField
                    {...register("notes_5_dollar", { required: true })}
                    type="number"
                    id="standard-basic"
                    label="5 dollar"
                    variant="standard"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-2">
                  <material.TextField
                    {...register("notes_10_dollar", { required: true })}
                    type="number"
                    id="standard-basic"
                    label="10 dollar"
                    variant="standard"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-2">
                  <material.TextField
                    {...register("notes_20_dollar", { required: true })}
                    type="number"
                    id="standard-basic"
                    label="20 dollar"
                    variant="standard"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-2">
                  <material.TextField
                    {...register("notes_50_dollar", { required: true })}
                    type="number"
                    id="standard-basic"
                    label="50 dollar"
                    variant="standard"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-2">
                  <material.TextField
                    {...register("notes_100_dollar", { required: true })}
                    type="number"
                    id="standard-basic"
                    label="100 dollar"
                    variant="standard"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
            </material.Card>
            <div className="col-3 mt-4">
              <material.TextField
                {...register("cashInHandAmount", { required: true })}
                type="number"
                id="standard-basic"
                label="Cash in Hand"
                variant="standard"
                fullWidth
                InputProps={{ readOnly: true }}
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-3 mt-4">
              <material.TextField
                {...register("variance")}
                type="number"
                id="standard-basic"
                label="Variance"
                variant="standard"
                fullWidth
                InputProps={{ readOnly: true }}
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-3 mt-4">
              <material.TextField
                {...register("comments")}
                type="text"
                id="standard-basic"
                label="Comments"
                variant="standard"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-3 mt-4">
              <material.TextField
                {...register("floatLimit", { required: true })}
                type="number"
                id="standard-basic"
                label="Float Limit"
                variant="standard"
                fullWidth
                InputProps={{ readOnly: true }}
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-3 mt-4">
              <material.TextField
                {...register("toHandOverBank", { required: true })}
                type="number"
                id="toHandOverBank"
                label="To Hand Over / Bank"
                variant="standard"
                fullWidth
                required
                InputProps={{ readOnly: true }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-3 mt-4">
              <material.TextField
                {...register("dayClosedByUserName", { required: true })}
                type="text"
                id="standard-basic"
                label="Day Closed by"
                variant="standard"
                fullWidth
                required
                InputProps={{ readOnly: true }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-3 mt-4">
              <material.TextField
                {...register("bankDepositByReceivedBy", { required: true })}
                type="text"
                id="standard-basic"
                label="Bank Deposit By / Received By"
                variant="standard"
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-3 mt-4">
              {/* <material.TextField
                {...register("addAttachment")}
                id="standard-basic"
                label="Add Attachment"
                variant="standard"
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}
              <material.Button onClick={addAttachment} variant="contained" color="success" sx={{ textTransform: "none", mr: 1 }} startIcon={<material.AttachmentIcon />}>Add Attachment</material.Button>
              {blankCashFloatData?.addAttachment !== null || blankCashFloatData?.addAttachment !== "" ? (
                <material.Button onClick={() => downloadAttachment(blankCashFloatData.addAttachment)} sx={{ textTransform: "none" }}>{blankCashFloatData?.addAttachment.split("/").pop()}</material.Button>
              ) : null}
            </div>
          </div>
        </material.DialogContent>
        <material.DialogActions sx={{ m: 2 }}>
          <span>
            <material.Button onClick={handleClose} variant="contained" color="error" sx={{ textTransform: "none", mr: 1 }} startIcon={<material.CloseIcon />}> Close </material.Button>
            {openCreateBankCashFloat?.cashFloatData?.isSubmitted ? (
              <>
                <material.Button onClick={handleSubmit(handleApprove)} variant="contained" color="success" sx={{ textTransform: "none", mr: 1 }} startIcon={<VerifiedUserIcon />}>Approve</material.Button>

                <material.Button onClick={handleSubmit(handleUpdate)} variant="contained" color="success" sx={{ textTransform: "none", mr: 1 }} startIcon={<EditNoteIcon />}> Update</material.Button>
              </>
            ) : null}
            {openCreateBankCashFloat?.cashFloatData?.isSubmitted ? null : (
              <>
                <material.Button onClick={handleSubmit(handleSaveAsDraft)} variant="contained" color="info" sx={{ textTransform: "none", mr: 1 }} startIcon={<SaveAsIcon />} hidden={blankCashFloatData?.isSubmitted}>Save As Draft </material.Button>
                <material.Button onClick={handleSubmit(handleSave)} variant="contained" color="success" sx={{ textTransform: "none", mr: 1 }} startIcon={<SaveIcon />} hidden={blankCashFloatData?.isSubmitted}>Save</material.Button>
              </>
            )}
          </span>
        </material.DialogActions>
      </material.Dialog>
      <AddAttachmentDialog
        openAddAttachment={openAddAttachment}
        setOpenAddAttachment={setOpenAddAttachment}
        getBlankCashFloatData={getBlankCashFloatData}
      />
      <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
      <WarningDialog
        openWarningDialog={openWarningDialog}
        setOpenWarningDialog={setOpenWarningDialog}
        getWarningDialogData={getWarningDialogData}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state,
    clinicData: state,
  };
};

const WarningDialog = (props) => {

  const { openWarningDialog, setOpenWarningDialog, getWarningDialogData } = props;

  const submit = () => {
    getWarningDialogData(openWarningDialog.click, openWarningDialog.formData);
    setOpenWarningDialog({ action: false, click: null, formData: null })
  };

  const handleClose = () => {
    setOpenWarningDialog({ action: false, click: null, formData: null })
  };

  return (
    <div>
      <material.Dialog open={openWarningDialog.action} fullWidth maxWidth="md">
        <material.DialogContent>
          <div className="d-flex flex-row">
            <material.Typography><material.WarningIcon color="warning" /> Closing balance and cash in hand data mismatch ! Do you want to save?</material.Typography>
            <span className="mt-2 ms-2">
              <material.Button onClick={handleClose} variant="contained" color="error" sx={{ textTransform: "none", mr: 1 }} startIcon={<material.CloseIcon />}>No</material.Button>
              <material.Button onClick={submit} variant="contained" color="info" sx={{ textTransform: "none", mr: 1 }} startIcon={<material.DoneIcon />}>Yes</material.Button>
            </span>
          </div>
        </material.DialogContent>
      </material.Dialog>
    </div>
  )
}

export default connect(mapStateToProps)(AddCreateBankCashFloat);
