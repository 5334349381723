import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { dateFormat, dateAndTimeFormat } from '../../date-and-time-format/DateAndTimeFormat';
import { addNewPatient } from '../../services/PatientService';
import Snackbar from '../toastrmessage/Snackbar';
import { socket } from '../../socket/Socket';
import KioskNavbar from '../navbar/KioskNavbar';
import { useTheme } from '@mui/material';

function KioskAddPatient(props) {

    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const navigate = useNavigate();
    const location = useLocation();
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [date, setDate] = useState(dayjs(new Date()));
    const [selectGender, setSelectGender] = useState('');
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const theme = useTheme();

    const clinicDetails = location.state?.clinicData

    const { register, handleSubmit, reset, control, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });

    const changeGender = (event) => {
        setSelectGender(event.target.value)
    };

    const addPatient = (fromData) => {
        let obj = {
            "clinicId": clinicDetails.clinicId,
            "orgId": userDetails.orgId,
            "firstName": fromData.firstName,
            "lastName": fromData.lastName,
            "dateOfBirth": dateFormat(dateOfBirth.$d),
            "gender": selectGender,
            "medicareNumber": fromData.medicareNumber,
            "medicareNumberReference": fromData.medicareNumberReference,
            "medicareExpiry": fromData.medicareExpiry,
            "address": fromData.address,
            "email": fromData.email,
            "phoneNumber": fromData.phoneNumber,
            "dateOfEntry": dateAndTimeFormat(date.$d),
        }
        addNewPatient(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                socket.emit("new-patient-added", resp.data);
                navigate("/kiosk-menu");
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data.messages,
                })
            })
    };

    return (
        <material.Grid spacing={2} className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 12
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 12
                },
            }}
        >
            <KioskNavbar />
            <material.Paper className='p-4 pb-5 mt-2' elevation={3}>
                <div className="row">
                    {/* <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            label="Organization ID"
                            variant="standard"
                            type="text"
                            value={userDetails.orgId}
                            size="small"
                            fullWidth
                            InputProps={{ readOnly: true }}
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            label="Clinic ID"
                            variant="standard"
                            type="text"
                            value={clinicDetails.clinicId}
                            size="small"
                            fullWidth
                            InputProps={{ readOnly: true }}
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                        />
                    </div> */}
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <material.DatePicker
                                label="Date Of Entry"
                                format='DD-MM-YYYY'
                                value={date}
                                onChange={(newValue) => {
                                    setDate(newValue);
                                }}
                                slotProps={{ textField: { variant: 'standard' } }}
                                sx={{ width: "100%" }}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.firstName?.type === "required"}
                            required
                            label="First Name"
                            variant="standard"
                            type="text"
                            size="small"
                            fullWidth
                            {...register("firstName", { required: true, minLength: 3 })}
                        />
                        <p className='form-text text-danger'>{errors.firstName?.type === "required" && 'This field is required'}</p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.lastName?.type === "required"}
                            required
                            label="Last Name"
                            variant="standard"
                            type="text"
                            size="small"
                            fullWidth
                            {...register("lastName", { required: true, minLength: 3 })}
                        />
                        <p className='form-text text-danger'>{errors.lastName?.type === "required" && 'This field is required'}</p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                                name="dateOfBirth"
                                control={control}
                                defaultValue={null}
                                render={({ field }) => (
                                    <material.DatePicker
                                        label="Date of Birth"
                                        format='DD-MM-YYYY'
                                        value={dateOfBirth}
                                        onChange={(newValue) => {
                                            setDateOfBirth(newValue);
                                            field.onChange(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <material.TextField
                                                {...params}
                                                variant="standard"
                                                color="success"
                                                {...field}
                                            />
                                        )}
                                        slotProps={{ textField: { variant: 'standard' } }}
                                        sx={{ width: "100%" }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.FormControl sx={{ marginTop: 2 }}>
                            <material.RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={changeGender}
                                value={selectGender}
                            >
                                <material.FormControlLabel value="MALE" control={<material.Radio color="secondary" />} label="Male" />
                                <material.FormControlLabel value="FEMALE" control={<material.Radio color="secondary" />} label="Female" />
                                <material.FormControlLabel value="TRANSGENDER" control={<material.Radio color="secondary" />} label="Other" />
                            </material.RadioGroup>
                        </material.FormControl>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.medicareNumber?.type === "required"}
                            label="Medicare Number"
                            variant="standard"
                            type="number"
                            size="small"
                            fullWidth
                            {...register("medicareNumber", { minLength: 10, maxLength: 10 })}
                        />
                        {(errors?.medicareNumber?.type === "minLength" || errors?.medicareNumber?.type === "maxLength") && (
                            <p className='text-danger'>This field only contain 10 digits</p>
                        )}
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.medicareNumberReference?.type === "required"}
                            label="Medicare Reference Number"
                            variant="standard"
                            type="number"
                            size="small"
                            fullWidth
                            {...register("medicareNumberReference", { minLength: 1, maxLength: 1 })}
                        />
                        {(errors?.medicareNumberReference?.type === "minLength" || errors?.medicareNumberReference?.type === "maxLength") && (
                            <p className='text-danger'>This field only contain 1 digits</p>
                        )}
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.medicareNumberReference?.type === "required"}
                            label="Medicare Expiry"
                            variant="standard"
                            type="text"
                            size="small"
                            fullWidth
                            {...register("medicareExpiry")}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.email?.type === "required"}
                            required
                            label="Email ID"
                            variant="standard"
                            type="email"
                            size="small"
                            fullWidth
                            {...register("email", { pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                        />
                        <p className='form-text text-danger'>{errors.email?.type === "required" && 'This field is required'}</p>
                        {(errors?.email?.type === "pattern") && (
                            <p className='text-danger'>This is not a valid Email</p>
                        )}
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <material.TextField
                            error={errors.phoneNumber?.type === "required"}
                            required
                            label="Phone Number"
                            variant="standard"
                            type="number"
                            size="small"
                            fullWidth
                            {...register("phoneNumber", { required: true, minLength: 10, maxLength: 10 })}
                        />
                        {(errors?.phoneNumber?.type === "minLength" || errors?.phoneNumber?.type === "maxLength") && (
                            <p className='text-danger'>This field only contain 10 digits</p>
                        )}
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <material.TextField
                            error={errors.address?.type === "required"}
                            required
                            label="Address"
                            variant="standard"
                            type="text"
                            size="small"
                            fullWidth
                            {...register("address", { required: true })}
                        />
                    </div>
                    <div className="col-12 mt-3">
                        <span className='float-end'>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={handleSubmit(addPatient)} disabled={!isValid}>Save</material.Button>
                        </span>
                    </div>
                </div>
            </material.Paper>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </material.Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(KioskAddPatient);