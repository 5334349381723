import axios from "axios";
import { rootApiUrl } from "../environment/Environment";


export const getAllCashFloat = async (payload) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/emp/get-cash-float-all`, { params: { ...payload } });
};

export const saveUpdateCashFloat = async (caseFloatData) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/emp/save-update-cash-float`, caseFloatData);
};

export const createBlankCashFloat = async (blankCaseFloatData) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/emp/create-blank-cash-float`, blankCaseFloatData);
};

export const saveCashFloatDocument = async (payload) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/emp/save-cash-float-document`, payload);
};

export const downloadDocument = async (link) => {
    return await axios.get(`${rootApiUrl}/agl-link`, { params: { key: link } });
};


