import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import { useTheme } from '@mui/material';
import Navbar from '../navbar/Navbar';
import AddOrEditSupplierClinicConfiguration from './AddOrEditSupplierClinicConfiguration';
import { getAllSupplierClinicConfig } from '../../services/SupplierConfigService';
import Snackbar from '../toastrmessage/Snackbar';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { getOrgList } from '../../services/UserManagementService';
import { getClinicByOrgId } from '../../services/ClinicService';
import { getAllSupplierList } from '../../services/SupplierProductConfigService';
import { Controller, useForm } from 'react-hook-form';

function SupplierClinicConfiguration(props) {

    localStorage.setItem("menuName", "Supplier Clinic Configuration");
    const theme = useTheme();
    const { register, handleSubmit, reset, setValue, control, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [openAddOrEditSupplierClinicConfig, setOpenAddOrEditSupplierClinicConfig] = useState({ action: false, rowData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [supplierClinicConfigData, setSupplierClinicConfigData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [organizationName, setOrganizationName] = useState([]);
    const [supplierName, setSupplierName] = useState([]);
    const [clinicName, setClinicName] = useState([]);
    const [organizationDetails, setOrganizationDetails] = useState([]);

    useEffect(() => {
        getSupplierClinicConfigData();
        getAllOrganization();
        getSupplierList();
    }, []);

    const getAllOrganization = () => {
        getOrgList()
            .then((resp) => {
                setOrganizationDetails(resp.data.data);
                const orgName = resp.data.data?.map((org) => org.orgName);
                setOrganizationName(orgName);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getAllClinic = (orgId) => {
        getClinicByOrgId(orgId)
            .then((resp) => {
                const clinicName = resp.data.data?.map((clinic) => clinic.clinicName);
                setClinicName(clinicName);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getSupplierList = () => {
        getAllSupplierList()
            .then((resp) => {
                const supplierName = resp.data.data?.map((supplier) => supplier.supplierName);
                setSupplierName(supplierName);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const addSupplierClinicConfig = () => {
        setOpenAddOrEditSupplierClinicConfig({ action: true, rowData: null })
    };

    const getSupplierClinicConfigData = (paginationData) => {
        setIsLoading(true);
        const payload = {
            limit: paginationData ? paginationData.limit : 10,
            page: paginationData ? paginationData.page : 0,
            clinicName: paginationData ? paginationData.clinicName : "",
            supplierName: paginationData ? paginationData.supplierName : "",
            organizationName: paginationData ? paginationData.organizationName : "",
        }
        getAllSupplierClinicConfig(payload)
            .then((resp) => {
                setPage(Number(resp.data.data.currentPage));
                setRowsPerPage(Number(resp.data.data.limit));
                setTotalElements(resp.data.data.totalItems);
                setSupplierClinicConfigData(resp.data.data.results);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const payload = {
            limit: rowsPerPage,
            page: newPage,
        }
        getSupplierClinicConfigData(payload)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        const payload = {
            limit: event.target.value,
            page: page
        }
        getSupplierClinicConfigData(payload)
    };

    const filterSupplierClinicConfig = (data) => {
        getSupplierClinicConfigData(data);
    };

    const handleRefresh = () => {
        reset({ organizationName: "", clinicName: "", supplierName: "" });
        getSupplierClinicConfigData();
    };

    return (
        <material.Grid className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 12
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 12
                },
            }}
        >
            <Navbar />
            <div className="row">
                <div className="col-10">
                    <form>
                        <div className="row" style={{ marginTop: -40 }}>
                            <div className="col-4">
                                <Controller
                                    name="organizationName"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <material.Autocomplete
                                            {...field}
                                            value={field.value}
                                            options={organizationName}
                                            renderInput={(params) => (
                                                <material.TextField
                                                    required
                                                    margin="dense"
                                                    {...params}
                                                    variant="standard"
                                                    label="Organization Name"
                                                    fullWidth
                                                />
                                            )}
                                            onChange={(event, data) => {
                                                field.onChange(data);
                                                if (data) {
                                                    const orgId = organizationDetails.filter((org) => org.orgName === data).map((x) => x.orgId)[0];
                                                    getAllClinic(orgId);
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-4">
                                <Controller
                                    name="clinicName"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <material.Autocomplete
                                            {...field}
                                            value={field.value}
                                            options={clinicName}
                                            renderInput={(params) => (
                                                <material.TextField
                                                    required
                                                    margin="dense"
                                                    {...params}
                                                    variant="standard"
                                                    label="Clinic Name"
                                                    fullWidth
                                                />
                                            )}
                                            onChange={(event, data) => {
                                                field.onChange(data);
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-3">
                                <Controller
                                    name="supplierName"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <material.Autocomplete
                                            {...field}
                                            value={field.value}
                                            options={supplierName}
                                            renderInput={(params) => (
                                                <material.TextField
                                                    required
                                                    margin="dense"
                                                    {...params}
                                                    variant="standard"
                                                    label="Supplier Name"
                                                    fullWidth
                                                />
                                            )}
                                            onChange={(event, data) => {
                                                field.onChange(data);
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-1" style={{ marginTop: 20 }}>
                                <span>
                                    <material.IconButton title='Apply Filter' onClick={handleSubmit(filterSupplierClinicConfig)}>
                                        <material.FilterListIcon color='info' />
                                    </material.IconButton>
                                    <material.IconButton title='Refresh' onClick={handleRefresh}>
                                        <material.RefreshIcon color='info' />
                                    </material.IconButton>
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-2">
                    <span className='float-end'>
                        <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addSupplierClinicConfig}>Add Supplier Clinic Config</material.Button>
                    </span>
                </div>
                <div className="col-12 mt-3">
                    <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <material.TableContainer sx={{ maxHeight: 460 }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell>Organization Name</StyledTableCell>
                                        <StyledTableCell>Clinic Name</StyledTableCell>
                                        <StyledTableCell>Supplier Name</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={15}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={15} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {supplierClinicConfigData.length ? supplierClinicConfigData.map((rowData, k) => (
                                                <material.TableRow key={k}>
                                                    <material.TableCell>{rowData.organizationName}</material.TableCell>
                                                    <material.TableCell>{rowData.clinicName}</material.TableCell>
                                                    <material.TableCell>{rowData.supplierName}</material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={15}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>
                                    )}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                        <hr />
                        <material.TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={totalElements}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </material.Paper>
                </div>
            </div>
            <AddOrEditSupplierClinicConfiguration
                openAddOrEditSupplierClinicConfig={openAddOrEditSupplierClinicConfig}
                setOpenAddOrEditSupplierClinicConfig={setOpenAddOrEditSupplierClinicConfig}
                getSupplierClinicConfigData={getSupplierClinicConfigData}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </material.Grid>
    );
}

export default SupplierClinicConfiguration;