import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import Navbar from '../navbar/Navbar';
import { useTheme } from '@mui/material';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import AddOrUpdateTierConfig from './AddOrUpdateTierConfig';
import { getTierConfigData } from '../../services/TierConfigService';
import Snackbar from '../toastrmessage/Snackbar';

function TierConfig(props) {

    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [tierListData, setTierListData] = useState([]);
    const [openAddOrUpdateTierDialog, setOpenAddOrUpdateTierDialog] = useState({ action: false, rowData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getTierConfigList();
    }, []);

    const getTierConfigList = () => {
        setIsLoading(true);
        getTierConfigData()
            .then((resp) => {
                setTierListData(resp.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const addTier = () => {
        setOpenAddOrUpdateTierDialog({ action: true, rowData: null });
    };

    const editTierList = (rowData) => {
        setOpenAddOrUpdateTierDialog({ action: true, rowData: rowData });
    };

    return (
        <material.Grid className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 12
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 12
                },
            }}
        >
            <Navbar />
            <div className="row">
                <div className="col-12">
                    <span className='float-end'>
                        <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addTier}>Add Tier Config</material.Button>
                    </span>
                </div>
                <div className="col-12 mt-3">
                    <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <material.TableContainer sx={{ maxHeight: 660 }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell>Org Name</StyledTableCell>
                                        <StyledTableCell>Clinic Name</StyledTableCell>
                                        <StyledTableCell>Tier Name</StyledTableCell>
                                        {/* <StyledTableCell>Payment Date</StyledTableCell>
                                        <StyledTableCell>Next Payment Date</StyledTableCell> */}
                                        {/* <StyledTableCell>Action</StyledTableCell> */}
                                    </material.TableRow>
                                </material.TableHead>
                                {isLoading ? (
                                    <material.TableRow >
                                        <material.TableCell colSpan={10}>
                                            <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                <Skeleton count={10} />
                                            </SkeletonTheme>
                                        </material.TableCell>
                                    </material.TableRow>
                                ) : (
                                    <>
                                        {tierListData.length ? tierListData.map((tier, k) => (
                                            <material.TableRow
                                                key={k}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } }}
                                                onClick={() => editTierList(tier)}
                                            >
                                                <material.TableCell size='small' sx={{ padding: 2 }}>{tier.orgName}</material.TableCell>
                                                <material.TableCell size='small'>{tier.clinicName}</material.TableCell>
                                                <material.TableCell size='small'>{tier.tierType}</material.TableCell>
                                                {/* <material.TableCell size='small'></material.TableCell> */}
                                            </material.TableRow>
                                        )) : (
                                            <material.TableRow >
                                                <material.TableCell colSpan={10}>
                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                </material.TableCell>
                                            </material.TableRow>
                                        )}
                                    </>
                                )}
                            </material.Table>
                        </material.TableContainer>
                    </material.Paper>
                </div>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <AddOrUpdateTierConfig
                openAddOrUpdateTierDialog={openAddOrUpdateTierDialog}
                setOpenAddOrUpdateTierDialog={setOpenAddOrUpdateTierDialog}
                getTierConfigList={getTierConfigList}
            />
        </material.Grid>
    );
}

export default TierConfig;