import axios from "axios";
import { rootApiUrl } from "../environment/Environment";



export const saveRoomsData = async (roomsData) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/clinic-configuration/save-rooms-data`, roomsData);
};

export const saveMachineList = async (data) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/clinic-configuration/save-machine-list`, data);
};

export const getMachineList = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/clinic-configuration/get-machine-list/${clinicId}`);
};

export const getRoomList = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/clinic-configuration/get-room-list/${clinicId}`);
};

export const getMachineScheduleClinic = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/schedule/get-machine-schedule-clinic/${clinicId}`);
};

export const getRoomScheduleClinic = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/schedule/get-room-schedule-clinic/${clinicId}`);
};

export const getCurrentOffers = async (payload) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/clinic-configuration/get-current_offers`, { params: { ...payload } });
};

export const saveCurrentOffers = async (payload) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/clinic-configuration/save-update-current_offers`, payload);
};

export const deleteCurrentOffers = async (offerId) => {
    return await axios.put(`${rootApiUrl}/api/v0.2/clinic-configuration/delete-current_offers`, null, { params: { id: offerId } });
};