import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { changeDateFormate } from '../../../date-and-time-format/DateAndTimeFormat';
import { getPatientHistory } from '../../../services/PatientService';
import Snackbar from '../../toastrmessage/Snackbar';

function PatientHistory(props) {

    const { patientData, patientHistory, getPatientHistoryApiCall } = props;
    const [history, setHistory] = useState([]);
    const [hideAndShowPatientHistory, setHideAndShowPatientHistory] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (patientHistory || Object.keys(patientData).length > 0) {
            getPatientHistoryByPatientId()
        }
    }, [getPatientHistoryApiCall === true || Object.keys(patientData ? patientData : {}).length > 0]);

    const getPatientHistoryByPatientId = async () => {
        await getPatientHistory(patientHistory ? patientHistory.patientId : patientData.patientId)
            .then((resp) => {
                setHistory(resp.data.data)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    return (
        <div className='mt-3'>
            <hr />
            <div className='d-flex flex-row'>
                {hideAndShowPatientHistory ? (
                    <material.ArrowDropDownIcon onClick={() => setHideAndShowPatientHistory(false)} sx={{ cursor: "pointer" }} />
                ) : (
                    <material.ArrowDropUpIcon onClick={() => setHideAndShowPatientHistory(true)} sx={{ cursor: "pointer" }} />
                )}
                <h5 className='visit-report-section-heading'>Patient History</h5>
            </div>
            {/* <material.Typography variant="h5" className='mb-3'>History</material.Typography> */}
            {hideAndShowPatientHistory && (
                <div>
                    {history ? history.map((ele, k) => (
                        <span key={k}>
                            <ul className='fw-bold text-align-justify'>{ele.label} :
                                {ele.info.map((item, i) => (
                                    <li key={i} className='ms-5 fw-normal'>{item.note} ({changeDateFormate(item.date)})</li>
                                ))}
                            </ul>
                        </span>
                    )) : null}
                    {patientHistory ? patientHistory.map((ele, k) => (
                        <span key={k}>
                            <ul className='fw-bold text-align-justify'>{ele.label} :
                                {ele.info.map((item, i) => (
                                    <li key={i} className='ms-5 fw-normal'>{item.note} ({changeDateFormate(item.date)})</li>
                                ))}
                            </ul>
                        </span>
                    )) : null}
                </div>
            )}
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default PatientHistory;