import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { material } from '../../../library/material';
import Navbar from '../../navbar/Navbar';
import { useTheme } from '@mui/material';
import Snackbar from '../../toastrmessage/Snackbar';
import { getSendMailData } from '../../../services/PlatformCrmService';


function ViewSendMail() {
    const location = useLocation();
    // const { email } = location.state;
    const [searchParams] = useSearchParams();
    const email = searchParams.get("email");

    const navigate = useNavigate();
    const theme = useTheme();

    const [sentEmailData, setSentEmailData] = useState([]);
    const [filteredEmailData, setFilteredEmailData] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });

    useEffect(() => {
        getSendMailEmailAllData();
    }, []);

    useEffect(() => {
        filterEmails();
    }, [searchQuery, sentEmailData]);

    const getSendMailEmailAllData = () => {
        setLoading(true);
        getSendMailData(email)
            .then((resp) => {
                const emailArray = Array.isArray(resp?.data?.data) ? resp.data.data : [];
                if (emailArray) {
                    const parsedEmails = emailArray.map((htmlString) => {
                        const parser = new DOMParser();
                        const doc = parser.parseFromString(htmlString, "text/html");

                        return {
                            subject: doc.querySelector("h2")?.innerText || "No Subject",
                            to: doc.querySelector("p strong")?.nextSibling?.nodeValue.trim() || "Unknown Recipient",
                            timestamp: doc.querySelector("p:nth-of-type(2) strong")?.nextSibling?.nodeValue.trim() || "Unknown Date",
                            content: doc.body.innerHTML,
                        };
                    });

                    setSentEmailData(parsedEmails);
                    setFilteredEmailData(parsedEmails);
                } else {
                    setSentEmailData([]);
                    setFilteredEmailData([]);
                }

                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data.message,
                });
            })
            .catch((error) => {
                // console.log(error);
                setSentEmailData([]);
                setFilteredEmailData([]);
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.message,
                });
            })
            .finally(() => setLoading(false));
    };

    const filterEmails = () => {
        if (searchQuery.trim() === "") {
            setFilteredEmailData(sentEmailData);
        } else {
            const filtered = sentEmailData.filter((email) =>
                email.subject.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredEmailData(filtered);
        }
        setCurrentPage(0);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleChangePage = (_, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const backToList = () => setSelectedEmail(null);

    return (
        <material.Grid className='container-fluid' sx={{ mt: { xs: 10, md: 12, lg: 12 } }}>
            <Navbar />
            <material.Grid container spacing={2}>
                <material.Grid item xs={12}>
                    <material.Stack direction='row' spacing={1} justifyContent='flex-end'>
                        {selectedEmail ? (
                            <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size="small" onClick={backToList}>
                                Back to Sent Emails
                            </material.Button>
                        ) : (
                            <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size="small" onClick={() => navigate("/platform-crm")}>
                                Back
                            </material.Button>
                        )}
                    </material.Stack>
                </material.Grid>

                {!selectedEmail ? (
                    <material.Grid item xs={12}>
                        <material.Paper sx={{ width: '100%', overflow: 'hidden', p: 2 }}>
                            <material.Grid item xs={12} sm={2} md={2} lg={2}>
                                <material.TextField
                                    fullWidth
                                    label="Search by Subject"
                                    variant="standard"
                                    size="small"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    sx={{ mb: 2 }}
                                />
                            </material.Grid>

                            {loading ? (
                                <material.Box
                                    sx={{
                                        display: "grid",
                                        placeItems: "center",
                                        height: "100vh",
                                        width: "100vw",
                                        position: "fixed",
                                        top: 0,
                                        left: 0,
                                    }}
                                >
                                    <material.CircularProgress />
                                </material.Box>
                            ) : filteredEmailData.length === 0 ? (
                                <h3 style={{ color: "red", textAlign: "center" }}>No sent emails found</h3>
                            ) : (
                                <>
                                    <material.TableContainer>
                                        <material.Table>
                                            <material.TableHead>
                                                <material.TableRow>
                                                    <material.TableCell><strong>Recipient</strong></material.TableCell>
                                                    <material.TableCell><strong>Subject</strong></material.TableCell>
                                                    <material.TableCell align="right"><strong>Sent At</strong></material.TableCell>
                                                </material.TableRow>
                                            </material.TableHead>
                                            <material.TableBody>
                                                {filteredEmailData
                                                    .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                                                    .map((email, index) => (
                                                        <material.TableRow
                                                            key={index}
                                                            onClick={() => setSelectedEmail(email)}
                                                            sx={{ cursor: "pointer", "&:hover": { backgroundColor: "#f5f5f5" } }}
                                                        >
                                                            <material.TableCell sx={{ fontWeight: "bold" }}>{email.to}</material.TableCell>
                                                            <material.TableCell>{email.subject}</material.TableCell>
                                                            <material.TableCell align="right">{email.timestamp}</material.TableCell>
                                                        </material.TableRow>
                                                    ))}
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>

                                    <material.TablePagination
                                        rowsPerPageOptions={[5, 10, 50]}
                                        component="div"
                                        count={filteredEmailData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={currentPage}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </>
                            )}
                        </material.Paper>
                    </material.Grid>
                ) : (
                    <material.Grid item xs={12}>
                        <material.Paper sx={{ width: "100%", overflow: "hidden", p: 2 }}>
                            <div dangerouslySetInnerHTML={{ __html: selectedEmail.content }} />
                        </material.Paper>
                    </material.Grid>
                )}
            </material.Grid>

            <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
        </material.Grid>
    );
}


export default ViewSendMail