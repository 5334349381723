import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import AddProductTreatmentMapping from '../../dialog/AddProductTreatmentMapping';
import { getProductTreatment } from '../../../services/TreatmentListService';
import { connect } from 'react-redux';
import Navbar from '../../navbar/Navbar';
import Snackbar from '../../toastrmessage/Snackbar';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';

function ProductTreatmentMapping(props) {

    const { userData, clinicData } = props;
    let userDetails = userData.authReducer.data;
    let clinicDetails = clinicData.clinicReducer.data;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [isLoading, setIsLoading] = useState(true);
    const [openProductTreatmentMapping, setOpenProductTreatmentMapping] = useState({ action: false, data: null });
    const [productList, setProductList] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    localStorage.setItem("menuName", "Product Treatment Mapping");

    useEffect(() => {
        getProductTreatmentList();
    }, []);

    const getProductTreatmentList = async () => {
        await getProductTreatment(clinicDetails.clinicId, userDetails.orgId)
            .then((resp) => {
                setIsLoading(false)
                setProductList(resp.data.data)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const addProductTreatmentMapping = () => {
        setOpenProductTreatmentMapping({ action: true, data: null })
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <div className='row'>
                    <div className='col-6'>
                    </div>
                    <div className='col-6'>
                        <span className="float-end">
                            <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addProductTreatmentMapping}>Add Product Treatment Map</material.Button>
                        </span>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <material.TableContainer sx={{ maxHeight: 460 }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead >
                                        <material.TableRow>
                                            <StyledTableCell>Treatment Name</StyledTableCell>
                                            <StyledTableCell>Company Name</StyledTableCell>
                                            <StyledTableCell>Product</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {isLoading ? (
                                            <material.TableRow >
                                                <material.TableCell colSpan={10}>
                                                    <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                        <Skeleton count={10} />
                                                    </SkeletonTheme>
                                                </material.TableCell>
                                            </material.TableRow>
                                        ) : (
                                            <>
                                                {productList.length ? productList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                    <material.TableRow
                                                        key={i}
                                                    >
                                                        <material.TableCell>{row.treatmentType}</material.TableCell>
                                                        <material.TableCell>{row.product?.map((comp, j) => <div key={j}>{comp.companyName}</div>)}</material.TableCell>
                                                        <material.TableCell>{row.product?.map((prod, k) => <div key={k}>{prod.product}</div>)}</material.TableCell>
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={10}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </>)}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                            <hr />
                            <material.TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                component="div"
                                count={productList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </material.Paper>
                    </div>
                </div>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <AddProductTreatmentMapping
                openProductTreatmentMapping={openProductTreatmentMapping}
                setOpenProductTreatmentMapping={setOpenProductTreatmentMapping}
                getProductTreatmentList={getProductTreatmentList}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(ProductTreatmentMapping);