import axios from "axios";
import { rootApiUrl } from "../environment/Environment";


export const getCallCredential = async (treatmentPlanRequestId) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/cosmetic-treatment/get-call-credential/${treatmentPlanRequestId}`)
};

export const triggerRejectCall = async (treatmentPlanRequestId) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/cosmetic-treatment/reject-call/${treatmentPlanRequestId}`)
};
