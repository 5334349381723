import React, { useState, useEffect } from 'react';
import { material } from '../../library/material';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getSkinTreatmentList } from '../../services/TreatmentListService';
import { saveSkinTreatmentPrice } from '../../services/ProductPriceManagment';
import Snackbar from '../toastrmessage/Snackbar';

var treatmentAreaData;
var treatmentId;

function AddSkinTreatmentPrice(props) {

    const { userData, clinicData, getSkinTreatmentPriceData } = props;
    const clinicDetails = clinicData.clinicReducer.data;
    const userDetails = userData.authReducer.data;
    const { openAddSkinTreatmentPrice, setOpenAddSkinTreatmentPrice } = props;
    const { register, handleSubmit, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [treatmentName, setTreatmentName] = useState([]);
    const [treatmentArea, setTreatmentArea] = useState([]);
    const [selectTreatment, setSelectTreatment] = useState("");
    const [selectTreatmentArea, setSelectTreatmentArea] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (openAddSkinTreatmentPrice.action && openAddSkinTreatmentPrice.priceData) {
            reset(openAddSkinTreatmentPrice.priceData ? openAddSkinTreatmentPrice.priceData : "")
        } else if (openAddSkinTreatmentPrice.action) {
            getSkinTreatmentData()
        }
    }, [openAddSkinTreatmentPrice.action === true]);

    const getSkinTreatmentData = async () => {
        let onlyTreatmentName = true;
        let treatmentName = "";
        await getSkinTreatmentList(clinicDetails.clinicId, onlyTreatmentName, treatmentName)
            .then((resp) => {
                setTreatmentName(resp.data.data)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };
    const selectTreatmentName = (event) => {
        setSelectTreatment(event.target.value);
        getSkinTreatmentArea(event.target.value)
    };
    const getSkinTreatmentArea = async (treatment) => {
        let clinicId = clinicDetails.clinicId;
        let onlyTreatmentName = false;
        let treatmentName = treatment;
        await getSkinTreatmentList(clinicId, onlyTreatmentName, treatmentName)
            .then((resp) => {
                treatmentAreaData = resp.data.data
                setTreatmentArea(resp.data.data)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };
    const selectArea = (event) => {
        setSelectTreatmentArea(event.target.value)
        treatmentId = treatmentAreaData?.filter((ele) => ele.treatmentArea === event.target.value).map((res) => res.treatmentId)
    };
    const addPrice = async (data) => {
        let obj = {
            "orgId": userDetails.orgId,
            "clinicId": clinicDetails.clinicId,
            "treatmentName": data.treatmentName,
            "treatmentArea": data.treatmentArea,
            "numberOfSessions": parseInt(data.numberOfSessions),
            "price": data.price,
            "treatmentId": treatmentId[0]
        }
        await saveSkinTreatmentPrice(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                getSkinTreatmentPriceData();
                reset({ treatmentName: "", treatmentArea: "", numberOfSessions: "", price: "", clinicId: "" })
                setSelectTreatment("")
                setSelectTreatmentArea("")
                setOpenAddSkinTreatmentPrice({ action: false, priceData: null })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };
    const updatePrice = async (data) => {
        let obj = {
            "orgId": openAddSkinTreatmentPrice.priceData.orgId,
            "clinicId": openAddSkinTreatmentPrice.priceData.clinicId,
            "treatmentName": openAddSkinTreatmentPrice.priceData.treatmentName,
            "treatmentArea": openAddSkinTreatmentPrice.priceData.treatmentArea,
            "numberOfSessions": parseInt(data.numberOfSessions),
            "price": data.price,
            "treatmentId": openAddSkinTreatmentPrice.priceData.treatmentId,
            "treatmentPriceId": openAddSkinTreatmentPrice.priceData.treatmentPriceId
        }
        await saveSkinTreatmentPrice(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                getSkinTreatmentPriceData(openAddSkinTreatmentPrice.priceData.clinicId)
                setSelectTreatment("")
                setSelectTreatmentArea("")
                reset({ treatmentName: "", treatmentArea: "", numberOfSessions: "", price: "", clinicId: "" })
                setOpenAddSkinTreatmentPrice({ action: false, priceData: null })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };
    const handleClose = () => {
        reset({ treatmentName: "", treatmentArea: "", numberOfSessions: "", price: "", clinicId: "" })
        setSelectTreatment("")
        setSelectTreatmentArea("")
        setOpenAddSkinTreatmentPrice({ action: false, priceData: null })
    };

    return (
        <div>
            <material.Dialog maxWidth="sm" fullWidth hideBackdrop open={openAddSkinTreatmentPrice.action}>
                <material.DialogTitle>{openAddSkinTreatmentPrice.priceData ? "Edit Skin Treatment Price" : "Add Skin Treatment Price"}</material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div>
                            {openAddSkinTreatmentPrice.priceData ? (
                                <material.TextField
                                    {...register("treatmentName")}
                                    required
                                    margin="dense"
                                    id="treatmentName"
                                    label="Treatment Name"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    disabled
                                />
                            ) : (
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="treatmentName">Treatment Name</material.InputLabel>
                                    <material.Select
                                        {...register("treatmentName", { required: true })}
                                        id="treatmentName"
                                        value={selectTreatment}
                                        onChange={selectTreatmentName}
                                    >
                                        {treatmentName?.map((name, i) => (
                                            <material.MenuItem value={name.treatmentName} key={i}>{name.treatmentName}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            )}
                        </div>
                        <div>
                            {openAddSkinTreatmentPrice.priceData ? (
                                <material.TextField
                                    {...register("treatmentArea")}
                                    required
                                    margin="dense"
                                    id="treatmentArea"
                                    label="Treatment Area"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    disabled
                                />
                            ) : (
                                <material.FormControl variant="standard" fullWidth required>
                                    <material.InputLabel id="treatmentArea">Treatment Area</material.InputLabel>
                                    <material.Select
                                        {...register("treatmentArea", { required: true })}
                                        id="treatmentArea"
                                        value={selectTreatmentArea}
                                        onChange={selectArea}
                                    >
                                        {treatmentArea?.map((area, i) => (
                                            <material.MenuItem value={area.treatmentArea} key={i}>{area.treatmentArea}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            )}
                        </div>
                        <div>
                            <material.TextField
                                {...register("numberOfSessions", { required: true })}
                                required
                                margin="dense"
                                id="numberOfSessions"
                                label="Number of Session"
                                type="number"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div>
                            <material.TextField
                                {...register("price", { required: true })}
                                required
                                margin="dense"
                                id="price"
                                label="Price"
                                type="number"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button onClick={handleClose} variant="contained" sx={{ textTransform: "none" }} color="error">Cancel</material.Button>
                    {openAddSkinTreatmentPrice.priceData ? (
                        <material.Button onClick={handleSubmit(updatePrice)} variant="contained" sx={{ textTransform: "none" }} disabled={!isValid}>Update</material.Button>
                    ) : (
                        <material.Button onClick={handleSubmit(addPrice)} variant="contained" sx={{ textTransform: "none" }} disabled={!isValid}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(AddSkinTreatmentPrice);