import axios from "axios"
import { rootApiUrl } from "../environment/Environment"



export const saveOrUpdateSupplierProductConfig = async (supplierProductData) => {
    return axios.post(`${rootApiUrl}/api/v0.2/admin/save-or-update-supplier-product-configuration`, supplierProductData)
};

export const getSupplierProductConfig = async () => {
    return axios.get(`${rootApiUrl}/api/v0.2/admin/get-all-supplier-product-config`)
};
export const getAllSupplierList = async () => {
    return axios.get(`${rootApiUrl}/api/v0.2/suppliers/get-all-supplier-name-id`)
};