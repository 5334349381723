import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { changeDateAndTimeFormate, changeDateFormate } from '../../../date-and-time-format/DateAndTimeFormat';
import { deleteOtherProduct, getOtherStockPriceDataByClinic, getOtherStockPriceDataByOrg } from '../../../services/ProductPriceManagment';
import { connect } from 'react-redux';
import Snackbar from '../../toastrmessage/Snackbar';
import { socket } from '../../../socket/Socket';
import AddOtherStock from '../../dialog/price-management-dialog/AddOtherStock';
import Navbar from '../../navbar/Navbar';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';

var allProductData;

function OtherStock(props) {

    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [productPriseData, setProductPriseData] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [isLoading, setisLoading] = useState(true);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [stockPriceUpdated, setStockPriceUpdated] = useState();
    const [openAddOtherStock, setOpenAddOtherStock] = useState({ action: false, otherStockData: null })

    socket.on("stock-price-updated-other", (res) => {
        setStockPriceUpdated(res)
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Price List Updated",
        })
    });

    localStorage.setItem("menuName", "Pricing Other Stock");

    useEffect(() => {
        getOtherStockPricemanagmentData();
    }, [stockPriceUpdated]);

    const getOtherStockPricemanagmentData = async () => {
        await getOtherStockPriceDataByClinic(clinicDetails.clinicId)
            .then((resp) => {
                allProductData = resp.data.data;
                setProductPriseData(resp.data.data)
                setisLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
        setStockPriceUpdated("");
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const addProduct = () => {
        setOpenAddOtherStock({ action: true, otherStockData: null });
    };

    const editProduct = (productData) => {
        setOpenAddOtherStock({ action: true, otherStockData: productData });
    };

    const deleteProduct = async (rowData) => {
        await deleteOtherProduct(rowData)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                getOtherStockPricemanagmentData()
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const filterByPrice = (value) => {
        setPage(0);
        const filterData = productPriseData.filter((ele) => {
            return ele.product
                .toString()
                .toLowerCase()
                .trim()
                .includes(value.toString().toLowerCase().trim())
        })
        if (value.trim().toString().length < 1) {
            setProductPriseData(allProductData)
        } else {
            setProductPriseData(filterData)
        }
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <div className='row'>
                    <div className='col-6'>
                        <span>
                            <material.TextField
                                fullWidth
                                variant="standard"
                                label="Filter by Product Name"
                                type='text'
                                onChange={(e) => filterByPrice(e.target.value)}
                                sx={{ width: "40ch" }}
                            />
                        </span>
                    </div>
                    <div className='col-6'>
                        <span className="float-end">
                            <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={() => addProduct("addProduct")}>Add-Stock</material.Button>
                        </span>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <material.TableContainer sx={{ maxHeight: 460 }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead >
                                        <material.TableRow>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Product Name</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Product Type</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}> Per Units Price</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>To Date</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>From Date</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Changed By</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Time Stamp</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 100 }}>Status</StyledTableCell>
                                            <StyledTableCell sx={{ minWidth: 220 }}>Action</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {isLoading ? (
                                            <material.TableRow >
                                                <material.TableCell colSpan={10}>
                                                    <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                        <Skeleton count={10} />
                                                    </SkeletonTheme>
                                                </material.TableCell>
                                            </material.TableRow>
                                        ) : (
                                            <>
                                                {productPriseData.length ? productPriseData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                    <material.TableRow
                                                        key={i}
                                                        sx={{
                                                            '&:last-child td, &:last-child th': { border: 0 }
                                                        }}
                                                    >
                                                        <material.TableCell size='small'>{row.product}</material.TableCell>
                                                        <material.TableCell size='small'>{row.productType}</material.TableCell>
                                                        <material.TableCell size='small'>{row.perUnitPrice}</material.TableCell>
                                                        <material.TableCell size='small'>{row.toDate ? changeDateFormate(row.toDate) : " "}</material.TableCell>
                                                        <material.TableCell size='small'>{row.formDate ? changeDateFormate(row.formDate) : " "}</material.TableCell>
                                                        <material.TableCell size='small'>{row.changedBy.split("_")[0] + " " + row.changedBy.split("_")[1]}</material.TableCell>
                                                        <material.TableCell size='small'>{row.timeStamp ? changeDateAndTimeFormate(row.timeStamp) : " "}</material.TableCell>
                                                        <material.TableCell size='small'>{row.active ? (<p style={{ color: "green", fontWeight: "bold" }}>active</p>) : (<p style={{ color: "red", fontWeight: "bold" }}>De-active</p>)}</material.TableCell>
                                                        <StyledTableCell size='small'>
                                                            <material.IconButton title='Edit Stocks' aria-label="create" size="large" onClick={() => editProduct({ ...row, "action": "edit" })}>
                                                                <material.CreateIcon color='primary' />
                                                            </material.IconButton>
                                                            <material.IconButton title="Delete Product" aria-label="create" size="large" onClick={() => deleteProduct(row)}>
                                                                <material.DeleteIcon color='warning' />
                                                            </material.IconButton>
                                                        </StyledTableCell>
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={10}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </>)}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                            <hr />
                            <material.TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                component="div"
                                count={productPriseData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </material.Paper>
                    </div>
                </div>
            </div>
            <AddOtherStock
                openAddOtherStock={openAddOtherStock}
                setOpenAddOtherStock={setOpenAddOtherStock}
                getOtherStockPricemanagmentData={getOtherStockPricemanagmentData}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(OtherStock);