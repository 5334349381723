import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { Controller, useForm } from 'react-hook-form';
import { getUserPermission, saveUserRolePermission } from '../../services/manage-roles-service/ManageRolesService';
import { Slide } from '@mui/material';
import Snackbar from '../toastrmessage/Snackbar';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const systemAdminPermission = ["AEST_SYST_ADMIN"];

function AddOrEditRoleDialog(props) {

    const { register, handleSubmit, reset, control, setValue, watch, getValues, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const { openAddOrEditRoleDialog, setOpenAddOrEditRoleDialog, userDetails, getUserRolePermissionData, clinicDetails, clinicId, orgId, openRoleTypeCheckDialog } = props;
    const [permissionList, setPermissionList] = useState([]);
    const [permissionListData, setPermissionListData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [selectValue, setSelectValue] = useState("");

    useEffect(() => {
        if (openAddOrEditRoleDialog.action) {
            getPermissionList();
            if (openRoleTypeCheckDialog.data === "Organization Level") {
                setSelectValue("CLINIC LEVEL");
                setValue("usingFor", "CLINIC LEVEL");
            } else if (openRoleTypeCheckDialog.data === "Platform Level") {
                setSelectValue("PLATFORM LEVEL");
                setValue("usingFor", "PLATFORM LEVEL");
            }
        }
    }, [openAddOrEditRoleDialog.action]);

    useEffect(() => {
        if (openAddOrEditRoleDialog.action && openAddOrEditRoleDialog.rowData) {
            const { roleName, permissions, usingFor } = openAddOrEditRoleDialog.rowData;
            setValue("roleName", roleName);
            setValue("usingFor", usingFor);
            setSelectValue(openAddOrEditRoleDialog.rowData.usingFor);
            if (permissionList.length) {
                permissionList.forEach((category) => {
                    category.permissions?.forEach((permission) => {
                        const isChecked = permissions.some((p) => p.permissionId === permission.id);
                        setValue(permission.id.toString(), isChecked);
                    })
                });
            }
        } else if (openAddOrEditRoleDialog.action) {
            permissionList?.forEach((category) => {
                category.permissions?.forEach((permission) => {
                    setValue(permission.id.toString(), false);
                })
            });
        }
    }, [permissionList, openAddOrEditRoleDialog.action]);

    const getPermissionList = () => {
        const payload = {
            clinicId: openRoleTypeCheckDialog.data === "Organization Level" ? clinicId : clinicDetails.clinicId,
            orgId: openRoleTypeCheckDialog.data === "Organization Level" ? orgId : userDetails.orgId
        }
        getUserPermission(payload)
            .then((resp) => {
                const grouped = {};
                setPermissionListData(resp.data.data);
                resp.data?.data?.forEach(permission => {
                    const category = permission.category;
                    if (!grouped[category]) {
                        grouped[category] = {
                            categoryName: category,
                            permissions: []
                        };
                    }
                    grouped[category].permissions.push({
                        permissionName: permission.permissionName,
                        id: permission.id
                    });
                });
                setPermissionList(Object.values(grouped))
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data || error.response.data.messages,
                    })
                }
            })
    };

    const handleClose = () => {
        setSelectValue('');
        reset({ roleName: "" });
        setOpenAddOrEditRoleDialog({ action: false, rowData: null })
    };

    const saveRole = (formData) => {
        const checkedPermissions = Object.keys(formData)
            .filter((key) => {
                return permissionListData.some((permission) => permission.id.toString() === key && formData[key]);
            })
            .map((key) => parseInt(key, 10));
        let payload;
        if (openAddOrEditRoleDialog.rowData) {
            payload = {
                id: openAddOrEditRoleDialog.rowData.id,
                roleName: formData.roleName,
                usingFor: formData.usingFor,
                clinicId: openRoleTypeCheckDialog.data === "Organization Level" ? clinicId : clinicDetails.clinicId,
                orgId: openRoleTypeCheckDialog.data === "Organization Level" ? orgId : userDetails.orgId,
                permissions: checkedPermissions,
                isSystemLevelRole: userDetails.role.permissions.some(item => systemAdminPermission.includes(item.permissionName)) ? true : false
            }
        } else {
            payload = {
                roleName: formData.roleName,
                usingFor: formData.usingFor,
                clinicId: openRoleTypeCheckDialog.data === "Organization Level" ? clinicId : clinicDetails.clinicId,
                orgId: openRoleTypeCheckDialog.data === "Organization Level" ? orgId : userDetails.orgId,
                permissions: checkedPermissions,
                isSystemLevelRole: userDetails.role.permissions.some(item => systemAdminPermission.includes(item.permissionName)) ? true : false
            }
        }
        saveUserRolePermission(payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                });
                setSelectValue('');
                reset({ roleName: "" });
                const payload = {
                    clinicId: openRoleTypeCheckDialog.data === "Organization Level" ? clinicId : clinicDetails.clinicId,
                    orgId: openRoleTypeCheckDialog.data === "Organization Level" ? orgId : userDetails.orgId
                }
                getUserRolePermissionData(payload);
                setOpenAddOrEditRoleDialog({ action: false, rowData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const handleChange = (event) => {
        setSelectValue(event.target.value)
    };

    return (
        <div>
            <material.Dialog open={openAddOrEditRoleDialog.action}
                fullScreen
                PaperProps={{
                    sx: {
                        backgroundColor: '#fff',
                    },
                }}
                TransitionComponent={Transition}
            >
                <material.AppBar sx={{
                    position: 'relative',
                }}>
                    <material.Toolbar>
                        <material.IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <material.CloseIcon />
                        </material.IconButton>
                        <material.Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {openAddOrEditRoleDialog.rowData !== null ? "Edit Protocol" : "Add Role"}
                        </material.Typography>
                    </material.Toolbar>
                </material.AppBar>
                <material.DialogContent>
                    <div className="row">
                        <div className="col-6">
                            <material.TextField
                                required
                                fullWidth
                                variant="standard"
                                label="Role Name"
                                id='roleName'
                                {...register("roleName", { required: true })}
                            />
                        </div>
                        <div className="col-6">
                            <material.FormControl variant="standard" required fullWidth>
                                <material.InputLabel id="demo-simple-select-standard-label">Use For</material.InputLabel>
                                <material.Select
                                    {...register("usingFor", { required: true })}
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectValue}
                                    onChange={handleChange}
                                    readOnly
                                >
                                    <material.MenuItem value="PLATFORM LEVEL">PLATFORM LEVEL</material.MenuItem>
                                    <material.MenuItem value="CLINIC LEVEL">CLINIC LEVEL</material.MenuItem>
                                </material.Select>
                            </material.FormControl>
                        </div>
                        {permissionList.length ? permissionList.map((permissions, k) => (
                            <material.Accordion key={k} sx={{ mt: 2 }}>
                                <material.AccordionSummary
                                    expandIcon={<material.ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <material.Typography variant='h6'>{permissions.categoryName}</material.Typography>
                                </material.AccordionSummary>
                                <material.AccordionDetails>
                                    <div className="row">
                                        {permissions?.permissions?.length ? permissions.permissions.map((permission, k) => (
                                            <div className="col-lg-6 col-md-6 col-sm-12" key={k} style={{ marginTop: -20 }}>
                                                <div className="row mt-3">
                                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                                        <material.Typography>{permission.permissionName}</material.Typography>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                                        <Controller
                                                            name={`${permission.id}`}
                                                            control={control}
                                                            defaultValue={false}
                                                            render={({ field }) => {
                                                                // const isReadOnly = watch(`${permission.id}`);
                                                                // console.log(isReadOnly)
                                                                return (
                                                                    <material.Switch
                                                                        {...field}
                                                                        color="secondary"
                                                                        checked={field.value}
                                                                        onChange={(e) => field.onChange(e.target.checked)}
                                                                        title={permission.description}
                                                                    // readOnly={isReadOnly}
                                                                    />
                                                                );
                                                            }}

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )) : null}
                                    </div>
                                </material.AccordionDetails>
                            </material.Accordion>
                        )) : null}
                    </div>
                </material.DialogContent>
                <material.DialogActions sx={{ m: 2 }}>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(saveRole)} startIcon={<material.DoneIcon />}>{openAddOrEditRoleDialog.rowData !== null ? "Update" : "Save"}</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div >
    );
}

export default AddOrEditRoleDialog;