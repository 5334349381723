import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { connect } from 'react-redux';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { changeDateAndTimeFormate, changeDateFormate } from '../../../date-and-time-format/DateAndTimeFormat';
import Snackbar from '../../toastrmessage/Snackbar';
import { getOtherStockPurchasedByClinic, getOtherStockPurchasedByOrg } from '../../../services/OtherStockService';
import { socket } from '../../../socket/Socket';
import OtherStockPurchasedDialog from '../../dialog/other-stock/OtherStockPurchasedDialog';
import Navbar from '../../navbar/Navbar';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import { useTheme } from '@mui/material';

var allStockData;

function OtherStockPurchased(props) {

    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const theme = useTheme();
    const [otherStocksData, setOtherStockesData] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [isLoading, setisLoading] = useState(true);
    const [openAddOtherStock, setOpenAddOtherStock] = useState({ action: false, stockData: null })
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [stockUpdated, setStockUpdated] = useState();

    socket.on("stock-updated-other", (res) => {
        setStockUpdated(res)
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Stock Updated",
        })
    });

    localStorage.setItem("menuName", "Other Stock Purchased");

    useEffect(() => {
        getOtherStockPurchasedData();
    }, [stockUpdated]);

    const getOtherStockPurchasedData = async () => {
        await getOtherStockPurchasedByClinic(clinicDetails.clinicId)
            .then((resp) => {
                allStockData = resp.data.data;
                setOtherStockesData(resp.data.data)
                setisLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const addStocks = () => {
        setOpenAddOtherStock({ action: true, stockData: null })
    };

    const editStock = (stockData) => {
        setOpenAddOtherStock({ action: true, stockData: stockData })
    };

    const filterByPrice = (value) => {
        setPage(0);
        const filterData = otherStocksData.filter((ele) => {
            return ele.product
                .toString()
                .toLowerCase()
                .trim()
                .includes(value.toString().toLowerCase().trim())
        })
        if (value.trim().toString().length < 1) {
            setOtherStockesData(allStockData)
        } else {
            setOtherStockesData(filterData)
        }
    };

    return (
        <material.Grid spacing={2} className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 10
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 10
                },
            }}
        >
            <Navbar />
            <div className='row'>
                <div className='col-6'>
                    <span>
                        <material.TextField
                            fullWidth
                            variant="standard"
                            label="Filter by Product Name"
                            type='text'
                            onChange={(e) => filterByPrice(e.target.value)}
                            sx={{ width: "40ch", mb: 2, mt: -2 }}
                        />
                    </span>
                </div>
                <div className='col-6'>
                    <span className="float-end">
                        <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={addStocks}>Add-Stock</material.Button>
                    </span>
                </div>
                <div className="col-12">
                    <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <material.TableContainer sx={{ maxHeight: 500 }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Supplier</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Product</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Product Type</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Units</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Box/Vial</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Date in</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Cost</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Batch No</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Expiry Date</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Edited by</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Time stamp</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Comments</StyledTableCell>
                                        <StyledTableCell sx={{ minWidth: 220 }}>Action</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={10}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={10} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {otherStocksData.length ? otherStocksData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                <material.TableRow
                                                    key={i}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': { border: 0 }
                                                    }}
                                                >
                                                    <material.TableCell sx={{ pt: 2, pb: 2 }} size='small'>{row.supplier}</material.TableCell>
                                                    <material.TableCell size='small'>{row.product}</material.TableCell>
                                                    <material.TableCell size='small'>{row.productType}</material.TableCell>
                                                    <material.TableCell size='small'>{row.units}</material.TableCell>
                                                    <material.TableCell size='small'>{row.unitType}</material.TableCell>
                                                    <material.TableCell size='small'>{changeDateFormate(row.dateIn)}</material.TableCell>
                                                    <material.TableCell size='small'>{row.cost}</material.TableCell>
                                                    <material.TableCell size='small'>{row.batchNo}</material.TableCell>
                                                    <material.TableCell size='small'>{changeDateFormate(row.expDate)}</material.TableCell>
                                                    <material.TableCell size='small'>{row.enitedBy.split("_")[0] + " " + row.enitedBy.split("_")[1]}</material.TableCell>
                                                    <material.TableCell size='small'>{changeDateAndTimeFormate(row.timeStamp)}</material.TableCell>
                                                    <material.TableCell size='small'>{row.comment}</material.TableCell>
                                                    <material.TableCell size='small'>
                                                        <material.IconButton title='Edit Stocks' aria-label="create" size="large" onClick={() => editStock({ ...row, "action": "edit" })}>
                                                            <material.CreateIcon color='primary' />
                                                        </material.IconButton>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={10}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>)}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                        <hr />
                        <material.TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={otherStocksData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </material.Paper>
                </div>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <OtherStockPurchasedDialog
                openAddOtherStock={openAddOtherStock}
                setOpenAddOtherStock={setOpenAddOtherStock}
                getOtherStockPurchasedData={getOtherStockPurchasedData}
            />
        </material.Grid >
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(OtherStockPurchased);