import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import InviteUser from '../dialog/InviteUser';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { activeOrDeActiveUser, getOrgList, getUsersList } from '../../services/UserManagementService';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Navbar from '../navbar/Navbar';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import Snackbar from '../toastrmessage/Snackbar';
import { useTheme } from '@mui/material';
import UserTypeCheckDialog from './UserTypeCheckDialog';
import { getClinicByOrgId } from '../../services/ClinicService';
import { Controller, useForm } from 'react-hook-form';


const label = { inputProps: { 'aria-label': 'Color switch demo' } };
const systemAdminPermission = ["AEST_SYST_ADMIN"];
let allUsersData;
const permissionsToCheckForClinic = ['CLINIC_SELECTOR'];

const Usermanagement = (props) => {

    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const { register, handleSubmit, reset, setValue, control, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [usersData, setUsersData] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const [openInvitePopup, setOpenInvitePopup] = React.useState({
        open: false,
        action: ""
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openUserTypeCheckDialog, setOpenUserTypeCheckDialog] = useState({ action: true, data: null });
    const [organizationName, setOrganizationName] = useState([]);
    const [clinicName, setClinicName] = useState([]);
    const [organizationDetails, setOrganizationDetails] = useState([]);
    const [clinicListData, setClinicListData] = useState([]);
    const [orgId, setOrgId] = useState(null);
    const [clinicId, setClinicId] = useState(null);
    const navigate = useNavigate();
    const theme = useTheme();

    localStorage.setItem("menuName", "User Management");

    useEffect(() => {
        if (userDetails.role.permissions.some((item) => permissionsToCheckForClinic.includes(item.permissionName))) {
            setOpenUserTypeCheckDialog({ action: false, data: "Platform Level" });
        } else {
            setOpenUserTypeCheckDialog({ action: true, data: null });
        }
    }, []);

    useEffect(() => {
        if (openUserTypeCheckDialog.data === "Clinic Level") {
            getAllOrganization();
        } else if (openUserTypeCheckDialog.data === "Platform Level") {
            getAllUserList();
        }
    }, [openUserTypeCheckDialog.action === false]);

    const getAllUserList = async (payloadData) => {
        const payload = {
            clinicId: payloadData ? payloadData.clinicId : clinicDetails.clinicId,
            orgId: payloadData ? payloadData.orgId : userDetails.orgId,
        }
        await getUsersList(payload)
            .then((resp) => {
                allUsersData = resp.data.data;
                setUsersData(resp.data.data)
                setisLoading(false)
            })
            .catch((error) => {
                setTimeout(() => {
                    setisLoading(false)
                }, 2000)
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getAllOrganization = () => {
        getOrgList()
            .then((resp) => {
                setOrganizationDetails(resp.data.data);
                const orgName = resp.data.data?.map((org) => org.orgName);
                setOrganizationName(orgName);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getAllClinic = (orgId) => {
        getClinicByOrgId(orgId)
            .then((resp) => {
                setClinicListData(resp.data.data);
                const clinicName = resp.data.data?.map((clinic) => clinic.clinicName);
                setClinicName(clinicName);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const openInviteDialog = (value) => {
        setOpenInvitePopup({ open: true, action: value })
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const enableAndDisableUser = async (userId) => {
        setisLoading(true)
        await activeOrDeActiveUser(userId)
            .then((resp) => {
                const payload = {
                    clinicId: openUserTypeCheckDialog.data === "Clinic Level" ? clinicId : clinicDetails.clinicId,
                    orgId: openUserTypeCheckDialog.data === "Clinic Level" ? orgId : userDetails.orgId
                }
                getAllUserList(payload);
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
            })
            .catch((error) => {
                setTimeout(() => {
                    setisLoading(false)
                }, 2000);
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const viewUserDetails = (userData) => {
        // console.log(data)
        const payload = {
            orgId: orgId,
            clinicId: clinicId,
            callFrom: openUserTypeCheckDialog.data
        }
        navigate("/user_management/edit-user_management", { state: { userData, payload } })
    };

    const filterByUserID = (value) => {
        setPage(0);
        const filteredRows = usersData.filter((row) => {
            return row.firstName
                .toString()
                .toLowerCase()
                .trim()
                .includes(value.toString().toLowerCase().trim())
        })
        if (value.trim().toString().length < 1) {
            setUsersData(allUsersData);
        } else {
            setUsersData(filteredRows);
        }
    };

    return (
        <material.Grid className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 10
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 10
                },
            }}
        >
            <Navbar />
            {openUserTypeCheckDialog.data === "Clinic Level" ? (
                <div className="row">
                    <div className="col-4">
                        <Controller
                            name="organizationName"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <material.Autocomplete
                                    {...field}
                                    value={field.value}
                                    options={organizationName}
                                    renderInput={(params) => (
                                        <material.TextField
                                            required
                                            margin="dense"
                                            {...params}
                                            variant="standard"
                                            label="Organization Name"
                                            fullWidth
                                        />
                                    )}
                                    onChange={(event, data) => {
                                        field.onChange(data);
                                        if (data) {
                                            const orgId = organizationDetails.filter((org) => org.orgName === data).map((x) => x.orgId)[0];
                                            getAllClinic(orgId);
                                            setOrgId(orgId);
                                        }
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className="col-4">
                        <Controller
                            name="clinicName"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <material.Autocomplete
                                    {...field}
                                    value={field.value}
                                    options={clinicName}
                                    renderInput={(params) => (
                                        <material.TextField
                                            required
                                            margin="dense"
                                            {...params}
                                            variant="standard"
                                            label="Clinic Name"
                                            fullWidth
                                        />
                                    )}
                                    onChange={(event, data) => {
                                        field.onChange(data);
                                        if (data) {
                                            const clinicId = clinicListData.filter((clinic) => clinic.clinicName === data).map((clinic) => clinic.clinicId)[0];
                                            setClinicId(clinicId);
                                            const payload = { clinicId: clinicId, orgId: orgId };
                                            getAllUserList(payload);
                                        }
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className="col-6">
                        <span style={{ marginLeft: 5, mb: 4 }}>
                            <material.TextField
                                sx={{ width: "30ch" }}
                                variant="standard"
                                label="Filter by User Name"
                                onChange={(e) => filterByUserID(e.target.value)}
                            />
                        </span>
                    </div>
                    <div className="col-6">
                        <span className="float-end">
                            <material.Button variant="contained" sx={{ mr: 1, textTransform: "none" }} startIcon={<material.PersonIcon />} onClick={() => openInviteDialog("add-user")}>Add-User</material.Button>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} hidden={!userDetails.role.permissions.some(item => systemAdminPermission.includes(item.permissionName)) || openUserTypeCheckDialog.data === "Clinic Level"} startIcon={<material.PersonIcon />} onClick={() => openInviteDialog("invite-user")}>Invite-User</material.Button>
                        </span>
                    </div>
                    {usersData.length ? (
                        <div className="col-12 mt-3">
                            <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <material.TableContainer sx={{ maxHeight: 460 }}>
                                    <material.Table stickyHeader aria-label="sticky table">
                                        <material.TableHead >
                                            <material.TableRow>
                                                <StyledTableCell>Org ID</StyledTableCell>
                                                <StyledTableCell>Email ID</StyledTableCell>
                                                <StyledTableCell>Role</StyledTableCell>
                                                <StyledTableCell>User Name</StyledTableCell>
                                                <StyledTableCell>Status</StyledTableCell>
                                                <StyledTableCell>Actions</StyledTableCell>
                                            </material.TableRow>
                                        </material.TableHead>
                                        <material.TableBody>
                                            {isLoading ? (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={6}>
                                                        <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                            <Skeleton count={10} />
                                                        </SkeletonTheme>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )
                                                :
                                                (
                                                    <>
                                                        {usersData.length ? usersData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                            <material.TableRow
                                                                key={i}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <material.TableCell size='small' component="th" scope="row">{row.orgId}  </material.TableCell>
                                                                <material.TableCell size='small'>{row.email}</material.TableCell>
                                                                <material.TableCell size='small'>{row.role.roleName}</material.TableCell>
                                                                <material.TableCell size='small'>{row.firstName} {row.lastName}</material.TableCell>
                                                                <material.TableCell size='small'>{row.active ? (<p style={{ color: "green", fontWeight: "bold" }}>active</p>) : (<p style={{ color: "red", fontWeight: "bold" }}>De-active</p>)}</material.TableCell>
                                                                <material.TableCell>
                                                                    <material.IconButton title='Edit users' aria-label="create" size="large" onClick={() => viewUserDetails({ ...row, "readOnly": false, "callFrom": "edit" })}>
                                                                        <material.CreateIcon color='primary' />
                                                                    </material.IconButton>
                                                                    <material.IconButton title='Show users details' aria-label="visibility" size="large" onClick={() => viewUserDetails({ ...row, "readOnly": true, "callFrom": "show" })}>
                                                                        <material.VisibilityIcon color='success' />
                                                                    </material.IconButton>
                                                                    <material.Switch {...label} checked={row.active} onChange={() => enableAndDisableUser(row.userId)} />

                                                                </material.TableCell>
                                                            </material.TableRow>
                                                        )) : (
                                                            <material.TableRow >
                                                                <material.TableCell colSpan={6}>
                                                                    <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                                </material.TableCell>
                                                            </material.TableRow>
                                                        )}
                                                    </>)}
                                        </material.TableBody>
                                    </material.Table>
                                </material.TableContainer>
                                <hr />
                                <material.TablePagination
                                    rowsPerPageOptions={[5, 10, 20]}
                                    component="div"
                                    count={usersData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </material.Paper>
                        </div>
                    ) : null}
                </div>
            ) : openUserTypeCheckDialog.data === "Platform Level" ? (
                <div className="row">
                    <div className="col-6">
                        <span style={{ marginLeft: 5 }}>
                            <material.TextField
                                sx={{ width: "30ch" }}
                                variant="standard"
                                label="Filter by User Name"
                                onChange={(e) => filterByUserID(e.target.value)}
                            />
                        </span>
                    </div>
                    <div className="col-6">
                        <span className="float-end">
                            <material.Button variant="contained" sx={{ mr: 1, textTransform: "none" }} startIcon={<material.PersonIcon />} onClick={() => openInviteDialog("add-user")}>Add-User</material.Button>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} hidden={!userDetails.role.permissions.some(item => systemAdminPermission.includes(item.permissionName))} startIcon={<material.PersonIcon />} onClick={() => openInviteDialog("invite-user")}>Invite-User</material.Button>
                        </span>
                    </div>
                    <div className="col-12 mt-3">
                        <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <material.TableContainer sx={{ maxHeight: 460 }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead >
                                        <material.TableRow>
                                            <StyledTableCell>Org ID</StyledTableCell>
                                            <StyledTableCell>Email ID</StyledTableCell>
                                            <StyledTableCell>Role</StyledTableCell>
                                            <StyledTableCell>User Name</StyledTableCell>
                                            <StyledTableCell>Status</StyledTableCell>
                                            <StyledTableCell>Actions</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {isLoading ? (
                                            <material.TableRow >
                                                <material.TableCell colSpan={6}>
                                                    <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                        <Skeleton count={10} />
                                                    </SkeletonTheme>
                                                </material.TableCell>
                                            </material.TableRow>
                                        )
                                            :
                                            (
                                                <>
                                                    {usersData.length ? usersData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                        <material.TableRow
                                                            key={i}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <material.TableCell size='small' component="th" scope="row">{row.orgId}  </material.TableCell>
                                                            <material.TableCell size='small'>{row.email}</material.TableCell>
                                                            <material.TableCell size='small'>{row.role.roleName}</material.TableCell>
                                                            <material.TableCell size='small'>{row.firstName} {row.lastName}</material.TableCell>
                                                            <material.TableCell size='small'>{row.active ? (<p style={{ color: "green", fontWeight: "bold" }}>active</p>) : (<p style={{ color: "red", fontWeight: "bold" }}>De-active</p>)}</material.TableCell>
                                                            <material.TableCell>
                                                                <material.IconButton title='Edit users' aria-label="create" size="large" onClick={() => viewUserDetails({ ...row, "readOnly": false, "callFrom": "edit" })}>
                                                                    <material.CreateIcon color='primary' />
                                                                </material.IconButton>
                                                                <material.IconButton title='Show users details' aria-label="visibility" size="large" onClick={() => viewUserDetails({ ...row, "readOnly": true, "callFrom": "show" })}>
                                                                    <material.VisibilityIcon color='success' />
                                                                </material.IconButton>
                                                                <material.Switch {...label} checked={row.active} onChange={() => enableAndDisableUser(row.userId)} />

                                                            </material.TableCell>
                                                        </material.TableRow>
                                                    )) : (
                                                        <material.TableRow >
                                                            <material.TableCell colSpan={6}>
                                                                <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                            </material.TableCell>
                                                        </material.TableRow>
                                                    )}
                                                </>)}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                            <hr />
                            <material.TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                component="div"
                                count={usersData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </material.Paper>
                    </div>
                </div>
            ) : null}
            <UserTypeCheckDialog
                openUserTypeCheckDialog={openUserTypeCheckDialog}
                setOpenUserTypeCheckDialog={setOpenUserTypeCheckDialog}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <InviteUser
                openUserTypeCheckDialog={openUserTypeCheckDialog}
                clinicId={clinicId}
                orgId={orgId}
                openInvitePopup={openInvitePopup}
                setOpenInvitePopup={setOpenInvitePopup}
                getAllUserList={getAllUserList}
                setisLoading={setisLoading}
            />
        </material.Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state,
    };
};

export default connect(mapStateToProps)(Usermanagement);