import axios from "axios";
import { rootApiUrl } from "../environment/Environment";



export const getAllScheduleByClinicId = async (payload) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/schedule/get-all-schedule-by-clinic-id/${payload.clinicId}/${payload.pastBookings}`);
};

export const getAllScheduleByInjectorId = async (injectorId) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/schedule/get-all-schedule-by-injector-id/${injectorId}`);
};

export const saveSchedule = async (scheduleData) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/schedule/save-schedule-clinic`, scheduleData);
};

export const getInjectorListByClinicId = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/users/get-injector-list-by-clinic-id/${clinicId}`);
};

export const getTodayScheduleByInjectorId = async (injectorId) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/schedule/get-today-schedule-by-injector-id/${injectorId}`);
};

export const deleteAppointmentById = async (Id) => {
    return await axios.put(`${rootApiUrl}/api/v0.2/schedule/delete-schedule`, null, { params: { Id: Id } });
};

export const changeScheduleStatus = async (statusChangeData) => {
    return await axios.put(`${rootApiUrl}/api/v0.2/schedule/change-schedule-status`, statusChangeData);
};

export const getPatientUpcomingSchedule = async (patientId) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/patients/get-patient-upcoming-schedule`, { params: { patientId: patientId } });
};

export const createBreak = async (payload) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/schedule/create-break`, payload);
};

export const getScheduleMaster = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/schedule/get-schedule-master`, { params: { clinicId } });
};

export const getEmailSubject = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/schedule/get-schedule-mater-formatted`, { params: { clinicId } });
};

export const getEmailSubjectOpen = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/get-schedule-mater-formatted-open`, { params: { clinicId } });
};


export const saveAppointmentOutside = async (scheduleData) => {
    return await axios.post(`${rootApiUrl}/save-schedule-clinic-open`, scheduleData);
};

export const getInjectorDetailsById = async (injectorId) => {
    return await axios.get(`${rootApiUrl}/get-injector-details/${injectorId}`);
};

export const getAllScheduleByInjectorIdOpen = async (injectorId) => {
    return await axios.get(`${rootApiUrl}/get-all-schedule-by-injector-id-open/${injectorId}`);
};

export const checkInjectorAvailability = async (payload) => {
    return await axios.put(`${rootApiUrl}/api/v0.2/schedule/check-injector-availability`, payload);
};