import axios from "axios";
import { rootApiUrl } from "../environment/Environment";

export const getAllInjectors = async (clinicId) => {
  return await axios.get(
    `${rootApiUrl}/api/v0.2/users/get-injector-list-by-clinic-id/${clinicId}`
  );
};

// export const addInjector = async (injectorDetails) => {
//     return await axios.post(`${rootApiUrl}/save-injector-details`, injectorDetails);
// };

export const updateInjector = async (injectorDetails) => {
  return await axios.post(
    `${rootApiUrl}/api/v0.2/injector/save-or-update-injector-details`,
    injectorDetails
  );
};

export const getInjectorById = async (injectorId) => {
  return await axios.get(`${rootApiUrl}/api/v0.2/injector/get-injector-by-id/${injectorId}`);
};

export const getInjectorByIdOpen = async (injectorId) => {
  return await axios.get(`${rootApiUrl}/get-injector-by-id-open/${injectorId}`);
};

export const getUserRosterDetails = async (params) => {
  return await axios.get(`${rootApiUrl}/api/v0.2/users/get-user-roster`, { params });
};

export const saveUserRoast = async (userRotationDetails) => {
  return await axios.post(
    `${rootApiUrl}/api/v0.2/users/save-user-roster`,
    userRotationDetails
  );
};

export const getUserHoliday = async (payload) => {
  return await axios.get(`${rootApiUrl}/api/v0.2/users/get-user-holiday`, { params: { ...payload } });
};

export const saveUserHoliday = async (payload) => {
  return await axios.post(`${rootApiUrl}/api/v0.2/users/save-user-holiday`, payload);
};
