import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { useForm } from 'react-hook-form';
import { addPatientHistory } from '../../services/PatientService';
import moment from 'moment';
import Snackbar from '../toastrmessage/Snackbar';
import { connect } from 'react-redux';
import SelectTreatmentTypeDialog from './SelectTreatmentTypeDialog';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function AddPatientNote(props) {

    const { openPatientNote, setOpenPatientNote, getPatientHistoryByPatientId, patientData, userData, clinicData, callFrom } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const patientId = localStorage.getItem("patientId")

    const { register, handleSubmit, reset, resetField, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [selectSmokingValue, setSelectSmokingValue] = useState("");
    const [selectDrugValue, setSelectDrugValue] = useState("");
    const [openSelectTreatmentDialog, setOpenSelectTreatmentDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [questions, setquestions] = useState({
        aw_q1: "",
        aw_q2: "",
        aw_q3: "",
        aw_q4: "",
        aw_q5: "",
        aw_q6: "",
        aw_q7: "",
        aw_q8: "",
        aw_q9: "",
        aw_q10: "",
    });
    const [selectAcne, setSelectAcne] = useState(false);
    const [selectDryness, setSelectDryness] = useState(false);
    const [selectOilySkin, setSelectOilySkin] = useState(false);
    const [selectWrinkles, setSelectWrinkles] = useState(false);
    const [selectDarkSpots, setSelectDarkSpots] = useState(false);
    const [selectRedness, setSelectRedness] = useState(false);
    const [selectSkinTone, setSelectSkinTone] = useState(false);
    const [selectUnwanted, setSelectUnwanted] = useState(false);
    const [selectNoConcern, setSelectNoConcern] = useState(false);
    const [selectLaser, setSelectLaser] = useState(false);
    const [selectHifu, setSelectHifu] = useState(false);
    const [selectHydratingFacial, setSelectHydratingFacial] = useState(false);
    const [selectAntiAgingFacial, setSelectAntiAgingFacial] = useState(false);
    const [selectChemicalPeel, setSelectChemicalPeel] = useState(false);
    const [selectMicrodermabrasion, setSelectMicrodermabrasion] = useState(false);
    const [selectFacialMassage, setSelectFacialMassage] = useState(false);
    const [selectNone, setSelectNone] = useState(false);
    const [selectOtherTreatment, setSelectOtherTreatment] = useState(false);
    const [selectOther, setSelectOther] = useState(false);
    const [selectType1, setSelectType1] = useState(false);
    const [selectType2, setSelectType2] = useState(false);
    const [selectType3, setSelectType3] = useState(false);
    const [selectType4, setSelectType4] = useState(false);
    const [selectType5, setSelectType5] = useState(false);
    const [selectType6, setSelectType6] = useState(false);

    useEffect(() => {
        if (openPatientNote.action && openPatientNote.note !== null) {
            reset(openPatientNote.note);
            setSelectDrugValue(openPatientNote.note.illicit_drugs);
            setSelectSmokingValue(openPatientNote.note.smoking);
            setSelectAcne(openPatientNote.note.acne)
            setSelectDryness(openPatientNote.note.dryness)
            setSelectOilySkin(openPatientNote.note.oilySkin)
            setSelectWrinkles(openPatientNote.note.agingAurWrinkles)
            setSelectDarkSpots(openPatientNote.note.darkSpots)
            setSelectRedness(openPatientNote.note.sensitivityRedness)
            setSelectSkinTone(openPatientNote.note.skinTone)
            setSelectUnwanted(openPatientNote.note.unwantedHair)
            setSelectNoConcern(openPatientNote.note.noConcern)
            setSelectLaser(openPatientNote.note.laserTreatment)
            setSelectHifu(openPatientNote.note.hifuTreatment)
            setSelectHydratingFacial(openPatientNote.note.hydratingFacialTreatment)
            setSelectAntiAgingFacial(openPatientNote.note.antiAgingFacialTreatment)
            setSelectChemicalPeel(openPatientNote.note.chemicalPeel)
            setSelectMicrodermabrasion(openPatientNote.note.microdermabrasion)
            setSelectFacialMassage(openPatientNote.note.facialMassage)
            setSelectNone(openPatientNote.note.treatmentNone)
            setSelectOtherTreatment(openPatientNote.note.otherTreatment)
            setSelectOther(openPatientNote.note.other_concerns)
            setSelectType1(openPatientNote.note.type1)
            setSelectType2(openPatientNote.note.type2)
            setSelectType3(openPatientNote.note.type3)
            setSelectType4(openPatientNote.note.type4)
            setSelectType5(openPatientNote.note.type5)
            setSelectType6(openPatientNote.note.type6)
            setquestions({
                aw_q1: openPatientNote.note.aw_q1,
                aw_q2: openPatientNote.note.aw_q2,
                aw_q3: openPatientNote.note.aw_q3,
                aw_q4: openPatientNote.note.aw_q4,
                aw_q5: openPatientNote.note.aw_q5,
                aw_q6: openPatientNote.note.aw_q6,
                aw_q7: openPatientNote.note.aw_q7,
                aw_q8: openPatientNote.note.aw_q8,
                aw_q9: openPatientNote.note.aw_q9,
                aw_q10: openPatientNote.note.aw_q10,
            })
        }
    }, [openPatientNote.action === true]);

    const selectQuestions = (qNumber, value) => {
        setquestions(prevValues => ({
            ...prevValues,
            [qNumber]: value,
        }))
    };

    const onChangeAcne = () => {
        setSelectAcne(!selectAcne)
    };

    const onChangeDryness = () => {
        setSelectDryness(!selectDryness)
    };

    const onChangeOilySkin = () => {
        setSelectOilySkin(!selectOilySkin)
    };

    const onChangeWrinkles = () => {
        setSelectWrinkles(!selectWrinkles)
    };

    const onChangeDarkSpots = () => {
        setSelectDarkSpots(!selectDarkSpots)
    };

    const onChangeRedness = () => {
        setSelectRedness(!selectRedness)
    };

    const onChangeSkinTone = () => {
        setSelectSkinTone(!selectSkinTone)
    };

    const onChangeUnwanted = () => {
        setSelectUnwanted(!selectUnwanted)
    };

    const onChangeNoConcern = () => {
        setSelectNoConcern(!selectNoConcern)
    };

    const onChangeOther = () => {
        setSelectOther(!selectOther)
    };

    const onChangeLaser = () => {
        setSelectLaser(!selectLaser)
    };

    const onChangeHifu = () => {
        setSelectHifu(!selectHifu)
    };

    const onChangeHydratingFacial = () => {
        setSelectHydratingFacial(!selectHydratingFacial)
    };

    const onChangeAntiAgingFacial = () => {
        setSelectAntiAgingFacial(!selectAntiAgingFacial)
    };

    const onChangeChemicalPeel = () => {
        setSelectChemicalPeel(!selectChemicalPeel)
    };

    const onChangeMicrodermabrasion = () => {
        setSelectMicrodermabrasion(!selectMicrodermabrasion)
    };

    const onChangeFacialMassage = () => {
        setSelectFacialMassage(!selectFacialMassage)
    };

    const onChangeNone = () => {
        setSelectNone(!selectNone)
    };

    const onChangeOtherTreatment = () => {
        setSelectOtherTreatment(!selectOtherTreatment)
    };

    const onChangeType1 = () => {
        setSelectType1(!selectType1);
    };

    const onChangeType2 = () => {
        setSelectType2(!selectType2);
    };

    const onChangeType3 = () => {
        setSelectType3(!selectType3);
    };

    const onChangeType4 = () => {
        setSelectType4(!selectType4);
    };

    const onChangeType5 = () => {
        setSelectType5(!selectType5);
    };

    const onChangeType6 = () => {
        setSelectType6(!selectType6);
    };

    const addNote = async (fromData) => {
        let obj = {
            patientId: openPatientNote.patientData ? openPatientNote.patientData.patientId : patientData.patientId,
            dateOfEntry: moment().format("YYYY-MM-DDTHH:mm:ss"),
            typeOfNotes: "GENERAL_HISTORY",
            noteDefinition: "DefaultNotes",
            user: userDetails.name,
            history: {
                acne: selectAcne,
                dryness: selectDryness,
                oilySkin: selectOilySkin,
                agingAurWrinkles: selectWrinkles,
                darkSpots: selectDarkSpots,
                sensitivityRedness: selectRedness,
                skinTone: selectSkinTone,
                unwantedHair: selectUnwanted,
                unwanted_concerns: fromData.unwanted_concerns,
                noConcern: selectNoConcern,
                other_concerns: selectOther,
                other_concerns_text: fromData.other_concerns_text,
                allergies: fromData.allergies,
                skin_allergies: fromData.skin_allergies,
                skin_issues_specify: fromData.skin_issues_specify,
                skincare_routine_specify: fromData.skincare_routine_specify,
                negative_reactions_specify: fromData.negative_reactions_specify,
                products_used_specify: fromData.products_used_specify,
                laserTreatment: selectLaser,
                hifuTreatment: selectHifu,
                hydratingFacialTreatment: selectHydratingFacial,
                antiAgingFacialTreatment: selectAntiAgingFacial,
                chemicalPeel: selectChemicalPeel,
                microdermabrasion: selectMicrodermabrasion,
                facialMassage: selectFacialMassage,
                treatmentNone: selectNone,
                otherTreatment: selectOtherTreatment,
                other_treatment_specify: fromData.other_treatment_specify,
                medication: fromData.medication,
                medication_specify: fromData.medication_specify,
                epilepsy_text: fromData.epilepsy_text,
                history_of_presenting_complaint: fromData.history_of_presenting_complaint,
                past_medical_history: fromData.past_medical_history,
                past_Surgical_history: fromData.past_Surgical_history,
                past_cosmetic_treatment_history: fromData.past_cosmetic_treatment_history,
                illicit_drugs: selectDrugValue,
                illicit_drugs_specify: fromData.illicit_drugs,
                occupation: fromData.occupation,
                smoking: selectSmokingValue,
                smoking_specify: fromData.smoking_specify,
                mental_health_history: fromData.mental_health_history,
                type1: selectType1,
                type2: selectType2,
                type3: selectType3,
                type4: selectType4,
                type5: selectType5,
                type6: selectType6,
                treatment_session: fromData.treatment_session,
                pregnantAndBreastfeeding: fromData.pregnantAndBreastfeeding,
                dental_implants_text: fromData.dental_implants_text,
                organ_transplant_text: fromData.organ_transplant_text,
                at_home_skin_routine: fromData.at_home_skin_routine,
                ...questions
            }
        }
        await addPatientHistory(obj)
            .then((resp) => {
                if (!callFrom === "Add Patient Component") {
                    getPatientHistoryByPatientId();
                }
                // setOpenSelectTreatmentDialog(true);
                // if (callFrom === "undefined") {
                //     getPatientHistoryByPatientId()
                // } else {
                //     setOpenSelectTreatmentDialog(true);
                // }
                reset({
                    unwanted_concerns: "", other_concerns_text: "", allergies: "", skin_allergies: "", skin_issues_specify: "",
                    skincare_routine_specify: "", negative_reactions_specify: "", products_used_specify: "", other_treatment_specify: "",
                    medication: "", medication_specify: "", epilepsy_text: "", history_of_presenting_complaint: "", past_medical_history: "",
                    past_Surgical_history: "", past_cosmetic_treatment_history: "", illicit_drugs_specify: "", smoking_specify: "",
                    mental_health_history: "", treatment_session: "", pregnantAndBreastfeeding: "", dental_implants_text: "",
                    organ_transplant_text: "", occupation: "", at_home_skin_routine: ""
                })
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                setSelectDrugValue("");
                setSelectSmokingValue("");
                setSelectAcne(false)
                setSelectDryness(false)
                setSelectOilySkin(false)
                setSelectWrinkles(false)
                setSelectDarkSpots(false)
                setSelectRedness(false)
                setSelectSkinTone(false)
                setSelectUnwanted(false)
                setSelectNoConcern(false)
                setSelectLaser(false)
                setSelectHifu(false)
                setSelectHydratingFacial(false)
                setSelectAntiAgingFacial(false)
                setSelectChemicalPeel(false)
                setSelectMicrodermabrasion(false)
                setSelectFacialMassage(false)
                setSelectNone(false)
                setSelectOtherTreatment(false)
                setSelectOther(false)
                setSelectType1(false)
                setSelectType2(false)
                setSelectType3(false)
                setSelectType4(false)
                setSelectType5(false)
                setSelectType6(false)
                setOpenPatientNote({ action: false, note: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };
    const handleChangeSmoking = (value) => {
        setSelectSmokingValue(value)
    };
    const handleChangeDrug = (value) => {
        setSelectDrugValue(value)
    };

    const cancel = () => {
        reset({
            unwanted_concerns: "", other_concerns_text: "", allergies: "", skin_allergies: "", skin_issues_specify: "",
            skincare_routine_specify: "", negative_reactions_specify: "", products_used_specify: "", other_treatment_specify: "",
            medication: "", medication_specify: "", epilepsy_text: "", history_of_presenting_complaint: "", past_medical_history: "",
            past_Surgical_history: "", past_cosmetic_treatment_history: "", illicit_drugs_specify: "", smoking_specify: "",
            mental_health_history: "", treatment_session: "", pregnantAndBreastfeeding: "", dental_implants_text: "",
            organ_transplant_text: "", occupation: "", at_home_skin_routine: ""
        })
        setSelectDrugValue("");
        setSelectSmokingValue("");
        setSelectAcne(false)
        setSelectDryness(false)
        setSelectOilySkin(false)
        setSelectWrinkles(false)
        setSelectDarkSpots(false)
        setSelectRedness(false)
        setSelectSkinTone(false)
        setSelectUnwanted(false)
        setSelectNoConcern(false)
        setSelectLaser(false)
        setSelectHifu(false)
        setSelectHydratingFacial(false)
        setSelectAntiAgingFacial(false)
        setSelectChemicalPeel(false)
        setSelectMicrodermabrasion(false)
        setSelectFacialMassage(false)
        setSelectNone(false)
        setSelectOtherTreatment(false)
        setSelectOther(false)
        setSelectType1(false)
        setSelectType2(false)
        setSelectType3(false)
        setSelectType4(false)
        setSelectType5(false)
        setSelectType6(false)
        setOpenPatientNote({ action: false, note: null });
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="lg" open={openPatientNote.action} hideBackdrop >
                <material.DialogTitle>{openPatientNote.note !== null ? "View Patient Notes" : "Add Patient Notes"}</material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div className="mb-2">
                            <material.Typography variant='h6'>Patient’s Concerns</material.Typography>
                        </div>
                        <div>
                            <span>What are your main skin concerns? (Check all that apply)</span>
                            <ul>
                                <li>Acne
                                    <material.Checkbox onChange={onChangeAcne} checked={selectAcne} color='secondary' />
                                </li>
                                <li>Dryness
                                    <material.Checkbox onChange={onChangeDryness} checked={selectDryness} color='secondary' />
                                </li>
                                <li>Oily Skin
                                    <material.Checkbox onChange={onChangeOilySkin} checked={selectOilySkin} color='secondary' />
                                </li>
                                <li>Aging / Wrinkles
                                    <material.Checkbox onChange={onChangeWrinkles} checked={selectWrinkles} color='secondary' />
                                </li>
                                <li>Hyperpigmentation / Dark Spots
                                    <material.Checkbox onChange={onChangeDarkSpots} checked={selectDarkSpots} color='secondary' />
                                </li>
                                <li>Sensitivity / Redness
                                    <material.Checkbox onChange={onChangeRedness} checked={selectRedness} color='secondary' />
                                </li>
                                <li>Uneven Skin Tone
                                    <material.Checkbox onChange={onChangeSkinTone} checked={selectSkinTone} color='secondary' />
                                </li>
                                <li>Unwanted / Excessive hair (specify area)
                                    <material.Checkbox onChange={onChangeUnwanted} checked={selectUnwanted} color='secondary' />
                                </li>
                                {selectUnwanted ? (
                                    <material.TextField
                                        label="Specify Area"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        sx={{ mb: 1 }}
                                        size="small"
                                        multiline
                                        fullWidth
                                        {...register("unwanted_concerns")}
                                    />
                                ) : null}
                                <li>No concern
                                    <material.Checkbox onChange={onChangeNoConcern} checked={selectNoConcern} color='secondary' />
                                </li>
                                <li>Other
                                    <material.Checkbox onChange={onChangeOther} checked={selectOther} color='secondary' />
                                </li>
                                {selectOther ? (
                                    <material.TextField
                                        label="Specify"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        sx={{ mb: 1 }}
                                        multiline
                                        fullWidth
                                        {...register("other_concerns_text")}
                                    />
                                ) : null}
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                            <material.Typography>What are your cosmetic concerns?</material.Typography>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-6 mb-2 mt-2">
                            <material.TextField
                                label="Please Specify"
                                id="standard-error"
                                variant="standard"
                                type="text"
                                size="small"
                                multiline
                                fullWidth
                                {...register("history_of_presenting_complaint")}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                            <material.Typography>History of Current Concern</material.Typography>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-6 mb-2 mt-2">
                            <material.TextField
                                label="History of Current Concern"
                                id="standard-error"
                                variant="standard"
                                type="text"
                                size="small"
                                multiline
                                fullWidth
                                {...register("history_of_presenting_complaint")}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                            <material.Typography>Allergies</material.Typography>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-6 mb-2 mt-2">
                            <div className="row">
                                <div className="col-lg-12">
                                    Please disclose any allergies you have, including to foods, medications, and bee or wasp stings.
                                </div>
                                <div className="col-lg-12">
                                    <material.TextField
                                        label="Allergies"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        multiline
                                        fullWidth
                                        {...register("allergies")}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                            <material.Typography>Skin Allergies</material.Typography>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-6 mb-2 mt-2">
                            <div className="row">
                                <div className="col-lg-12">
                                    Have you experienced any skin allergies or conditions? Please specify if applicable.
                                </div>
                                <div className="col-lg-12">
                                    <material.TextField
                                        label="Skin Allergies"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        multiline
                                        fullWidth
                                        {...register("skin_allergies")}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-6 mb-2">
                            <material.Typography>Are you receiving treatment from a dermatologist or another medical professional for any skin issues?
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q1", event.target.value)}
                                        value={questions.aw_q1}
                                    >
                                        <material.FormControlLabel value="Yes" control={<material.Radio color="secondary" />} label="Yes" />
                                        <material.FormControlLabel value="No" control={<material.Radio color="secondary" />} label="No" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </material.Typography>
                            {questions.aw_q1 === "Yes" ? (
                                <material.TextField
                                    multiline
                                    label="Please Specify"
                                    id="standard-error"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    {...register("skin_issues_specify")}
                                />
                            ) : null}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-6 mb-2">
                            <material.Typography>Have you experienced any negative reactions to skincare products or treatments before?
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q2", event.target.value)}
                                        value={questions.aw_q2}
                                    >
                                        <material.FormControlLabel value="Yes" control={<material.Radio color="secondary" />} label="Yes" />
                                        <material.FormControlLabel value="No" control={<material.Radio color="secondary" />} label="No" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </material.Typography>
                            {questions.aw_q2 === "Yes" ? (
                                <material.TextField
                                    multiline
                                    label="Please Specify"
                                    id="standard-error"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    {...register("negative_reactions_specify")}
                                />
                            ) : null}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-6 mb-2">
                            <material.Typography>What is your daily skincare routine like? (Please describe products and steps):
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q3", event.target.value)}
                                        value={questions.aw_q3}
                                    >
                                        <material.FormControlLabel value="Yes" control={<material.Radio color="secondary" />} label="Yes" />
                                        <material.FormControlLabel value="No" control={<material.Radio color="secondary" />} label="No" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </material.Typography>
                            {questions.aw_q3 === "Yes" ? (
                                <material.TextField
                                    multiline
                                    label="Please Specify"
                                    id="standard-error"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    {...register("skincare_routine_specify")}
                                />
                            ) : null}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-6 mb-2">
                            <material.Typography>Do you have any specific preferences for the products used during your treatment? (e.g., organic, vegan, fragrance-free) :
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q4", event.target.value)}
                                        value={questions.aw_q4}
                                    >
                                        <material.FormControlLabel value="Yes" control={<material.Radio color="secondary" />} label="Yes" />
                                        <material.FormControlLabel value="No" control={<material.Radio color="secondary" />} label="No" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </material.Typography>
                            {questions.aw_q4 === "Yes" ? (
                                <material.TextField
                                    multiline
                                    label="Please Specify"
                                    id="standard-error"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    {...register("products_used_specify")}
                                />
                            ) : null}
                        </div>
                        <div>
                            <span>What type of facial treatments have you had in the past? (Check all that apply)</span>
                            <ul>
                                <li>Laser / IPL
                                    <material.Checkbox onChange={onChangeLaser} checked={selectLaser} color='secondary' />
                                </li>
                                <li>HIFU
                                    <material.Checkbox onChange={onChangeHifu} checked={selectHifu} color='secondary' />
                                </li>
                                <li>Hydrating Facial
                                    <material.Checkbox onChange={onChangeHydratingFacial} checked={selectHydratingFacial} color='secondary' />
                                </li>
                                <li>Anti-Aging Facial
                                    <material.Checkbox onChange={onChangeAntiAgingFacial} checked={selectAntiAgingFacial} color='secondary' />
                                </li>
                                <li>Chemical Peel
                                    <material.Checkbox onChange={onChangeChemicalPeel} checked={selectChemicalPeel} color='secondary' />
                                </li>
                                <li>Microdermabrasion
                                    <material.Checkbox onChange={onChangeMicrodermabrasion} checked={selectMicrodermabrasion} color='secondary' />
                                </li>
                                <li>Facial Massage
                                    <material.Checkbox onChange={onChangeFacialMassage} checked={selectFacialMassage} color='secondary' />
                                </li>
                                <li>None
                                    <material.Checkbox onChange={onChangeNone} checked={selectNone} color='secondary' />
                                </li>
                                <li>Other
                                    <material.Checkbox onChange={onChangeOtherTreatment} checked={selectOtherTreatment} color='secondary' />
                                </li>
                                {selectOtherTreatment ? (
                                    <material.TextField
                                        label="Specify"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        sx={{ mb: 1 }}
                                        multiline
                                        fullWidth
                                        {...register("other_treatment_specify")}
                                    />
                                ) : null}
                            </ul>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 mb-2 mt-2">
                            <div className="row">
                                <div className="col-lg-12">
                                    Medication (Please list all medications you regularly or occasionally take, including supplements and multivitamins.):
                                </div>
                                <div className="col-lg-12">
                                    <material.TextField
                                        label="Medication"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        multiline
                                        fullWidth
                                        {...register("medication")}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                            <material.Typography>Are you using any doctor-prescribed medications for your skin, either topical or oral (e.g., Retin-A, Accutane)?
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q5", event.target.value)}
                                        value={questions.aw_q5}
                                    >
                                        <material.FormControlLabel value="Yes" control={<material.Radio color="secondary" />} label="Yes" />
                                        <material.FormControlLabel value="No" control={<material.Radio color="secondary" />} label="No" />
                                    </material.RadioGroup>
                                </material.FormControl>
                                {questions.aw_q5 === "Yes" ? (
                                    <material.TextField
                                        multiline
                                        label="Please Specify"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        {...register("medication_specify")}
                                    />
                                ) : null}
                            </material.Typography>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                            <material.Typography>Have you ever suffered from claustrophobia?
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q6", event.target.value)}
                                        value={questions.aw_q6}
                                    >
                                        <material.FormControlLabel value="Yes" control={<material.Radio color="secondary" />} label="Yes" />
                                        <material.FormControlLabel value="No" control={<material.Radio color="secondary" />} label="No" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </material.Typography>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                            <material.Typography>Do you have Epilepsy?
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q7", event.target.value)}
                                        value={questions.aw_q7}
                                    >
                                        <material.FormControlLabel value="Yes" control={<material.Radio color="secondary" />} label="Yes" />
                                        <material.FormControlLabel value="No" control={<material.Radio color="secondary" />} label="No" />
                                    </material.RadioGroup>
                                </material.FormControl>
                                {questions.aw_q7 === "Yes" ? (
                                    <material.TextField
                                        multiline
                                        label="Please Specify"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        {...register("epilepsy_text")}
                                    />
                                ) : null}
                            </material.Typography>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                            <material.Typography>Past Medical History</material.Typography>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-6 mt-2">
                            <material.TextField
                                multiline
                                label="Past Medical History"
                                id="standard-error"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                {...register("past_medical_history")}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                            <material.Typography>Past Surgical History</material.Typography>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-6 mt-2">
                            <material.TextField
                                multiline
                                label="Past Surgical History"
                                id="standard-error"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                {...register("past_Surgical_history")}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                            <material.Typography>Past Cosmetic Treatment History</material.Typography>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-6 mt-2">
                            <material.TextField
                                multiline
                                label="Past Cosmetic Treatment History"
                                id="standard-error"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                {...register("past_cosmetic_treatment_history")}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                            <material.Typography>Social History:</material.Typography>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-6 mt-2"></div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                            <material.Typography sx={{ ml: 2 }}>Occupation</material.Typography>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-6 mt-2">
                            <material.TextField
                                multiline
                                label="Occupation"
                                id="standard-error"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                {...register("occupation")}
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                            <material.Typography sx={{ ml: 2 }}>Smoking</material.Typography>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-6 mt-2">
                            <material.FormControl>
                                <material.RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={event => handleChangeSmoking(event.target.value)}
                                    value={selectSmokingValue}
                                >
                                    <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                    <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                </material.RadioGroup>
                            </material.FormControl>
                            {selectSmokingValue === "YES" ? (
                                <div className="mt-2">
                                    <material.TextField
                                        multiline
                                        label="Please Specify"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        {...register("smoking_specify")}
                                    />
                                </div>
                            ) : null}
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                            <material.Typography sx={{ ml: 2 }}>Illicit Drugs</material.Typography>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-6 mt-2">
                            <material.FormControl>
                                <material.RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={event => handleChangeDrug(event.target.value)}
                                    value={selectDrugValue}
                                >
                                    <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                    <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                </material.RadioGroup>
                            </material.FormControl>
                            {selectDrugValue === "YES" ? (
                                <div className="mt-2">
                                    <material.TextField
                                        multiline
                                        label="Please Specify"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        {...register("illicit_drugs_specify")}
                                    />
                                </div>
                            ) : null}
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
                            <material.Typography>Mental Health History</material.Typography>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-6 mt-2">
                            <material.TextField
                                multiline
                                label="Mental Health History"
                                id="standard-error"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                {...register("mental_health_history")}
                            />
                        </div>
                        <div className="mb-2 mt-2">
                            <material.Typography variant='h6'>Skin Type (Fitzpatrick Scale):</material.Typography>
                        </div>
                        <div>
                            <span className='fw-bold mb-2'>Please choose the description that best matches your skin's characteristics. This helps us tailor treatments to your specific needs.
                                (Only one to choose)
                            </span>
                            <ul>
                                <li>Type I: Very fair skin, often with red or blond hair and blue eyes. Burns easily and rarely tans.
                                    <material.Checkbox onChange={onChangeType1} checked={selectType1} color='secondary' />
                                </li>
                                <li>Type II: Fair skin, possibly with blond or red hair and blue, green, or hazel eyes. Burns easily and tans minimally.
                                    <material.Checkbox onChange={onChangeType2} checked={selectType2} color='secondary' />
                                </li>
                                <li>Type III: Light to intermediate skin tone, with any eye or hair color. Burns moderately and tans gradually to a light brown.
                                    <material.Checkbox onChange={onChangeType3} checked={selectType3} color='secondary' />
                                </li>
                                <li>Type IV: Olive or light brown skin, typically with dark brown eyes and hair. Burns minimally and tans easily to a moderate brown.
                                    <material.Checkbox onChange={onChangeType4} checked={selectType4} color='secondary' />
                                </li>
                                <li>Type V: Brown skin, usually with dark brown eyes and hair. Rarely burns and tans very easily.
                                    <material.Checkbox onChange={onChangeType5} checked={selectType5} color='secondary' />
                                </li>
                                <li>Type VI: Dark brown to black skin, with dark brown or black eyes and hair. Very rarely burns and tans very easily.
                                    <material.Checkbox onChange={onChangeType6} checked={selectType6} color='secondary' />
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <material.Typography>Do you have a specific goal or desired outcome for this consultation/treatment session? (For example, relaxation, improved skin texture, reduced redness)
                            </material.Typography>
                            <material.TextField
                                multiline
                                label="Please Specify"
                                id="standard-error"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                {...register("treatment_session")}
                            />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <material.Typography>Are you Pregnant, trying to conceive or Breastfeeding:
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q8", event.target.value)}
                                        value={questions.aw_q8}
                                    >
                                        <material.FormControlLabel value="Yes" control={<material.Radio color="secondary" />} label="Yes" />
                                        <material.FormControlLabel value="No" control={<material.Radio color="secondary" />} label="No" />
                                    </material.RadioGroup>
                                </material.FormControl>
                                {questions.aw_q8 === "Yes" ? (
                                    <material.TextField
                                        multiline
                                        label="Please Specify"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        {...register("pregnantAndBreastfeeding")}
                                    />
                                ) : null}
                            </material.Typography>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <material.Typography>Have you had any implant surgeries, such as breast, hip, or dental implants? If yes, please specify the type and date of the implant procedures.
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q9", event.target.value)}
                                        value={questions.aw_q9}
                                    >
                                        <material.FormControlLabel value="Yes" control={<material.Radio color="secondary" />} label="Yes" />
                                        <material.FormControlLabel value="No" control={<material.Radio color="secondary" />} label="No" />
                                    </material.RadioGroup>
                                </material.FormControl>
                                {questions.aw_q9 === "Yes" ? (
                                    <material.TextField
                                        multiline
                                        label="Please Specify"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        {...register("dental_implants_text")}
                                    />
                                ) : null}
                            </material.Typography>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <material.Typography>Have you ever received an organ transplant? If yes, please specify the type of organ and the date of the transplant.
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q10", event.target.value)}
                                        value={questions.aw_q10}
                                    >
                                        <material.FormControlLabel value="Yes" control={<material.Radio color="secondary" />} label="Yes" />
                                        <material.FormControlLabel value="No" control={<material.Radio color="secondary" />} label="No" />
                                    </material.RadioGroup>
                                </material.FormControl>
                                {questions.aw_q10 === "Yes" ? (
                                    <material.TextField
                                        multiline
                                        label="Please Specify"
                                        id="standard-error"
                                        variant="standard"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        {...register("organ_transplant_text")}
                                    />
                                ) : null}
                            </material.Typography>
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <div className='mt-3'>
                        <span className='float-end py-3'>
                            {/* {patientData?.history?.length ? ( */}
                            <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" className='me-2' color='error' onClick={cancel}>
                                Cancel
                            </material.Button>
                            {/* ) : null} */}
                            <material.Button variant="contained" hidden={openPatientNote.note !== null} sx={{ textTransform: "none" }} size="medium" onClick={handleSubmit(addNote)} disabled={!isValid}>
                                Save
                            </material.Button>
                        </span>
                    </div>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <SelectTreatmentTypeDialog
                patientData={openPatientNote.patientData ? openPatientNote.patientData : patientData}
                openSelectTreatmentDialog={openSelectTreatmentDialog}
                setOpenSelectTreatmentDialog={setOpenSelectTreatmentDialog}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(AddPatientNote);