import React, { useEffect, useState } from 'react';
import { material } from '../../../../library/material';
import { getOtherInHandStocksByClinic } from '../../../../services/OtherStockService';
import Snackbar from '../../../toastrmessage/Snackbar';
import { Controller, useForm } from 'react-hook-form';
import AddOtherProduct from './AddOtherProduct';
import { savePlanDetails } from '../../../../services/TreatmentPlanService';


function AddHomeTreatmentPlan(props) {

    const { clinicDetails, openAddHomeTreatment, setOpenAddHomeTreatment, getProposedTreatmentPlan } = props;
    const { register, handleSubmit, resetField, control, watch, setValue, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [productNameOptions, setProductNameOptions] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [openAddOtherProduct, setOpenAddOtherProduct] = useState(false);
    const days = [...Array(31).keys()].map((day) => (day + 1).toString());
    const selectDateFormat = ['Day', 'Week', "Month", 'Year'];
    const routine = ['Morning (AM Routine)', 'Night (PM Routine)', 'Morning & Night (AM & PM)', 'Morning - Under Sunscreen', 'Night - After Cleansing', 'Weekly (1-2 times per week)', 'As Needed (Spot Treatment or Specific Use)'];

    useEffect(() => {
        if (openAddHomeTreatment.action) {
            getProductName()
        }
    }, [openAddHomeTreatment.action === true]);

    const getProductName = () => {
        getOtherInHandStocksByClinic(clinicDetails.clinicId)
            .then((res) => {
                const productName = res.data?.data?.map((ele) => ele.product);
                setProductNameOptions(productName);
            }).catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            });
    };

    const getOtherProduct = (data) => {
        setProductNameOptions([...productNameOptions, ...data])
    };

    const saveTreatment = (data) => {
        const formData = { ...data, recordType: "At_Home" };
        const planId = openAddHomeTreatment.planData.id;
        const array = [];
        for (const element of formData.productName) {
            let temp = {}
            temp.treatmentOrProductName = element;
            temp.rational = formData.rational;
            temp.recordType = formData.recordType;
            temp.treatmentAreaOrProductInstructions = formData[element + "_instruction"];
            temp.slNo = formData[element + "_slNo"];
            temp.proposedPlanId = planId;
            temp.sessionNumber = 1;
            temp.totalNumberOfSessionsRequired = 1;
            temp.days = formData[element + "_days"] !== "" ? formData[element + "_days"] : 0;
            temp.dateFormat = formData[element + "_dateFormat"];
            temp.routine = formData[element + "_routine"];
            temp.totalCost = formData[element + "_totalCost"];
            temp.comment = formData[element + "_comment"];
            array.push(temp);
        }
        savePlanDetails(array)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data.messages,
                });
                getProposedTreatmentPlan(planId);
                reset();
                reset({ rational: "" });
                setOpenAddHomeTreatment({ action: false, patientData: null, planData: null });
                setSelectedProduct([]);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const cancel = () => {
        reset();
        reset({ rational: "" });
        setOpenAddHomeTreatment({ action: false, patientData: null, planData: null });
        setSelectedProduct([]);
    };

    const addOtherProduct = () => {
        setOpenAddOtherProduct(true);
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="xl" open={openAddHomeTreatment.action} hideBackdrop>
                <material.DialogTitle>At Home</material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                        <span>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} size="small" onClick={addOtherProduct} startIcon={<material.AddIcon />}>Add Other Product</material.Button>
                        </span>
                        <div className="col-6">
                            <Controller
                                name="productName"
                                control={control}
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <material.Autocomplete
                                        multiple
                                        disableCloseOnSelect
                                        {...field}
                                        value={field.value || []}
                                        options={productNameOptions}
                                        renderInput={(params) => (
                                            <material.TextField
                                                required
                                                margin="dense"
                                                {...params}
                                                variant="standard"
                                                label="Product Name"
                                                fullWidth
                                            />
                                        )}
                                        onChange={(_, data) => {
                                            field.onChange(data);
                                            setSelectedProduct(data)
                                            data.forEach(name => {
                                                setValue(`${name}_slNo`, "");
                                                setValue(`${name}_instruction`, "");
                                                setValue(`${name}_routine`, "");
                                                setValue(`${name}_days`, "");
                                                setValue(`${name}_dateFormat`, "");
                                            })
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-6">
                            <material.TextField
                                {...register("rational")}
                                margin="dense"
                                id="name"
                                label="Rational"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </div>
                    </div>
                    {selectedProduct.length ? selectedProduct.map((product, ind) => (
                        <div className="row border border-2 p-2 my-2 rounded-2 mt-2" key={ind} style={{ boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" }}>
                            <div className="col-lg-2 col-md-4">
                                <material.TextField
                                    {...register(`${product}_slNo`, { required: true })}
                                    required
                                    margin="dense"
                                    id="name"
                                    label="Sequence Number"
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                            <div className="col-lg-2 col-md-4">
                                <material.TextField
                                    {...register(`${product}_productName`)}
                                    required
                                    margin="dense"
                                    id="name"
                                    label="Product Name"
                                    type="text"
                                    fullWidth
                                    defaultValue={product}
                                    InputProps={{ readOnly: true }}
                                    variant="standard"
                                />
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <material.TextField
                                    {...register(`${product}_instruction`)}
                                    margin="dense"
                                    id="name"
                                    label="Instruction"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <Controller
                                    name={`${product}_routine`}
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <material.Autocomplete
                                            {...field}
                                            options={routine}
                                            renderInput={(params) => (
                                                <material.TextField
                                                    margin="dense"
                                                    {...params}
                                                    variant="standard"
                                                    label="Routine"
                                                    fullWidth
                                                />
                                            )}
                                            onChange={(event, data) => {
                                                field.onChange(data);
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <material.TextField
                                    {...register(`${product}_totalCost`, { required: true, pattern: /^[0-9]+(?:\.[0-9]{1,4})?$/ })}
                                    required
                                    margin="dense"
                                    id="name"
                                    label="Total Cost"
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                />
                            </div>
                            <div className="col-lg-8 col-md-6">
                                <material.TextField
                                    {...register(`${product}_comment`)}
                                    margin="dense"
                                    id="name"
                                    label="Comment"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    multiline
                                />
                            </div>
                            <div className="col-12">
                                <div className="row border border-2 p-2 my-2 rounded-2 mt-2" key={ind} style={{ boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" }}>
                                    <span className='fw-bold'>Frequency</span>
                                    <div className="col-lg-2 col-md-4">
                                        <Controller
                                            name={`${product}_days`}
                                            control={control}
                                            defaultValue=""
                                            // rules={{ required: true }}
                                            render={({ field }) => (
                                                <material.Autocomplete
                                                    {...field}
                                                    options={days}
                                                    renderInput={(params) => (
                                                        <material.TextField
                                                            required
                                                            margin="dense"
                                                            {...params}
                                                            variant="standard"
                                                            // label="Days"
                                                            fullWidth
                                                        />
                                                    )}
                                                    onChange={(event, data) => {
                                                        field.onChange(data);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-4">
                                        <Controller
                                            name={`${product}_dateFormat`}
                                            control={control}
                                            defaultValue=""
                                            // rules={{ required: true }}
                                            render={({ field }) => (
                                                <material.Autocomplete
                                                    {...field}
                                                    options={selectDateFormat}
                                                    renderInput={(params) => (
                                                        <material.TextField
                                                            required
                                                            margin="dense"
                                                            {...params}
                                                            variant="standard"
                                                            // label="Date Format"
                                                            fullWidth
                                                        />
                                                    )}
                                                    onChange={(event, data) => {
                                                        field.onChange(data);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )) : ""}
                </material.DialogContent>
                <material.DialogActions sx={{ m: 2 }}>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" color="error" onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" color="primary" onClick={handleSubmit(saveTreatment)} disabled={!isValid} startIcon={<material.DoneIcon />}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <AddOtherProduct
                openAddOtherProduct={openAddOtherProduct}
                setOpenAddOtherProduct={setOpenAddOtherProduct}
                getOtherProduct={getOtherProduct}
            />
        </div>
    );
}

export default AddHomeTreatmentPlan;