import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { saveProtocolDocument } from '../../../services/on-boarding-service/OnBoardingService';
import { material } from '../../../library/material';
import Snackbar from '../../toastrmessage/Snackbar';

function UploadDocument(props) {
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm({
        mode: "onTouched",
    });
    const { openUploadProtocolDocument, setOPenUploadProtocolDocument, getProtocolDocumentList } = props;
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/jpeg",
        "image/png",
        "image/svg+xml",
    ];

    const validateFile = (files) => {
        if (files && files.length > 0) {
            for (let file of files) {
                if (!allowedTypes.includes(file.type)) {
                    return `Invalid file type: ${file.type}. Only PDF, DOC, DOCX, JPEG, PNG, and SVG files are allowed.`;
                }
            }
        }
        return true;
    };

    const handleClose = () => {
        setOPenUploadProtocolDocument(false);
        reset({ file: "" })
    };

    const uploadDocument = (data) => {
        let formData = new FormData();
        for (const file of data.file) {
            formData.append('files', file);
        }
        saveProtocolDocument(formData)
            .then((resp) => {
                getProtocolDocumentList();
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                setOPenUploadProtocolDocument(false);
                reset({ file: "" })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openUploadProtocolDocument} fullWidth maxWidth="sm">
                <material.DialogTitle>Upload Protocol Document
                    <span className='float-end'>
                        <material.IconButton title='Close' onClick={handleClose}>
                            <material.CloseIcon color='error' />
                        </material.IconButton>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    <span>
                        <material.InputLabel>Chose File</material.InputLabel>
                        <input
                            type="file"
                            multiple
                            {...register("file", {
                                required: "This field is required",
                                validate: validateFile,
                            })}
                        />
                        {errors.file && (
                            <p style={{ color: "red" }}>{errors.file.message}</p>
                        )}
                    </span>
                </material.DialogContent>
                <material.DialogActions sx={{ m: 2 }}>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(uploadDocument)} startIcon={<material.UploadIcon />}>
                        Upload
                    </material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default UploadDocument;