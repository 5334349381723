import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { material } from '../../library/material'
import { getClinicList, getOrgList, inviteUser, userRegisterByAdmin, userRegisterBySuperAdmin } from '../../services/UserManagementService';
import Snackbar from '../toastrmessage/Snackbar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { dateAndTimeFormat, dateFormat, changeDateFormate } from '../../date-and-time-format/DateAndTimeFormat';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { getUserRolePermission } from '../../services/manage-roles-service/ManageRolesService';

let organisationDetails;
let organisationId;
let clinicDetails;
let filteredClinicId;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const systemAdminPermission = ["AEST_SYST_ADMIN"];

function InviteUser(props) {
    const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const { openInvitePopup, setOpenInvitePopup, userData, getAllUserList, setisLoading, clinicData, orgId, clinicId, openUserTypeCheckDialog } = props;

    const userDetails = userData.authReducer.data;
    const clinicAllDetails = clinicData.clinicReducer.data;
    const theme = useTheme();
    const [role, setRole] = useState("");
    const [multipleRole, setMultipleRole] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [organisation, setOrganisation] = useState([]);
    const [clinicName, setClinicName] = useState([]);
    const [dateOfBirth, setDateOfBirth] = useState(dayjs(new Date()));
    const [date, setDate] = useState(dayjs(new Date()));
    const [selectValue, setSelectValue] = useState('');
    const [endDate, setEndDate] = useState(dayjs(new Date()));
    const [startDate, setStartDate] = useState(dayjs(new Date()));
    const [userType, setUserType] = useState("");
    const [getRolePermissionData, setGetRolePermission] = useState([]);
    const [roleId, setRoleId] = useState(null);

    useEffect(() => {
        if (openInvitePopup.open) {
            if (userDetails.role.permissions.some(item => systemAdminPermission.includes(item.permissionName))) {
                getAllOrg();
            } else {
                getAllClinic();
            }
            getUserRolePermissionData();
        }
    }, [openInvitePopup.open]);

    const getUserRolePermissionData = () => {
        // getUserRolePermission(userDetails.orgId)
        const payload = {
            orgId: openUserTypeCheckDialog.data === "Clinic Level" ? orgId : userDetails.orgId,
            clinicId: openUserTypeCheckDialog.data === "Clinic Level" ? clinicId : clinicAllDetails.clinicId
        }
        getUserRolePermission(payload)
            .then((resp) => {
                setGetRolePermission(resp.data.data);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getAllOrg = async () => {
        await getOrgList()
            .then((resp) => {
                organisationDetails = resp.data.data;
                let orgName = resp.data.data.map((ele) => ele.orgName);
                setOrganisation(orgName)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getAllClinic = async () => {
        await getClinicList()
            .then((resp) => {
                clinicDetails = resp.data.data;
                let clinicName = resp.data?.data?.map((ele) => ele.clinicName)
                setClinicName(clinicName)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const selectOrg = (e, value) => {
        let orgId = organisationDetails.filter((ele) => ele.orgName === value).map((element) => element.orgId)
        organisationId = orgId
    };

    const handleClose = () => {
        setOpenInvitePopup({ open: false, action: "" });
        reset({ email: "", firstName: "", lastName: "", password: "", AHPRA_Reg_ID: "", address: "", phone: "" });
        setRole("");
        setMultipleRole([]);
        setDateOfBirth(dayjs(new Date()));
        setDate(dayjs(new Date()));
        setEndDate(dayjs(new Date()));
        setUserType("");
    };

    const roleChange = (event) => {
        setRole(event.target.value)
    };

    const selectMultipleRole = (event) => {
        setMultipleRole(event.target.value);
        const roleId = getRolePermissionData.filter((ele) => event.target.value.includes(ele.roleName)).map((res) => res.id)[0];
        setRoleId(roleId);
    };

    const selectClinic = (e, value) => {
        let id = clinicDetails.filter((ele) => ele.clinicName === value).map((res) => res.clinicId)
        filteredClinicId = id
    };

    const selectMultipleClinic = (e, value) => {
        let id = clinicDetails.filter((ele) => value.includes(ele.clinicName)).map((res) => res.clinicId)
        filteredClinicId = id
    };

    const handleChange = (event) => {
        setSelectValue(event.target.value)
    };

    const inviteUserByMail = async (fromData) => {
        await inviteUser(fromData).then((res) => {
            reset({ email: "" });
            setUserType("");
            setRole("")
            setMultipleRole([])
            setOpenSnackBar({
                "action": true,
                "type": "success",
                "message": "Invite send successfully"
            })
            setOpenInvitePopup({ open: false, action: "" });
        }).catch((error) => {
            // console.log()
            setOpenSnackBar({
                "action": true,
                "type": "error",
                "message": error.message
            })
        })
    };

    const addUserByOrg = async (fromData) => {
        if (userDetails.role.permissions.some(item => systemAdminPermission.includes(item.permissionName))) {
            if (userType === "ADMIN") {
                let obj = {
                    "firstName": fromData.firstName,
                    "lastName": fromData.lastName,
                    "dateOfBirth": dateFormat(dateOfBirth.$d),
                    "roleId": roleId,
                    "email": fromData.email,
                    "orgId": openUserTypeCheckDialog.data === "Clinic Level" ? orgId : organisationId[0],
                    "password": fromData.password,
                    "clinicId": openUserTypeCheckDialog.data === "Clinic Level" ? [clinicId] : filteredClinicId,
                }
                await userRegisterBySuperAdmin(obj)
                    .then((resp) => {
                        setOpenSnackBar({
                            "action": true,
                            "type": "success",
                            "message": resp.data.messages
                        })
                        setOpenInvitePopup({ open: false, action: "" });
                        reset({ email: "", firstName: "", lastName: "", password: "", AHPRA_Reg_ID: "", address: "", phone: "" });
                        setRole("");
                        setMultipleRole([]);
                        setUserType("");
                        setDateOfBirth(dayjs(new Date()));
                        setDate(dayjs(new Date()));
                        setEndDate(dayjs(new Date()));
                        setisLoading(true);
                        setUserType("");
                        const payload = {
                            clinicId: openUserTypeCheckDialog.data === "Clinic Level" ? clinicId : clinicDetails.clinicId,
                            orgId: openUserTypeCheckDialog.data === "Clinic Level" ? orgId : userDetails.orgId
                        }
                        getAllUserList(payload);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            setOpenSnackBar({
                                "action": true,
                                "type": "error",
                                "message": error.response.data.data.info,
                            })
                        } else {
                            setOpenSnackBar({
                                "action": true,
                                "type": "error",
                                "message": error.response.data.data,
                            })
                        }
                    })
            } else if (userType === "PRESCRIBER") {
                let obj = {
                    "firstName": fromData.firstName,
                    "lastName": fromData.lastName,
                    "dateOfBirth": dateFormat(dateOfBirth.$d),
                    "roleId": roleId,
                    "email": fromData.email,
                    "gender": selectValue,
                    "AHPRA_Reg_ID": fromData.AHPRA_Reg_ID,
                    "address": fromData.address,
                    "startDate": dateFormat(startDate.$d),
                    "endDate": dateFormat(endDate.$d),
                    "timeStamp": dateAndTimeFormat(date.$d),
                    "phone": fromData.phone,
                    "password": fromData.password
                }
                await userRegisterBySuperAdmin(obj)
                    .then((resp) => {
                        setOpenSnackBar({
                            "action": true,
                            "type": "success",
                            "message": resp.data.messages
                        })
                        setOpenInvitePopup({ open: false, action: "" });
                        reset({ email: "", firstName: "", lastName: "", password: "", AHPRA_Reg_ID: "", address: "", phone: "" });
                        setMultipleRole([]);
                        setUserType("");
                        setRole("");
                        setDateOfBirth(dayjs(new Date()));
                        setDate(dayjs(new Date()));
                        setEndDate(dayjs(new Date()));
                        setisLoading(true);
                        setUserType("");
                        getAllUserList();
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            setOpenSnackBar({
                                "action": true,
                                "type": "error",
                                "message": error.response.data.data.info,
                            })
                        } else {
                            setOpenSnackBar({
                                "action": true,
                                "type": "error",
                                "message": error.response.data.data,
                            })
                        }
                    })
            } else if (userType === "SUPPORT") {
                let obj = {
                    "firstName": fromData.firstName,
                    "lastName": fromData.lastName,
                    "dateOfBirth": dateFormat(dateOfBirth.$d),
                    "roleId": roleId,
                    "email": fromData.email,
                    "phone": fromData.phone,
                    "password": fromData.password
                }
                await userRegisterBySuperAdmin(obj)
                    .then((resp) => {
                        setOpenSnackBar({
                            "action": true,
                            "type": "success",
                            "message": resp.data.messages
                        })
                        setOpenInvitePopup({ open: false, action: "" });
                        reset({ email: "", firstName: "", lastName: "", password: "", AHPRA_Reg_ID: "", address: "", phone: "" });
                        setMultipleRole([]);
                        setUserType("");
                        setRole("");
                        setDateOfBirth(dayjs(new Date()));
                        setDate(dayjs(new Date()));
                        setEndDate(dayjs(new Date()));
                        setisLoading(true);
                        setUserType("");
                        getAllUserList();
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            setOpenSnackBar({
                                "action": true,
                                "type": "error",
                                "message": error.response.data.data.info,
                            })
                        } else {
                            setOpenSnackBar({
                                "action": true,
                                "type": "error",
                                "message": error.response.data.data,
                            })
                        }
                    })
            }
        } else {
            let obj = {
                "firstName": fromData.firstName,
                "lastName": fromData.lastName,
                "dateOfBirth": dateFormat(dateOfBirth.$d),
                "roleId": roleId,
                "email": fromData.email,
                "orgId": openUserTypeCheckDialog.data === "Clinic Level" ? orgId : userDetails.orgId,
                "clinicId": [clinicAllDetails.clinicId],
                "password": fromData.password
            }
            await userRegisterByAdmin(obj)
                .then((res) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": res.data.messages
                    })
                    setMultipleRole([]);
                    setUserType("");
                    setRole("");
                    reset({ email: "", firstName: "", lastName: "", password: "", AHPRA_Reg_ID: "", address: "", phone: "" });
                    setOpenInvitePopup({ open: false, action: "" });
                    setDateOfBirth(dayjs(new Date()));
                    setDate(dayjs(new Date()));
                    setEndDate(dayjs(new Date()));
                    setisLoading(true);
                    setUserType("");
                    const payload = {
                        clinicId: openUserTypeCheckDialog.data === "Clinic Level" ? clinicId : clinicAllDetails.clinicId,
                        orgId: openUserTypeCheckDialog.data === "Clinic Level" ? orgId : userDetails.orgId
                    }
                    getAllUserList(payload);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.data.info,
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.data,
                        })
                    }
                })
        }

    };

    const selectUserType = (event) => {
        setUserType(event.target.value)
    };


    return (
        <div>
            <form >
                <material.Dialog maxWidth="md" fullWidth open={openInvitePopup.open} hideBackdrop >
                    {openInvitePopup.action === "add-user" ? (
                        <>
                            <material.DialogTitle>Add User For Organization</material.DialogTitle>
                            <material.DialogContent sx={{ height: "50vh" }}>
                                <div>
                                    <material.TextField
                                        error={errors.firstName?.type === "required"}
                                        {...register("firstName", { required: true, minLength: 3 })}
                                        required
                                        margin="dense"
                                        id="name"
                                        label="First Name"
                                        type="email"
                                        fullWidth
                                        variant="standard"
                                    />
                                    <p className='form-text text-danger'>{errors.firstName?.type === "required" && 'This field is required'}</p>
                                    {(errors?.firstName?.type === "minLength") && (
                                        <p className='text-danger'>This field contain minimum 3 digits</p>
                                    )}
                                </div>
                                <div>
                                    <material.TextField
                                        error={errors.lastName?.type === "required"}
                                        {...register("lastName", { required: true, minLength: 3 })}
                                        required
                                        margin="dense"
                                        id="name"
                                        label="Last Name"
                                        type="email"
                                        fullWidth
                                        variant="standard"
                                    />
                                    <p className='form-text text-danger'>{errors.lastName?.type === "required" && 'This field is required'}</p>
                                    {(errors?.lastName?.type === "minLength") && (
                                        <p className='text-danger'>This field contain minimum 3 digits</p>
                                    )}
                                </div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <material.DesktopDatePicker
                                        format='DD-MM-YYYY'
                                        label="Date of Birth *"
                                        value={dateOfBirth}
                                        onChange={(newValue) => {
                                            setDateOfBirth(newValue)
                                        }}
                                        slotProps={{ textField: { variant: 'standard' } }}
                                        sx={{ width: "100%", marginTop: { xs: 3, sm: 3, md: 3 } }}
                                    />
                                </LocalizationProvider>
                                <material.TextField
                                    {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                                    required
                                    margin="dense"
                                    id="name"
                                    label="Email Address"
                                    type="email"
                                    fullWidth
                                    variant="standard"
                                />
                                <p className='form-text text-danger'>{errors.email?.type === "required" && 'This field is required'}</p>
                                {(errors?.email?.type === "pattern") && (
                                    <p className='text-danger'>This is not a valid Email</p>
                                )}
                                {!userDetails.role.permissions.some(item => systemAdminPermission.includes(item.permissionName)) ? (
                                    <material.FormControl variant="standard" fullWidth className='mt-2' required>
                                        <material.InputLabel id="demo-simple-select-label">User Type</material.InputLabel>
                                        <material.Select
                                            {...register("userType", { required: true, })}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={userType}
                                            onChange={selectUserType}
                                        >
                                            <material.MenuItem value="INJECTOR">INJECTOR</material.MenuItem>
                                            <material.MenuItem value="OTHER">OTHER</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                ) : (
                                    <material.FormControl variant="standard" fullWidth className='mt-2' required>
                                        <material.InputLabel id="demo-simple-select-label">User Type</material.InputLabel>
                                        <material.Select
                                            {...register("userType", { required: true, })}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={userType}
                                            onChange={selectUserType}
                                        >
                                            <material.MenuItem value="ADMIN">ADMIN</material.MenuItem>
                                            <material.MenuItem value="PRESCRIBER">PRESCRIBER</material.MenuItem>
                                            <material.MenuItem value="SUPPORT">SUPPORT</material.MenuItem>
                                        </material.Select>
                                    </material.FormControl>
                                )}
                                <material.FormControl variant="standard" fullWidth className='mt-2' required>
                                    <material.InputLabel id="demo-simple-select-label">Role</material.InputLabel>
                                    <material.Select
                                        {...register("role", { required: true, })}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={multipleRole}
                                        onChange={selectMultipleRole}
                                    >
                                        {getRolePermissionData?.map((role, i) => (
                                            <material.MenuItem key={i} value={role.roleName}>{role.roleName}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                                {userDetails.role.permissions.some(item => systemAdminPermission.includes(item.permissionName)) ? (
                                    <>
                                        {userType == "ADMIN" && openUserTypeCheckDialog.data === "Platform Level" ? (
                                            <material.Autocomplete
                                                fullWidth
                                                id="orgId"
                                                className='mt-2'
                                                onChange={selectOrg}
                                                options={organisation}
                                                renderInput={(params) => <material.TextField {...params} variant="standard" label="Organization Name" required
                                                    {...register("orgId", {
                                                        required: true,
                                                    })}
                                                />}
                                            />
                                        ) : null}
                                    </>
                                ) : (
                                    <>
                                        {/* {userType === "OTHER" ? (
                                            <material.Autocomplete
                                                fullWidth
                                                id="orgId"
                                                className='mt-2'
                                                onChange={selectClinic}
                                                options={clinicName}
                                                renderInput={(params) => <material.TextField {...params} variant="standard" label="Clinic Name" required
                                                    {...register("clinicName", {
                                                        required: true,
                                                    })}
                                                />}
                                            />
                                        ) : null} */}
                                    </>
                                )}
                                {/* {userType === "INJECTOR" ? (
                                    <material.Autocomplete
                                        multiple
                                        fullWidth
                                        id="orgId"
                                        className='mt-2'
                                        onChange={selectMultipleClinic}
                                        options={clinicName}
                                        renderInput={(params) => <material.TextField {...params} variant="standard" label="Clinic Name"
                                        />}
                                    />
                                ) : null} */}
                                {userType === "PRESCRIBER" ? (
                                    <div>
                                        <material.FormControl sx={{ marginTop: 4 }}>
                                            <material.FormLabel id="demo-radio-buttons-group-label">Gender</material.FormLabel>
                                            <material.RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                onChange={handleChange}
                                                value={selectValue}
                                            >
                                                <material.FormControlLabel value="MALE" control={<material.Radio color="secondary" />} label="Male" />
                                                <material.FormControlLabel value="FEMALE" control={<material.Radio color="secondary" />} label="Female" />
                                                <material.FormControlLabel value="TRANSGENDER" control={<material.Radio color="secondary" />} label="Other" />
                                            </material.RadioGroup>
                                        </material.FormControl>
                                        <div>
                                            <material.TextField
                                                {...register("AHPRA_Reg_ID", { required: true })}
                                                required
                                                margin="dense"
                                                id="name"
                                                label="AHPRA Reg ID"
                                                type="text"
                                                fullWidth
                                                variant="standard"
                                            />
                                            <p className='form-text text-danger'>{errors.AHPRA_Reg_ID?.type === "required" && 'This field is required'}</p>
                                        </div>
                                        <div>
                                            <material.TextField
                                                {...register("address", { required: true })}
                                                required
                                                margin="dense"
                                                id="name"
                                                label="Address"
                                                type="text"
                                                fullWidth
                                                variant="standard"
                                            />
                                            <p className='form-text text-danger'>{errors.address?.type === "required" && 'This field is required'}</p>
                                        </div>
                                        <div>
                                            <material.TextField
                                                error={errors.phone?.type === "required"}
                                                {...register("phone", { required: true, minLength: 10, maxLength: 10 })}
                                                required
                                                margin="dense"
                                                id="name"
                                                label="Phone"
                                                type="number"
                                                fullWidth
                                                variant="standard"
                                            />
                                            <p className='form-text text-danger'>{errors.phone?.type === "required" && 'This field is required'}</p>
                                            {(errors?.phone?.type === "minLength" || errors?.phone?.type === "maxLength") && (
                                                <p className='text-danger'>This field only contain 10 digits</p>
                                            )}
                                        </div>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <material.DesktopDatePicker
                                                label="Start Date"
                                                format='DD-MM-YYYY'
                                                readOnly
                                                value={startDate}
                                                onChange={(newValue) => {
                                                    setStartDate(newValue)
                                                }}
                                                slotProps={{ textField: { variant: 'standard' } }}
                                                sx={{ width: "100%", marginTop: { xs: 3, sm: 3, md: 3 } }}
                                            />
                                        </LocalizationProvider>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <material.DesktopDatePicker
                                                label="End Date"
                                                value={endDate}
                                                format='DD-MM-YYYY'
                                                onChange={(newValue) => {
                                                    setEndDate(newValue)
                                                }}
                                                slotProps={{ textField: { variant: 'standard' } }}
                                                sx={{ width: "100%", marginTop: { xs: 3, sm: 3, md: 3 } }}
                                            />
                                        </LocalizationProvider>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <material.DateTimePicker
                                                readOnly
                                                label="Date Of Entry"
                                                format='DD-MM-YYYY'
                                                value={date}
                                                onChange={(newValue) => {
                                                    setDate(newValue);
                                                }}
                                                slotProps={{ textField: { variant: 'standard' } }}
                                                sx={{ width: "100%", marginTop: { xs: 3, sm: 3, md: 3 } }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                ) : null}
                                <material.TextField
                                    {...register("password", { required: true })}
                                    required
                                    margin="dense"
                                    id="name"
                                    label="Password"
                                    type="password"
                                    fullWidth
                                    variant="standard"
                                />
                                <p className='form-text text-danger'>{errors.password?.type === "required" && 'This field is required'}</p>
                                {(errors?.password?.type === "password") && (
                                    <p className='text-danger'>This is not a valid Password</p>
                                )}
                            </material.DialogContent>
                            <material.DialogActions className='me-3'>
                                <material.Button onClick={handleClose} variant="outlined" sx={{ textTransform: "none" }} color="error" startIcon={<material.CloseIcon />}>Cancel</material.Button>
                                <material.Button onClick={handleSubmit(addUserByOrg)} variant="contained" sx={{ textTransform: "none" }} startIcon={<material.ForwardToInboxIcon />} disabled={!isValid}>Send</material.Button>
                            </material.DialogActions>
                        </>
                    ) : openInvitePopup.action === "invite-user" ? (
                        <>
                            <material.DialogTitle>Invite User</material.DialogTitle>
                            <material.DialogContent>
                                <material.TextField
                                    {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                                    margin="dense"
                                    id="name"
                                    label="Email Address"
                                    type="email"
                                    fullWidth
                                    variant="standard"
                                />
                                <p className='form-text text-danger'>{errors.email?.type === "required" && 'This field is required'}</p>
                                {(errors?.email?.type === "pattern") && (
                                    <p className='text-danger'>This is not a valid Email</p>
                                )}
                                <material.FormControl variant="standard" fullWidth className='mt-2'>
                                    <material.InputLabel id="demo-simple-select-label">Role</material.InputLabel>
                                    <material.Select
                                        {...register("role", { required: true, })}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={multipleRole}
                                        onChange={selectMultipleRole}
                                    >
                                        {getRolePermissionData?.map((role, i) => (
                                            <material.MenuItem key={i} value={role.roleName}>{role.roleName}</material.MenuItem>
                                        ))}
                                    </material.Select>
                                </material.FormControl>
                            </material.DialogContent>
                            <material.DialogActions className='me-3'>
                                <material.Button onClick={handleClose} variant="outlined" sx={{ textTransform: "none" }} color="error" startIcon={<material.CloseIcon />}>Cancel</material.Button>
                                <material.Button onClick={handleSubmit(inviteUserByMail)} variant="contained" sx={{ textTransform: "none" }} startIcon={<material.ForwardToInboxIcon />}>Send</material.Button>
                            </material.DialogActions>
                        </>
                    ) : null}
                    {/* <material.DialogActions className='me-3'>
                        <material.Button onClick={handleClose} variant="outlined" color="error" startIcon={<material.CloseIcon />}>Cancel</material.Button>
                        <material.Button onClick={handleSubmit(inviteUserByMail)} variant="contained" startIcon={<material.ForwardToInboxIcon />}>Send</material.Button>
                    </material.DialogActions> */}
                </material.Dialog>
            </form>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state,
    };
};

export default connect(mapStateToProps)(InviteUser)
