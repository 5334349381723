import axios from "axios";
import { rootApiUrl } from "../environment/Environment";


export const getEmployeePayrollHours = async (payload) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/emp/get-employee-time-sheets`, { params: { ...payload } }); 
};

export const saveUpdateEmployeePayrollHours = async (payrollHours) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/emp/save-update-employee-time-sheets`, payrollHours);
};

export const approveEmployeePayrollHours = async (payrollHours) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/emp/approve-employee-time-sheets`, payrollHours);
};