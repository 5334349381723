import React, { useState, useEffect, useRef } from 'react';
import { material } from '../../../library/material';
import { connect } from 'react-redux';
import Snackbar from '../../toastrmessage/Snackbar';
import GenerateOtherInvoice from '../../dialog/GenerateOtherInvoice';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { getInvoiceListOtherByClinicId, getInvoiceOtherByInvoiceId, sendInvoiceOtherByInvoiceId } from '../../../services/InvoiceManagementService';
import Navbar from '../../navbar/Navbar';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import ViewCurrentOfferList from '../clinic-config/corrent-offers/ViewCurrentOfferList';
import { useTheme } from '@mui/material';

var allOtherInvoiceData = [];

function OtherInvoice(props) {

    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const iframeRef = useRef(null);
    const theme = useTheme();
    const [openGenerateInvoice, setOpenGenerateInvoice] = useState(false);
    const [otherInvoiceData, setOtherInvoiceData] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [selectFilter, setSelectFilter] = useState("");
    const [openViewCurrentOfferList, setOpenViewCurrentOfferList] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);

    useEffect(() => {
        getOtherInvoiceListOther();
    }, []);

    useEffect(() => {
        if (iframeRef.current && selectedInvoice) {
            const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
            iframeDoc.open();
            iframeDoc.write(selectedInvoice);
            iframeDoc.close();
        }
    }, [selectedInvoice]);

    const getOtherInvoiceListOther = async () => {
        await getInvoiceListOtherByClinicId(clinicDetails.clinicId)
            .then((resp) => {
                allOtherInvoiceData = resp.data.data;
                setOtherInvoiceData(resp.data.data);
                setisLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const invoiceGenerate = () => {
        setOpenGenerateInvoice(true)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const showInvoice = async (invoiceId) => {
        await getInvoiceOtherByInvoiceId(invoiceId)
            .then((resp) => {
                // const newTab = window.open("", "_blank")
                // newTab.document.write(resp.data)
                setSelectedInvoice(resp.data.data)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const sendInvoice = async (rowData) => {
        await sendInvoiceOtherByInvoiceId(rowData.invoiceId)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const handleChangeFilter = (event) => {
        setSelectFilter(event.target.value)
    };

    const filterByPatientName = (value) => {
        setPage(0);
        const filteredRows = otherInvoiceData.filter((row) => {
            return row.patientName
                .toString()
                .toLowerCase()
                .trim()
                .includes(value.toString().toLowerCase().trim())
        })
        if (value.trim().toString().length < 1) {
            setOtherInvoiceData(allOtherInvoiceData);
        } else {
            setOtherInvoiceData(filteredRows);
        };
    };

    const filterByEmail = (value) => {
        setPage(0);
        const filteredRows = otherInvoiceData.filter((row) => {
            return row.patientEmail
                .toString()
                .toLowerCase()
                .trim()
                .includes(value.toString().toLowerCase().trim())
        })
        if (value.trim().toString().length < 1) {
            setOtherInvoiceData(allOtherInvoiceData);
        } else {
            setOtherInvoiceData(filteredRows);
        };
    };

    const filterByInvoiceId = (value) => {
        setPage(0);
        const filteredRows = otherInvoiceData.filter((row) => {
            return row.invoiceId
                .toString()
                .toLowerCase()
                .trim()
                .includes(value.toString().toLowerCase().trim())
        })
        if (value.trim().toString().length < 1) {
            setOtherInvoiceData(allOtherInvoiceData);
        } else {
            setOtherInvoiceData(filteredRows);
        };
    };

    const refreshFilter = () => {
        setSelectFilter("");
        setOtherInvoiceData(allOtherInvoiceData);
    };

    const viewCurrentOffer = () => {
        setOpenViewCurrentOfferList(true)
    };

    const handlePrintClick = () => {
        if (iframeRef.current) {
            // Get the iframe document and print it
            iframeRef.current.contentWindow.focus();
            iframeRef.current.contentWindow.print();
        }
    };

    return (
        <material.Grid spacing={2} className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 10
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 10
                },
            }}
        >
            <Navbar />
            <div className='row'>
                <div className='col-6'>
                    {selectFilter === "patientName" ? (
                        <material.TextField
                            fullWidth
                            type='text'
                            variant="standard"
                            label="Filter by Name"
                            onChange={(e) => filterByPatientName(e.target.value)}
                            sx={{ width: "40ch", mb: 3, mt: -3 }}
                        />
                    ) : selectFilter === "email" ? (
                        <material.TextField
                            fullWidth
                            type='text'
                            variant="standard"
                            label="Filter by Email"
                            onChange={(e) => filterByEmail(e.target.value)}
                            sx={{ width: "40ch", mb: 3, mt: -3 }}
                        />
                    ) : selectFilter === "invoiceId" ? (
                        <material.TextField
                            fullWidth
                            type='text'
                            variant="standard"
                            label="Filter by Invoice Id"
                            onChange={(e) => filterByInvoiceId(e.target.value)}
                            sx={{ width: "40ch", mb: 3, mt: -3 }}
                        />
                    ) : (
                        <material.FormControl fullWidth variant='standard'>
                            <material.InputLabel id="demo-simple-select-label">Filter by</material.InputLabel>
                            <material.Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectFilter}
                                label="Age"
                                onChange={handleChangeFilter}
                                sx={{ width: "30ch", mb: 3, mt: -3 }}
                            >
                                <material.MenuItem value="patientName">Patient Name</material.MenuItem>
                                <material.MenuItem value="email">Email</material.MenuItem>
                                <material.MenuItem value="invoiceId">Invoice Id</material.MenuItem>
                            </material.Select>
                        </material.FormControl>
                    )}
                    {selectFilter === "patientName" || selectFilter === "email" || selectFilter === "invoiceId" ? (
                        <material.ReplayIcon sx={{ mt: -2, ml: 2, cursor: "pointer" }} onClick={refreshFilter} titleAccess='Refresh Filter' />
                    ) : null}
                </div>
                <div className='col-6'>
                    <span className="float-end">
                        <material.Button variant="contained" sx={{ textTransform: "none" }} className='me-2' onClick={viewCurrentOffer} startIcon={<material.VisibilityIcon />}>View Current Offer</material.Button>
                        <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={invoiceGenerate} startIcon={<material.ApartmentIcon />}>Invoice Generate</material.Button>
                    </span>
                </div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    <material.Paper elevation={3} sx={{ p: 3 }}>
                        <material.TableContainer sx={{ maxHeight: 585 }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell>Patient Name</StyledTableCell>
                                        <StyledTableCell>Patient Email</StyledTableCell>
                                        {/* <StyledTableCell>Invoice Creator</StyledTableCell> */}
                                        <StyledTableCell>Invoice ID</StyledTableCell>
                                        {/* <StyledTableCell>Total Payable Amount</StyledTableCell> */}
                                        <StyledTableCell>Action</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={10}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={10} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {otherInvoiceData.length ? otherInvoiceData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                <material.TableRow
                                                    key={i}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': { border: 0 }
                                                    }}
                                                >
                                                    <material.TableCell sx={{ pt: 2, pb: 2 }} size='small'>{row.patientName}</material.TableCell>
                                                    <material.TableCell size='small'>{row.patientEmail}</material.TableCell>
                                                    {/* <material.TableCell size='small'>{row?.invoiceCreatorId?.split("_")[0] + " " + row?.invoiceCreatorId?.split("_")[1]}</material.TableCell> */}
                                                    <material.TableCell size='small'>{row.invoiceId}</material.TableCell>
                                                    {/* <material.TableCell size='small'>{row.totalPayableAmount}</material.TableCell> */}
                                                    <StyledTableCell size='small'>
                                                        <material.IconButton title='View Invoice' aria-label="create" size="large" onClick={() => showInvoice(row.invoiceId)}>
                                                            <material.VisibilityIcon color='secondary' />
                                                        </material.IconButton>
                                                        <material.IconButton title='Send Invoice' aria-label="create" size="large" onClick={() => sendInvoice(row)}>
                                                            <material.SendIcon color='primary' />
                                                        </material.IconButton>
                                                    </StyledTableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={10}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>
                                    )}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                        <material.TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={otherInvoiceData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </material.Paper>
                </div>
                <div className="col-6">
                    <material.Paper elevation={3} sx={{ p: 3 }}>
                        {selectedInvoice ? (
                            <div>
                                <iframe
                                    ref={iframeRef}
                                    title="Invoice Viewer"
                                    style={{
                                        width: "100%",
                                        height: "70vh",
                                        border: "1px solid #ccc",
                                        borderRadius: "8px",
                                        backgroundColor: "#f9f9f9",
                                    }}
                                ></iframe>
                                <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handlePrintClick}>
                                    Print
                                </material.Button>
                            </div>
                        ) : (
                            <p>Select an invoice to view its details.</p>
                        )}
                    </material.Paper>
                </div>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <GenerateOtherInvoice
                openGenerateInvoice={openGenerateInvoice}
                setOpenGenerateInvoice={setOpenGenerateInvoice}
                getOtherInvoiceListOther={getOtherInvoiceListOther}
            />
            <ViewCurrentOfferList
                userDetails={userDetails}
                clinicDetails={clinicDetails}
                openViewCurrentOfferList={openViewCurrentOfferList}
                setOpenViewCurrentOfferList={setOpenViewCurrentOfferList}
            />
        </material.Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(OtherInvoice);