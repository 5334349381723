import React from 'react';
import { material } from '../../library/material';

function RoleTypeCheckDialog(props) {

    const { openRoleTypeCheckDialog, setOpenRoleTypeCheckDialog } = props;

    return (
        <div>
            <material.Dialog open={openRoleTypeCheckDialog.action} maxWidth="sm" fullWidth>
                <material.DialogContent>
                    <div className="d-flex flex-row space-between">
                        <material.Typography>What type of role will you create ?</material.Typography>
                        <span className='mt-2 ms-1'>
                            <material.Button variant="contained" color="primary" sx={{ textTransform: "none", mr: 1 }} onClick={() => setOpenRoleTypeCheckDialog({ action: false, data: "Platform Level" })}>Platform Level</material.Button>
                            <material.Button variant="contained" color="secondary" sx={{ textTransform: "none" }} onClick={() => setOpenRoleTypeCheckDialog({ action: false, data: "Organization Level" })}>Organization Level</material.Button>
                        </span>
                    </div>
                </material.DialogContent>
            </material.Dialog>
        </div>
    )
}

export default RoleTypeCheckDialog;