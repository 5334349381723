import React, { useEffect, useState } from "react";
import Navbar from "../../navbar/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import { material } from "../../../library/material";
import { StyledTableCell } from "../../../shared/TableHeaderStyle";
import AddHolidayDate from "../../dialog/AddHolidayDate";
import { getUserHoliday } from "../../../services/InjectorService";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { changeDateFormate, changeTimeFormate } from "../../../date-and-time-format/DateAndTimeFormat";
import Snackbar from "../../toastrmessage/Snackbar";

const InjectorAddHoliday = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userData = location.state;
  const [openAddHolidayDateDialog, setOpenAddHolidayDateDialog] = useState({
    action: false,
    data: null,
    userData: null
  });
  const [userHolidayData, setUserHolidayData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState({
    action: false,
    type: "",
    message: "",
  });

  localStorage.setItem("menuName", "Injector Holiday");

  useEffect(() => {
    getUsersHolidayData();
  }, []);

  const getUsersHolidayData = () => {
    setIsLoading(true);
    const payload = {
      userId: userData.userId,
      clinicId: userData.clinicId,
      orgId: userData.orgId
    }
    getUserHoliday(payload)
      .then((resp) => {
        setUserHolidayData(resp.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response.data.data.info,
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response.data.messages,
          })
        }
      })
  };

  const openAddHolidayDialog = () => {
    setOpenAddHolidayDateDialog({ action: true, data: null, userData: userData });
  };

  const goBack = () => {
    navigate(-1)
  };

  return (
    <div className="container-fluid" style={{ marginTop: "6%" }}>
      <Navbar />
      <div className="row">
        <div className="col-12">
          <span className="float-end">
            <material.Button startIcon={<material.ReplyIcon />} variant="contained" onClick={goBack} sx={{ textTransform: "none", mb: 1 }}>Back</material.Button>
          </span>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px solid #ccc",
          borderRadius: "8px",
          padding: "16px",
          marginBottom: "8px",
          boxShadow:
            "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
        }}
      >
        <h6>{userData.fullName}</h6>
        <span>
          <material.Button
            startIcon={<material.AddIcon />}
            variant="contained"
            sx={{ textTransform: "none" }}
            onClick={openAddHolidayDialog}
          >
            Add
          </material.Button>
        </span>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <material.Paper sx={{ width: "100%", overflow: "hidden" }}>
            <material.TableContainer>
              <material.Table stickyHeader aria-label="sticky table">
                <material.TableHead>
                  <material.TableRow>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell>Start Time</StyledTableCell>
                    <StyledTableCell>End Time</StyledTableCell>
                    <StyledTableCell>Purpose</StyledTableCell>
                  </material.TableRow>
                </material.TableHead>
                <material.TableBody>
                  {isLoading ? (
                    <material.TableRow>
                      <material.TableCell colSpan={10}>
                        <SkeletonTheme
                          baseColor="#bbdefb"
                          highlightColor="#c6ff00"
                          enableAnimation="true"
                          inline="true"
                          width="100% "
                          height="30px"
                        >
                          <Skeleton count={10} />
                        </SkeletonTheme>
                      </material.TableCell>
                    </material.TableRow>
                  ) : (
                    <>
                      {userHolidayData.length ? userHolidayData.map((holiday, i) => (
                        <material.TableRow key={i}>
                          <material.TableCell>{changeDateFormate(holiday.date)}</material.TableCell>
                          <material.TableCell>{changeTimeFormate(holiday.startTime)}</material.TableCell>
                          <material.TableCell>{changeTimeFormate(holiday.endTime)}</material.TableCell>
                          <material.TableCell>{holiday.purpose}</material.TableCell>
                        </material.TableRow>
                      )) : (
                        <material.TableRow>
                          <material.TableCell colSpan={10}>
                            <h6 className="d-flex justify-content-center text-danger fw-bold">
                              No data found
                            </h6>
                          </material.TableCell>
                        </material.TableRow>
                      )}
                    </>
                  )}
                </material.TableBody>
              </material.Table>
            </material.TableContainer>
          </material.Paper>
        </div>
      </div>
      <AddHolidayDate
        setOpenAddHolidayDateDialog={setOpenAddHolidayDateDialog}
        openAddHolidayDateDialog={openAddHolidayDateDialog}
        getUsersHolidayData={getUsersHolidayData}
      />
      <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
    </div>
  );
};

export default InjectorAddHoliday;
