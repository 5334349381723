import React, { useState, useEffect } from 'react';
import { material } from '../../../library/material';
import { getOtherInventoryListByOrg, getOtherInventoryListByClinicId, saveOrUpdateOtherStockPrice } from '../../../services/ProductPriceManagment';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useForm } from 'react-hook-form';
import Snackbar from '../../toastrmessage/Snackbar';
import { connect } from 'react-redux';
import { dateFormat } from '../../../date-and-time-format/DateAndTimeFormat';
import moment from 'moment';

var inventoryData;

function AddOtherStock(props) {

    const { openAddOtherStock, setOpenAddOtherStock, userData, getOtherStockPricemanagmentData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const { register, handleSubmit, resetField, formState: { errors, isValid }, reset, setValue } = useForm({
        mode: "onTouched"
    });

    const [productName, setProductName] = useState([]);
    const [date, setDate] = React.useState(dayjs(new Date()));
    const [fromDate, setFromDate] = React.useState(dayjs(new Date()));
    const [toDate, setToDate] = React.useState(dayjs(new Date()));
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getInventoryList()
    }, [openAddOtherStock.action === true]);

    useEffect(() => {
        if (openAddOtherStock.action && openAddOtherStock.otherStockData) {
            reset(openAddOtherStock.otherStockData);
            setDate(dayjs(openAddOtherStock.otherStockData.timeStamp))
            setFromDate(dayjs(openAddOtherStock.otherStockData.formDate))
            setToDate(dayjs(openAddOtherStock.otherStockData.toDate))
        }
    }, [openAddOtherStock.action === true])

    const getInventoryList = async () => {
        await getOtherInventoryListByClinicId(clinicDetails.clinicId)
            .then((resp) => {
                inventoryData = resp.data.data
                let productName = resp.data?.data?.map((res) => res.product);
                setProductName(productName)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const selectProduct = (e, value) => {
        let product = inventoryData.filter((ele) => ele.product === value).map((res) => res.productType);
        reset(product?.length ? { "productType": product[0] } : { "productType": "" })
    };

    const saveProduct = async (fromData) => {
        let obj = {
            "clinicId": clinicDetails.clinicId,
            "forTreatment": "",
            "product": fromData.product,
            "productType": fromData.productType,
            "perUnitPrice": fromData.perUnitPrice,
            "formDate": dateFormat(fromDate),
            "toDate": dateFormat(toDate),
            "changedBy": userDetails.userId,
            "timeStamp": moment(date).format("YYYY-MM-DDTHH:mm:ss"),
            "orgId": userDetails.orgId
        }
        await saveOrUpdateOtherStockPrice(obj)
            .then((res) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": res.data.messages,
                })
                setOpenAddOtherStock({ action: false, otherStockData: null })
                getOtherStockPricemanagmentData();
                reset({ product: "", perUnitPrice: "", productType: "" });
                setFromDate(dayjs(new Date()));
                setToDate(dayjs(new Date()));
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const updateProduct = async (fromData) => {
        await saveOrUpdateOtherStockPrice(fromData)
            .then((res) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": res.data.messages,
                })
                setOpenAddOtherStock({ action: false, otherStockData: null })
                getOtherStockPricemanagmentData();
                reset({ product: "", perUnitPrice: "", productType: "" });
                setFromDate(dayjs(new Date()));
                setToDate(dayjs(new Date()));
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const cancel = () => {
        reset({ product: "", perUnitPrice: "", productType: "" });
        setOpenAddOtherStock({ action: false, otherStockData: null })
        setFromDate(dayjs(new Date()));
        setToDate(dayjs(new Date()));
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="lg" open={openAddOtherStock.action} hideBackdrop >
                <material.DialogTitle>{openAddOtherStock.otherStockData ? "Edit Product" : "Add Product"}</material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            {openAddOtherStock.otherStockData ? (
                                <material.TextField
                                    label="Product"
                                    size="small"
                                    variant='standard'
                                    fullWidth
                                    {...register("product", { required: true })}
                                    disabled
                                />
                            ) : (
                                <material.Autocomplete
                                    id="area"
                                    onChange={selectProduct}
                                    options={productName}
                                    renderInput={(params) => <material.TextField {...params} variant="standard" label="Select Product"
                                        fullWidth {...register("product", { required: true })}
                                    />}
                                />
                            )}
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <material.TextField
                                label="Product Type"
                                size="small"
                                variant='standard'
                                fullWidth
                                {...register("productType", { required: true })}
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <material.TextField
                                label="Per Unit Price"
                                size="small"
                                variant='standard'
                                fullWidth
                                {...register("perUnitPrice", { required: true })}
                            />
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <material.DatePicker
                                    label="To Date"
                                    sx={{ width: "100%" }}
                                    format='DD-MM-YYYY'
                                    slotProps={{ textField: { variant: 'standard' } }}
                                    value={toDate}
                                    onChange={(newValue) => {
                                        setToDate(newValue)
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <material.DatePicker
                                    label="From Date"
                                    sx={{ width: "100%" }}
                                    format='DD-MM-YYYY'
                                    slotProps={{ textField: { variant: 'standard' } }}
                                    value={fromDate}
                                    onChange={(newValue) => {
                                        setFromDate(newValue)
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 mt-3'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <material.DatePicker
                                    label="Time Stamp"
                                    sx={{ width: "100%" }}
                                    format='DD-MM-YYYY'
                                    slotProps={{ textField: { variant: 'standard' } }}
                                    disabled
                                    value={date}
                                    onChange={(newValue) => {
                                        setDate(newValue)
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <span className='float-end me-2 py-3'>
                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 2 }} color='error' onClick={cancel}>Cancel</material.Button>
                        {openAddOtherStock.otherStockData ? (
                            <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(updateProduct)} disabled={!isValid}>Update</material.Button>
                        ) : (
                            <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSubmit(saveProduct)} disabled={!isValid}>Save</material.Button>
                        )}
                    </span>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(AddOtherStock);