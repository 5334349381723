import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { getClinicDetailsByClinicId } from '../../../services/TreatmentPlanService';
import { saveSkinConsentForm } from '../../../services/ConsentFormService';
import { socket } from '../../../socket/Socket';
import AddAndUploadPhotoDialog from '../skin-treatment-booking/AddAndUploadPhotoDialog';
import Snackbar from '../../toastrmessage/Snackbar';
import SignaturePad from 'react-signature-canvas';

var sigPad = {};

function SkinTagsRemoval(props) {

    const { openConsentForm, setOpenConsentForm, patientDataDetails, callFrom } = props;
    const [questions, setQuestions] = useState({
        aw_q1: "", aw_q2: "", aw_q3: "", aw_q4: "", aw_q5: "", aw_q6: "", aw_q7: "", aw_q8: "", aw_q9: "", aw_q10: "", aw_q11: "", aw_q12: "", aw_q13: "", aw_q14: "", aw_q15: ""
    });
    const [isHide, setIsHide] = useState(false);
    const [getSignature, setGetSignature] = useState(null);
    const [clinicDetails, setClinicDetails] = useState({});
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [openPhotoUploadDialog, setOpenPhotoUploadDialog] = useState({ action: false, treatmentData: null });

    useEffect(() => {
        if (openConsentForm.action) {
            getClinicByClinicId()
        };
    }, [openConsentForm.action === true]);

    const getClinicByClinicId = async () => {
        await getClinicDetailsByClinicId(openConsentForm.patientData.clinicId)
            .then((resp) => {
                setClinicDetails(resp.data.data)
                if (openConsentForm.patientData?.concent_data?.data) {
                    const element = document.getElementById("button-section")
                    element.scrollIntoView({ behavior: "smooth" });
                };
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data.messages,
                })
            })
    };

    const selectQuestions = (qNumber, value) => {
        setQuestions(prevValues => ({
            ...prevValues,
            [qNumber]: value,
        }))
    };
    const isValid = Object.values(questions).some(value => value === "");

    const getPatientSignature = () => {
        setGetSignature(sigPad.getTrimmedCanvas().toDataURL('image/png'))
    };

    const saveConsent = async () => {
        let obj = {
            patientId: openConsentForm.patientData.patientId,
            skinTreatmentPlanId: openConsentForm.patientData.skinTreatmentPlanId,
            concent_data: { ...questions, getSignature }
        }
        await saveSkinConsentForm(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                if (callFrom === "secondary-user") {
                    socket.emit("new-concent-added");
                    setOpenConsentForm({ action: false, patientData: null })
                } else {
                    setOpenPhotoUploadDialog({ action: true, treatmentData: patientDataDetails });
                    setIsHide(true)
                };
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data.messages,
                })
            })
    };
    const cancel = () => {
        setOpenConsentForm({ action: false, patientData: null })
    };

    const clear = () => {
        sigPad.clear()
        setGetSignature(null)
    };

    const openSettings = () => {
        setOpenPhotoUploadDialog({ action: true, treatmentData: patientDataDetails });
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="md" open={openConsentForm.action} hideBackdrop>
                <material.DialogContent>
                    <div className=''>
                        <div className='ms-3'>
                            <div className='mt-2 fw-bold'>
                                <span className='d-flex justify-content-center'>CONSENT FORM FOR SKIN TAGS REMOVAL TREATMENTS</span>
                            </div>
                            <div>
                                <span>
                                    I acknowledge that I have been informed about the CO2 Laser treatments, their potential benefits, and the associated risks. CO2 Laser is a fractional ablative laser treatment used for various medical and cosmetic applications, including but not limited to:
                                    {openConsentForm.patientData?.concent_data ? (
                                        <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q15}</span>
                                    ) : (
                                        <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                            <material.RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                onChange={event => selectQuestions("aw_q15", event.target.value)}
                                                value={questions.aw_q15}
                                            >
                                                <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                            </material.RadioGroup>
                                        </material.FormControl>
                                    )}
                                </span>
                                <div className='mt-3'>
                                    <ul>
                                        <li><span className='fw-bold'>Skin Rejuvenation:</span> Improving skin texture, tone, and elasticity.</li>
                                        <li><span className='fw-bold'>Scar Reduction:</span> Treatment of acne scars, surgical scars, and stretch marks.</li>
                                        <li><span className='fw-bold'>Pigmentation Treatment:</span> Reducing sunspots, age spots, and uneven pigmentation.</li>
                                        <li><span className='fw-bold'>Mole, wart, skin tag & Lesion Removal:</span> Safe removal of benign moles, warts, skin tags, and other superficial skin irregularities.</li>
                                        <li><span className='fw-bold'>Wrinkle and Fine Line Reduction:</span> Smoothing wrinkles and fine lines for a youthful appearance.</li>
                                        <li><span className='fw-bold'>Pore Size Reduction:</span> Minimizing enlarged pores.</li>
                                        <li><span className='fw-bold'>Sebaceous Hyperplasia Treatment:</span> Reducing enlarged oil glands on the face.</li>
                                        <li><span className='fw-bold'>Hair Therapy & Scalp Rejuvenation:</span> Stimulating hair follicles for non-surgical hair restoration and improving scalp health.</li>
                                        <li><span className='fw-bold'>Vaginal Rejuvenation (if applicable to clinic services):</span> Enhancing tissue elasticity and hydration in intimate areas.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <h5>
                                    Procedure Information and Risks:
                                </h5>
                            </div>
                            <div className='mt-2'>
                                <div>
                                    <span>
                                        I acknowledge that I have been informed about the CO2 Laser treatments, their potential benefits, and the associated risks. These include, but are not limited to:
                                        {openConsentForm.patientData?.concent_data ? (
                                            <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q12}</span>
                                        ) : (
                                            <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                <material.RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    onChange={event => selectQuestions("aw_q12", event.target.value)}
                                                    value={questions.aw_q12}
                                                >
                                                    <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                    <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                </material.RadioGroup>
                                            </material.FormControl>
                                        )}
                                    </span>
                                </div>
                                <div>
                                    <ul>
                                        <li>Temporary redness, swelling, or crusting.</li>
                                        <li>Hyperpigmentation (darkening of the skin) or hypopigmentation (lightening of the skin).</li>
                                        <li>Scarring, including hypertrophic or keloid scars.</li>
                                        <li>Burns or blisters.</li>
                                        <li>Infection at the treatment site (rare).</li>
                                        <li>Pain or discomfort during recovery.</li>
                                        <li>Increased sensitivity to sunlight.</li>
                                        <li>Crusting, oozing, or scabbing of the treated area.</li>
                                        <li>Prolonged redness.</li>
                                        <li>Acne flares or milia (small white bumps).</li>
                                        <li>Reactivation of herpes simplex virus (cold sores) in susceptible individuals.</li>
                                        <li>Delayed healing.</li>
                                        <li>Ectropion (outward turning of the eyelid) when treating areas near the eyes.</li>
                                        <li>Permanent changes in skin texture.</li>
                                        <li>Allergic reactions to topical preparations used before or after treatment.</li>
                                    </ul>
                                </div>
                                <div>
                                    <span>
                                        I understand and acknowledge that multiple treatments will be required and results are not guaranteed.
                                        {openConsentForm.patientData?.concent_data ? (
                                            <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q13}</span>
                                        ) : (
                                            <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                <material.RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    onChange={event => selectQuestions("aw_q13", event.target.value)}
                                                    value={questions.aw_q13}
                                                >
                                                    <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                    <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                </material.RadioGroup>
                                            </material.FormControl>
                                        )}
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        I understand and acknowledge that treatment packages are not transferrable to any other person and no refund will be provided for change of mind or for any other reason.
                                        {openConsentForm.patientData?.concent_data ? (
                                            <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q14}</span>
                                        ) : (
                                            <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                <material.RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    onChange={event => selectQuestions("aw_q14", event.target.value)}
                                                    value={questions.aw_q14}
                                                >
                                                    <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                    <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                </material.RadioGroup>
                                            </material.FormControl>
                                        )}
                                    </span>
                                </div>
                                <h5 className='mt-2'>Contraindications:</h5>
                                <h6 className='mt-2'>Please indicate YES or NO for the following:</h6>
                                <div>
                                    <ul className='ms-3'>
                                        <li>Are you pregnant or breastfeeding, undergoing IVF treatment, or trying to conceive?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q1}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q1", event.target.value)}
                                                        value={questions.aw_q1}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Do you have an active skin infection (e.g., herpes) or wounds in the treatment area?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q2}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q2", event.target.value)}
                                                        value={questions.aw_q2}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Do you have a history of keloids or hypertrophic scarring?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q3}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q3", event.target.value)}
                                                        value={questions.aw_q3}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Have you used Accutane or similar medication in the past six months?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q4}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q4", event.target.value)}
                                                        value={questions.aw_q4}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Do you have any autoimmune or connective tissue diseases?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q5}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q5", event.target.value)}
                                                        value={questions.aw_q5}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Do you have skin cancer in the treatment area?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q6}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q6", event.target.value)}
                                                        value={questions.aw_q6}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Have you had recent tanning or sunburn (within 2 weeks)?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q7}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q7", event.target.value)}
                                                        value={questions.aw_q7}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Do you have a history of photosensitivity or conditions exacerbated by light exposure?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q8}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q8", event.target.value)}
                                                        value={questions.aw_q8}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Have you undergone recent facial surgery or other facial treatments?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q9}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q9", event.target.value)}
                                                        value={questions.aw_q9}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                    </ul>
                                </div>
                                <h5>Pre-Procedure Care:</h5>
                                <div>
                                    <span>I confirm that I have taken the following pre-procedure care:</span>
                                </div>
                                <div>
                                    <ul>
                                        <li>Avoid sun tanning and use of tanning beds for 4 weeks before treatment.</li>
                                        <li>Discontinue use of Retin-A, Retinols, or similar products 5-7 days before treatment.</li>
                                        <li>Inform the clinic of all medications, supplements, and medical conditions before the procedure.</li>
                                    </ul>
                                </div>
                                <h5 className='mt-2'>Photography Consent:</h5>
                                <div>
                                    <ul className='ms-3'>
                                        <li>I consent to before and after photos taken for clinical use:
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q10}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q10", event.target.value)}
                                                        value={questions.aw_q10}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>I consent to before and after photos taken for marketing and advertising purposes:
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q11}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q11", event.target.value)}
                                                        value={questions.aw_q11}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                    </ul>
                                </div>
                                <h5>Aftercare Advice:</h5>
                                <div>
                                    <span>I understand that I need to follow the provided aftercare advice:</span>
                                </div>
                                <div>
                                    <ul>
                                        <li>Avoid direct sun exposure and use a broad-spectrum SPF 30 or higher sunscreen daily.</li>
                                        <li>Keep the treated area clean and moisturized.</li>
                                        <li>Avoid wearing makeup or using irritants on the treated area during the healing process.</li>
                                        <li>Avoid hot showers, saunas, and heavy sweating for 48 hours post-procedure.</li>
                                        <li>Report any complications or concerns to the clinic promptly.</li>
                                    </ul>
                                </div>
                                <h5>Consent:</h5>
                                <div>
                                    <span>
                                        By signing below, I acknowledge that I understand the risks, benefits, and instructions associated with the CO2 Laser procedure(s). I have been given the opportunity to ask questions, and all of my questions have been answered to my satisfaction. I hereby give my consent to undergo the CO2 Laser procedure(s) selected above at <span style={{ fontWeight: "bold" }}>{clinicDetails.clinicName}</span>.
                                    </span>
                                </div>
                            </div>
                            <div className='mt-5 d-flex'>
                                <span>Patient Signature</span>
                                {openConsentForm.patientData?.concent_data ? (
                                    <span className='ms-3'>
                                        <img
                                            src={openConsentForm.patientData?.concent_data?.data?.getSignature}
                                        />
                                    </span>
                                ) : (
                                    <span className='sigBox ms-3'>
                                        <SignaturePad penColor='black'
                                            canvasProps={{ width: 400, height: 100, className: 'sigPad' }}
                                            ref={(ref) => { sigPad = ref }}
                                            onEnd={getPatientSignature}
                                        />
                                    </span>
                                )}
                                {openConsentForm.patientData?.concent_data ? null : (
                                    <span className='ms-2'>
                                        <material.Button size='small' sx={{ textTransform: "none" }} onClick={clear}>Clear</material.Button>
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <div id='button-section' className='m-3'>
                        {!isHide ? (
                            <span className='float-end'>
                                <material.Button variant="outlined" sx={{ textTransform: "none", mr: 2 }} color='error' onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                                {openConsentForm.patientData?.concent_data ? (
                                    <material.Button variant="outlined" sx={{ textTransform: "none" }} hidden={callFrom === "patient_past_procedure"} onClick={openSettings}>Next</material.Button>
                                ) : (
                                    <material.Button variant="outlined" sx={{ textTransform: "none" }} hidden={callFrom === "patient_past_procedure"} onClick={saveConsent} disabled={isValid}>Save Consent</material.Button>
                                )}
                            </span>
                        ) : null}
                    </div>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <AddAndUploadPhotoDialog
                openPhotoUploadDialog={openPhotoUploadDialog}
                setOpenPhotoUploadDialog={setOpenPhotoUploadDialog}
                callFrom="Consent Form"
                setOpenConsentForm={setOpenConsentForm}
            />
        </div>
    );
}

export default SkinTagsRemoval;