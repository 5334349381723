import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { treatmentAreaData } from './Treatment';
import { useForm } from 'react-hook-form';
import { getTreatmentListByClinicId, saveTreatmentPlanDetails } from '../../../services/TreatmentListService';
import { connect } from 'react-redux';
import { getStockPriceId } from '../../../services/TreatmentPlanService';
import Snackbar from '../../toastrmessage/Snackbar';
import { getProductTreatment } from '../../../services/TreatmentListService';

var uniqueTreatmentName = [];
var allTreatmentList;
var products;

function AddTreatmentPlan(props) {

    const { openTreatmentPlan, setOpenTreatmentPlan, getTreatmentData, clinicData, userData, blankTreatmentData } = props;
    const [areaOption, setAreaOption] = useState([]);
    const [treatmentName, setTreatmentName] = useState([]);
    const [selectAreaData, setSelectAreaData] = useState([]);
    const [selectTreatmentData, setSelectTreatmentData] = useState("");
    const [productPriceId, setProductPriceId] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [productName, setProductName] = useState([]);
    let userDetails = userData.authReducer.data;
    let clinicDetails = clinicData.clinicReducer.data;
    const { register, handleSubmit, resetField, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });

    useEffect(() => {
        if (openTreatmentPlan.action === true && openTreatmentPlan.treatmentData === null) {
            getAllTreatmentList()
            getProductTreatmentList()
        } else if (openTreatmentPlan.action === true && openTreatmentPlan.treatmentData) {
            getAllTreatmentList()
            getProductTreatmentList()
            reset(openTreatmentPlan.treatmentData)
            getStockPriceIdByProductName(openTreatmentPlan.treatmentData.product)
        }
    }, [openTreatmentPlan.action === true]);

    const getAllTreatmentList = async () => {
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Please Wait....!",
        })
        await getTreatmentListByClinicId(clinicDetails.clinicId, userDetails.orgId)
            .then((resp) => {
                allTreatmentList = resp.data.data;
                let treatmentName = resp.data.data?.map((res) => res.treatmentName);
                // uniqueTreatmentName = treatmentName.filter((value, index, self) => {
                //     return self.indexOf(value) === index;
                // });
                if (openTreatmentPlan.action && openTreatmentPlan.treatmentData) {
                    let treatmentArea = allTreatmentList?.filter((ele) => ele.treatmentName === openTreatmentPlan.treatmentData?.treatmentName);
                    setAreaOption(treatmentArea[0]?.treatmentArea);
                }
                setTreatmentName(treatmentName);
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Fetching complete",
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getProductTreatmentList = async () => {
        let product = null;
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Please Wait....!",
        })
        await getProductTreatment(clinicDetails.clinicId, userDetails.orgId)
            .then((resp) => {
                products = resp.data.data;
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Fetching complete",
                })
                if (openTreatmentPlan.action && openTreatmentPlan.treatmentData) {
                    product = products?.filter((resp) => resp.treatmentType === openTreatmentPlan.treatmentData.treatmentName)?.map((ele) => ele.product)
                    let productName = product ? product[0].map((name) => name.product) : null;
                    setProductName(productName? productName : [])
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const selectTreatment = (e, value) => {
        setSelectTreatmentData(value)
        let product = null;
        reset({ treatmentArea: "" });
        let treatmentArea = allTreatmentList?.filter((ele) => ele.treatmentName === value);
        product = products?.filter((resp) => resp.treatmentType === value)?.map((ele) => ele.product);
        let productName = product ? product[0].map((name) => name.product) : null;
        setProductName(productName? productName : [])
        setAreaOption(treatmentArea?.length ? treatmentArea[0].treatmentArea : []);
    };

    const selectArea = async (e, value) => {
        // productName = allTreatmentList.filter((ele) => ele.area === value && ele.treatment === selectTreatmentData).map((resp) => resp.product);
        // reset(productName?.length ? { "product": productName[0] } : { "product": "" });
        // if (productName.length) {
        //     await getStockPriceId({ clinicId: clinicDetails.clinicId, product: productName[0], forTreatment: selectTreatmentData })
        //         .then((res) => {
        //             setProductPriceId(res.data)
        //         })
        //         .catch((error) => {
        //             setOpenSnackBar({
        //                 "action": true,
        //                 "type": "error",
        //                 "message": error.response.data,
        //             })
        //         })
        // }
        setSelectAreaData(value);
    };

    const handleChangeProduct = async (e, value) => {
        setProductPriceId(null)
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Please Wait....!",
        })
        getStockPriceIdByProductName(value)
    };

    const getStockPriceIdByProductName = async (productName) => {
        let obj = {
            clinicId: clinicDetails.clinicId,
            product: productName,
            forTreatment: openTreatmentPlan.treatmentData ? openTreatmentPlan.treatmentData.treatmentName : selectTreatmentData
        }
        await getStockPriceId(obj)
            .then((res) => {
                setProductPriceId(res.data.data)
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": res.data.messages,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data,
                    })
                }
            })
    };

    const addTreatment = async (data) => {
        await saveTreatmentPlanDetails({ ...data, stockPriceId: productPriceId, treatmentPlanRequestId: blankTreatmentData.treatmentPlanRequestId })
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                reset({ "product": "", "qty": "", treatmentName: "", treatmentArea: "" });
                getTreatmentData(blankTreatmentData.treatmentPlanRequestId)
                setOpenTreatmentPlan({ action: false, treatmentData: null })
                setProductPriceId(null)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const close = () => {
        reset({ "product": "", "qty": "", treatmentName: "", treatmentArea: "" })
        setOpenTreatmentPlan(false)
        setProductPriceId(null)
    };

    return (
        <div>
            <material.Dialog maxWidth="500px" open={openTreatmentPlan.action} hideBackdrop>
                <material.DialogTitle>Add Treatment</material.DialogTitle>
                <material.DialogContent>
                    <div className='row'>
                        <div className='col-12'>
                            <material.Autocomplete
                                id="treatment"
                                className='mt-3'
                                onChange={selectTreatment}
                                options={treatmentName}
                                defaultValue={openTreatmentPlan.treatmentData?.treatmentName}
                                renderInput={(params) => <material.TextField {...params} variant="standard" label="Treatment"
                                    {...register("treatmentName", { required: true })}
                                />}
                            />
                        </div>
                        <div className='col-12'>
                            <material.Autocomplete
                                id="area"
                                className='mt-3'
                                onChange={selectArea}
                                options={areaOption}
                                defaultValue={openTreatmentPlan.treatmentData?.treatmentArea}
                                renderInput={(params) => <material.TextField {...params} variant="standard" label="Area"
                                    {...register("treatmentArea", { required: true })}
                                />}
                            />
                        </div>
                        <div className='col-12'>
                            <material.Autocomplete
                                id="product"
                                className='mt-3'
                                onChange={handleChangeProduct}
                                options={productName}
                                defaultValue={openTreatmentPlan.treatmentData?.product}
                                renderInput={(params) => <material.TextField {...params} variant="standard" label="Product"
                                    {...register("product", { required: true })}
                                />}
                            />
                        </div>
                        <div className='col-12'>
                            <material.TextField
                                label="Qty"
                                id="qty"
                                variant="standard"
                                type="number"
                                size="small"
                                fullWidth
                                inputProps={{ style: { textTransform: 'capitalize' } }}
                                sx={{ marginTop: { xs: 3, sm: 3, md: 3 } }}
                                {...register("qty", { required: true })}
                            />
                        </div>
                        <div>
                            <span className='float-end mt-3'>
                                <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" className='me-2' color='error' onClick={close}>
                                    Cancel
                                </material.Button>
                                <material.Button disabled={productPriceId === null} variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={handleSubmit(addTreatment)}>
                                    Add
                                </material.Button>
                            </span>
                        </div>
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(AddTreatmentPlan);