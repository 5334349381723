import React, { useState } from 'react'
import Navbar from '../../../navbar/Navbar'
import { material } from '../../../../library/material'
import { useLocation, useNavigate } from "react-router-dom";
import GRS from './GRS';
import { linkGrsToProposedTreatmentPlan } from '../../../../services/GRSService';
import Snackbar from '../../../toastrmessage/Snackbar';


function LinkGRS() {
    const location = useLocation();
    const navigate = useNavigate();
    const patientData = location.state?.patientData;
    const treatmentPlanId = location.state?.treatmentPlanId;
    const [grsRefNo, setGrsRefNo] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });

    const goBack = () => {
        navigate(-1);
    };

    const getGrsRefNo = (imageKey) => {
        if (grsRefNo.includes(imageKey)) {
            setGrsRefNo(grsRefNo.filter(key => key !== imageKey));
        } else {
            setGrsRefNo([...grsRefNo, imageKey]);
        }
        // setGrsRefNo(data)
    };

    const linkGrsWithTreatmentPlan = () => {
        const payload = {
            grs_ref_no: grsRefNo[0],
            proposedTreatmentPlanId: treatmentPlanId[0]
        }
        linkGrsToProposedTreatmentPlan(payload)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data,
                });
                navigate(-1);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    return (
        <div>
            <Navbar />
            <div className='container-fluid' style={{ marginTop: "6%" }}>
                <div className="row">
                    <div className="col-12">
                        <span className='float-end'>
                            <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} onClick={linkGrsWithTreatmentPlan} color='secondary' disabled={!grsRefNo.length || grsRefNo.length > 1} startIcon={<material.AddLinkIcon />}>Link GRS With Treatment Plan</material.Button>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={goBack} startIcon={<material.ReplyIcon />}>Back</material.Button>
                        </span>
                    </div>
                    <div className="col-12">
                        <GRS
                            patientData={patientData}
                            callFrom="Link-GRS"
                            getGrsRefNo={getGrsRefNo}
                        />
                    </div>
                </div>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

export default LinkGRS

