import React, { useState } from 'react';
import { material } from '../../../library/material';
import Snackbar from '../../toastrmessage/Snackbar';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { uploadTemplateForCampaign } from '../../../services/PlatformCrmService';


function UploadCrmTemplate(props) {

    const { setOpenUploadTemplate, openUploadTemplate, userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const { register, handleSubmit, reset, watch, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [selectFolder, setSelectFolder] = useState(null);

    const chooseFile = (e) => {
        let fileName = e.target.files[0];
        setSelectFolder(fileName);
    };

    const uploadTemplate = (formData) => {
        let data = new FormData();
        data.append('htmlFile', selectFolder);
        data.append('orgId', userDetails.orgId);
        // data.append('clinicId', clinicDetails.clinicId);
        data.append('emailTemplateName', formData.templateName);
        uploadTemplateForCampaign(data)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                });
                reset({ templateName: "" });
                setOpenUploadTemplate({ action: false, data: null });
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data.messages,
                });
            })
    };

    const cancel = () => {
        reset({ templateName: "" });
        setOpenUploadTemplate({ action: false, data: null });
    };

    return (
        <div>
            <material.Dialog open={openUploadTemplate.action} fullWidth maxWidth="sm" hideBackdrop>
                <material.DialogTitle>Upload HTML Template</material.DialogTitle>
                <material.DialogContent>
                    <material.TextField
                        required
                        margin="dense"
                        id="subject"
                        label="Template Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        {...register("templateName", { required: true })}
                    />
                    <label for="excelFile">Choose HTML File</label><br />
                    <input
                        type="file"
                        accept=".html"
                        margin="dense"
                        onChange={chooseFile}
                    />
                </material.DialogContent>
                <material.DialogActions>
                    <span className='m-2'>
                        <material.Button sx={{ textTransform: "none", mr: 2 }} variant="outlined" color='error' onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                        <material.Button sx={{ textTransform: "none" }} variant="outlined" onClick={handleSubmit(uploadTemplate)} startIcon={<material.DoneIcon />} disabled={selectFolder === null}>Save</material.Button>
                    </span>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(UploadCrmTemplate)