import axios from "axios";
import { rootApiUrl } from "../environment/Environment";

export const saveOrUpdateSupplierConfiguration = async (treatmentData) => {
    return axios.post(`${rootApiUrl}/api/v0.2/admin/save-or-update-supplier-configuration`, treatmentData)
};

export const getAllSupplierConfig = async (payload) => {
    return axios.get(`${rootApiUrl}/api/v0.2/admin/get-all-supplier-config`, { params: { ...payload } })
};

export const getCart = async (payload) => {
    return axios.get(`${rootApiUrl}/api/v0.2/cart/get-cart`, { params: { ...payload } })
};

export const updateProductQuantity = async (payload) => {
    return axios.put(`${rootApiUrl}/api/v0.2/cart/update-cart-entry`, payload)
};

export const getAllSupplierClinicConfig = async (payload) => {
    return axios.get(`${rootApiUrl}/api/v0.2/admin/get-all-supplier-clinic-configuration`, { params: { ...payload } })
};

export const saveOrUpdateSupplierClinicConfiguration = async (payload) => {
    return axios.post(`${rootApiUrl}/api/v0.2/admin/save-or-update-supplier-clinic-configuration`, payload)
};

export const getAllSupplierClinicConfigRequest = async (payload) => {
    return axios.get(`${rootApiUrl}/api/v0.2/suppliers/get-all-supplier-configuration-request`, { params: { ...payload } })
};

export const getBlankSupplierForm = async (payload) => {
    return axios.get(`${rootApiUrl}/api/v0.2/suppliers/get-blank-supplier-form`, { params: { ...payload } })
};

export const uploadSupplierForm = async (payload) => {
    return axios.post(`${rootApiUrl}/api/v0.2/suppliers/upload-filled-supplier-form`, payload)
};

export const paymentGateway = async (payload) => {
    return axios.post(`${rootApiUrl}/api/v0.2/cart/payment-gateway`, payload)
};