import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { useTheme } from '@mui/material';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Navbar from '../../navbar/Navbar';
import { getAllSupplierClinicConfigRequest } from '../../../services/SupplierConfigService';
import AddOrEditSupplierForm from './AddOrEditSupplierForm';
import { connect } from 'react-redux';
import Snackbar from '../../toastrmessage/Snackbar';
import { rootApiUrl } from '../../../environment/Environment';

function SupplierForms(props) {

    localStorage.setItem("menuName", "Supplier Forms");
    const theme = useTheme();
    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [getSupplierForms, setGetSupplierForms] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [openAddOrEditSupplierForm, setOpenAddOrEditSupplierForm] = useState({ action: false, rowData: null });

    useEffect(() => {
        getSupplierFormData()
    }, []);

    const getSupplierFormData = (paginationData) => {
        setIsLoading(true);
        const payload = {
            limit: paginationData ? paginationData.limit : 10,
            page: paginationData ? paginationData.page : 0,
            supplierName: paginationData ? paginationData.supplierName : "",
            clinicId: clinicDetails.clinicId
        }
        getAllSupplierClinicConfigRequest(payload)
            .then((resp) => {
                setGetSupplierForms(resp.data.data.results);
                setPage(Number(resp.data.data.currentPage));
                setRowsPerPage(Number(resp.data.data.limit));
                setTotalElements(resp.data.data.totalItems);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const payload = {
            limit: rowsPerPage,
            page: newPage,
        }
        getSupplierFormData(payload)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        const payload = {
            limit: event.target.value,
            page: page
        }
        getSupplierFormData(payload)
    };

    const searchSupplier = (value) => {
        const payload = {
            supplierName: value
        }
        getSupplierFormData(payload)
    };

    const addSupplierForm = () => {
        setOpenAddOrEditSupplierForm({ action: true, rowData: null });
    };

    const downloadForm = (downloadLink) => {
        window.open(`${rootApiUrl}/agl-link?key=${downloadLink}`, "_blank")
    };

    const downloadSupportingDoc = (downloadLink) => {
        window.open(`${rootApiUrl}/agl-link?key=${downloadLink}`, "_blank")
    };

    return (
        <material.Grid className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 12
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 12
                },
            }}
        >
            <Navbar />
            <div className="row">
                <div className="col-6">
                    <span style={{ marginLeft: 5 }}>
                        <material.TextField
                            fullWidth
                            variant="standard"
                            label="Search Supplier"
                            onChange={(e) => searchSupplier(e.target.value)}
                            sx={{ mt: -2, width: "30ch" }}
                        />
                    </span>
                </div>
                <div className="col-6">
                    <span className='float-end'>
                        <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addSupplierForm}>Add Supplier Form</material.Button>
                    </span>
                </div>
                <div className="col-12 mt-3">
                    <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <material.TableContainer sx={{ maxHeight: 460 }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell>Supplier Name</StyledTableCell>
                                        <StyledTableCell>Form Link</StyledTableCell>
                                        <StyledTableCell>Supporting Docs Link</StyledTableCell>
                                        <StyledTableCell>Submission Date</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={15}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={15} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {getSupplierForms.length ? getSupplierForms.map((rowData, k) => (
                                                <material.TableRow key={k}>
                                                    <material.TableCell>{rowData.supplierName}</material.TableCell>
                                                    <material.TableCell>
                                                        <material.Link component="button" variant="body2" title='Click to download' onClick={() => downloadForm(rowData.formLink)}>{rowData.formLink.split("/").pop()}</material.Link>
                                                    </material.TableCell>
                                                    <material.TableCell>{rowData.supportingDocumentLinks.map((ele, k) => (
                                                        <div key={k}>
                                                            <material.Link component="button" variant="body2" title='Click to download' onClick={() => downloadSupportingDoc(ele)}>{ele.split("/").pop()}</material.Link>
                                                        </div>
                                                    ))}</material.TableCell>
                                                    <material.TableCell>{rowData.applicationSubmissionDate}</material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={15}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>
                                    )}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                        <hr />
                        <material.TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={totalElements}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </material.Paper>
                </div>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <AddOrEditSupplierForm
                openAddOrEditSupplierForm={openAddOrEditSupplierForm}
                setOpenAddOrEditSupplierForm={setOpenAddOrEditSupplierForm}
                clinicDetails={clinicDetails}
                userDetails={userDetails}
                getSupplierFormData={getSupplierFormData}
            />
        </material.Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(SupplierForms);