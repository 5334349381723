import React, { useEffect, useState, useRef } from 'react';
import { material } from '../../../library/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { createBlankTreatmentPlan, getClinicDetailsByClinicId, getImageListCosmetic, getTreatmentPlanByPlanId, saveConsentFormAsFile, saveTreatmentPlan, uploadCosmeticTreatmentImage, uploadCosmeticTreatmentImageFile } from '../../../services/TreatmentPlanService';
import moment from 'moment';
import Snackbar from '../../toastrmessage/Snackbar';
import { changeDateAndTimeFormate, changeDateFormate } from '../../../date-and-time-format/DateAndTimeFormat';
import AddTreatmentPlan from './AddTreatmentPlan';
import Webcam from 'react-webcam';
import { getBddFormDataByFormId } from '../../../services/BddFormService';
import SendBddForm from '../../dialog/SendBddForm';
import { socket } from '../../../socket/Socket';
import { getPatientById, uploadPatientGeneralImage, uploadPatientGeneralImageFile } from '../../../services/PatientService';
import AddSupportingDoc from '../../dialog/AddSupportingDoc';
import { sendConsentForm } from '../../../services/ConsentFormService';
import ConsentFormAW from '../consent-form/ConsentFormAW';
import ConsentFormDF from '../consent-form/ConsentFormDF';
import { changePlanStatus } from '../../../services/PrescriberService';
import StatusChangeReasonDialog from '../../dialog/StatusChangeReasonDialog';
import ViewPatientProfile from '../../dialog/ViewPatientProfile';
import AddBddForm from '../../dialog/AddBddForm';
import SkinBooster from '../consent-form/SkinBooster';
import { deleteTreatmentPlanDetails, getTreatmentDetailsByTreatmentPlanRequestId } from '../../../services/TreatmentListService';
import HyalaseTreatment from '../consent-form/HyalaseTreatment';
import Navbar from '../../navbar/Navbar';
import { rootApiUrl } from '../../../environment/Environment';
import { useTheme } from '@mui/material/styles';
import { uploadSkinBeforeImage } from '../../../services/SkinTreatmentService';
import LocalAnaesthetic from '../consent-form/injectable/LocalAnaesthetic';
import Threads from '../consent-form/injectable/Threads';
import ExomideScalp from '../consent-form/injectable/ExomideScalp';
import ViewProtocolList from '../clinic-config/protocol/ViewProtocolList';
import Camera from '../camera/Camera';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';

var treatmentData = [];
var uploadImage = [];
var allImages = [];
var uniqueTreatmentName = [];
const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
    facingMode: FACING_MODE_USER,
    width: 1920,
    height: 1080
};

var bddFormId;
var patientData;

function TreatmentPlan(props) {

    const { userData, clinicData, patientIds } = props;
    const [date, setDate] = React.useState(moment().format("YYYY-MM-DDTHH:mm:ss"));
    const [openConsentForm, setOpenConsentForm] = useState({ action: true, patientData: null });
    const [openTreatmentPlan, setOpenTreatmentPlan] = useState({ action: false, treatmentData: null });
    const [clinic, setClinic] = useState({});
    const [hideShow, setHideShow] = useState(true);
    const [blankTreatmentData, setBlankTreatmentData] = useState([]);
    const [treatmentPlanData, setTreatmentPlanData] = useState([]);
    const [openFullImage, setOpenFullImage] = useState({ "open": false, "image": "" });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [hideAndShowPatientDetails, setHideAndShowPatientDetails] = useState(true);
    const [hideAndShowTreatmentPlan, setHideAndShowTreatmentPlan] = useState(true);
    const [openSendBddForm, setOpenSendBddForm] = useState({ "action": false, "data": {} });
    const [bddFormData, setBddFormData] = useState({});
    const [stockUpdated, setStockUpdated] = useState();
    const [consentUpdated, setConsentUpdated] = useState();
    const [getPatientData, setGetPatientData] = useState({})
    const [addSupportingDoc, setAddSupportingDoc] = useState({ "action": false, data: [] });
    const [getConsentFormAWData, setGetConsentFormAWData] = useState(null);
    const [getConsentFormDFData, setGetConsentFormDFData] = useState(null);
    const [getConsentFormHTData, setGetConsentFormHTData] = useState(null);
    const [getConsentFormLAData, setGetConsentFormLAData] = useState(null);
    const [getConsentFormThreadsData, setGetConsentFormThreadsData] = useState(null);
    const [getConsentFormExomideScalpData, setGetConsentFormExomideScalpData] = useState(null);
    const [getConsentFormSBData, setGetConsentFormSBData] = useState(null);
    const [openPatientProfile, setOpenPatientProfile] = useState({ action: false, patientData: null });
    const [openStatusChangeDialog, setOpenStatusChangeDialog] = useState({
        "action": false, "data": ""
    });
    const [openBddForm, setOpenBddForm] = useState({ action: false, patientData: null })
    const [openDocumentUpload, setOpenDocumentUpload] = useState({ action: false, fileUpload: false });
    const [openUploadPhoto, setOpenUploadPhoto] = useState({ action: false, uploadType: null });
    const [cosmeticBeforeImage, setCosmeticBeforeImage] = useState([]);
    const [consentFormAdded, setConsentFormAdded] = useState(false);
    const [isLoading, setIsLoading] = useState(null);
    const [openViewProtocolList, setOpenViewProtocolList] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const patientData = location.state ? location.state.patientData : "";
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;

    socket.on("bdd-submitted-by-patient", (res) => {
        setStockUpdated(res)
        setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": "BDD Form Received",
        })
    });

    socket.on("concent-status", (res) => {
        setConsentUpdated(res)
        setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": "Consent Form Received",
        })
    });

    socket.on("no-secondary", (resp) => {
        if (resp) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Connection lost or No Secondary found. Please Login into Secondary to use this function",
            })
        };
    });

    socket.on("new-photo-added", (resp) => {
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Photo Uploaded",
        })
        getCosmeticImageList(blankTreatmentData);
    });

    socket.on("new-concent-added", (resp) => {
        setConsentFormAdded(resp[0]);
        setOpenSnackBar({
            "action": true,
            "type": "success",
            "message": resp[1],
        })
    });

    localStorage.setItem("menuName", `Treatment Plan for ${patientData.name ? patientData.name : patientData.firstName + " " + patientData.lastName}`);

    useEffect(() => {
        getClinicByClinicId()
        createBlankTreatment()
        treatmentData = [];
        // if (patientData?.bdd_form_id) {
        //     getBddFormData()
        // };
        // if (patientData.bdd_form_id === null) {
        //     setOpenSendBddForm({ action: true, data: patientData })
        // };
        // getCosmeticImageList();
    }, []);

    useEffect(() => {
        getPatientDataById()
        // if (getPatientData.bdd_form_id && stockUpdated) {
        //     getBddFormData()
        // };
        // if (getPatientData.bdd_form_id && stockUpdated) {
        //     setOpenSendBddForm({ action: true, data: patientData })
        // };
    }, [stockUpdated]);

    const getClinicByClinicId = async () => {
        await getClinicDetailsByClinicId(clinicDetails.clinicId)
            .then((resp) => {
                setClinic(resp.data.data)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getPatientDataById = async () => {
        await getPatientById(patientData.patientId)
            .then((resp) => {
                setGetPatientData(resp.data.data);
                if (resp.data.data.bdd_form_id) {
                    getBddFormData(resp.data.data.bdd_form_id)
                } else if (resp.data.data.bdd_form_id && stockUpdated) {
                    getBddFormData()
                } else {
                    setOpenBddForm({ action: true, patientData: resp.data.data })
                };
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getBddFormData = async (bdd_form_id) => {
        await getBddFormDataByFormId(bdd_form_id)
            .then((resp) => {
                setBddFormData(resp.data.data)
                if (!resp.data.data.isValid) {
                    setOpenSendBddForm({ action: true, data: resp.data.data })
                };
                if (resp.data.data.formId) {
                    if (resp.data.data.is_assessed) {
                        if (resp.data.data.daysRemain > 0) {
                            if (resp.data.data.assessment_result === "POSITIVE") {
                                setAddSupportingDoc({ action: true })
                            }
                            else {
                                setBddFormData(resp.data.data)
                            };
                        }
                        else {
                            // setOpenSendBddForm({ action: true, data: resp.data })
                            setOpenBddForm({ action: true, patientData: resp.data.data })
                        };
                    }
                    else {
                        bddFormId = resp.data.data.formId;
                    };
                }
                else {
                    // setOpenSendBddForm({ action: true, data: resp.data })
                    setOpenBddForm({ action: true, patientData: resp.data.data })
                };
                bddFormId = resp.data.data.formId;
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const createBlankTreatment = async () => {
        let obj = {
            patientId: patientData.patientId,
            timeStamp: date
        };
        await createBlankTreatmentPlan(obj)
            .then((resp) => {
                setBlankTreatmentData(resp.data.data)
                getTreatmentData(resp.data.data.treatmentPlanRequestId)
                getCosmeticImageList(resp.data.data)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getCosmeticImageList = (patientData) => {
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Please wait fetching data",
        })
        let payload = {
            treatmentPlanRequestId: patientData.treatmentPlanRequestId,
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId,
            typeOfImage: "before_image",
            patientId: patientData.patientId
        }
        getImageListCosmetic(payload)
            .then((resp) => {
                setCosmeticBeforeImage(resp.data.data);
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Image list fetch complete",
                })
                setTimeout(() => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "info",
                        "message": "Please wait while images are loading",
                    })
                }, 3000);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getTreatmentPlanData = async (value) => {
        await getTreatmentPlanByPlanId(value)
            .then((resp) => {
                // setTreatmentPlanData(resp.data)
            })
    };

    const goBack = () => {
        if (patientData.component === "patientList") {
            navigate("/patient-list")
        } else {
            navigate("/patient-list/view-patient-details", { state: { patientData } })
        }
    };

    const openTreatment = () => {
        setOpenTreatmentPlan({ action: true, treatmentData: null })
    };

    const getTreatmentData = async (treatmentPlanRequestId) => {
        await getTreatmentDetailsByTreatmentPlanRequestId(treatmentPlanRequestId)
            .then((resp) => {
                setTreatmentPlanData(resp.data.data)
                setHideShow(false)
                let array = resp.data?.data?.map((ele) => ele.treatmentName)
                uniqueTreatmentName = array.filter((value, index, self) => {
                    return self.indexOf(value) === index;
                });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const sendConsent = async () => {
        for (let i = 0; i < uniqueTreatmentName.length; i++) {
            if (uniqueTreatmentName[i] === "Anti-Wrinkle") {
                let obj = {
                    patientName: getPatientData.name,
                    email: getPatientData.email,
                    treatmentPlanRequestId: blankTreatmentData.treatmentPlanRequestId,
                    url: 'https://app.aestheticsconsults.com.au/consent-form/A_W'
                }
                await sendConsentForm(obj)
                    .then((resp) => {
                        setOpenSnackBar({
                            "action": true,
                            "type": "success",
                            "message": resp.data.messages,
                        })
                    })
                    .catch((error) => {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.messages,
                        })
                    })
            } else if (uniqueTreatmentName[i] === "Dermal Filler") {
                let obj = {
                    patientName: getPatientData.name,
                    email: getPatientData.email,
                    treatmentPlanRequestId: blankTreatmentData.treatmentPlanRequestId,
                    url: 'https://app.aestheticsconsults.com.au/consent-form/D_F'
                }
                await sendConsentForm(obj)
                    .then((resp) => {
                        setOpenSnackBar({
                            "action": true,
                            "type": "success",
                            "message": resp.data.messages,
                        })
                    })
                    .catch((error) => {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.messages,
                        })
                    })
            } else if (uniqueTreatmentName[i] === "Skin Booster") {
                let obj = {
                    patientName: getPatientData.name,
                    email: getPatientData.email,
                    treatmentPlanRequestId: blankTreatmentData.treatmentPlanRequestId,
                    url: 'https://app.aestheticsconsults.com.au/consent-form/S_B'
                }
                await sendConsentForm(obj)
                    .then((resp) => {
                        setOpenSnackBar({
                            "action": true,
                            "type": "success",
                            "message": resp.data.messages,
                        })
                    })
                    .catch((error) => {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.messages,
                        })
                    })
            } else if (uniqueTreatmentName[i] === "Skin Booster") {
                let obj = {
                    patientName: getPatientData.name,
                    email: getPatientData.email,
                    treatmentPlanRequestId: blankTreatmentData.treatmentPlanRequestId,
                    url: 'https://app.aestheticsconsults.com.au/consent-form/H_T'
                }
                await sendConsentForm(obj)
                    .then((resp) => {
                        setOpenSnackBar({
                            "action": true,
                            "type": "success",
                            "message": resp.data.messages,
                        })
                    })
                    .catch((error) => {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.messages,
                        })
                    })
            };
        };
    };

    const addPhoto = () => {
        setOpenUploadPhoto({ action: true, uploadType: "click_and_upload" })
    };

    const addConsentForm = () => {
        setOpenConsentForm({ action: false, patientData: getPatientData })
    };

    const showImage = (image) => {
        setOpenFullImage({ "open": true, "image": image })
    };

    const viewBddForm = () => {
        navigate("/user_management/patient-list/edit-patient/treatment-plan/view-bdd-form", { state: { patientData } })
    };

    const saveAsDraft = async () => {
        let obj = {
            treatmentPlanRequestId: blankTreatmentData.treatmentPlanRequestId,
            patientId: blankTreatmentData.patientId,
            injectorId: blankTreatmentData.injectorId,
            timeStamp: blankTreatmentData.timeStamp,
            // targetAreaBefore: uploadImage,
            treatmentPlan: treatmentPlanData,
            clinicId: clinicDetails.clinicId,
            bdd_form_id: bddFormId !== null ? bddFormId : "",
            bdd_support_image: addSupportingDoc?.data ? addSupportingDoc.data : ""
        };
        await saveTreatmentPlan(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                allImages = [];
                uploadImage = [];
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const submitConsentForm = async () => {
        let obj = {
            treatmentPlanRequestId: blankTreatmentData.treatmentPlanRequestId,
            patientId: blankTreatmentData.patientId,
            injectorId: blankTreatmentData.injectorId,
            timeStamp: blankTreatmentData.timeStamp,
            // targetAreaBefore: uploadImage,
            treatmentPlan: treatmentPlanData,
            clinicId: clinicDetails.clinicId,
            bdd_form_id: bddFormId !== null ? bddFormId : "",
            bdd_support_image: addSupportingDoc?.data ? addSupportingDoc.data : ""
        };
        await saveTreatmentPlan(obj)
            .then((resp) => {
                changePlanStatus({ treatmentPlanRequestId: blankTreatmentData.treatmentPlanRequestId, "status": "PENDING" })
                    .then((res) => {
                        setOpenSnackBar({
                            "action": true,
                            "type": "success",
                            "message": resp.data.messages,
                        })
                        setTimeout(() => {
                            navigate("/treatment-queue")
                        }, 2000)
                        allImages = [];
                        uploadImage = [];
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            setOpenSnackBar({
                                "action": true,
                                "type": "error",
                                "message": error.response.data.data.info,
                            })
                        } else {
                            setOpenSnackBar({
                                "action": true,
                                "type": "error",
                                "message": error.response.data.messages,
                            })
                        }
                    })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };
    const approvedTreatment = () => {
        setOpenStatusChangeDialog({ action: true, data: { treatmentPlanData, parentComponent: "treatmentPlan" } })
    };
    const getStatusChangeData = async (data) => {
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Please wait....",
        })
        let obj = {
            treatmentPlanRequestId: blankTreatmentData.treatmentPlanRequestId,
            patientId: blankTreatmentData.patientId,
            injectorId: blankTreatmentData.injectorId,
            timeStamp: blankTreatmentData.timeStamp,
            // targetAreaAfter: [],
            // targetAreaBefore: uploadImage,
            treatmentPlan: treatmentPlanData,
            clinicId: clinicDetails.clinicId,
            bdd_form_id: bddFormId !== null ? bddFormId : "",
            bdd_support_image: addSupportingDoc?.data ? addSupportingDoc.data : ""
        };
        await saveTreatmentPlan(obj)
            .then((resp) => {
                changePlanStatus({ treatmentPlanRequestId: blankTreatmentData.treatmentPlanRequestId, "status": "APPROVED", "reason": data })
                    .then((res) => {
                        setOpenSnackBar({
                            "action": true,
                            "type": "success",
                            "message": res.data.messages,
                        })
                        setTimeout(() => {
                            navigate("/treatment-queue")
                        }, 2000);
                        allImages = [];
                        uploadImage = [];
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            setOpenSnackBar({
                                "action": true,
                                "type": "error",
                                "message": error.response.data.data.info,
                            })
                        } else {
                            setOpenSnackBar({
                                "action": true,
                                "type": "error",
                                "message": error.response.data.messages,
                            })
                        }
                    })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const viewPatientProfile = () => {
        setOpenPatientProfile({ action: true, patientData: patientData })
    };

    const addBdd = () => {
        setOpenBddForm({ action: true, patientData: getPatientData })
    };

    const deleteRow = async (rowData) => {
        await deleteTreatmentPlanDetails(rowData.id)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                getTreatmentData(blankTreatmentData.treatmentPlanRequestId)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const editRow = (rowData) => {
        setOpenTreatmentPlan({ action: true, treatmentData: rowData })
    };

    const uploadConsent = () => {
        setOpenDocumentUpload({ action: true, fileUpload: false })
    };

    const selectAndUploadPhoto = () => {
        setOpenUploadPhoto({ action: true, uploadType: "upload_file" })
    };

    const addPhotoUsingSecondary = () => {
        let obj = {
            blankTreatmentData,
            clinicDetails,
            userDetails,
        }
        socket.emit("add-photo-kiosk", obj)
    };

    const addConsentUsingSecondary = () => {
        let consentData = {
            blankTreatmentData,
            uniqueTreatmentName,
            patientData: getPatientData,
            clinicDetails: clinicDetails
        }
        socket.emit("add-concent-kiosk", consentData);
    };

    const viewProtocol = () => {
        setOpenViewProtocolList(true);
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <div className='row'>
                    <div className='col-6'>
                        {/* <span><material.Typography variant="h5">Treatment Plan</material.Typography></span> */}
                    </div>
                    <div className='col-6'>
                        <span className="float-end">
                            {getPatientData.bdd_form_id === null ? (
                                <material.Button variant="contained" sx={{ textTransform: "none", mr: 2 }} size='small' onClick={addBdd} startIcon={<material.AddIcon />}>Add BDD</material.Button>
                            ) : null}
                            <material.Button variant="contained" sx={{ textTransform: "none", mr: 2 }} size='small' onClick={viewProtocol} startIcon={<material.VisibilityIcon />}>View Protocol</material.Button>
                            <material.Button variant="contained" sx={{ textTransform: "none", mr: 2 }} size='small' onClick={viewPatientProfile} startIcon={<material.VisibilityIcon />}>View Patient Profile</material.Button>
                            {/* {getPatientData.bdd_form_id ? (
                                <material.Button variant="contained" sx={{ textTransform: "none" }}
                                    color={!bddFormData.isValid && bddFormData.daysRemain > 0 ? "warning" : bddFormData.isValid && bddFormData.daysRemain > 0 ? "primary" : "error"}
                                    className='me-2' onClick={viewBddForm} startIcon={<material.VisibilityIcon />}>View-BDD-Form</material.Button>
                            ) : null} */}
                            <material.Button variant="contained" sx={{ textTransform: "none" }} size='small' onClick={goBack} startIcon={<material.ReplyIcon />}>Back</material.Button>
                        </span>
                    </div>
                </div>
                <material.Paper sx={{ p: 4, mt: 2, pb: 6 }}>
                    <div className='d-flex flex-row'>
                        {hideAndShowPatientDetails ? (
                            <material.ArrowDropDownIcon onClick={() => setHideAndShowPatientDetails(false)} sx={{ cursor: "pointer" }} />
                        ) : (
                            <material.ArrowDropUpIcon onClick={() => setHideAndShowPatientDetails(true)} sx={{ cursor: "pointer" }} />
                        )}
                        <h5 className='visit-report-section-heading'>Patient Details</h5>
                    </div>
                    {hideAndShowPatientDetails && (
                        <div className='row ms-2'>
                            <div className='col-6 mt-2'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='fw-bold'>
                                            <td>Patient Name</td>
                                            <td>{getPatientData.name}</td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td>Patient Date of Birth</td>
                                            <td>{changeDateFormate(getPatientData.dateOfBirth)}</td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td>Injector Id</td>
                                            <td>{userDetails.userId}</td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td>Injector Name</td>
                                            <td>{userDetails.name}</td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td>Clinic Name</td>
                                            <td>{clinic.clinicName}</td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td>Clinic Address</td>
                                            <td>{clinic.registeredOfficeAddress}</td>
                                        </tr>
                                        <tr className='fw-bold'>
                                            <td>Time Stamp</td>
                                            <td>{changeDateAndTimeFormate(date)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-6">
                                <span>
                                    {cosmeticBeforeImage.length ? cosmeticBeforeImage.map((ele, i) => (
                                        <img
                                            key={i}
                                            src={`${rootApiUrl}/get-skin-treatment-image?key=${ele}`}
                                            height={200} width="30%"
                                            style={{ margin: 3 }}
                                        />
                                    )) : ""}
                                </span>
                            </div>
                        </div>
                    )}
                    <hr />
                    <div className='row'>
                        <div>
                            <span className="float-end">
                                <material.Button className='me-2' variant="contained" size='small' sx={{ textTransform: "none" }} startIcon={<material.AddAPhotoIcon />} onClick={addPhotoUsingSecondary} >Add Photo Using Secondary</material.Button>
                                <material.Button className='me-2' variant="contained" size='small' sx={{ textTransform: "none" }} startIcon={<material.UploadFileIcon />} onClick={selectAndUploadPhoto}>Upload Photo</material.Button>
                                <material.Button className='me-2' variant="contained" size='small' sx={{ textTransform: "none" }} startIcon={<material.AddAPhotoIcon />} onClick={addPhoto} >Add Photo</material.Button>
                                <material.Button variant="contained" size='small' sx={{ textTransform: "none" }} className='me-2' startIcon={<material.AddIcon />} onClick={openTreatment} >Add Treatment</material.Button>
                            </span>
                        </div>
                        <div hidden={hideShow}>
                            <div className='d-flex flex-row'>
                                {hideAndShowTreatmentPlan ? (
                                    <material.ArrowDropDownIcon onClick={() => setHideAndShowTreatmentPlan(false)} sx={{ cursor: "pointer" }} />
                                ) : (
                                    <material.ArrowDropUpIcon onClick={() => setHideAndShowTreatmentPlan(true)} sx={{ cursor: "pointer" }} />
                                )}
                                <h5 className='visit-report-section-heading'>Treatment Plan</h5>
                            </div>
                            {hideAndShowTreatmentPlan && (
                                <div>
                                    <material.TableContainer>
                                        <material.Table>
                                            <material.TableHead >
                                                <material.TableRow>
                                                    <StyledTableCell>Treatment</StyledTableCell>
                                                    <StyledTableCell>Area</StyledTableCell>
                                                    <StyledTableCell>Product</StyledTableCell>
                                                    <StyledTableCell>Qty</StyledTableCell>
                                                    <StyledTableCell>Action</StyledTableCell>
                                                </material.TableRow>
                                            </material.TableHead>
                                            <material.TableBody>
                                                {treatmentPlanData.length ? treatmentPlanData.map((row, i) => (
                                                    <material.TableRow
                                                        key={i}
                                                        sx={{
                                                            '&:last-child td, &:last-child th': { border: 0 }
                                                        }}
                                                    >
                                                        <material.TableCell sx={{ pt: 3, pb: 3 }} size='small' component="th" scope="row">{row.treatmentName}</material.TableCell>
                                                        <material.TableCell size='small'>{row.treatmentArea}</material.TableCell>
                                                        <material.TableCell size='small'>{row.product}</material.TableCell>
                                                        <material.TableCell size='small'>{row.qty}</material.TableCell>
                                                        <material.TableCell size='small'>
                                                            <material.IconButton title='Edit Treatment' aria-label="delete" size="large" onClick={() => editRow(row)}>
                                                                <material.CreateIcon color='info' />
                                                            </material.IconButton>
                                                            <material.IconButton title='Delete Treatment' aria-label="delete" size="large" onClick={() => deleteRow(row)}>
                                                                <material.DeleteIcon color='warning' />
                                                            </material.IconButton>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )) : (
                                                    <material.TableRow >
                                                        <material.TableCell colSpan={5}>
                                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                        </material.TableCell>
                                                    </material.TableRow>
                                                )}
                                            </material.TableBody>
                                        </material.Table>
                                    </material.TableContainer>
                                    {/* )) : ""} */}
                                </div>
                            )}
                            {!consentFormAdded ? (
                                <div className='mb-3'>
                                    {openConsentForm.action === true && treatmentPlanData.length ? (
                                        <span className="float-end mt-5 me-3">
                                            <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' startIcon={<material.AddIcon />} onClick={addConsentUsingSecondary}>Add Consent Using Secondary</material.Button>
                                            <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' startIcon={<material.UploadFileIcon />} onClick={uploadConsent}>Upload Consent</material.Button>
                                            <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' startIcon={<material.AddIcon />} onClick={addConsentForm}>Add Consent</material.Button>
                                            <material.Button variant="contained" sx={{ textTransform: "none" }} size='small' startIcon={<material.ForwardToInboxIcon />} onClick={sendConsent}>Send Consent</material.Button>
                                        </span>
                                    ) : ""}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <hr />
                    {uniqueTreatmentName.length ? uniqueTreatmentName.map((treatment, i) => (
                        <div key={i}>
                            {treatment === "Anti-Wrinkle" && openConsentForm.action === false ? (
                                <div hidden={getConsentFormAWData}>
                                    <ConsentFormAW
                                        blankTreatmentData={blankTreatmentData}
                                        openConsentForm={openConsentForm}
                                        setGetConsentFormAWData={setGetConsentFormAWData}
                                        callForm="primary-user"
                                    />
                                </div>
                            ) : null}
                            {treatment === "Dermal Filler" && openConsentForm.action === false ? (
                                <div hidden={getConsentFormDFData}>
                                    <ConsentFormDF
                                        blankTreatmentData={blankTreatmentData}
                                        openConsentForm={openConsentForm}
                                        setGetConsentFormDFData={setGetConsentFormDFData}
                                        callForm="primary-user"
                                    />
                                </div>
                            ) : null}
                            {treatment === "Skin Booster" && openConsentForm.action === false ? (
                                <div hidden={getConsentFormSBData}>
                                    <SkinBooster
                                        blankTreatmentData={blankTreatmentData}
                                        openConsentForm={openConsentForm}
                                        setGetConsentFormSBData={setGetConsentFormSBData}
                                        callForm="primary-user"
                                    />
                                </div>
                            ) : null}
                            {treatment === "Hyalase Treatment" && openConsentForm.action === false ? (
                                <div hidden={getConsentFormHTData}>
                                    <HyalaseTreatment
                                        blankTreatmentData={blankTreatmentData}
                                        openConsentForm={openConsentForm}
                                        setGetConsentFormHTData={setGetConsentFormHTData}
                                        callForm="primary-user"
                                    />
                                </div>
                            ) : null}
                            {treatment === "Local Anaesthetic" && openConsentForm.action === false ? (
                                <div hidden={getConsentFormLAData}>
                                    <LocalAnaesthetic
                                        blankTreatmentData={blankTreatmentData}
                                        openConsentForm={openConsentForm}
                                        setGetConsentFormLAData={setGetConsentFormLAData}
                                        callForm="primary-user"
                                        clinicDetails={clinicDetails}
                                    />
                                </div>
                            ) : null}
                            {treatment === "Threads" && openConsentForm.action === false ? (
                                <div hidden={getConsentFormThreadsData}>
                                    <Threads
                                        blankTreatmentData={blankTreatmentData}
                                        openConsentForm={openConsentForm}
                                        setGetConsentFormThreadsData={setGetConsentFormThreadsData}
                                        callForm="primary-user"
                                        clinicDetails={clinicDetails}
                                    />
                                </div>
                            ) : null}
                            {treatment === "Exomide Scalp" && openConsentForm.action === false ? (
                                <div hidden={getConsentFormExomideScalpData}>
                                    <ExomideScalp
                                        blankTreatmentData={blankTreatmentData}
                                        openConsentForm={openConsentForm}
                                        setGetConsentFormExomideScalpData={setGetConsentFormExomideScalpData}
                                        callForm="primary-user"
                                        clinicDetails={clinicDetails}
                                    />
                                </div>
                            ) : null}
                        </div>
                    )) : null}
                    {getConsentFormAWData || getConsentFormDFData || consentUpdated || openDocumentUpload.fileUpload || getConsentFormHTData || getConsentFormSBData || getConsentFormThreadsData || getConsentFormLAData || getConsentFormExomideScalpData ? (
                        <>
                            <span className="float-end me-2" hidden={userDetails.isDoctor}>
                                <material.Button variant="contained" sx={{ textTransform: "none" }} size='small' onClick={saveAsDraft} className='me-3'>Save As Draft</material.Button>
                                <material.Button variant="contained" sx={{ textTransform: "none" }} size='small' onClick={submitConsentForm}>Submit</material.Button>
                            </span>
                            <span className="float-end me-2" hidden={!userDetails.isDoctor}>
                                <material.Button variant="contained" color='success' sx={{ textTransform: "none" }} size='small' onClick={approvedTreatment}>Approved Treatment</material.Button>
                            </span>
                        </>
                    ) : consentFormAdded ? (
                        <>
                            <span className="float-end me-2" hidden={userDetails.isDoctor}>
                                <material.Button variant="contained" sx={{ textTransform: "none" }} size='small' onClick={saveAsDraft} className='me-3'>Save As Draft</material.Button>
                                <material.Button variant="contained" sx={{ textTransform: "none" }} size='small' onClick={submitConsentForm}>Submit</material.Button>
                            </span>
                            <span className="float-end me-2" hidden={!userDetails.isDoctor}>
                                <material.Button variant="contained" color='success' sx={{ textTransform: "none" }} size='small' onClick={approvedTreatment}>Approved Treatment</material.Button>
                            </span>
                        </>
                    ) : null}
                </material.Paper>
                <AddTreatmentPlan
                    openTreatmentPlan={openTreatmentPlan}
                    setOpenTreatmentPlan={setOpenTreatmentPlan}
                    getTreatmentData={getTreatmentData}
                    blankTreatmentData={blankTreatmentData}
                />
                <Snackbar
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                />
                <ShowFullImage
                    openFullImage={openFullImage}
                    setOpenFullImage={setOpenFullImage}
                />
                <SendBddForm
                    openSendBddForm={openSendBddForm}
                    setOpenSendBddForm={setOpenSendBddForm}
                    patientData={getPatientData}
                    stockUpdated={stockUpdated}
                />
                <AddSupportingDoc
                    addSupportingDoc={addSupportingDoc}
                    setAddSupportingDoc={setAddSupportingDoc}
                />
                <StatusChangeReasonDialog
                    openStatusChangeDialog={openStatusChangeDialog}
                    setOpenStatusChangeDialog={setOpenStatusChangeDialog}
                    getStatusChangeData={getStatusChangeData}
                />
            </div>
            <ViewPatientProfile
                openPatientProfile={openPatientProfile}
                setOpenPatientProfile={setOpenPatientProfile}
            />
            <AddBddForm
                openBddForm={openBddForm}
                setOpenBddForm={setOpenBddForm}
                getPatientDataById={getPatientDataById}
            />
            <DocumentUpload
                openDocumentUpload={openDocumentUpload}
                setOpenDocumentUpload={setOpenDocumentUpload}
                blankTreatmentData={blankTreatmentData}
            />
            {/* <UploadPhotoDialog
                openUploadPhoto={openUploadPhoto}
                setOpenUploadPhoto={setOpenUploadPhoto}
                blankTreatmentData={blankTreatmentData}
                userDetails={userDetails}
                clinicDetails={clinicDetails}
                getCosmeticImageList={getCosmeticImageList}
                callFrom="primary-user"
            /> */}
            <Camera
                openUploadPhoto={openUploadPhoto}
                setOpenUploadPhoto={setOpenUploadPhoto}
                blankTreatmentData={blankTreatmentData}
                userDetails={userDetails}
                clinicDetails={clinicDetails}
                getCosmeticImageList={getCosmeticImageList}
                callFrom="primary-user"
            />
            <ViewProtocolList
                openViewProtocolList={openViewProtocolList}
                setOpenViewProtocolList={setOpenViewProtocolList}
                userDetails={userDetails}
                clinicDetails={clinicDetails}
            />
        </div>
    );
};



const ShowFullImage = (props) => {

    const { openFullImage, setOpenFullImage } = props;

    return (
        <div>
            <material.Dialog maxWidth="500px" open={openFullImage.open} hideBackdrop sx={{ padding: "20px" }}>
                <material.DialogActions>
                    <material.CloseIcon onClick={() => setOpenFullImage({ "open": false, "image": "" })} sx={{ cursor: "pointer", margin: "10px" }} />
                </material.DialogActions>
                <material.DialogContent>
                    {openFullImage.image ? (
                        <img
                            src={openFullImage.image}
                        />
                    ) : ""}
                </material.DialogContent>
            </material.Dialog>
        </div>
    )
};

const DocumentUpload = (props) => {

    const { openDocumentUpload, setOpenDocumentUpload, blankTreatmentData } = props;
    const [selectFolder, setSelectFolder] = useState();
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const chooseFile = (e) => {
        let fileName = e.target.files[0];
        setSelectFolder(fileName);
    };

    const upload = () => {
        let data = new FormData();
        data.append('file', selectFolder);
        data.append('treatmentPlanRequestId', blankTreatmentData.treatmentPlanRequestId);
        data.append('patientId', blankTreatmentData.patientId);
        saveConsentFormAsFile(data)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                setOpenDocumentUpload({ action: false, fileUpload: true })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data.messages,
                })
            })
    };

    const cancel = () => {
        setOpenDocumentUpload({ action: false, fileUpload: false })
    };

    return (
        <div>
            <material.Dialog maxWidth="sm" fullWidth open={openDocumentUpload.action} hideBackdrop>
                <material.DialogTitle>Upload Consent</material.DialogTitle>
                <material.DialogContent>
                    <material.TextField
                        margin="dense"
                        id="number"
                        label="Choose File"
                        type="file"
                        fullWidth
                        variant="standard"
                        onChange={chooseFile}
                    />
                </material.DialogContent>
                <material.DialogActions className='m-2'>
                    <material.Button sx={{ textTransform: "none", mr: 1 }} variant="contained" color='error' size="small" onClick={cancel}>Cancel</material.Button>
                    <material.Button sx={{ textTransform: "none" }} variant="contained" color='info' size="small" onClick={upload}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
};

export const UploadPhotoDialog = (props) => {

    const { openUploadPhoto, setOpenUploadPhoto, blankTreatmentData, userDetails, clinicDetails, getCosmeticImageList, callFrom, patientData, getAllImagePatient, typeOfImage } = props;
    const [selectFolder, setSelectFolder] = useState();
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);
    const [close, setClose] = useState(true);
    const [stream, setStream] = useState(null);
    const [image, setImage] = useState(null);
    const [isWebcamActive, setIsWebcamActive] = useState(true);
    const videoRef = useRef(null);
    const theme = useTheme();
    const navigate = useNavigate();

    useEffect(() => {
        if (openUploadPhoto.action && openUploadPhoto.uploadType === "click_and_upload") {
            setClose(false)
            openCamera();
            setImage(null);
            allImages = [];
        }
    }, [openUploadPhoto.action === true]);

    // const addPhoto = () => {
    //     setClose(false)
    //     openCamera();
    //     setImage(null);
    //     allImages = [];
    // };

    async function openCamera() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            setStream(stream);
            videoRef.current.srcObject = stream;
        } catch (error) {
            console.error(error);
        }
        setIsWebcamActive(true)
    };

    function closeCamera() {
        if (stream) {
            stream.getTracks().forEach((track) => track.stop());
            setStream(null);
        }
        setIsWebcamActive(false)
    };


    function capturePhoto() {
        const imageSrc = videoRef.current.getScreenshot();
        uploadPhoto(imageSrc);
        closeCamera();
    };

    async function uploadPhoto(photo) {
        setImage(photo)
        allImages.push({ "image": photo });
        uploadImage.push(photo);
    };

    const switchCamera = React.useCallback(() => {
        setFacingMode(
            prevState =>
                prevState === FACING_MODE_USER
                    ? FACING_MODE_ENVIRONMENT
                    : FACING_MODE_USER
        );
    }, []);

    function toggleCamera() {
        if (stream) {
            closeCamera();
        } else {
            openCamera();
        }
    };

    const chooseFile = (e) => {
        let fileName = e.target.files;
        setSelectFolder(fileName);
    };

    const uploadFile = () => {
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Please wait...",
        })
        let data = new FormData();
        for (const file of selectFolder) {
            data.append('images', file);
        }
        data.append('typeOfImage', callFrom === "patient-details" ? "profile" : typeOfImage === "after_image" ? "after_image" : "before_image");
        data.append('treatmentPlanRequestId', callFrom === "patient-details" ? null : blankTreatmentData.treatmentPlanRequestId);
        data.append('patientId', callFrom === "patient-details" ? patientData.patientId : blankTreatmentData.patientId);
        data.append('orgId', userDetails.orgId);
        data.append('clinicId', clinicDetails.clinicId);
        data.append('date', moment().format("DD/MM/YYYY, h:mm"));
        data.append('treatmentName', "");
        data.append('clinicName', clinicDetails.clinicName);
        data.append('patientName', callFrom === "patient-details" ? patientData.firstName + " " + patientData.lastName : blankTreatmentData.patientName);
        if (callFrom === "patient-details") {
            uploadPatientGeneralImageFile(data)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data.messages,
                    })
                    getAllImagePatient();
                    setOpenUploadPhoto({ action: false, uploadType: null });
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                })
        } else {
            uploadCosmeticTreatmentImageFile(data)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data.messages,
                    })
                    if (callFrom === "primary-user") {
                        getCosmeticImageList(blankTreatmentData)
                    } else {
                        socket.emit("new-photo-added");
                        navigate("/kiosk-menu");
                    }
                    setOpenUploadPhoto({ action: false, uploadType: null });
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                })
        };
    };

    const upload = () => {
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Please wait...",
        })
        let obj = {
            image: image,
            patientId: callFrom === "patient-details" ? patientData.patientId : blankTreatmentData.patientId,
            treatmentPlanRequestId: callFrom === "patient-details" ? null : blankTreatmentData.treatmentPlanRequestId,
            orgId: userDetails.orgId,
            clinicId: clinicDetails.clinicId,
            typeOfImage: callFrom === "patient-details" ? "profile" : typeOfImage === "after_image" ? "after_image" : "before_image",
            date: moment().format("DD/MM/YYYY, h:mm"),
            treatmentName: "",
            clinicName: clinicDetails.clinicName,
            patientName: callFrom === "patient-details" ? patientData.firstName + " " + patientData.lastName : blankTreatmentData.patientName
        }
        if (callFrom === "patient-details") {
            uploadPatientGeneralImage(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data.messages,
                    })
                    setClose(false)
                    openCamera();
                    setImage(null);
                    allImages = [];
                    getAllImagePatient();
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                })
        } else {
            uploadCosmeticTreatmentImage(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data.messages,
                    })
                    if (callFrom === "primary-user") {
                        setClose(false)
                        openCamera();
                        setImage(null);
                        allImages = [];
                        getCosmeticImageList(blankTreatmentData)
                    } else {
                        socket.emit("new-photo-added");
                        // navigate("/kiosk-menu");
                        setClose(false)
                        openCamera();
                        setImage(null);
                        allImages = [];
                    }
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                })
        };
    };

    const cancel = () => {
        setOpenUploadPhoto({ action: false, uploadType: null });
        closeCamera();
        setClose(true);
        setImage(null);
        allImages = [];
    };

    return (
        <div>
            <material.Dialog maxWidth={openUploadPhoto.uploadType === "click_and_upload" ? "lg" : "sm"} fullWidth open={openUploadPhoto.action} hideBackdrop>
                <material.DialogTitle>Upload Photo
                    {/* {openUploadPhoto.uploadType === "click_and_upload" ? (
                        <span>
                            <material.IconButton
                                color='info'
                                title='Add Photo'
                                onClick={addPhoto}
                            >
                                <material.AddAPhotoIcon />
                            </material.IconButton>
                        </span>
                    ) : null} */}
                </material.DialogTitle>
                {openUploadPhoto.uploadType === "upload_file" ? (
                    <material.DialogContent>
                        <input
                            margin="dense"
                            id="fileInput"
                            label="Choose Photo"
                            accept=".jpg, .jpeg, .png"
                            fullWidth
                            type="file"
                            onChange={chooseFile}
                            multiple
                        />
                    </material.DialogContent>
                ) : (
                    <material.DialogContent>
                        {close ? null : (
                            <div className='py-2'>
                                {image ? (
                                    <>
                                        <span className='me-5'>
                                            {allImages.length ? allImages.map((ele, i) => (
                                                <img
                                                    key={i}
                                                    src={ele.image}
                                                    height={200} width="25%"
                                                    // onClick={() => showImage(ele.image)}
                                                    style={{ cursor: "pointer", margin: "5px" }}
                                                />
                                            )) : ""}
                                        </span>
                                    </>
                                ) : (
                                    <span>
                                        {isWebcamActive ? (
                                            <Webcam
                                                height={400}
                                                width="100%"
                                                screenshotFormat="image/jpeg"
                                                ref={videoRef}
                                                videoConstraints={{
                                                    ...videoConstraints,
                                                    facingMode
                                                }}
                                            />
                                        ) : (
                                            <div style={{ height: "300px", width: "100%" }}></div>
                                        )}
                                        <span className='d-flex justify-content-center mt-2'>
                                            <material.Button sx={{
                                                textTransform: "none", mr: 1,
                                                [theme.breakpoints.down('sm')]: {
                                                    fontSize: '0.600rem',
                                                },
                                                [theme.breakpoints.up('sm')]: {
                                                    padding: '8px 16px',
                                                    fontSize: '0.800rem',
                                                },
                                                [theme.breakpoints.up('md')]: {
                                                    padding: '8px 16px',
                                                    fontSize: '0.800rem',
                                                },
                                                [theme.breakpoints.up('lg')]: {
                                                    padding: '8px 16px',
                                                    fontSize: '0.800rem',
                                                },
                                            }} size='small' variant="outlined" onClick={switchCamera} startIcon={<material.CameraswitchIcon />} >Switch camera</material.Button>
                                            <material.Button sx={{
                                                textTransform: "none",
                                                [theme.breakpoints.down('sm')]: {
                                                    padding: '8px 16px',
                                                    fontSize: '0.600rem',
                                                },
                                                [theme.breakpoints.up('sm')]: {
                                                    padding: '8px 16px',
                                                    fontSize: '0.800rem',
                                                },
                                                [theme.breakpoints.up('md')]: {
                                                    padding: '8px 16px',
                                                    fontSize: '0.800rem',
                                                },
                                                [theme.breakpoints.up('lg')]: {
                                                    padding: '8px 16px',
                                                    fontSize: '0.800rem',
                                                },
                                            }} size='small' variant="outlined" onClick={capturePhoto} startIcon={<material.CameraAltIcon />} >Take photo</material.Button>
                                            <material.Button sx={{
                                                textTransform: "none",
                                                [theme.breakpoints.down('sm')]: {
                                                    padding: '8px 16px',
                                                    fontSize: '0.600rem',
                                                },
                                                [theme.breakpoints.up('sm')]: {
                                                    padding: '8px 16px',
                                                    fontSize: '0.800rem',
                                                },
                                                [theme.breakpoints.up('md')]: {
                                                    padding: '8px 16px',
                                                    fontSize: '0.800rem',
                                                },
                                                [theme.breakpoints.up('lg')]: {
                                                    padding: '8px 16px',
                                                    fontSize: '0.800rem',
                                                },
                                            }} size='small' variant="outlined" onClick={toggleCamera} className='ms-2'>
                                                {stream ? 'Close camera' : 'Open camera'}
                                            </material.Button>
                                        </span>
                                    </span>
                                )}
                            </div>
                        )}
                    </material.DialogContent>
                )}
                <material.DialogActions className='m-2'>
                    <material.Button sx={{ textTransform: "none", mr: 1 }} variant="outlined" color='error' size="small" onClick={cancel}>Cancel</material.Button>
                    {openUploadPhoto.uploadType === "upload_file" ? (
                        <material.Button sx={{ textTransform: "none" }} variant="outlined" color='info' size="small" onClick={uploadFile}>Upload</material.Button>
                    ) : (
                        <material.Button sx={{ textTransform: "none" }} variant="outlined" color='info' size="small" hidden={image === null} onClick={upload}>Upload</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state,
    };
};

export default connect(mapStateToProps)(TreatmentPlan);