import React, { useState } from 'react';
import { material } from '../../library/material';
import "./Login.css";
import logo from '../../assets/new-logo.jpg';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { kioskLogin, userLogin } from '../../services/LoginService';
import { connect } from 'react-redux';
import { loginAction } from '../../store/action/Action';
import Snackbar from '../toastrmessage/Snackbar';
import { socket } from '../../socket/Socket';
import { userFrocedLogout } from '../../services/LoginService';
import ChangePassword from '../dialog/ChangePassword';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <material.MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Login(props) {

  const { authLogin } = props;

  const [isVisible, setIsVisible] = useState(false);
  const [type, setType] = useState("password");
  const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm();
  const [openSnackBar, setOpenSnackBar] = useState({
    "action": false,
    "type": "",
    "message": "",
  });
  const [open, setOpen] = useState({ "action": false, "data": "" });
  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false);
  const navigate = useNavigate();

  function showPassword() {
    setIsVisible(!isVisible)
    setType("text")
  };

  function hidePassword() {
    setIsVisible(!isVisible)
    setType("password")
  };

  async function login(formData) {
    await userLogin(formData)
      .then((res) => {
        authLogin(res.data)
        setOpenSnackBar({
          "action": true,
          "type": "success",
          "message": "You have successfully loggedin",
        })
        socket.emit('user-info', res.data)
        setTimeout(() => {
          const checkPermissionName = ["AEST_SYST_ADMIN", "AEST_PRESCRIBER"];
          if (res.data.role.permissions.some(item => checkPermissionName.includes(item.permissionName))) {
            navigate("/card-component")
          } else {
            navigate("/clinic-selector")
          };
          // else if (res.data.role.includes("PRESCRIBER") && res.data.role.length == 1) {
          //   navigate("/card-component")
          // }
          reset()
        }, 500);
      }).catch((error) => {
        setOpenSnackBar({
          "action": true,
          "type": "error",
          "message": error.response.data,
        })
        if (error.response.status === 495) {
          setOpen({ action: true, data: { error: error.response.data, userData: formData } })
        }
      })
  };

  const reLogin = (loginData) => {
    login(loginData)
  };
  const resetPassword = () => {
    setOpenChangePasswordDialog(true)
  };

  const userKioskLogin = async (userData) => {
    await kioskLogin(userData)
      .then((res) => {
        authLogin(res.data)
        socket.emit('kiosk-info', res.data)
        setOpenSnackBar({
          "action": true,
          "type": "success",
          "message": "You have successfully loggedin",
        })
        navigate("/kiosk-menu")
        setOpen({ "action": false })
      })
      .catch((error) => {
        setOpenSnackBar({
          "action": true,
          "type": "error",
          "message": error.response?.data,
        })
      })
  };

  return (
    <div>
      <div className="wrapper">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="text-center mt-4 name">
          Aesthetics Consults
        </div>
        <form className="p-3 mt-3">
          <div className="form-field d-flex align-items-center">
            <span ><material.PermIdentityIcon /></span>
            <input type="text" name="userName" id="userName" placeholder="Email" {...register("email", { required: true })} />
          </div>
          <div className="errmsg align-items-center">{errors.userId && <span>This field is required</span>}</div>
          <div className="form-field d-flex align-items-center">
            <span><material.KeyOutlinedIcon /></span>
            <input type={type} name="password" id="pwd" placeholder="Password" {...register("password", { required: true })} />
            {isVisible === false ? (<span className='me-2'><material.VisibilityIcon onClick={showPassword} /></span>) : (<span className='me-2'><material.VisibilityOffIcon onClick={hidePassword} /></span>)}
          </div>
          <div className="errmsg  align-items-center">{errors.password && <span>This field is required</span>}</div>

          <button className="btn mt-3" onClick={handleSubmit(login)} disabled={!isValid}>Login</button>
        </form>
        <div className="text-center row fs-6">
          <div className="col-6"><a href="/registerorganisation">Register by invite code</a></div>
          <div className="col-6"> <a href="#" onClick={resetPassword}>Forget password?</a> </div>

        </div>
      </div>
      <Snackbar
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
      />
      <LoginErrorNotification
        open={open}
        setOpen={setOpen}
        reLogin={reLogin}
        userKioskLogin={userKioskLogin}
      />
      <ChangePassword
        openChangePasswordDialog={openChangePasswordDialog}
        setOpenChangePasswordDialog={setOpenChangePasswordDialog}
      />
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    user: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authLogin: (data) => {
      dispatch(loginAction(data));
    },
  };
};


const LoginErrorNotification = (props) => {

  const { open, setOpen, reLogin, userKioskLogin } = props;

  const continueLogin = async () => {
    await userFrocedLogout(open.data.userData.email)
      .then((resp) => {
        reLogin(open.data.userData)
        setOpen({ "action": false })
      })
  };

  const cancel = () => {
    setOpen({ "action": false })
  };

  const kioskLogin = () => {
    userKioskLogin(open.data.userData)
  };

  return (
    <div>
      <material.Stack>
        <material.Snackbar open={open.action} autoHideDuration={20000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert severity="warning">{open.data?.error}
            <span className='d-block mt-5 float-end'>
              <material.Button variant="contained" color="error" sx={{ textTransform: "none", mr: 2 }} onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
              <material.Button variant="contained" sx={{ textTransform: "none", mr: 2 }} onClick={kioskLogin} endIcon={<material.ArrowForwardIcon />}>Secondary Login</material.Button>
              <material.Button style={{ backgroundColor: "orange", color: "white" }} sx={{ textTransform: "none", mr: 2 }} onClick={continueLogin} endIcon={<material.ArrowForwardIcon />}>Force Logout & Continue</material.Button>
            </span>
          </Alert>
        </material.Snackbar>
      </material.Stack>
    </div>
  )

}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

