import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import Navbar from '../../navbar/Navbar';
import { useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import ConsentFormAW from '../consent-form/ConsentFormAW';
import ConsentFormDF from '../consent-form/ConsentFormDF';
import SkinBooster from '../consent-form/SkinBooster';
import HyalaseTreatment from '../consent-form/HyalaseTreatment';
import LocalAnaesthetic from '../consent-form/injectable/LocalAnaesthetic';
import Threads from '../consent-form/injectable/Threads';
import ExomideScalp from '../consent-form/injectable/ExomideScalp';
import { useNavigate } from 'react-router-dom';
import Snackbar from '../../toastrmessage/Snackbar';
import { getSingleSkinTreatmentPlanForPatient, getSkinTreatmentImageList } from '../../../services/SkinTreatmentService';
import ConsentFormCP from '../consent-form/ConsentFormCP';
import ConsentFormIPL from '../consent-form/ConsentFormIPL';
import ConsentFormPDT from '../consent-form/ConsentFormPDT';
import ConsentFormLT from '../consent-form/ConsentFormLT';
import Hydrafacial from '../consent-form/Hydrafacial';
import Microneedling from '../consent-form/Microneedling';
import { getImageListCosmetic } from '../../../services/TreatmentPlanService';
import { rootApiUrl } from '../../../environment/Environment';

function ViewPatientPastProcedure(props) {

    const { userData, clinicData } = props;
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const pastProcedureData = location.state?.pastProcedureData;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [openConsentForm, setOpenConsentForm] = useState({ action: true, patientData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [afterImageList, setAfterImageList] = useState([]);
    const [imageList, setImageList] = useState([]);

    const goBack = () => {
        navigate(-1)
    };

    useEffect(() => {
        if (pastProcedureData.treatmentName === "Skin") {
            getSkinTreatmentPlanForPatient();
            getImageList();
        } else {
            getCosmeticAfterImageList();
            getCosmeticImageList();
        }
    }, []);

    const getSkinTreatmentPlanForPatient = () => {
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Please wait fetching data",
        })
        const payload = {
            clinicId: clinicDetails.clinicId,
            patientId: pastProcedureData.patientId,
            skinTreatmentPlanId: pastProcedureData?.consent_data[0]?.treatmentPlanRequestId
        }
        getSingleSkinTreatmentPlanForPatient(payload)
            .then((resp) => {
                // setPatientSkinTreatmentPlanData(resp.data);
                if (resp.data.data.remainingSessions !== 0) {
                    if (resp.data.data.consentFormId === "" || resp.data.data.consentFormId === null) {
                        setOpenConsentForm({ action: true, patientData: resp.data.data });
                    } else {
                        setOpenConsentForm({ action: true, patientData: resp.data.data });
                    };
                }
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getCosmeticAfterImageList = () => {
        const afterPayload = {
            treatmentPlanRequestId: pastProcedureData?.concent_data[0]?.treatmentPlanRequestId,
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId,
            typeOfImage: "after_image",
            patientId: pastProcedureData?.concent_data[0]?.patientId
        }
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Please wait fetching data",
        })
        getImageListCosmetic(afterPayload)
            .then((resp) => {
                setAfterImageList(resp.data.data);
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Image list fetch complete",
                })
                setTimeout(() => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "info",
                        "message": "Please wait while images are loading",
                    })
                }, 3000);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getCosmeticImageList = () => {
        const payload = {
            treatmentPlanRequestId: pastProcedureData?.concent_data[0]?.treatmentPlanRequestId,
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId,
            typeOfImage: "before_image",
            patientId: pastProcedureData?.concent_data[0]?.patientId
        };
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Please wait fetching data",
        })
        getImageListCosmetic(payload)
            .then((resp) => {
                setImageList(resp.data.data);
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": "Image list fetch complete",
                })
                setTimeout(() => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "info",
                        "message": "Please wait while images are loading",
                    })
                }, 3000);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getImageList = async () => {
        let payload = {
            sessionId: pastProcedureData?.skin_treatment_session_id,
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId,
            typeOfImage: "skin_before",
            patientId: pastProcedureData?.consent_data[0]?.patientId
        }
        await getSkinTreatmentImageList(payload)
            .then((resp) => {
                setImageList(resp.data.data)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    return (
        <material.Grid spacing={2} className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 12
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 12
                },
            }}
        >
            <Navbar />
            <div>
                <span className='float-end'>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} size='small' onClick={goBack} startIcon={<material.ReplyIcon />}>Back</material.Button>
                </span>
            </div>
            <material.Paper elevation={3} sx={{ p: 5, mt: 5 }}>
                <div className="row">
                    <div className='col-lg-4 col-md-4 col-sm-12 mt-2'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='fw-bold'>
                                    <td>Treatment Name</td>
                                    <td>{pastProcedureData.treatment}</td>
                                </tr>
                                <tr className='fw-bold'>
                                    <td>Treatment Area</td>
                                    <td>{pastProcedureData.area}</td>
                                </tr>
                                <tr className='fw-bold'>
                                    <td>Date</td>
                                    <td>{pastProcedureData.date}</td>
                                </tr>
                                <tr className='fw-bold'>
                                    <td>Purchased Session</td>
                                    <td>{pastProcedureData.purchasedSession}</td>
                                </tr>
                                <tr className='fw-bold'>
                                    <td>Session Number</td>
                                    <td>{pastProcedureData.sessionNumber}</td>
                                </tr>
                                <tr className='fw-bold'>
                                    <td>Treatment Notes</td>
                                    <td>{pastProcedureData.treatmentNote}</td>
                                </tr>
                                <tr className='fw-bold'>
                                    <td>Prescriber Notes</td>
                                    <td>{pastProcedureData.prescribesNote}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='col-lg-8 col-md-8 col-sm-12'>
                        <div className="row">
                            <div className={pastProcedureData.treatmentName === "Skin" ? "col-12" : "col-6"}>
                                <material.Paper elevation={3} sx={{ p: 1 }}>
                                    <div>
                                        <span><material.Typography variant='h6'>Before Photo</material.Typography></span>
                                    </div>
                                    {imageList.length ? (
                                        <div className="row">
                                            {imageList.map((ele, i) => (
                                                <div className={pastProcedureData.treatmentName === "Skin" ? "col-4" : "col-6"} key={i}>
                                                    <material.Paper key={i} elevation={3} sx={{ width: "100%", p: 1 }}>
                                                        <img
                                                            key={i}
                                                            src={`${rootApiUrl}/get-skin-treatment-image?key=${ele}`}
                                                            height={250} width="100%"
                                                        />
                                                    </material.Paper>
                                                </div>
                                            ))}
                                        </div>
                                    ) : ""}
                                </material.Paper>
                            </div>
                            <div className="col-6" hidden={pastProcedureData.treatmentName === "Skin"}>
                                <material.Paper elevation={3} sx={{ p: 1 }}>
                                    <div>
                                        <span><material.Typography variant='h6'>After Photo</material.Typography></span>
                                    </div>
                                    {afterImageList.length ? (
                                        <div className="row">
                                            {afterImageList.map((ele, i) => (
                                                <div className='col-6 mt-2' key={i}>
                                                    <material.Paper key={i} elevation={3} sx={{ width: "100%", p: 1 }}>
                                                        <img
                                                            key={i}
                                                            src={`${rootApiUrl}/get-skin-treatment-image?key=${ele}`}
                                                            height={250} width="100%"
                                                        />
                                                    </material.Paper>
                                                </div>
                                            ))}
                                        </div>
                                    ) : ""}
                                </material.Paper>
                            </div>
                        </div>
                    </div>
                    {pastProcedureData.treatmentName === "Cosmetic" ?
                        pastProcedureData.concent_data?.map((consent, i) => (
                            <div className='col-12' key={i}>
                                {consent.concent_type === "Anti-Wrinkle" ? (
                                    <ConsentFormAW
                                        consentData={consent.concent_data}
                                        openConsentForm={openConsentForm}
                                    />
                                ) : null}
                                {consent.concent_type === "Dermal Filler" ? (
                                    <ConsentFormDF
                                        consentData={consent.concent_data}
                                        openConsentForm={openConsentForm}
                                    />
                                ) : null}
                                {consent.concent_type === "Skin Booster" ? (
                                    <SkinBooster
                                        consentData={consent.concent_data}
                                        openConsentForm={openConsentForm}
                                    />
                                ) : null}
                                {consent.concent_type === "Hyalase Treatment" ? (
                                    <HyalaseTreatment
                                        consentData={consent.concent_data}
                                        openConsentForm={openConsentForm}
                                    />
                                ) : null}
                                {consent.concent_type === "Local Anaesthetic" ? (
                                    <LocalAnaesthetic
                                        consentData={consent.concent_data}
                                        openConsentForm={openConsentForm}
                                        clinicDetails={clinicDetails}
                                    />
                                ) : null}
                                {consent.concent_type === "Threads" ? (
                                    <Threads
                                        consentData={consent.concent_data}
                                        openConsentForm={openConsentForm}
                                        clinicDetails={clinicDetails}
                                    />
                                ) : null}
                                {consent.concent_type === "Exomide Scalp" ? (
                                    <ExomideScalp
                                        consentData={consent.concent_data}
                                        openConsentForm={openConsentForm}
                                        clinicDetails={clinicDetails}
                                    />
                                ) : null}
                            </div>
                        )) : pastProcedureData.treatmentName === "Skin" ?
                            openConsentForm.patientData?.treatmentName.toLowerCase().includes("chemical") ? (
                                <ConsentFormCP
                                    openConsentForm={openConsentForm}
                                    setOpenConsentForm={setOpenConsentForm}
                                    callFrom="patient_past_procedure"
                                // patientDataDetails={patientDataDetails}
                                />
                            ) : openConsentForm.patientData?.treatmentName.toLowerCase().includes("ipl") ? (
                                <ConsentFormIPL
                                    openConsentForm={openConsentForm}
                                    setOpenConsentForm={setOpenConsentForm}
                                    callFrom="patient_past_procedure"
                                // patientDataDetails={patientDataDetails}
                                />
                            ) : openConsentForm.patientData?.treatmentName.toLowerCase().includes("pdt") ? (
                                <ConsentFormPDT
                                    openConsentForm={openConsentForm}
                                    setOpenConsentForm={setOpenConsentForm}
                                    callFrom="patient_past_procedure"
                                // patientDataDetails={patientDataDetails}
                                />
                            ) : openConsentForm.patientData?.treatmentName.toLowerCase().includes("laser") ? (
                                <ConsentFormLT
                                    openConsentForm={openConsentForm}
                                    setOpenConsentForm={setOpenConsentForm}
                                    callFrom="patient_past_procedure"
                                // patientDataDetails={patientDataDetails}
                                />
                            ) : openConsentForm.patientData?.treatmentName.toLowerCase().includes("aqua") ? (
                                <Hydrafacial
                                    openConsentForm={openConsentForm}
                                    setOpenConsentForm={setOpenConsentForm}
                                    callFrom="patient_past_procedure"
                                // patientDataDetails={patientDataDetails}
                                />
                            ) : openConsentForm.patientData?.treatmentName.toLowerCase().includes("microneedling") ? (
                                <Microneedling
                                    openConsentForm={openConsentForm}
                                    setOpenConsentForm={setOpenConsentForm}
                                    callFrom="patient_past_procedure"
                                // patientDataDetails={patientDataDetails}
                                />
                            ) : null : null}
                </div>
            </material.Paper>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </material.Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(ViewPatientPastProcedure);