import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { useForm } from 'react-hook-form';
import { uploadSupplierForm } from '../../services/SupplierConfigService';
import Snackbar from '../toastrmessage/Snackbar';

function RejectionNoteDialog(props) {

    const { openRejectionNoteDialog, setOpenRejectionNoteDialog, getSupplierClinicConfigReqData } = props;
    const { register, handleSubmit, reset, setValue, control, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (openRejectionNoteDialog.action && openRejectionNoteDialog.rowData.rejectionReason !== null) {
            reset(openRejectionNoteDialog.rowData)
        }
    }, [openRejectionNoteDialog.action]);

    const handleClose = () => {
        reset({ rejectionReason: "" });
        setOpenRejectionNoteDialog({ action: false, rowData: null });
    };

    const saveNote = (data) => {
        const formData = new FormData();
        formData.append("rejected", true)
        formData.append("requestId", openRejectionNoteDialog.rowData.requestId)
        formData.append("rejectionReason", data.rejectionReason)
        uploadSupplierForm(formData)
            .then((resp) => {
                getSupplierClinicConfigReqData();
                reset({ rejectionReason: "" });
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                setOpenRejectionNoteDialog({ action: false, rowData: null })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openRejectionNoteDialog.action} fullWidth maxWidth="sm">
                <material.DialogTitle>Rejection Note</material.DialogTitle>
                <material.DialogContent>
                    <div className='mt-3'>
                        <material.TextField
                            required
                            id='rejectionNote'
                            type='text'
                            label="Rejection Note"
                            rows={3}
                            multiline
                            fullWidth
                            variant='outlined'
                            {...register("rejectionReason", { required: true })}
                        />
                    </div>
                </material.DialogContent>
                <material.DialogActions sx={{ m: 1 }}>
                    <material.Button sx={{ textTransform: "none", mr: 1 }} variant="contained" color='error' startIcon={<material.CloseIcon />} onClick={handleClose}>Cancel</material.Button>
                    <material.Button sx={{ textTransform: "none" }} variant="contained" color='success' startIcon={<material.DoneIcon />} onClick={handleSubmit(saveNote)} hidden={openRejectionNoteDialog.rowData?.rejectionReason !== null}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default RejectionNoteDialog;