import React, { useState, useEffect } from 'react';
import { material } from '../../../library/material';
import { connect } from 'react-redux';
import { getOtherInHandStocksByClinic } from '../../../services/OtherStockService';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Navbar from '../../navbar/Navbar';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import Snackbar from '../../toastrmessage/Snackbar';
import { useTheme } from '@mui/material';


var allProductData;

function OtherStockInHand(props) {
    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const theme = useTheme();
    const [products, setProducts] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });

    localStorage.setItem("menuName", "Other Stock Insight");

    useEffect(() => {
        getOtherInHandStockData();
    }, []);

    const getOtherInHandStockData = async () => {
        await getOtherInHandStocksByClinic(clinicDetails.clinicId)
            .then((resp) => {
                allProductData = resp.data.data;
                setProducts(resp.data.data)
                setisLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const filterByPrice = (value) => {
        setPage(0);
        const filterData = products.filter((ele) => {
            return ele.product
                .toString()
                .toLowerCase()
                .trim()
                .includes(value.toString().toLowerCase().trim())
        })
        if (value.trim().toString().length < 1) {
            setProducts(allProductData)
        } else {
            setProducts(filterData)
        }
    };

    return (
        <material.Grid spacing={2} className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 10
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 10
                },
            }}
        >
            <Navbar />
            <div className='row'>
                <div className='col-12'>
                    <span>
                        <material.TextField
                            fullWidth
                            variant="standard"
                            label="Filter by Product Name"
                            type='text'
                            onChange={(e) => filterByPrice(e.target.value)}
                            sx={{ width: "40ch" }}
                        />
                    </span>
                </div>
                <div className='col-12'>
                    <material.Paper elevation={3} sx={{ p: 3, mt: 3 }}>
                        <material.TableContainer sx={{ maxHeight: 500 }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell>Product Name</StyledTableCell>
                                        <StyledTableCell>Product Type</StyledTableCell>
                                        <StyledTableCell>Total Purchased Unit</StyledTableCell>
                                        <StyledTableCell>In-Hand</StyledTableCell>
                                        <StyledTableCell>Total Sold</StyledTableCell>
                                        <StyledTableCell>Per-Unit Price</StyledTableCell>
                                        <StyledTableCell>Total Adjusted</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={10}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={10} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {products.length ? products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                <material.TableRow
                                                    key={i}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': { border: 0 }
                                                    }}
                                                >
                                                    <material.TableCell sx={{ pt: 2, pb: 2 }} size='small'>{row.product}</material.TableCell>
                                                    <material.TableCell size='small'>{row.productType}</material.TableCell>
                                                    <material.TableCell size='small'>{row.totalPurchasedUnit}</material.TableCell>
                                                    <material.TableCell size='small'>{row.inHand}</material.TableCell>
                                                    <material.TableCell size='small'>{row.totalSold}</material.TableCell>
                                                    <material.TableCell size='small'>{row.perUnitPrice}</material.TableCell>
                                                    <material.TableCell size='small'>{row.totalAdjusted}</material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={10}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>
                                    )}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                        <material.TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={products.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </material.Paper>
                </div>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </material.Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(OtherStockInHand);