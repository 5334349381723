import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { Slide } from '@mui/material';
import { useForm } from 'react-hook-form';
import AddTreatmentPackage from './AddTreatmentPackage';
import { createNewClinicPackage, getPackageDetails, getSinglePackage, saveOrUpdatePackage } from '../../../services/PackageConfigurationService';
import Snackbar from '../../toastrmessage/Snackbar';
import AddProductPackage from './AddProductPackage';
import ViewPackageList from './ViewPackageList';
import AddServicePackage from './AddServicePackage';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const packageEditPermission = ["CLINIC_PACKAGE_EDIT"];

function AddPackage(props) {

    const { openAddPackage, setOPenAddPackage, clinicDetails, userDetails, getPackageData } = props;
    const { register, handleSubmit, reset, setValue, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [openAddTreatmentPackage, setOpenAddTreatmentPackage] = useState({ action: false, treatmentData: null });
    const [openAddProductPackage, setOpenAddProductPackage] = useState({ action: false, productData: null });
    const [getPackageList, setGetPackageList] = useState([]);
    const [getNewPackageData, setGetNewPackageData] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [openAddServicePackage, setOpenAddServicePackage] = useState({ action: false, data: null });
    const [getPackageId, setPackageId] = useState(null);

    useEffect(() => {
        if (openAddPackage.action && openAddPackage.data === null) {
            createNewPackage()
        } else if (openAddPackage.action && openAddPackage.data !== null) {
            reset(openAddPackage.data);
            setPackageId(openAddPackage.data.id)
            getClinicPackageDetails(openAddPackage.data.id)
        }
    }, [openAddPackage.action === true]);

    const createNewPackage = () => {
        const payload = {
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId
        }
        createNewClinicPackage(payload)
            .then((resp) => {
                reset(resp.data.data.data);
                setGetNewPackageData(resp.data.data.data);
                setPackageId(resp.data.data.data.id);
                getClinicPackageDetails(resp.data.data.data.id)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getSingleClinicPackages = (packageId) => {
        setIsLoading(true);
        const payload = {
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId,
            id: packageId
        }
        getSinglePackage(payload)
            .then((resp) => {
                reset(resp.data.data);
                setPackageId(resp.data.data.id)
                getClinicPackageDetails(resp.data.data.id);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const close = () => {
        setOPenAddPackage({ action: false, data: null })
    };

    const addTreatmentPackage = () => {
        setOpenAddTreatmentPackage({ action: true, treatmentData: null });
    };

    const addProductPackage = () => {
        setOpenAddProductPackage({ action: true, productData: null });
    };

    const addServicePackage = () => {
        setOpenAddServicePackage({ action: true, data: null })
    };

    const savePackage = (formData) => {
        const payload = {
            "id": formData.id ? formData.id : "",
            "clinicId": clinicDetails.clinicId,
            "orgId": userDetails.orgId,
            "isDeleted": false,
            "isSave": true,
            "active": true,
            "total": formData.total,
            "discount": formData.discount,
            "totalPayableAmount": formData.totalPayableAmount,
            "packageName": formData.packageName,
            "packageDescription": formData.packageDescription
        }
        saveOrUpdatePackage(payload)
            .then((resp) => {
                getPackageData();
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data.messages,
                });
                setOPenAddPackage({ action: false, data: null })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const packageSaveAsDraft = (formData) => {
        const payload = {
            "id": formData.id ? formData.id : "",
            "clinicId": clinicDetails.clinicId,
            "orgId": userDetails.orgId,
            "isDeleted": false,
            "isSave": false,
            "active": true,
            "total": formData.total,
            "discount": formData.discount,
            "totalPayableAmount": formData.totalPayableAmount,
            "packageName": formData.packageName,
            "packageDescription": formData.packageDescription
        }
        saveOrUpdatePackage(payload)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data.messages,
                });
                getSingleClinicPackages(getPackageId)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getClinicPackageDetails = (packageId) => {
        getPackageDetails(packageId)
            .then((resp) => {
                setGetPackageList(resp.data.data)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog hideBackdrop TransitionComponent={Transition} fullScreen open={openAddPackage.action}>
                <material.AppBar sx={{ position: 'relative' }}>
                    <material.Toolbar>
                        <material.IconButton
                            edge="start"
                            color="inherit"
                            onClick={close}
                            aria-label="close"
                        >
                            <material.CloseIcon />
                        </material.IconButton>
                        <material.Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Package Configuration
                        </material.Typography>
                        {userDetails.role.permissions.some(item => packageEditPermission.includes(item.permissionName)) ? (
                            <>
                                <material.Button autoFocus sx={{ textTransform: "none" }} color="inherit" onClick={handleSubmit(savePackage)}>Save</material.Button>
                                <material.Button autoFocus sx={{ textTransform: "none" }} color="inherit" onClick={handleSubmit(packageSaveAsDraft)} hidden={openAddPackage.data?.isSave}>Save as Draft</material.Button>
                            </>
                        ) : null}
                    </material.Toolbar>
                </material.AppBar>
                <material.DialogContent>
                    <div className="row">
                        <div className="col-12">
                            {userDetails.role.permissions.some(item => packageEditPermission.includes(item.permissionName)) ? (
                                <span className='float-end'>
                                    <material.Button variant="outlined" sx={{ textTransform: "none", mr: 1 }} onClick={addTreatmentPackage}>Treatment</material.Button>
                                    <material.Button variant="outlined" sx={{ textTransform: "none", mr: 1 }} onClick={addProductPackage}>Product</material.Button>
                                    <material.Button variant="outlined" sx={{ textTransform: "none", mr: 1 }} onClick={addServicePackage}>Service</material.Button>
                                </span>
                            ) : null}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <material.TextField
                                        {...register("packageName", { required: true })}
                                        margin="dense"
                                        id="packageName"
                                        label="Package Name"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <material.TextField
                                        {...register("total")}
                                        margin="dense"
                                        id="total"
                                        label="Total Amount"
                                        type="number"
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <material.TextField
                                        {...register("discount")}
                                        margin="dense"
                                        id="discount"
                                        label="Discount"
                                        type="number"
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <material.TextField
                                        {...register("totalPayableAmount")}
                                        margin="dense"
                                        id="discount"
                                        label="Total Payable Amount"
                                        type="number"
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                                <div className="col-12">
                                    <material.TextField
                                        {...register("packageDescription")}
                                        margin="dense"
                                        id="discount"
                                        label="Package Description"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <span>
                                <material.Typography variant='h6'>The rows highlighted in violet are complementary.</material.Typography>
                            </span>
                            <ViewPackageList
                                getPackageList={getPackageList}
                                clinicDetails={clinicDetails}
                                userDetails={userDetails}
                                getSingleClinicPackages={getSingleClinicPackages}
                                packageId={getPackageId}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <AddTreatmentPackage
                openAddTreatmentPackage={openAddTreatmentPackage}
                setOpenAddTreatmentPackage={setOpenAddTreatmentPackage}
                userDetails={userDetails}
                clinicDetails={clinicDetails}
                packageId={getPackageId}
                getSingleClinicPackages={getSingleClinicPackages}
                setPackageId={setPackageId}
            />
            <AddProductPackage
                openAddProductPackage={openAddProductPackage}
                setOpenAddProductPackage={setOpenAddProductPackage}
                userDetails={userDetails}
                clinicDetails={clinicDetails}
                packageId={getPackageId}
                setPackageId={setPackageId}
                getSingleClinicPackages={getSingleClinicPackages}
            />
            <AddServicePackage
                openAddServicePackage={openAddServicePackage}
                setOpenAddServicePackage={setOpenAddServicePackage}
                userDetails={userDetails}
                clinicDetails={clinicDetails}
                packageId={getPackageId}
                setPackageId={setPackageId}
                getSingleClinicPackages={getSingleClinicPackages}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default AddPackage;