import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { useTheme } from '@mui/material';
import Navbar from '../navbar/Navbar';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import AddOrEditSupplierConfig from './AddOrEditSupplierConfig';
import { getAllSupplierConfig } from '../../services/SupplierConfigService';
import { rootApiUrl } from '../../environment/Environment';
import Snackbar from '../toastrmessage/Snackbar';

function SupplierConfiguration(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [supplierConfigData, setSupplierConfigData] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [openAddOrEditSupplierConfig, setOPenAddOrEditSupplierConfig] = useState({ action: false, rowData: null });
    localStorage.setItem("menuName", "Supplier Configuration");
    const theme = useTheme();

    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getAllSupplierConfigData()
    }, []);

    const getAllSupplierConfigData = (paginationData) => {
        setIsLoading(true);
        const payload = {
            limit: paginationData ? paginationData.limit : 10,
            page: paginationData ? paginationData.page : 0,
            search: paginationData ? paginationData.search : ""
        }
        getAllSupplierConfig(payload)
            .then((resp) => {
                setPage(Number(resp.data.data.currentPage));
                setRowsPerPage(Number(resp.data.data.limit));
                setTotalElements(resp.data.data.totalItems);
                setSupplierConfigData(resp.data.data.results);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const addSupplierConfig = () => {
        setOPenAddOrEditSupplierConfig({ action: true, rowData: null })
    };

    const editSupplierConfig = (rowData) => {
        setOPenAddOrEditSupplierConfig({ action: true, rowData: rowData })
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const payload = {
            limit: rowsPerPage,
            page: newPage,
        }
        getAllSupplierConfigData(payload)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        const payload = {
            limit: event.target.value,
            page: page
        }
        getAllSupplierConfigData(payload)
    };

    const searchSupplier = (value) => {
        const payload = {
            search: value
        }
        getAllSupplierConfigData(payload)
    };

    const downloadForm = (pdfLink) => {
        setOPenAddOrEditSupplierConfig({ action: false, rowData: null });
        window.open(`${rootApiUrl}/agl-link?key=${pdfLink}`, "_blank")
    };

    return (
        <material.Grid className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 12
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 12
                },
            }}
        >
            <Navbar />
            <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <span style={{ marginLeft: 5 }}>
                        <material.TextField
                            fullWidth
                            variant="standard"
                            label="Search..."
                            onChange={(e) => searchSupplier(e.target.value)}
                            sx={{ mt: -2 }}
                        />
                    </span>
                </div>
                <div className="col-lg-9 col-md-6 col-sm-6">
                    <span className='float-end'>
                        <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addSupplierConfig}>Add Supplier Config</material.Button>
                    </span>
                </div>
                <div className="col-12 mt-3">
                    <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <material.TableContainer sx={{ maxHeight: 460 }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell>Supplier Name</StyledTableCell>
                                        <StyledTableCell>Contact Name</StyledTableCell>
                                        <StyledTableCell>Position</StyledTableCell>
                                        <StyledTableCell>Contact Email</StyledTableCell>
                                        <StyledTableCell>Contact Phone Number</StyledTableCell>
                                        <StyledTableCell>Nurse account Setup Form</StyledTableCell>
                                        <StyledTableCell>Nurse Account Setup Form Submit Email</StyledTableCell>
                                        <StyledTableCell>Setup Support Contact</StyledTableCell>
                                        <StyledTableCell>Setup Support Name</StyledTableCell>
                                        <StyledTableCell>Product Purchase Contact Name</StyledTableCell>
                                        <StyledTableCell>Product Purchase Contact Number</StyledTableCell>
                                        <StyledTableCell>Product Purchase Email</StyledTableCell>
                                        <StyledTableCell>Shipping Cost</StyledTableCell>
                                        <StyledTableCell>Free Shipping Threshold</StyledTableCell>
                                        <StyledTableCell>Action</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={15}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={15} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {supplierConfigData.length ? supplierConfigData.map((row, i) => (
                                                <material.TableRow
                                                    key={i}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } }}
                                                    onClick={() => editSupplierConfig(row)}
                                                >
                                                    <material.TableCell size='small'>{row.supplierName}</material.TableCell>
                                                    <material.TableCell size='small'>{row.contactName}</material.TableCell>
                                                    <material.TableCell size='small'>{row.position}</material.TableCell>
                                                    <material.TableCell size='small'>{row.contactEmail}</material.TableCell>
                                                    <material.TableCell size='small'>{row.contactPhoneNumber}</material.TableCell>
                                                    <material.TableCell size='small'>{row.nurseAccountSetupForm.split("/").pop()}</material.TableCell>
                                                    <material.TableCell size='small'>{row.nurseAccountSetupEmail}</material.TableCell>
                                                    <material.TableCell size='small'>{row.setupSupportContact}</material.TableCell>
                                                    <material.TableCell size='small'>{row.setupSupportName}</material.TableCell>
                                                    <material.TableCell size='small'>{row.productPurchaseContactName}</material.TableCell>
                                                    <material.TableCell size='small'>{row.productPurchaseContactNumber}</material.TableCell>
                                                    <material.TableCell size='small'>{row.productPurchaseEmail}</material.TableCell>
                                                    <material.TableCell size='small'>{row.shippingCost}</material.TableCell>
                                                    <material.TableCell size='small'>{row.freeShippingThreshold}</material.TableCell>
                                                    <material.TableCell size='small'>
                                                        <material.IconButton title='Download Form' onClick={(e) =>{e.stopPropagation(); downloadForm(row.nurseAccountSetupForm)}}>
                                                            <material.DownloadIcon color='info' />
                                                        </material.IconButton>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={15}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>)}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                        <hr />
                        <material.TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={totalElements}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </material.Paper>
                </div>
            </div>
            <AddOrEditSupplierConfig
                openAddOrEditSupplierConfig={openAddOrEditSupplierConfig}
                setOPenAddOrEditSupplierConfig={setOPenAddOrEditSupplierConfig}
                getAllSupplierConfigData={getAllSupplierConfigData}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </material.Grid>
    );
}

export default SupplierConfiguration;