import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { getAllCustomCampaignList, getCrmEmailConfigurationData, getCrmTemplateList, startCrmCampaign } from '../../../services/PlatformCrmService';
import Snackbar from '../../toastrmessage/Snackbar';


function CrmCampaign(props) {

    const { openStartCrmCampaignDialog, setOpenStartCrmCampaignDialog, clinicDetails, userDetails, getCrmCampaignName } = props;
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm({
        mode: "onTouched",
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [selectFolder, setSelectFolder] = useState();
    const [selectTemplate, setSelectTemplate] = useState("");
    const [templateList, setTemplateList] = useState([]);
    // const [customCampaignList, setCustomCampaignList] = useState([]);
    const [emailConfiguration, setEmailConfiguration] = useState("");
    const [emailConfigurationData, setEmailConfigurationData] = useState([]);

    useEffect(() => {
        if (openStartCrmCampaignDialog) {
            getTemplateListByClinicId();
            // getCustomCampaignList();
            getCrmEmailConfiguration();
        }
    }, [openStartCrmCampaignDialog === true]);

    const getTemplateListByClinicId = () => {
        const payload = {
            orgId: userDetails.orgId
        }
        getCrmTemplateList(payload)
            .then((resp) => {
                setTemplateList(resp.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                });
            })
    };

    const getCrmEmailConfiguration = () => {
        // const payload = {
        //     // clinicId: clinicDetails.clinicId,
        //     orgId: userDetails.orgId
        // }
        getCrmEmailConfigurationData()
            .then((resp) => {
                console.log(resp.data)
                setEmailConfigurationData(Array.isArray(resp.data) ? resp.data : []);
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                });
            })
    };

    // const getCustomCampaignList = () => {
    //     const payload = {
    //         // clinicId: clinicDetails.clinicId,
    //         orgId: userDetails.orgId
    //     }
    //     getAllCustomCampaignList(payload)
    //         .then((resp) => {
    //             setCustomCampaignList(resp.data)
    //         })
    //         .catch((error) => {
    //             setOpenSnackBar({
    //                 "action": true,
    //                 "type": "error",
    //                 "message": error.response.data,
    //             });
    //         })
    // };

    const chooseFile = (e) => {
        let fileName = e.target.files[0];
        setSelectFolder(fileName);
    };

    const cancel = () => {
        reset({ campaignName: "", emailSubject: "", smsText: "" });
        setSelectTemplate("");
        setOpenStartCrmCampaignDialog(false);
    };

    const save = async (formData) => {
        let data = new FormData();
        data.append('excelFile', selectFolder);
        data.append('orgId', userDetails.orgId);
        data.append('campaignName', formData.campaignName);
        data.append('emailSubject', formData.emailSubject);
        data.append('sourceEmailAddress', formData.sourceEmailAddress);
        data.append('emailTemplateId', selectTemplate);

        await startCrmCampaign(data)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: "Data save Successful",
                });
                reset({ campaignName: "", emailSubject: "", smsText: "" });
                setSelectTemplate("");
                getCrmCampaignName();
                setOpenStartCrmCampaignDialog(false);
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error?.response?.data,
                });
            });
    };

    const handleChangeEmailConfiguration = (event) => {
        setEmailConfiguration(event.target.value);
    };
    const handleChangeTemplate = (event) => {
        setSelectTemplate(event.target.value);
    };

    return (
        <div>
            <material.Dialog open={openStartCrmCampaignDialog} fullWidth maxWidth="sm" hideBackdrop>
                <material.DialogTitle>Start Campaign</material.DialogTitle>
                <material.DialogContent>
                    <material.FormControl variant="standard" fullWidth margin="dense">
                        <material.InputLabel id="demo-simple-select-standard-label" required>Select Source Mail</material.InputLabel>
                        <material.Select
                            {...register("sourceEmailAddress", { required: true })}
                            required
                            margin="dense"
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={emailConfiguration}
                            onChange={handleChangeEmailConfiguration}
                        >
                            {(emailConfigurationData ? emailConfigurationData : []).map((ele, i) =>
                                <material.MenuItem key={i} value={ele.email_address}>{ele.email_address}</material.MenuItem>
                            )};
                        </material.Select>
                    </material.FormControl>
                    <material.TextField
                        required
                        margin="dense"
                        id="subject"
                        label="Email Subject"
                        type="text"
                        fullWidth
                        variant="standard"
                        {...register("emailSubject", { required: true })}
                    />
                    <material.TextField
                        required
                        margin="dense"
                        id="number"
                        label="Campaign Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        {...register("campaignName", { required: true })}
                    />
                    <div className='py-2'>
                        <label for="excelFile">Choose Excel File</label><br />
                        <input
                            type="file"
                            accept=".xlsx"
                            margin="dense"
                            onChange={chooseFile}
                        />
                    </div>
                    <material.FormControl variant="standard" fullWidth margin="dense">
                        <material.InputLabel id="demo-simple-select-standard-label" required>Select Template</material.InputLabel>
                        <material.Select
                            {...register("emailTemplateId", { required: true })}
                            required
                            margin="dense"
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={selectTemplate}
                            onChange={handleChangeTemplate}
                        >
                            {templateList.map((ele, i) =>
                                <material.MenuItem key={i} value={ele.emailTemplateId}>{ele.emailTemplateName}</material.MenuItem>
                            )};
                        </material.Select>
                    </material.FormControl>
                </material.DialogContent>
                <material.DialogActions>
                    <span className='m-2'>
                        <material.Button sx={{ textTransform: "none", mr: 2 }} variant="outlined" color='error' onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                        <material.Button sx={{ textTransform: "none" }} variant="outlined" onClick={handleSubmit(save)} startIcon={<material.DoneIcon />}>Save</material.Button>
                    </span>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(CrmCampaign);