import React, { useEffect, useState } from 'react'
import { material } from '../../../library/material'
import Snackbar from '../../toastrmessage/Snackbar'
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import { getCrmEmailReceivedData } from '../../../services/PlatformCrmService';
import moment from 'moment';
// import he from "he";



// function ViewReceiveMailData(props) {

//     const { openViewReceiveEmail, setOpenViewReceiveEmail } = props;
//     const [openSnackBar, setOpenSnackBar] = useState({
//         action: false,
//         type: "",
//         message: "",
//     });

//     const [emailReceiveData, setEmailReceiveData] = useState([]);
//     const [filteredEmailData, setFilteredEmailData] = useState([]);
//     const [searchQuery, setSearchQuery] = useState("");
//     const [currentPage, setCurrentPage] = useState(1);
//     const [rowsPerPage, setRowsPerPage] = useState(5);

//     useEffect(() => {
//         getReceiveEmailData();
//     }, []);

//     useEffect(() => {
//         handleSearch();
//     }, [searchQuery, emailReceiveData]);

//     const getReceiveEmailData = () => {
//         // const param = {
//         //     orgId: userDetails.orgId
//         // }
//         getCrmEmailReceivedData()
//             .then((resp) => {
//                 console.log(resp)
//                 setEmailReceiveData(resp.data.data);
//                 setFilteredEmailData(resp.data.data);
//             })
//             .catch((error) => {
//                 console.log(error)
//                 setOpenSnackBar({
//                     "action": true,
//                     "type": "error",
//                     "message": error.response.data.messages,
//                 })
//             })
//     };

//     const handleSearch = () => {
//         const filteredData = emailReceiveData.filter((email) =>
//             email.from.toLowerCase().includes(searchQuery.toLowerCase())
//         );
//         setFilteredEmailData(filteredData);
//         setCurrentPage(1); // Reset to the first page when searching
//     };

//     const handleChangePage = (event, newPage) => {
//         setCurrentPage(newPage + 1);
//     };
//     const handleChangeRowsPerPage = (event) => {
//         setRowsPerPage(parseInt(event.target.value, 10)); // Update rowsPerPage
//         setCurrentPage(1); // Reset to the first page when rowsPerPage changes
//     };

//     const paginatedData = filteredEmailData.slice(
//         (currentPage - 1) * rowsPerPage,
//         currentPage * rowsPerPage
//     );


//     return (
//         <div>
//             <material.Dialog fullWidth maxWidth="lg" open={openViewReceiveEmail} hideBackdrop >
//                 <material.DialogTitle className="fw-bold"> {"View Receive Mail Data"}
//                     <material.IconButton
//                         aria-label="close"
//                         onClick={() => setOpenViewReceiveEmail(false)}
//                         sx={{
//                             position: "absolute",
//                             right: 8,
//                             top: 8,
//                             color: "red",
//                         }}
//                     >
//                         <material.CloseIcon />
//                     </material.IconButton>
//                 </material.DialogTitle>
//                 <material.DialogContent>
//                     <material.Grid container spacing={2}>
//                         <material.Grid item xs={3}>
//                             <material.TextField
//                                 fullWidth
//                                 variant="standard"
//                                 label="Search by Email"
//                                 value={searchQuery}
//                                 onChange={(e) => setSearchQuery(e.target.value)}
//                             />
//                         </material.Grid>
//                         <material.Grid item xs={12}>
//                             <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
//                                 <material.TableContainer sx={{ maxHeight: "70vh" }}>
//                                     <material.Table stickyHeader aria-label="sticky table">
//                                         <material.TableHead >
//                                             <material.TableRow>
//                                                 <StyledTableCell>Email From</StyledTableCell>
//                                                 <StyledTableCell>Email Body</StyledTableCell>
//                                                 <StyledTableCell>Email Subject</StyledTableCell>
//                                                 <StyledTableCell>Email Receive Data</StyledTableCell>

//                                             </material.TableRow>
//                                         </material.TableHead>
//                                         <material.TableBody>
//                                             {paginatedData.length ? paginatedData.map((row, k) => (
//                                                 <material.TableRow key={k}
//                                                     sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } }}
//                                                 // onClick={() => editCrmData(row)}
//                                                 >
//                                                     <material.TableCell>{row.from}</material.TableCell>
//                                                     <material.TableCell>{row.body}</material.TableCell>
//                                                     <material.TableCell>{row.subject}</material.TableCell>
//                                                     <material.TableCell>{row.timestamp ? moment(row.timestamp).format('YYYY-MM-DD') : ""}</material.TableCell>

//                                                 </material.TableRow>
//                                             )) : (
//                                                 <material.TableRow>
//                                                     <material.TableCell colSpan={18}>
//                                                         <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
//                                                     </material.TableCell>
//                                                 </material.TableRow>
//                                             )}
//                                         </material.TableBody>
//                                     </material.Table>
//                                 </material.TableContainer>
//                                 <material.TablePagination
//                                     rowsPerPageOptions={[5, 10, 50]}
//                                     component="div"
//                                     count={filteredEmailData.length}
//                                     rowsPerPage={rowsPerPage}
//                                     page={currentPage - 1}
//                                     onPageChange={handleChangePage}
//                                     onRowsPerPageChange={handleChangeRowsPerPage}
//                                 />
//                             </material.Paper>
//                         </material.Grid>
//                     </material.Grid>
//                 </material.DialogContent>
//             </material.Dialog>
//             <Snackbar
//                 openSnackBar={openSnackBar}
//                 setOpenSnackBar={setOpenSnackBar}
//             />
//         </div>
//     )
// }

function ViewReceiveMailData({ openViewReceiveEmail, setOpenViewReceiveEmail }) {
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });

    const [emailReceiveData, setEmailReceiveData] = useState([]);
    const [filteredEmailData, setFilteredEmailData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [loading, setLoading] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState(null);

    useEffect(() => {
        getReceiveEmailData();
    }, []);

    useEffect(() => {
        filterEmails();
    }, [searchQuery, emailReceiveData]);

    // const fetchEmails = async () => {
    //     setLoading(true);
    //     try {
    //         const resp = await getCrmEmailReceivedData();
    //         if (resp.data?.data) {
    //             setEmailReceiveData(resp.data.data);
    //             setFilteredEmailData(resp.data.data);
    //         } else {
    //             setEmailReceiveData([]);
    //             setFilteredEmailData([]);
    //         }
    //     } catch (error) {
    //         setOpenSnackBar({
    //             action: true,
    //             type: "error",
    //             message: error?.response?.data?.messages || "Error fetching emails",
    //         });
    //     } finally {
    //         setLoading(false);
    //     }
    // };
    const getReceiveEmailData = () => {
        getCrmEmailReceivedData()
            .then((resp) => {
                // console.log(resp);
                const rawEmails = resp.data.data;

                // Extract useful fields from HTML strings
                const parsedEmails = rawEmails.map(emailHtml => {
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(emailHtml, "text/html");

                    return {
                        from: doc.querySelector(".email-header p strong")?.nextSibling?.textContent.trim() || "Unknown",
                        subject: doc.querySelector(".email-header h2")?.textContent.replace("Subject: ", "").trim() || "No Subject",
                        body: doc.querySelector(".email-body")?.innerHTML.trim() || "No Content",
                        timestamp: doc.querySelector(".email-header p:nth-of-type(2)")?.textContent.replace("Received At:", "").trim() || "",
                    };
                });

                setEmailReceiveData(parsedEmails);
                setFilteredEmailData(parsedEmails);
            })
            .catch((error) => {
                console.log(error);
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response?.data?.messages || "Error fetching emails",
                });
            });
    };
    const filterEmails = () => {
        const filteredData = emailReceiveData.filter((email) => {
            const from = email.from ? email.from.toLowerCase() : "";
            const subject = email.subject ? email.subject.toLowerCase() : "";
            const body = email.body ? email.body.toLowerCase() : "";
            return from.includes(searchQuery.toLowerCase()) ||
                subject.includes(searchQuery.toLowerCase()) ||
                body.includes(searchQuery.toLowerCase());
        });

        setFilteredEmailData(filteredData);
        setCurrentPage(0);
    };
    const handleChangePage = (_, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const paginatedData = filteredEmailData.slice(
        currentPage * rowsPerPage,
        currentPage * rowsPerPage + rowsPerPage
    );

    return (
        <div>
            <material.Dialog fullWidth maxWidth="lg" open={openViewReceiveEmail}>
                <material.DialogTitle className="fw-bold">
                    {selectedEmail ? "Email Details" : "View Received Emails"}
                    <material.IconButton
                        aria-label="close"
                        onClick={() => setOpenViewReceiveEmail(false)}
                        sx={{ position: "absolute", right: 8, top: 8, color: "red" }}
                    >
                        <material.CloseIcon />
                    </material.IconButton>
                </material.DialogTitle>

                <material.DialogContent>
                    <material.Grid container spacing={2}>
                        {!selectedEmail ? (
                            <>
                                <material.Grid item xs={4}>
                                    <material.TextField
                                        fullWidth
                                        variant="standard"
                                        label="Search by Sender, Subject, or Body"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </material.Grid>

                                <material.Grid item xs={12}>
                                    <material.Paper sx={{ width: "100%", overflow: "hidden" }}>
                                        <material.TableContainer sx={{ maxHeight: "70vh" }}>
                                            {loading ? (
                                                <material.Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                                    <material.CircularProgress />
                                                </material.Box>
                                            ) : (
                                                <material.Table stickyHeader>
                                                    <material.TableHead>
                                                        <material.TableRow>
                                                            <StyledTableCell>Sender</StyledTableCell>
                                                            <StyledTableCell>Subject</StyledTableCell>
                                                            <StyledTableCell>Received Date</StyledTableCell>
                                                        </material.TableRow>
                                                    </material.TableHead>
                                                    <material.TableBody>
                                                        {paginatedData.length > 0 ? (
                                                            paginatedData.map((email, index) => (
                                                                <material.TableRow
                                                                    key={index}
                                                                    sx={{
                                                                        cursor: "pointer",
                                                                        "&:hover": { backgroundColor: "#f5f5f5" }
                                                                    }}
                                                                    onClick={() => setSelectedEmail(email)}
                                                                >
                                                                    <material.TableCell>{email.from}</material.TableCell>
                                                                    <material.TableCell>{email.subject}</material.TableCell>
                                                                    <material.TableCell>
                                                                        {email.timestamp ? moment(email.timestamp).format("YYYY-MM-DD") : ""}
                                                                    </material.TableCell>
                                                                </material.TableRow>
                                                            ))
                                                        ) : (
                                                            <material.TableRow>
                                                                <material.TableCell colSpan={3} align="center">
                                                                    <h6 className="text-danger fw-bold">No emails found</h6>
                                                                </material.TableCell>
                                                            </material.TableRow>
                                                        )}
                                                    </material.TableBody>
                                                </material.Table>
                                            )}
                                        </material.TableContainer>

                                        <material.TablePagination
                                            rowsPerPageOptions={[5, 10, 50]}
                                            component="div"
                                            count={filteredEmailData.length}
                                            rowsPerPage={rowsPerPage}
                                            page={currentPage}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </material.Paper>
                                </material.Grid>
                            </>
                        ) : (
                            // Email Details View
                            <material.Grid item xs={12}>
                                <material.Paper sx={{ width: "100%", overflow: "hidden", p: 2 }}>
                                    <material.Box sx={{ mb: 2 }}>
                                        <strong>From:</strong> {selectedEmail.from}
                                    </material.Box>
                                    <material.Box sx={{ mb: 2 }}>
                                        <strong>Subject:</strong> {selectedEmail.subject}
                                    </material.Box>
                                    <material.Box sx={{ mb: 2 }}>
                                        <strong>Received Date:</strong> {selectedEmail.timestamp ? moment(selectedEmail.timestamp).format("YYYY-MM-DD") : "Unknown"}
                                    </material.Box>
                                    <material.Divider sx={{ mb: 2 }} />
                                    <div dangerouslySetInnerHTML={{ __html: selectedEmail.body }} />
                                    <material.Box sx={{ mt: 2, textAlign: "right" }}>
                                        <material.Button variant="contained" onClick={() => setSelectedEmail(null)}>
                                            Back to List
                                        </material.Button>
                                    </material.Box>
                                </material.Paper>
                            </material.Grid>
                        )}
                    </material.Grid>
                </material.DialogContent>
            </material.Dialog>

            <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
        </div>
    );
}

export default ViewReceiveMailData