import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import UploadDocument from './UploadDocument';
import { deleteDocumentFormSendFolder, saveProtocolDocumentToSendFolder, viewProtocolDocument } from '../../../services/on-boarding-service/OnBoardingService';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Snackbar from '../../toastrmessage/Snackbar';
import { rootApiUrl } from '../../../environment/Environment';

const label = { inputProps: { 'aria-label': 'Color switch demo' } };

function ViewDocument(props) {

    const { openViewDocument, setViewDocument } = props;
    const [openUploadProtocolDocument, setOPenUploadProtocolDocument] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [getDocumentListData, setGetDocumentListData] = useState([]);
    const [sendInEmail, setSendInEmail] = useState(false);

    useEffect(() => {
        if (openViewDocument) {
            getProtocolDocumentList()
        }
    }, [openViewDocument, sendInEmail]);

    const getProtocolDocumentList = () => {
        setIsLoading(true);
        viewProtocolDocument({ inSent: sendInEmail })
            .then((resp) => {
                setGetDocumentListData(resp.data.data)
                setIsLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const uploadDocument = () => {
        setOPenUploadProtocolDocument(true);
    };

    const handleClose = () => {
        setSendInEmail(false);
        setViewDocument(false);
    };

    const handleCheck = (e) => {
        setSendInEmail(e.target.checked)
    };

    const downloadDocument = (rowData) => {
        window.open(`${rootApiUrl}/agl-link?key=${rowData.Key}`, "_blank")
    };

    const deleteDocument = (rowData) => {
        deleteDocumentFormSendFolder({ key: rowData.Key })
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                getProtocolDocumentList();
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const sendDocument = (rowData) => {
        saveProtocolDocumentToSendFolder({ key: rowData.Key })
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openViewDocument} fullWidth maxWidth="lg">
                <material.DialogTitle>
                    Protocol Document List
                    <span className='d-flex flex-row float-end'>
                        <span>
                            <material.FormGroup onChange={handleCheck}>
                                <material.FormControlLabel control={<material.Switch color='secondary' checked={sendInEmail} />} label="Send in Email" />
                            </material.FormGroup>
                        </span>
                        <material.Button variant="contained" size='small' sx={{ textTransform: "none", mr: 1 }} onClick={uploadDocument} startIcon={<material.UploadFileIcon />} hidden={sendInEmail}>Upload Document</material.Button>
                        <material.Button variant="contained" size='small' sx={{ textTransform: "none" }} color='error' onClick={handleClose} startIcon={<material.CloseIcon />}>Close</material.Button>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <material.TableContainer sx={{ maxHeight: "70vh" }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell>Document Name</StyledTableCell>
                                        <StyledTableCell>Last Modify Date</StyledTableCell>
                                        <StyledTableCell>Action</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={10}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={10} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {getDocumentListData.length ? getDocumentListData.map((row, k) => (
                                                <material.TableRow key={k}>
                                                    <material.TableCell>{row.Key.split("/").pop()}</material.TableCell>
                                                    <material.TableCell>{row.LastModified}</material.TableCell>
                                                    <material.TableCell>
                                                        <material.IconButton title='Download Document' onClick={() => downloadDocument(row)}>
                                                            <material.DownloadIcon color='info' />
                                                        </material.IconButton>
                                                        <material.IconButton title='Send Document' onClick={() => sendDocument(row)} hidden={sendInEmail}>
                                                            <material.SendIcon color='secondary' />
                                                        </material.IconButton>
                                                        <material.IconButton title='Delete Document' hidden={!sendInEmail} onClick={() => deleteDocument(row)}>
                                                            <material.DeleteIcon color='error' />
                                                        </material.IconButton>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow>
                                                    <material.TableCell colSpan={10}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>
                                    )}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                    </material.Paper>
                </material.DialogContent>
            </material.Dialog>
            <UploadDocument
                openUploadProtocolDocument={openUploadProtocolDocument}
                setOPenUploadProtocolDocument={setOPenUploadProtocolDocument}
                getProtocolDocumentList={getProtocolDocumentList}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default ViewDocument;