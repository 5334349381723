import React, { useRef, useState, useEffect } from 'react';
import { material } from '../../library/material';
import { getRoomList, getRoomScheduleClinic } from '../../services/ConfigureService';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Scheduler } from "@aldabil/react-scheduler";
import Snackbar from '../toastrmessage/Snackbar';


var getAllRoomData = [];
var selectRoomData;

function ViewRoomSchedule(props) {

    const { openRoomBookedSchedule, setOpenRoomBookedSchedule, clinicData, userData } = props;
    const scheduleObj = useRef(null);
    const clinicDetails = clinicData.clinicReducer.data;
    const userDetails = userData.authReducer.data;
    const [roomList, setRoomList] = useState([]);
    const [roomName, setRoomName] = useState([]);
    const [roomScheduleData, setRoomScheduleData] = useState([]);
    const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [isLoading, setIsLoading] = useState(true);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });

    useEffect(() => {
        if (openRoomBookedSchedule.action) {
            getAllRoomList()
        }
    }, [openRoomBookedSchedule.action === true]);

    useEffect(() => {
        if (openRoomBookedSchedule.action) {
            getRoomScheduleList()
        }
    }, [openRoomBookedSchedule.action === true]);

    const getAllRoomList = async () => {
        await getRoomList(clinicDetails.clinicId)
            .then((resp) => {
                getAllRoomData = resp.data.data;
                let roomName = resp.data?.data?.map((ele) => ele.roomName);
                setRoomName(roomName)
                let data = resp.data?.data?.map((ele) => {
                    return {
                        ...ele,
                        admin_id: ele.roomId,
                        title: ele.roomName,
                        color: "#" + Math.floor(Math.random() * 16777215).toString(16),
                    }
                })
                setRoomList(data)
                setIsLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            });
    };

    const getRoomScheduleList = async () => {
        await getRoomScheduleClinic(clinicDetails.clinicId)
            .then((resp) => {
                let data = resp.data?.data?.map((ele) => {
                    return {
                        ...ele,
                        start: new Date(ele.start),
                        end: new Date(ele.end),
                        admin_id: ele.RoomId,
                    }
                })
                setRoomScheduleData(data)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            });
    };

    const selectRoom = (e, value) => {
        selectRoomData = getAllRoomData.filter((ele) => ele.roomName === value)
    };

    const saveRoomName = (data) => {
        let value = selectRoomData[0]
        setOpenRoomBookedSchedule({ action: false, data: value })
    };

    return (
        <div>
            <material.Dialog maxWidth="lg" fullWidth hideBackdrop open={openRoomBookedSchedule.action}>
                <material.DialogTitle>
                    <span><material.Typography variant="h5">Select Room</material.Typography></span>
                    <span>
                        <material.Autocomplete
                            id="roomName"
                            onChange={selectRoom}
                            options={roomName}
                            renderInput={(params) => <material.TextField {...params} variant="standard" label="Select Room"
                                {...register("roomName", { required: true })}
                                sx={{ width: "30%" }}
                            />}
                        />
                        <p className='form-text text-danger'>{errors.roomName?.type === "required" && 'This field is required'}</p>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    {isLoading ? null : (
                        <div>
                            <Scheduler
                                view="day"
                                selectedDate={new Date()}
                                week={{
                                    startHour: 9,
                                    endHour: 23,
                                    step: 30
                                }}
                                day={{
                                    startHour: 9,
                                    endHour: 23,
                                    step: 30
                                }}
                                resourceFields={{
                                    idField: "admin_id",
                                    textField: "title",
                                    avatarField: "title",
                                }}
                                events={roomScheduleData}
                                resources={roomList}
                                viewerExtraComponent={(fields, event) => {
                                    return (
                                        <div>
                                            {fields.map((field, i) => {
                                                if (field.name === "admin_id") {
                                                    const admin = field.options.find(
                                                        (fe) => fe.id === event.admin_id
                                                    );
                                                    return (
                                                        <material.Typography
                                                            key={i}
                                                            style={{ display: "flex", alignItems: "center", textTransform: "capitalize" }}
                                                            color="textSecondary"
                                                            variant="caption"
                                                            noWrap
                                                        >
                                                            <material.PersonIcon /> {admin.text}
                                                        </material.Typography>
                                                    );
                                                } else {
                                                    return "";
                                                }
                                            })}
                                        </div>
                                    );
                                }}
                            />
                        </div>
                    )}
                </material.DialogContent>
                <material.DialogActions sx={{ marginRight: 7, paddingBottom: 3 }}>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} color='error' size="medium" onClick={() => setOpenRoomBookedSchedule({ action: false })}>
                        Cancel
                    </material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={handleSubmit(saveRoomName)}>
                        Save
                    </material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(ViewRoomSchedule);