import axios from "axios";
import { rootApiUrl } from "../environment/Environment";


export const getProductPriceDataByClinicId = async (clinicId) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/stocks/get-stock-price-clinic/${clinicId}`);
};

export const getProductPriceDataByOrgId = async (orgId) => {
    return await axios.get(`${rootApiUrl}/get-stock-price-org/${orgId}`);
};

export const saveOrUpdateStockPrice = async (productData) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/stocks/save-or-update-stock-prices`, productData);
};

export const getInventoryListByClinicId = (clinicId) => {
    return axios.get(`${rootApiUrl}/api/v0.2/stocks/get-inventory-list-clinic/${clinicId}`)
};

export const getInventoryListByorgId = (orgId) => {
    return axios.get(`${rootApiUrl}/api/v0.2/stocks/get-inventory-list-org/${orgId}`)
};

export const getOtherStockPriceDataByClinic = (clinicId) => {
    return axios.get(`${rootApiUrl}/api/v0.2/stocks/get-stock-price-clinic-other/${clinicId}`)
};

export const getOtherStockPriceDataByOrg = (orgId) => {
    return axios.get(`${rootApiUrl}/api/v0.2/stocks/get-stock-price-org-other/${orgId}`)
};

export const getOtherInventoryListByClinicId = (clinicId) => {
    return axios.get(`${rootApiUrl}/api/v0.2/stocks/get-inventory-list-clinic-other/${clinicId}`)
};

export const getOtherInventoryListByOrg = (orgId) => {
    return axios.get(`${rootApiUrl}/api/v0.2/stocks/get-inventory-list-org-other/${orgId}`)
};

export const saveOrUpdateOtherStockPrice = (otherStockData) => {
    return axios.post(`${rootApiUrl}/api/v0.2/stocks/save-or-update-stock-prices-other`, otherStockData)
};

export const getSkinTreatmentPriceListByClinicId = (clinicId) => {
    return axios.get(`${rootApiUrl}/api/v0.2/skin-treatment/get-skin-treatment-price-list`, { params: { clinicId: clinicId } })
};

export const saveSkinTreatmentPrice = (skinTreatmentData) => {
    return axios.post(`${rootApiUrl}/api/v0.2/skin-treatment/save-skin-treatment-price`, skinTreatmentData)
};

export const deleteCosmeticInjectableProduct = (productData) => {
    return axios.put(`${rootApiUrl}/api/v0.2/stocks/delete-stock-prices`, productData)
};

export const deleteOtherProduct = (productData) => {
    return axios.put(`${rootApiUrl}/api/v0.2/stocks/delete-stock-prices-other`, productData)
};

export const deleteSkinTreatmentProduct = (productData) => {
    return axios.put(`${rootApiUrl}/api/v0.2/skin-treatment/delete-skin-treatment-prices`, productData)
};