import { rootApiUrl } from "../../environment/Environment";
import axios from "axios";

export const getUserPermission = async (payload) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/role-management/permissions`, { params: { clinicId: payload.clinicId } });
};

export const saveUserRolePermission = async (payload) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/role-management/role`, payload);
};

export const getUserRolePermission = async (payload) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/role-management/role`, { params: { orgId: payload.orgId, clinicId: payload.clinicId } });
};