import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { getPreWrittenNotesInjector, getPreWrittenNotesPrescriber } from '../../services/TreatmentPlanService';
import { material } from '../../library/material';
import { Slide } from '@mui/material';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import Snackbar from '../toastrmessage/Snackbar';

var notesArray = [];
var preWrittenNoteArray = [];

const TransitionSide = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
const cosmeticInjectablePermission = ["COSMETIC_INJECTABLE_TREATMENTS"];
const prescriberPermission = ["AEST_PRESCRIBER"];

function PreSelectNotes(props) {

    const { openPreSelectNote, setOpenPreSelectNote, userData, getNoteData } = props;
    const { register, handleSubmit, reset, setValue, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const userDetails = userData.authReducer.data;
    const [preWrittenNotes, setPreWrittenNotes] = useState([]);
    const [treatmentData, setTreatmentData] = useState([]);
    const [treatmentAreaData, setTreatmentAreaData] = useState([]);
    const [selectTreatmentData, setSelectTreatmentData] = useState("");
    const [selectTreatmentAreaData, setSelectTreatmentAreaData] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (openPreSelectNote.action) {
            let treatmentName = openPreSelectNote.treatmentData?.treatmentPlan ? openPreSelectNote.treatmentData.treatmentPlan.map((ele) => ele.treatmentName) :
                openPreSelectNote.treatmentData?.map((ele) => ele.treatmentName);
            setTreatmentData(treatmentName);
            setPreWrittenNotes([]);
        };
    }, [openPreSelectNote.action === true]);

    const handleChangeTreatment = (e, value) => {
        setSelectTreatmentData(value)
        let treatmentArea = openPreSelectNote.treatmentData?.treatmentPlan ? openPreSelectNote.treatmentData.treatmentPlan.filter((ele) => ele.treatmentName === value).map((resp) => resp.treatmentArea) :
            openPreSelectNote.treatmentData.filter((ele) => ele.treatmentName === value).map((resp) => resp.treatmentArea);
        setTreatmentAreaData(treatmentArea);
    };

    const handleChangeArea = (e, value) => {
        setSelectTreatmentAreaData(value)
        getPreWrittenNotes(value)
    };

    const getPreWrittenNotes = async (treatmentArea) => {
        if (userDetails.role.permissions.some(item => cosmeticInjectablePermission.includes(item.permissionName)) && userDetails.isDoctor === false) {
            await getPreWrittenNotesInjector(selectTreatmentData, treatmentArea)
                .then(async (resp) => {
                    await resp.data.data.map((ele) => {
                        preWrittenNoteArray.push(
                            {
                                treatmentName: selectTreatmentData,
                                treatmentArea: treatmentArea,
                                notes: ele
                            })
                    })
                    setPreWrittenNotes(resp.data)
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.data.info,
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.messages,
                        })
                    }
                })
        } else if (userDetails.role.permissions.some(item => prescriberPermission.includes(item.permissionName)) || (userDetails.role.permissions.some(item => cosmeticInjectablePermission.includes(item.permissionName)) && userDetails.isDoctor === true)) {
            await getPreWrittenNotesPrescriber(selectTreatmentData, treatmentArea)
                .then(async (resp) => {
                    await resp.data.data.map((ele) => {
                        preWrittenNoteArray.push(
                            {
                                treatmentName: selectTreatmentData,
                                treatmentArea: treatmentArea,
                                notes: ele
                            })
                    })
                    setPreWrittenNotes(resp.data)
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.data.info,
                        })
                    } else {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": error.response.data.messages,
                        })
                    }
                })
        };
    };

    const saveNote = (data) => {
        // console.log(notesArray)
        getNoteData(notesArray);
        reset({ treatmentName: "", treatmentNote: "" });
        preWrittenNoteArray = [];
        setOpenPreSelectNote({ action: false, treatmentData: null });
    };

    const close = () => {
        preWrittenNoteArray = [];
        setOpenPreSelectNote({ action: false, treatmentData: null });
        reset({ treatmentName: "", treatmentNote: "" });
        setPreWrittenNotes([]);
    };

    const handleCheckedNotes = (notes) => {
        if (notesArray.includes(notes)) {
            notesArray.pop(notes)
        } else {
            notesArray.push(notes)
        };
    };

    return (
        <div>
            <material.Dialog fullScreen open={openPreSelectNote.action} hideBackdrop TransitionComponent={TransitionSide}>
                <material.AppBar sx={{ position: 'relative' }}>
                    <material.Toolbar>
                        <material.IconButton
                            edge="start"
                            color="inherit"
                            onClick={close}
                            aria-label="close"
                        >
                            <material.CloseIcon />
                        </material.IconButton>
                        <material.Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Select Pre Written Notes
                        </material.Typography>
                        <material.Button autoFocus color="inherit" onClick={handleSubmit(saveNote)}>
                            save
                        </material.Button>
                    </material.Toolbar>
                </material.AppBar>
                <material.DialogContent>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <material.Autocomplete
                                id="machineName"
                                onChange={handleChangeTreatment}
                                options={treatmentData}
                                renderInput={(params) => <material.TextField {...params} variant="standard"
                                    label="Treatment Name"
                                    required
                                    {...register("treatmentName", { required: true })}
                                />}
                            />
                        </div>
                        <div className='col-lg-4'>
                            <material.Autocomplete
                                id="machineName"
                                onChange={handleChangeArea}
                                options={treatmentAreaData}
                                renderInput={(params) => <material.TextField {...params} variant="standard"
                                    label="Treatment Area"
                                    required
                                    {...register("treatmentArea", { required: true })}
                                />}
                            />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                            <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <material.TableContainer sx={{ maxHeight: 460 }}>
                                    <material.Table stickyHeader aria-label="sticky table">
                                        <material.TableHead>
                                            <material.TableRow>
                                                <StyledTableCell style={{ width: "10%" }}>Treatment</StyledTableCell>
                                                <StyledTableCell style={{ width: "10%" }}>Area</StyledTableCell>
                                                <StyledTableCell style={{ width: "75%" }}>Notes</StyledTableCell>
                                                <StyledTableCell style={{ width: "5%" }}></StyledTableCell>
                                            </material.TableRow>
                                        </material.TableHead>
                                        <material.TableBody>
                                            {preWrittenNoteArray?.map((ele, i) => (
                                                <material.TableRow key={i}>
                                                    <material.TableCell>{ele.treatmentName}</material.TableCell>
                                                    <material.TableCell>{ele.treatmentArea}</material.TableCell>
                                                    <material.TableCell>{ele.notes}</material.TableCell>
                                                    <material.TableCell>
                                                        <material.Checkbox onChange={() => handleCheckedNotes(ele)} color="primary" />
                                                    </material.TableCell>
                                                </material.TableRow>
                                            ))}
                                        </material.TableBody>
                                    </material.Table>
                                </material.TableContainer>
                            </material.Paper>
                        </div>
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userData: state,
    };
};

export default connect(mapStateToProps)(PreSelectNotes);