import React, { useState } from 'react';
import { material } from '../../../library/material';
import { useForm } from 'react-hook-form';
import { saveCashFloatDocument } from '../../../services/CreateBankCashFloatService';
import Snackbar from '../../toastrmessage/Snackbar';

function AddAttachmentDialog(props) {

    const { openAddAttachment, setOpenAddAttachment, getBlankCashFloatData } = props;
    const { register, handleSubmit, resetField, watch, formState: { errors } } = useForm({
        mode: "onTouched",
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "application/vnd.ms-excel", // For .xls files
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // For .xlsx files
    ];

    const validateFile = (files) => {
        if (files && files.length > 0) {
            for (let file of files) {
                if (!allowedTypes.includes(file.type)) {
                    return `Invalid file type: ${file.type}. Only PDF, DOC, DOCX, JPEG, PNG, SVG, XLS, and XLSX files are allowed.`;
                }
            }
        }
        return true;
    };


    const handleClose = () => {
        setOpenAddAttachment({ action: false, formData: null });
    };

    const uploadFile = (data) => {
        let formData = new FormData();
        formData.append("id", openAddAttachment.formData.id);
        formData.append("clinicId", openAddAttachment.formData.clinicId);
        formData.append("file", data.file[0]);
        saveCashFloatDocument(formData)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data.messages,
                });
                getBlankCashFloatData();
                setOpenAddAttachment({ action: false, formData: null });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    return (
        <div>
            <material.Dialog open={openAddAttachment.action} fullWidth maxWidth="sm">
                <material.DialogTitle>Upload Attachment</material.DialogTitle>
                <material.DialogContent>
                    <span>
                        <material.InputLabel>Choose File</material.InputLabel>
                        <input
                            type="file"
                            {...register("file", {
                                required: "This field is required",
                                validate: validateFile,
                            })}
                        />
                        {errors.file && (
                            <p style={{ color: "red" }}>{errors.file.message}</p>
                        )}
                    </span>
                </material.DialogContent>
                <material.DialogActions>
                    <material.Button onClick={handleClose} variant="contained" color="error" sx={{ textTransform: "none", mr: 1 }} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button onClick={handleSubmit(uploadFile)} variant="contained" color="success" sx={{ textTransform: "none" }} startIcon={<material.UploadFileIcon />}>Upload</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default AddAttachmentDialog;