import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { material } from '../../../library/material';
import { getSkinTreatmentPriceListForBookingByClinicId } from '../../../services/SkinTreatmentService';
import { debounce } from 'lodash';
import Snackbar from '../../toastrmessage/Snackbar';
import { saveOrUpdatePackageDetails } from '../../../services/PackageConfigurationService';

const complementary = ["Yes", "No"];

function AddTreatmentPackage(props) {

    const { clinicDetails, userDetails, openAddTreatmentPackage, setOpenAddTreatmentPackage, getSingleClinicPackages, packageId, setPackageId } = props;
    const { register, handleSubmit, getValues, control, watch, setValue, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [treatmentNameOptions, setTreatmentNameOptions] = useState([]);
    const [treatmentList, setTreatmentList] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [selectedTreatmentName, setSelectedTreatmentName] = useState([]);

    useEffect(() => {
        const calculateTotalCost = debounce(() => {
            selectedTreatmentName.forEach((treatmentName) => {
                const costPerSession = watch(`${treatmentName}_perSessionCost`);
                const numberOfSessions = watch(`${treatmentName}_numberOfSession`);
                const discountPercentage = watch(`${treatmentName}_discountPercentage`);
                const discount = watch(`${treatmentName}_discount`);
                const totalCost = watch(`${treatmentName}_totalCost`);
                if (costPerSession && numberOfSessions) {
                    const totalCost = parseFloat(costPerSession) * parseInt(numberOfSessions);
                    setValue(`${treatmentName}_totalCost`, totalCost.toFixed(2));
                }
                if (totalCost && discountPercentage) {
                    const discount = parseFloat(totalCost) * parseInt(discountPercentage) / 100;
                    setValue(`${treatmentName}_discount`, discount.toFixed(2));
                }
                if (discount && totalCost) {
                    const payableAmount = parseFloat(totalCost) - parseInt(discount)
                    setValue(`${treatmentName}_totalPayableAmount`, payableAmount.toFixed(2));
                }
            });
        }, 300);

        calculateTotalCost();
        return () => {
            calculateTotalCost.cancel();
        };
    }, [
        selectedTreatmentName,
        ...selectedTreatmentName.map(treatmentName => watch(`${treatmentName}_perSessionCost`)),
        ...selectedTreatmentName.map(treatmentName => watch(`${treatmentName}_numberOfSession`)),
        ...selectedTreatmentName.map(treatmentName => watch(`${treatmentName}_totalCost`)),
        ...selectedTreatmentName.map(treatmentName => watch(`${treatmentName}_discountPercentage`)),
        ...selectedTreatmentName.map(treatmentName => watch(`${treatmentName}_discount`)),
        ...selectedTreatmentName.map(treatmentName => watch(`${treatmentName}_totalPayableAmount`)),
    ]);

    useEffect(() => {
        if (openAddTreatmentPackage.action) {
            getSkinTreatmentList()
        }
    }, [openAddTreatmentPackage.action === true])

    const getSkinTreatmentList = () => {
        getSkinTreatmentPriceListForBookingByClinicId(clinicDetails.clinicId)
            .then((resp) => {
                const skinTreatment = resp.data.data.map((x) => {
                    return {
                        treatmentName: x.treatmentName,
                        treatmentDetails: x.treatmentDetails,
                    };
                });
                setTreatmentList(skinTreatment);
                const treatmentName = skinTreatment.length ? skinTreatment.map((x) => x.treatmentName) : [];
                setTreatmentNameOptions(treatmentName)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getTreatmentAreaOptions = (treatmentName) => {
        const treatment = treatmentList.find(ele => ele.treatmentName === treatmentName);
        const treatmentArea = treatment?.treatmentDetails?.map((x) => x.treatmentArea);
        return treatmentArea ? treatmentArea : [];
    };

    const saveTreatment = (formData) => {
        const array = [];
        for (const element of formData.treatmentName) {
            let temp = {}
            temp.clinicId = clinicDetails.clinicId;
            temp.orgId = userDetails.orgId;
            temp.treatment_product_service = "TREATMENT";
            temp.packageId = packageId;
            temp.treatmentProductName = element;
            temp.treatmentProductArea = formData[element + "_treatmentArea"]?.length > 1 ? formData[element + "_treatmentArea"].join(",") : formData[element + "_treatmentArea"][0];
            temp.numberOfSession_quantityOfProduct = formData[element + "_numberOfSession"];
            temp.perSessionCost = formData[element + "_perSessionCost"];
            temp.discountPercentage = formData[element + "_discountPercentage"];
            temp.total = formData[element + "_totalCost"];
            temp.discount = formData[element + "_discount"];
            temp.totalPayableAmount = formData[element + "_totalPayableAmount"];
            temp.isComplementary = formData[element + "_isComplementary"] == "Yes" ? true : false;
            array.push(temp);
        }
        saveOrUpdatePackageDetails(array)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data.messages,
                });
                reset();
                setSelectedTreatmentName([]);
                setOpenAddTreatmentPackage({ action: false, treatmentData: null });
                getSingleClinicPackages(resp.data.data.newAssignedId);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const cancel = () => {
        reset();
        setSelectedTreatmentName([]);
        setOpenAddTreatmentPackage({ action: false, treatmentData: null });
    };

    return (
        <div>
            <material.Dialog open={openAddTreatmentPackage.action} maxWidth="lg" fullWidth>
                <material.DialogTitle>Add Treatment Plan</material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                        <div className="col-12">
                            <Controller
                                name="treatmentName"
                                control={control}
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <material.Autocomplete
                                        multiple
                                        disableCloseOnSelect
                                        {...field}
                                        value={field.value || []}
                                        options={treatmentNameOptions}
                                        renderInput={(params) => (
                                            <material.TextField
                                                required
                                                margin="dense"
                                                {...params}
                                                variant="standard"
                                                label="Treatment Name"
                                                fullWidth
                                            />
                                        )}
                                        onChange={(_, data) => {
                                            field.onChange(data);
                                            setSelectedTreatmentName(data);
                                            data.forEach(name => {
                                                setValue(`${name}_treatmentName`, name);
                                                setValue(`${name}_numberOfSession`, "");
                                                setValue(`${name}_treatmentArea`, "");
                                                setValue(`${name}_perSessionCost`, "");
                                                setValue(`${name}_totalCost`, "");
                                                setValue(`${name}_discountPercentage`, "");
                                                setValue(`${name}_discount`, "");
                                                setValue(`${name}_totalPayableAmount`, "");
                                            })
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-12">
                            {selectedTreatmentName.length ? selectedTreatmentName.map((treatmentName, ind) => (
                                <div className="row border border-2 p-2 my-2 rounded-2 mt-2" key={ind} style={{ boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" }}>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <material.TextField
                                            {...register(`${treatmentName}_treatmentName`)}
                                            required
                                            margin="dense"
                                            id="name"
                                            label="Treatment Name"
                                            type="text"
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            variant="standard"
                                        />
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <Controller
                                            name={`${treatmentName}_treatmentArea`}
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <material.Autocomplete
                                                    multiple
                                                    disableCloseOnSelect
                                                    {...field}
                                                    value={field.value || []}
                                                    options={getTreatmentAreaOptions(watch(`${treatmentName}_treatmentName`))}
                                                    renderInput={(params) => (
                                                        <material.TextField
                                                            margin="dense"
                                                            {...params}
                                                            variant="standard"
                                                            label="Treatment Area"
                                                            fullWidth
                                                        />
                                                    )}
                                                    onChange={(_, data) => {
                                                        field.onChange(data);
                                                        const selectedPrices = data.map(treatmentArea => {
                                                            const treatmentDetails = treatmentList.flatMap(x =>
                                                                x.treatmentDetails.filter(area => area.treatmentArea === treatmentArea)
                                                            );
                                                            const prices = treatmentDetails.map(ele => ele.sessionAndPrice?.[0]?.price || 0);

                                                            return prices;
                                                        });
                                                        setValue(`${treatmentName}_perSessionCost`, selectedPrices.flat().reduce((a, b) => a + b, 0));
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-4">
                                        <Controller
                                            name={`${treatmentName}_isComplementary`}
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <material.Autocomplete
                                                    {...field}
                                                    options={complementary}
                                                    renderInput={(params) => (
                                                        <material.TextField
                                                            required
                                                            margin="dense"
                                                            {...params}
                                                            variant="standard"
                                                            label="Is Complementary"
                                                            fullWidth
                                                        />
                                                    )}
                                                    onChange={(event, data) => {
                                                        field.onChange(data);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-4">
                                        <material.TextField
                                            {...register(`${treatmentName}_numberOfSession`, { required: true })}
                                            required
                                            margin="dense"
                                            id="name"
                                            label="Number of Session"
                                            type="number"
                                            fullWidth
                                            variant="standard"
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-4">
                                        <material.TextField
                                            {...register(`${treatmentName}_perSessionCost`, { required: true })}
                                            required
                                            margin="dense"
                                            id="name"
                                            label="Per Session Cost"
                                            type="text"
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            InputLabelProps={{ shrink: true }}
                                            variant="standard"
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-4">
                                        <material.TextField
                                            {...register(`${treatmentName}_totalCost`, { required: true })}
                                            required
                                            margin="dense"
                                            id="name"
                                            label="Total Cost"
                                            type="number"
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            InputLabelProps={{ shrink: true }}
                                            variant="standard"
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-4">
                                        <material.TextField
                                            {...register(`${treatmentName}_discountPercentage`, { required: true })}
                                            required
                                            margin="dense"
                                            id="name"
                                            label="Discount Percentage"
                                            type="number"
                                            fullWidth
                                            variant="standard"
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-4">
                                        <material.TextField
                                            {...register(`${treatmentName}_discount`, { required: true })}
                                            required
                                            margin="dense"
                                            id="name"
                                            label="Discount"
                                            type="number"
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            InputLabelProps={{ shrink: true }}
                                            variant="standard"
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-4">
                                        <material.TextField
                                            {...register(`${treatmentName}_totalPayableAmount`, { required: true })}
                                            required
                                            margin="dense"
                                            id="name"
                                            label="Total Payable Amount"
                                            type="number"
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            InputLabelProps={{ shrink: true }}
                                            variant="standard"
                                        />
                                    </div>
                                </div>
                            )) : null}
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions sx={{ m: 2 }}>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" color="error" onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" color="primary" onClick={handleSubmit(saveTreatment)} startIcon={<material.DoneIcon />}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default AddTreatmentPackage;