import React, { useState } from 'react';
import { material } from '../../../library/material';
import SignaturePad from 'react-signature-canvas';
import { saveConsentFormOpen, saveConsentForm } from '../../../services/ConsentFormService';
import Snackbar from '../../toastrmessage/Snackbar';
import { useNavigate } from 'react-router-dom';
import { socket } from '../../../socket/Socket';
import './ConsentForm.css';
import { useTheme } from '@mui/material';

var sigPad = {};

function ConsentFormDF(props) {

    const { blankTreatmentData, openConsentForm, setGetConsentFormDFData, consentData, callForm } = props;
    const theme = useTheme();
    const navigate = useNavigate();
    const [aw_text1, setAw_text1] = useState('');
    const [aw_text2, setAw_text2] = useState('');
    const [aw_text3, setAw_text3] = useState('');
    const [saveConsentData, setSaveConsentData] = useState(false);
    const [refNo, setRefNo] = useState('');
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [questions, setQuestions] = useState({
        aw_q1: "",
        aw_q2: "",
        aw_q3: "",
        aw_q4: "",
        aw_q5: "",
        aw_q6: "",
        aw_q7: "",
        aw_q8: "",
        aw_q9: "",
        aw_q10: "",
        aw_q11: "",
    });

    const getPatientSignature = () => {
        // getSignature(sigPad.getTrimmedCanvas().toDataURL('image/png'))
    };

    const selectQuestions = (qNumber, value) => {
        setQuestions(prevValues => ({
            ...prevValues,
            [qNumber]: value,
        }))
    };
    const isValid = Object.values(questions).some(value => value === "");

    const saveConsent = async (data) => {
        if (openConsentForm.action === false) {
            let obj = {
                patientId: blankTreatmentData.patientId,
                treatmentPlanRequestId: blankTreatmentData.treatmentPlanRequestId,
                concent_type: "Dermal Filler",
                concent_data: { aw_text1: aw_text1, aw_text2: aw_text2, ...questions, patientSignature: sigPad.getTrimmedCanvas().toDataURL('image/png') }
            }
            await saveConsentForm(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data.messages,
                    })
                    if (callForm === "primary-user") {
                        setTimeout(() => {
                            setGetConsentFormDFData("save")
                        }, 2000);
                    } else {
                        socket.emit("new-concent-added", "Dermal Filler Consent Form Submit successfully");
                        // navigate('/kiosk-menu')
                        setSaveConsentData(true);
                    }
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                })
        } else {
            let obj = {
                refNo: refNo,
                concent_type: "Dermal Filler",
                concent_data: { aw_text1: aw_text1, aw_text2: aw_text2, aw_text3: aw_text3, ...questions, patientSignature: sigPad.getTrimmedCanvas().toDataURL('image/png') }
            }
            await saveConsentFormOpen(obj)
                .then((resp) => {
                    setGetConsentFormDFData(resp.data)
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": "Consent Form Submit successfully",
                    })
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data,
                    })
                })
        };
    };
    const clear = () => {
        sigPad.clear()
    };

    return (
        <div className={callForm === "secondary-user" ? "consent-form-body" : 'content'} id='form-section' hidden={saveConsentData}>
            <material.Paper elevation={3} sx={{
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
                [theme.breakpoints.up('sm')]: {
                    width: '100%',
                },
                [theme.breakpoints.up('md')]: {
                    width: '70%',
                },
                [theme.breakpoints.up('lg')]: {
                    width: '70%',
                },
                mt: 2, m: 2, p: 3, pb: 10
            }}>
                <div className='mt-2 fw-bold'>
                    <span className='d-flex justify-content-center'>Consent Form for Dermal Filler Treatments</span>
                </div>
                <div className='mt-5 ms-5'>
                    <div hidden={openConsentForm.action === false || consentData} className='mb-3'>
                        <material.TextField
                            type='text'
                            variant="standard"
                            sx={{ width: "30%", ml: 1, mr: 1, mt: -1 }}
                            name='refNo'
                            label="Reference No"
                            onChange={e => setRefNo(e.target.value)}
                        />
                    </div>
                    <div className='mb-3'>
                        <span>
                            It is important that you are informed of all the details about your cosmetic injectable procedure.
                            This consent form forms part of the disclosure to better inform you of your treatment today.
                        </span>
                    </div>
                    <h5>Common Risk :</h5>
                    <div>
                        <span>
                            I understand that the risks associated with the above treatment include but are not
                            limited to the following and injector has explained these risks to me
                        </span>
                        {consentData ? (
                            <span className='ms-2'>{consentData.aw_q1}</span>
                        ) : (
                            <span>
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q1", event.target.value)}
                                        value={questions.aw_q1}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </span>
                        )}
                    </div>
                    <ul className='ms-3'>
                        <li>Localised burning or stinging pain during injection</li>
                        <li>Bruising</li>
                        <li>Redness</li>
                        <li>Tenderness</li>
                        <li>Mild swelling at the injection site of Infection at the injection site</li>
                        <li>An uneven/inadequate result – may require additional treatment at review</li>
                        <li>Headache, nausea, cold like symptoms after treatment </li>
                    </ul>
                    <h5>Uncommon Risk :</h5>
                    <div>
                        <span>
                            I understand that Uncommon and rare effects include but are not
                            limited to following and injector has explained these risks to me
                        </span>
                        {consentData ? (
                            <span className='ms-2'>{consentData.aw_q2}</span>
                        ) : (
                            <span>
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q2", event.target.value)}
                                        value={questions.aw_q2}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </span>
                        )}
                    </div>
                    <ul className='ms-3'>
                        <li>Permanent granuloma/nodule/lump formation that may require surgical correction and may be untreatable.</li>
                        <li>Necrosis of the skin</li>
                        <li>Prolonged redness</li>
                        <li>Prolonged sensitivity/pain at the treated sites</li>
                        <li>Scar formation after injection (keloid)</li>
                        <li>Infection and subsequent scar formation</li>
                        <li>Nerve damage and resulting numbness and relaxation</li>
                        <li>Immediate hypersensitivity reactions including anaphylaxis</li>
                        <li>Nasolacrimal duct obstruction, blindness (when treating around the eye area)</li>
                    </ul>
                    <h5>
                        I confirm that:
                    </h5>
                    <ul className='ms-3'>
                        <li>I have disclosed all medical history.
                            {consentData ? (
                                <span className='ms-2'>{consentData.aw_q3}</span>
                            ) : (
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q3", event.target.value)}
                                        value={questions.aw_q3}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            )}
                        </li>
                        <li>I am not pregnant nor breastfeeding or planning to be pregnant in the next 3 months.
                            {consentData ? (
                                <span className='ms-2'>{consentData.aw_q4}</span>
                            ) : (
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q4", event.target.value)}
                                        value={questions.aw_q4}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            )}
                        </li>
                        <li>I have no active infection in the area being treated today.
                            {consentData ? (
                                <span className='ms-2'>{consentData.aw_q5}</span>
                            ) : (
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q5", event.target.value)}
                                        value={questions.aw_q5}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            )}
                        </li>
                        <li>I do  not have a neuromuscular disorder (e.g myasthenia gravis or Eaton Lambert Syndrome)
                            {consentData ? (
                                <span className='ms-2'>{consentData.aw_q6}</span>
                            ) : (
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q6", event.target.value)}
                                        value={questions.aw_q6}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            )}
                        </li>
                        <li>I have been adequately informed about the procedure including the potential benefits and limitations.
                            {consentData ? (
                                <span className='ms-2'>{consentData.aw_q7}</span>
                            ) : (
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q7", event.target.value)}
                                        value={questions.aw_q7}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            )}
                        </li>
                        <li>I understand that results are not guaranteed and I accept the risks, side effects and possible complications inherent in undergoing anti-wrinkle treatments.
                            {consentData ? (
                                <span className='ms-2'>{consentData.aw_q8}</span>
                            ) : (
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q8", event.target.value)}
                                        value={questions.aw_q8}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            )}
                        </li>
                        <li>I understand that no refunds will be given once the treatment has been administered.
                            {consentData ? (
                                <span className='ms-2'>{consentData.aw_q9}</span>
                            ) : (
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q9", event.target.value)}
                                        value={questions.aw_q9}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            )}
                        </li>
                    </ul>
                    <h5>Financial Consent</h5>
                    <span>
                        I
                        {consentData ? (
                            <material.TextField
                                type='text'
                                variant="standard"
                                sx={{ width: "30%", ml: 1, mr: 1, mt: -1 }}
                                name='aw_text1'
                                onChange={e => setAw_text1(e.target.value)}
                                value={openConsentForm?.patientData?.patientName}
                            />
                        ) : (
                            <material.TextField
                                type='text'
                                variant="standard"
                                sx={{ width: "30%", ml: 1, mr: 1, mt: -1 }}
                                name='aw_text1'
                                onChange={e => setAw_text1(e.target.value)}
                                value={openConsentForm?.patientData?.name}
                            />
                        )}
                        consent to the cost of my cosmetic procedure of
                        {consentData ? (
                            <material.TextField
                                id="input-with-icon-textfield"
                                InputProps={{
                                    startAdornment: (
                                        <material.InputAdornment position="start">
                                            <material.AttachMoneyIcon />
                                        </material.InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                type='number'
                                sx={{ width: "20%", ml: 1, mr: 1, mt: -1 }}
                                name='aw_text2'
                                onChange={e => setAw_text2(e.target.value)}
                                value={consentData ? consentData.aw_text2 : ""}
                            />
                        ) : (
                            <material.TextField
                                id="input-with-icon-textfield"
                                InputProps={{
                                    startAdornment: (
                                        <material.InputAdornment position="start">
                                            <material.AttachMoneyIcon />
                                        </material.InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                type='number'
                                sx={{ width: "20%", ml: 1, mr: 1, mt: -1 }}
                                name='aw_text2'
                                onChange={e => setAw_text2(e.target.value)}
                            />
                        )} (Excluding Surcharge)
                    </span>
                    <div className='mt-3 mb-3'>
                        <span>
                            I understand that there may be additional charges included in my treatment plan if
                            I choose to receive further treatment.
                        </span>
                    </div>
                    <span>
                        If there is a complication that requires specialist medical intervention,
                        I understand that the medical specialist may charge additional fees.
                    </span>
                    <h5 className='mt-2'>Photo Consent</h5>
                    <div className='mt-3'>
                        <span>
                            I consent to before and after photos taken for clinical use:
                        </span>
                        {consentData ? (
                            <span className='me-2'>{consentData.aw_q10}</span>
                        ) : (
                            <span>
                                <material.FormControl sx={{ marginTop: -1, mr: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q10", event.target.value)}
                                        value={questions.aw_q10}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </span>
                        )}
                    </div>
                    <div className='mt-3'>
                        <span>
                            I consent to before and after photos taken for marketing and advertisement purposes:
                        </span>
                        {consentData ? (
                            <span className='me-2'>{consentData.aw_q11}</span>
                        ) : (
                            <span>
                                <material.FormControl sx={{ marginTop: -1, mr: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q11", event.target.value)}
                                        value={questions.aw_q11}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </span>
                        )}
                        <div className='mt-3'>
                            <span>
                                By signing below, I acknowledge that I understand the risks, benefits, and instructions associated with the dermal filler procedure. I have been given an opportunity to ask questions and have had them answered to my satisfaction. I hereby give my consent to undergo the dermal filler treatment.
                            </span>
                        </div>
                    </div>
                    <div className='mt-5 d-flex'>
                        <span>Patient Signature</span>
                        {consentData ? (
                            <span className='ms-3'>
                                <img
                                    src={consentData.patientSignature}
                                />
                            </span>
                        ) : (
                            <>
                                <span className='sigBox ms-3'>
                                    <SignaturePad penColor='black'
                                        canvasProps={{ width: 400, height: 100, className: 'sigPad' }}
                                        ref={(ref) => { sigPad = ref }}
                                        onEnd={getPatientSignature}
                                    />
                                </span>
                                <span className='ms-2'>
                                    <material.Button size='small' sx={{ textTransform: "none" }} onClick={clear}>Clear</material.Button>
                                </span>
                            </>
                        )}
                    </div>
                    <span className='float-end mt-3 pe-3'>
                        <material.Button variant="contained" hidden={consentData} sx={{ textTransform: "none" }} onClick={saveConsent} disabled={isValid}>Save Consent Form</material.Button>
                    </span>
                </div>
                <Snackbar
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                />
            </material.Paper>
        </div>
    );
}

export default ConsentFormDF;