import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { useForm } from 'react-hook-form';
import { addVendors } from '../../services/VendorManagementService';
import { connect } from 'react-redux';
import Snackbar from '../toastrmessage/Snackbar';


function AddVendor(props) {

    const { open, setOpen, userData, getVendors, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });

    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (open.action && open.supplierData) {
            reset(open.supplierData)
        };
    }, [open.action === true]);

    const addVendor = async (formData) => {
        let obj = { ...formData, clinicId: clinicDetails.clinicId, orgId: userDetails.orgId }
        await addVendors(obj)
            .then((resp) => {
                reset({ companyName: "", ACN: "", ABN: "", registeredOffice: "", owner: "", manager: "", contactName: "", contactPhone: "", email: "", address: "", clinicId: "" })
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                setOpen({ action: false, supplierData: null })
                getVendors()
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };
    const updateSupplier = async (fromData) => {
        await addVendors(fromData)
            .then((resp) => {
                reset({ companyName: "", abn: "", acn: "", registeredOffice: "", owner: "", manager: "", contactName: "", contactPhone: "", email: "", address: "", clinicId: "" })
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                setOpen({ action: false, supplierData: null })
                getVendors()
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const cancel = () => {
        reset({ companyName: "", acn: "", abn: "", registeredOffice: "", owner: "", manager: "", contactName: "", contactPhone: "", email: "", address: "", clinicId: "" })
        setOpen({ action: false, supplierData: null })
    };

    return (
        <div>
            <material.Dialog maxWidth="md" fullWidth hideBackdrop open={open.action}>
                <material.DialogTitle>{open.supplierData ? "Edit Supplier" : "Add Supplier"}
                </material.DialogTitle>
                <material.DialogContent>
                    <div>
                        <material.TextField
                            error={errors.companyName?.type === "required"}
                            {...register("companyName", { required: true })}
                            required
                            margin="dense"
                            id="name"
                            label="Company Name"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                        <p className='form-text text-danger'>{errors.companyName?.type === "required" && 'This field is required'}</p>
                    </div>
                    <div>
                        <material.TextField
                            error={errors.acn?.type === "required"}
                            {...register("acn", { minLength: 9, maxLength: 9, pattern: /^[0-9]+$/ })}
                            margin="dense"
                            id="number"
                            label="ACN"
                            type="number"
                            fullWidth
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                        />
                        {(errors?.acn?.type === "minLength" || errors?.acn?.type === "maxLength") && (
                            <p className='text-danger'>This field only contain 9 digits</p>
                        )}
                        {errors.acn && errors.acn.type === "pattern" && (
                            <p className="errorMsg text-danger">Allow only number.</p>
                        )}
                    </div>
                    <div>
                        <material.TextField
                            error={errors.abn?.type === "required"}
                            {...register("abn", { minLength: 11, maxLength: 11, pattern: /^[0-9]+$/ })}
                            required
                            margin="dense"
                            id="number"
                            label="ABN"
                            type="number"
                            fullWidth
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                        />
                        <p className='form-text text-danger'>{errors.abn?.type === "required" && 'This field is required'}</p>
                        {errors.abn && errors.abn.type === "pattern" && (
                            <p className="errorMsg text-danger">Allow only number.</p>
                        )}
                        {(errors?.abn?.type === "minLength" || errors?.abn?.type === "maxLength") && (
                            <p className='text-danger'>This field only contain 11 digits</p>
                        )}
                    </div>
                    <div>
                        <material.TextField
                            error={errors.registeredOffice?.type === "required"}
                            {...register("registeredOffice")}
                            required
                            margin="dense"
                            id="name"
                            label="Registered Office"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                        <p className='form-text text-danger'>{errors.registeredOffice?.type === "required" && 'This field is required'}</p>
                    </div>
                    <div>
                        <material.TextField
                            error={errors.owner?.type === "required"}
                            {...register("owner")}
                            margin="dense"
                            id="name"
                            label="Owner"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                    </div>
                    <div>
                        <material.TextField
                            error={errors.manager?.type === "required"}
                            {...register("manager")}
                            margin="dense"
                            id="name"
                            label="Manager"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                    </div>
                    <div>
                        <material.TextField
                            error={errors.contactName?.type === "required"}
                            {...register("contactName")}
                            required
                            margin="dense"
                            id="name"
                            label="Contact Name"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                        <p className='form-text text-danger'>{errors.contactName?.type === "required" && 'This field is required'}</p>
                    </div>
                    <div>
                        <material.TextField
                            error={errors.contactNumber?.type === "required"}
                            {...register("contactNumber")}
                            required
                            margin="dense"
                            id="name"
                            label="Contact Number"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                        <p className='form-text text-danger'>{errors.contactNumber?.type === "required" && 'This field is required'}</p>
                    </div>
                    <div>
                        <material.TextField
                            error={errors.email?.type === "required"}
                            {...register("email", { pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                            required
                            margin="dense"
                            id="name"
                            label="Email"
                            type="email"
                            fullWidth
                            variant="standard"
                        />
                        <p className='form-text text-danger'>{errors.email?.type === "required" && 'This field is required'}</p>
                        {errors?.contact2Mail?.type === "pattern" && (
                            <p className='text-danger'>This is not a valid email</p>
                        )}
                    </div>
                </material.DialogContent>
                <material.DialogActions className='m-3'>
                    <material.Button onClick={cancel} variant="outlined" sx={{ textTransform: "none" }} color="error" startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    {open.supplierData ? (
                        <material.Button onClick={handleSubmit(updateSupplier)} variant="outlined" sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />}>Update</material.Button>
                    ) : (
                        <material.Button onClick={handleSubmit(addVendor)} variant="outlined" sx={{ textTransform: "none" }} startIcon={<material.DoneIcon />}>Save</material.Button>
                    )}
                </material.DialogActions>
            </material.Dialog>
            <div>
                <Snackbar
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(AddVendor);