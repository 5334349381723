import React, { useEffect, useState } from 'react';
import Navbar from '../navbar/Navbar';
import { material } from '../../library/material';
import { useTheme } from '@mui/material';
import { StyledTableCell } from '../../shared/TableHeaderStyle';
import AddOrEditRoleDialog from './AddOrEditRoleDialog';
import { connect } from 'react-redux';
import { getUserRolePermission } from '../../services/manage-roles-service/ManageRolesService';
import Snackbar from '../toastrmessage/Snackbar';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import RoleTypeCheckDialog from './RoleTypeCheckDialog';
import { getClinicByOrgId } from '../../services/ClinicService';
import { getOrgList } from '../../services/UserManagementService';
import { Controller, useForm } from 'react-hook-form';

const permissionsToCheckForClinic = ['CLINIC_SELECTOR'];

function ManageRoles(props) {

    const { userData, clinicData } = props;
    const { register, handleSubmit, reset, setValue, control, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    localStorage.setItem("menuName", "Manage Roles");
    const theme = useTheme();
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [openAddOrEditRoleDialog, setOpenAddOrEditRoleDialog] = useState({ action: false, rowData: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [getRolePermissionData, setGetRolePermission] = useState([]);
    const [openRoleTypeCheckDialog, setOpenRoleTypeCheckDialog] = useState({ action: true, data: null });
    const [organizationName, setOrganizationName] = useState([]);
    const [clinicName, setClinicName] = useState([]);
    const [organizationDetails, setOrganizationDetails] = useState([]);
    const [clinicListData, setClinicListData] = useState([]);
    const [orgId, setOrgId] = useState(null);
    const [clinicId, setClinicId] = useState(null);

    useEffect(() => {
        if (userDetails.role.permissions.some((item) => permissionsToCheckForClinic.includes(item.permissionName))) {
            setOpenRoleTypeCheckDialog({ action: false, data: "Platform Level" });
        } else {
            setOpenRoleTypeCheckDialog({ action: true, data: null });
        }
    }, []);

    useEffect(() => {
        if (openRoleTypeCheckDialog.data == "Platform Level") {
            getUserRolePermissionData();
        } else if (openRoleTypeCheckDialog.data == "Organization Level") {
            getAllOrganization();
        }
    }, [openRoleTypeCheckDialog.action === false]);

    const addRole = () => {
        setOpenAddOrEditRoleDialog({ action: true, rowData: null })
    };

    const getUserRolePermissionData = (payloadData) => {
        setIsLoading(true);
        let payload = {};
        if (userDetails.role.permissions.some((item) => permissionsToCheckForClinic.includes(item.permissionName))) {
            payload = {
                clinicId: clinicDetails.clinicId,
                orgId: userDetails.orgId
            }
        } else {
            payload = {
                clinicId: payloadData ? payloadData.clinicId : null,
                orgId: payloadData ? payloadData.orgId : null
            }
        }
        getUserRolePermission(payload)
            .then((resp) => {
                setGetRolePermission(resp.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getAllOrganization = () => {
        getOrgList()
            .then((resp) => {
                setOrganizationDetails(resp.data.data);
                const orgName = resp.data.data?.map((org) => org.orgName);
                setOrganizationName(orgName);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getAllClinic = (orgId) => {
        getClinicByOrgId(orgId)
            .then((resp) => {
                setClinicListData(resp.data.data);
                const clinicName = resp.data.data?.map((clinic) => clinic.clinicName);
                setClinicName(clinicName);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };


    const editRolePermission = (rowData) => {
        setOpenAddOrEditRoleDialog({ action: true, rowData: rowData })
    };

    return (
        <material.Grid className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 12
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 12
                },
            }}
        >
            <Navbar />
            {openRoleTypeCheckDialog.data === "Organization Level" ? (
                <div className="row">
                    <div className="col-4">
                        <Controller
                            name="organizationName"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <material.Autocomplete
                                    {...field}
                                    value={field.value}
                                    options={organizationName}
                                    renderInput={(params) => (
                                        <material.TextField
                                            required
                                            margin="dense"
                                            {...params}
                                            variant="standard"
                                            label="Organization Name"
                                            fullWidth
                                        />
                                    )}
                                    onChange={(event, data) => {
                                        field.onChange(data);
                                        if (data) {
                                            const orgId = organizationDetails.filter((org) => org.orgName === data).map((x) => x.orgId)[0];
                                            getAllClinic(orgId);
                                            setOrgId(orgId);
                                        }
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className="col-4">
                        <Controller
                            name="clinicName"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <material.Autocomplete
                                    {...field}
                                    value={field.value}
                                    options={clinicName}
                                    renderInput={(params) => (
                                        <material.TextField
                                            required
                                            margin="dense"
                                            {...params}
                                            variant="standard"
                                            label="Clinic Name"
                                            fullWidth
                                        />
                                    )}
                                    onChange={(event, data) => {
                                        field.onChange(data);
                                        if (data) {
                                            const clinicId = clinicListData.filter((clinic) => clinic.clinicName === data).map((clinic) => clinic.clinicId)[0];
                                            setClinicId(clinicId);
                                            const payload = { clinicId: clinicId, orgId: orgId };
                                            getUserRolePermissionData(payload);
                                        }
                                    }}
                                />
                            )}
                        />
                    </div>
                    {clinicListData.length ? (
                        <>
                            <div className="col-12">
                                <span className='float-end'>
                                    <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addRole}>Add Role</material.Button>
                                </span>
                            </div>
                            <div className="col-12 mt-3">
                                <material.Paper sx={{ width: '100%', overflow: 'hidden', p: 3 }}>
                                    <material.TableContainer sx={{ maxHeight: 600 }}>
                                        <material.Table stickyHeader aria-label="sticky table">
                                            <material.TableHead >
                                                <material.TableRow>
                                                    <StyledTableCell>Role</StyledTableCell>
                                                    <StyledTableCell>Permission</StyledTableCell>
                                                    <StyledTableCell>Using For</StyledTableCell>
                                                    {/* <StyledTableCell>Tier Type</StyledTableCell> */}
                                                </material.TableRow>
                                            </material.TableHead>
                                            {isLoading ? (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={10}>
                                                        <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                            <Skeleton count={10} />
                                                        </SkeletonTheme>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            ) : (
                                                <>
                                                    {getRolePermissionData.length ? getRolePermissionData.map((role, k) => (
                                                        <material.TableRow
                                                            key={k}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } }}
                                                            onClick={() => editRolePermission(role)}
                                                        >
                                                            <material.TableCell size='small' sx={{ padding: 2 }}>{role.roleName}</material.TableCell>
                                                            <material.TableCell size='small'>{role.permissions?.map((permission, ind) => (
                                                                <span key={ind}>{permission.permissionName}, </span>
                                                            ))}</material.TableCell>
                                                            <material.TableCell size='small' sx={{ width: 100 }}>{role.usingFor}</material.TableCell>
                                                            {/* <material.TableCell size='small' sx={{ width: 100 }}>{role.tierType}</material.TableCell> */}
                                                        </material.TableRow>
                                                    )) : (
                                                        <material.TableRow >
                                                            <material.TableCell colSpan={10}>
                                                                <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                            </material.TableCell>
                                                        </material.TableRow>
                                                    )}
                                                </>
                                            )}
                                        </material.Table>
                                    </material.TableContainer>
                                </material.Paper>
                            </div>
                        </>
                    ) : null}
                </div>
            ) : openRoleTypeCheckDialog.data === "Platform Level" ? (
                <div className="row">
                    <div className="col-12">
                        <span className='float-end'>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} startIcon={<material.AddIcon />} onClick={addRole}>Add Role</material.Button>
                        </span>
                    </div>
                    <div className="col-12 mt-3">
                        <material.Paper sx={{ width: '100%', overflow: 'hidden', p: 3 }}>
                            <material.TableContainer sx={{ maxHeight: 600 }}>
                                <material.Table stickyHeader aria-label="sticky table">
                                    <material.TableHead >
                                        <material.TableRow>
                                            <StyledTableCell>Role</StyledTableCell>
                                            <StyledTableCell>Permission</StyledTableCell>
                                            <StyledTableCell>Using For</StyledTableCell>
                                            {/* <StyledTableCell>Tier Type</StyledTableCell> */}
                                        </material.TableRow>
                                    </material.TableHead>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={10}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={10} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {getRolePermissionData.length ? getRolePermissionData.map((role, k) => (
                                                <material.TableRow
                                                    key={k}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } }}
                                                    onClick={() => editRolePermission(role)}
                                                >
                                                    <material.TableCell size='small' sx={{ padding: 2 }}>{role.roleName}</material.TableCell>
                                                    <material.TableCell size='small'>{role.permissions?.map((permission, ind) => (
                                                        <span key={ind}>{permission.permissionName}, </span>
                                                    ))}</material.TableCell>
                                                    <material.TableCell size='small' sx={{ width: 100 }}>{role.usingFor}</material.TableCell>
                                                    {/* <material.TableCell size='small' sx={{ width: 100 }}>{role.tierType}</material.TableCell> */}
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={10}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>
                                    )}
                                </material.Table>
                            </material.TableContainer>
                        </material.Paper>
                    </div>
                </div>
            ) : null}

            <AddOrEditRoleDialog
                openAddOrEditRoleDialog={openAddOrEditRoleDialog}
                setOpenAddOrEditRoleDialog={setOpenAddOrEditRoleDialog}
                userDetails={userDetails}
                clinicDetails={clinicDetails}
                clinicId={clinicId}
                orgId={orgId}
                getUserRolePermissionData={getUserRolePermissionData}
                openRoleTypeCheckDialog={openRoleTypeCheckDialog}
            />
            <RoleTypeCheckDialog
                openRoleTypeCheckDialog={openRoleTypeCheckDialog}
                setOpenRoleTypeCheckDialog={setOpenRoleTypeCheckDialog}
            />
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </material.Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state,
    };
};

export default connect(mapStateToProps)(ManageRoles);