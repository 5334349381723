import React, { useState, useEffect } from 'react';
import { material } from '../../../library/material';
import SignaturePad from 'react-signature-canvas';
import { saveSkinConsentForm } from '../../../services/ConsentFormService';
import Snackbar from '../../toastrmessage/Snackbar';
import { getClinicDetailsByClinicId } from '../../../services/TreatmentPlanService';
import AddAndUploadPhotoDialog from '../skin-treatment-booking/AddAndUploadPhotoDialog';
import { socket } from '../../../socket/Socket';

var sigPad = {};

function Microneedling(props) {

    const { openConsentForm, setOpenConsentForm, patientDataDetails, callFrom, getImageList } = props;
    const [questions, setQuestions] = useState({
        aw_q1: "", aw_q2: "", aw_q3: "", aw_q4: "", aw_q5: "", aw_q6: ""
    });
    const [isHide, setIsHide] = useState(false);
    const [getSignature, setGetSignature] = useState(null);
    const [clinicDetails, setClinicDetails] = useState({});
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const selectQuestions = (qNumber, value) => {
        setQuestions(prevValues => ({
            ...prevValues,
            [qNumber]: value,
        }))
    };
    const isValid = Object.values(questions).some(value => value === "");
    const [openPhotoUploadDialog, setOpenPhotoUploadDialog] = useState({ action: false, treatmentData: null });

    useEffect(() => {
        if (openConsentForm.action) {
            getClinicByClinicId()
        }
    }, [openConsentForm.action === true]);

    const getClinicByClinicId = async () => {
        await getClinicDetailsByClinicId(openConsentForm.patientData.clinicId)
            .then((resp) => {
                setClinicDetails(resp.data.data);
                if (openConsentForm.patientData?.concent_data?.data) {
                    const element = document.getElementById("button-section")
                    element.scrollIntoView({ behavior: "smooth" });
                };
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data.messages,
                })
            })
    };

    const getPatientSignature = () => {
        setGetSignature(sigPad.getTrimmedCanvas().toDataURL('image/png'))
    };

    const saveConsent = async () => {
        let obj = {
            patientId: openConsentForm.patientData.patientId,
            skinTreatmentPlanId: openConsentForm.patientData.skinTreatmentPlanId,
            concent_data: { ...questions, getSignature }
        }
        await saveSkinConsentForm(obj)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                if (callFrom === "secondary-user") {
                    socket.emit("new-concent-added");
                    setOpenConsentForm({ action: false, patientData: null })
                } else {
                    setOpenPhotoUploadDialog({ action: true, treatmentData: patientDataDetails });
                    setIsHide(true)
                };
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data.messages,
                })
            })
    };

    const cancel = () => {
        setOpenConsentForm({ action: false, patientData: null })
    };

    const clear = () => {
        sigPad.clear()
        setGetSignature(null)
    };

    const openSettings = () => {
        setOpenPhotoUploadDialog({ action: true, treatmentData: patientDataDetails });
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="md" open={openConsentForm.action} hideBackdrop>
                <material.DialogContent>
                    <div className=''>
                        {/* <material.Paper elevation={3} sx={{ mt: 2, m: 2, p: 3, pb: 10 }}> */}
                        <div className='ms-3'>
                            <div className='mt-2 fw-bold'>
                                <span className='d-flex justify-content-center'>MICRONEEDLING  TREATMENT CONSENT FORM</span>
                            </div>
                            <h5 className='mt-5'>Risks:</h5>
                            <div className='mt-2'>
                                <div>
                                    <span>
                                        I acknowledge that I have been informed about the Microneedling procedure, its potential benefits, and the associated risks. These include, but are not limited to:
                                    </span>
                                </div>
                                <div>
                                    <ul>
                                        <li>Temporary redness, swelling, or discomfort.</li>
                                        <li>Possible changes in skin pigmentation.</li>
                                        <li>Risk of infection.</li>
                                        <li>Minor bruising or irritation.</li>
                                        <li>Incomplete or unsatisfactory results.</li>
                                        <li>I understand and acknowledge that multiple treatments may be required and results are not guaranteed.</li>
                                        <li>I understand and acknowledge that treatment packages are not transferable to any other person and no refund will be provided for change of mind or for any other reason.</li>
                                    </ul>
                                </div>
                                <div>
                                    <div className='mt-2 fw-bold'>
                                        <span className=''>Contraindications: Please indicate YES or NO for each:</span>
                                    </div>
                                </div>
                                <div>
                                    <ul className='ms-3'>
                                        <li>Are you pregnant or breastfeeding?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q1}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q1", event.target.value)}
                                                        value={questions.aw_q1}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Do you have a known allergy to topical anesthetics or any other contraindicated substances?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q2}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q2", event.target.value)}
                                                        value={questions.aw_q2}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Are you currently taking medications that cause increased skin sensitivity?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q3}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q3", event.target.value)}
                                                        value={questions.aw_q3}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Have you recently undergone any facial treatments or surgeries?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q4}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q4", event.target.value)}
                                                        value={questions.aw_q4}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Do you have a history of keloids or hypertrophic scarring?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q5}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q5", event.target.value)}
                                                        value={questions.aw_q5}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                        <li>Do you have active skin infections, lesions, or severe acne in the treatment area?
                                            {openConsentForm.patientData?.concent_data ? (
                                                <span className='ms-2'>{openConsentForm.patientData?.concent_data?.data?.aw_q6}</span>
                                            ) : (
                                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                                    <material.RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={event => selectQuestions("aw_q6", event.target.value)}
                                                        value={questions.aw_q6}
                                                    >
                                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                                    </material.RadioGroup>
                                                </material.FormControl>
                                            )}
                                        </li>
                                    </ul>
                                </div>
                                <h5>Pre-Procedure Care:</h5>
                                <div>
                                    <span>I confirm that I have taken the following pre-procedure care:</span>
                                </div>
                                <div>
                                    <ul>
                                        <li>Avoid direct sun exposure for at least 1 week prior to treatment.</li>
                                        <li>Discontinue use of exfoliating agents or products containing Retin-A, Retinols, or similar products 3-5 days before treatment.</li>
                                        <li>Inform the clinic about any current medications or skin products you're using.</li>
                                        <li>Ensure your face is clean and free of makeup on the day of the treatment.</li>
                                    </ul>
                                </div>
                                <h5>Pre-Procedure Care:</h5>
                                <div>
                                    <span>I understand that I need to follow the provided aftercare advice:</span>
                                </div>
                                <div>
                                    <ul>
                                        <li>Keep the treated area clean and protected from direct sun exposure; apply SPF 30+ sunscreen daily.</li>
                                        <li>Avoid wearing makeup for at least 48 hours post-treatment.</li>
                                        <li>Use only gentle, non-exfoliating cleansers and moisturizers.</li>
                                        <li>Avoid picking or scratching the treated area.</li>
                                        <li>Avoid saunas, hot tubs, and strenuous exercise for 24-48 hours post-treatment.</li>
                                    </ul>
                                </div>
                                <h5>Acknowledgment and Consent:</h5>
                                <div>
                                    <span>By signing below, I acknowledge that I understand the risks, benefits, and instructions associated with the Microneedling procedure. I have been given the opportunity to ask questions, and all of my questions have been answered to my satisfaction. I hereby give my consent to undergo the Microneedling procedure at <span style={{ fontWeight: "bold" }}>{clinicDetails.clinicName}</span>.</span>
                                </div>
                            </div>
                            <div className='mt-5 d-flex'>
                                <span>Patient Signature</span>
                                {openConsentForm.patientData?.concent_data ? (
                                    <span className='ms-3'>
                                        <img
                                            src={openConsentForm.patientData?.concent_data?.data?.getSignature}
                                        />
                                    </span>
                                ) : (
                                    <span className='sigBox ms-3'>
                                        <SignaturePad penColor='black'
                                            canvasProps={{ width: 400, height: 100, className: 'sigPad' }}
                                            ref={(ref) => { sigPad = ref }}
                                            onEnd={getPatientSignature}
                                        />
                                    </span>
                                )}
                                {openConsentForm.patientData?.concent_data ? null : (
                                    <span className='ms-2'>
                                        <material.Button size='small' sx={{ textTransform: "none" }} onClick={clear}>Clear</material.Button>
                                    </span>
                                )}
                            </div>
                        </div>
                        {/* </material.Paper> */}
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <div id='button-section' className='m-3'>
                        {!isHide ? (
                            <span className='float-end'>
                                <material.Button variant="outlined" sx={{ textTransform: "none", mr: 2 }} color='error' onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                                {openConsentForm.patientData?.concent_data ? (
                                    <material.Button variant="outlined" sx={{ textTransform: "none" }} hidden={callFrom === "patient_past_procedure"} onClick={openSettings}>Next</material.Button>
                                ) : (
                                    <material.Button variant="outlined" sx={{ textTransform: "none" }} hidden={callFrom === "patient_past_procedure"} onClick={saveConsent} disabled={isValid}>Save Consent</material.Button>
                                )}
                            </span>
                        ) : null}
                    </div>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <AddAndUploadPhotoDialog
                openPhotoUploadDialog={openPhotoUploadDialog}
                setOpenPhotoUploadDialog={setOpenPhotoUploadDialog}
                callFrom="Consent Form"
                setOpenConsentForm={setOpenConsentForm}
                getImageList={getImageList}
            />
        </div>
    );
}

export default Microneedling;