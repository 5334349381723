import axios from "axios"
import { rootApiUrl } from "../environment/Environment"

export const addVendors = async (vendorData) => {
    return axios.post(`${rootApiUrl}/api/v0.2/suppliers/add-vendors`, vendorData)
};

export const getVendorByClinicId = async (clinicId) => {
    return axios.get(`${rootApiUrl}/api/v0.2/suppliers/get-vendor-by-clinic/${clinicId}`)
};

export const getVendorByOrgId = async (orgId) => {
    return axios.get(`${rootApiUrl}/api/v0.2/suppliers/get-vendor-by-org/${orgId}`)
};