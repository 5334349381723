import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { material } from '../../../library/material';
import Snackbar from '../../toastrmessage/Snackbar';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { dateFormat } from '../../../date-and-time-format/DateAndTimeFormat';
import { saveUpdateCrmAllData } from '../../../services/PlatformCrmService';
import { Link } from 'react-router-dom';



function AddPlatFormCrm(props) {
    const { openEditCrm, setOpenEditCrm, getCrmData, clinicDetails, userDetails } = props;
    const { register, handleSubmit, reset, control, getValues, setValue, watch, formState: { errors, isValid }, } = useForm({ mode: "onTouched" });
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [nextFollowUpData, setNextFollowUpData] = useState(dayjs(new Date()));
    const [emailSentDate, setEmailSentDate] = useState(dayjs(new Date()));
    const [interested, setInterested] = useState("");
    const [status, setStatus] = useState("");
    const [phone, setPhone] = useState("");

    // useEffect(() => {
    //     if (openEditCrm.action && openEditCrm.data) {
    //         reset(openEditCrm.data);
    //         setPhone(openEditCrm.data.phoneNumber);
    //         setStatus(openEditCrm.data.status);
    //         setNextFollowUpData(dayjs(openEditCrm.data.nextFollowUpDate));
    //         setEmailSentDate(dayjs(openEditCrm.data.emailSentDate));
    //     }
    // }, [openEditCrm, reset]);

    useEffect(() => {
        if (openEditCrm.action && openEditCrm.data) {
            // Create a copy of the data and exclude the 'note' field
            const { notes, ...dataWithoutNotes } = openEditCrm.data;

            reset(dataWithoutNotes); // Reset form with data excluding 'note'
            setPhone(openEditCrm.data.phoneNumber);
            setStatus(openEditCrm.data.status);
            setInterested(openEditCrm.data.interested)
            setNextFollowUpData(dayjs(openEditCrm.data.nextFollowUpDate));
            setEmailSentDate(dayjs(openEditCrm.data.emailSentDate));
        }
    }, [openEditCrm, reset]);


    const savePlatformCrmData = (data) => {
        const payload = {
            'orgId': userDetails.orgId,
            // 'clinicId': clinicDetails.clinicId,
            "clinicName": data.clinicName,
            "website": data.website,
            "nurseName": data.nurseName,
            "email": data.email,
            "linkedin": data.linkedin,
            "address": data.address,
            "phoneNumber": phone,
            "faceBook": data.faceBook,
            "tiktok": data.tiktok,
            "instagram": data.instagram,
            "status": status,
            "nextFollowUpDate": dateFormat(nextFollowUpData.$d),
            "emailSentDate": dateFormat(emailSentDate.$d),
            "notes": data.notes,
            "crmName": data.crmName,
            "interested": interested,
            // "others": interested === "Others" ? data.others : null
        }

        saveUpdateCrmAllData(payload)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp?.data?.messages,
                });
                getCrmData();
                cancel();
                setOpenEditCrm({ action: false, data: null });
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error?.response?.data.data,
                });
            })
    };

    const updatePlatformCrmData = (data) => {
        const payload = {
            "platformCrmId": data.platformCrmId,
            "clinicName": data.clinicName,
            "website": data.website,
            "nurseName": data.nurseName,
            "email": data.email,
            "linkedin": data.linkedin,
            "address": data.address,
            "phoneNumber": phone,
            "faceBook": data.faceBook,
            "tiktok": data.tiktok,
            "instagram": data.instagram,
            "status": status,
            "nextFollowUpDate": dateFormat(nextFollowUpData.$d),
            "emailSentDate": dateFormat(emailSentDate.$d),
            "notes": data.notes,
            "crmName": data.crmName,
            "interested": interested,
            // "others": interested === "Others" ? data.others : null
        }

        saveUpdateCrmAllData(payload)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp?.data?.messages,
                });
                getCrmData();
                cancel();
                setOpenEditCrm({ action: false, data: null });
            })
            .catch((error) => {
                console.log(error)
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error?.response?.data.data,
                });
            })
    };

    const cancel = () => {
        reset({ clinicName: "", website: "", nurseName: "", email: "", linkedin: "", address: "", faceBook: "", tiktok: "", instagram: "", notes: "" })
        setPhone("");
        setStatus("");
        setNextFollowUpData("");
        setInterested("");
        setEmailSentDate("");
        setOpenEditCrm({ action: false, data: null });
    }

    const handleChangeStatus = (event) => {
        setStatus(event.target.value);
    };
    const handleChangeInterested = (event) => {
        setInterested(event.target.value);
    };

    const handleOpenNewTab = () => {
        const email = openEditCrm?.data?.email;
        if (!email) return;

        const url = `/view-receive-mail?email=${encodeURIComponent(email)}`;
        window.open(url, "_blank");
    };
    const handleOpenNewTabForSentMail = () => {
        const email = openEditCrm?.data?.email;
        if (!email) return;

        const url = `/view-send-mail?email=${encodeURIComponent(email)}`;
        window.open(url, "_blank");
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="lg" open={openEditCrm.action} hideBackdrop >
                <material.DialogTitle className="fw-bold"> {openEditCrm.data ? "Edit Platform CRM Data" : "Add Platform CRM Data"} </material.DialogTitle>
                <material.DialogContent>
                    <div style={{
                        width: "100%", border: "1px solid #ccc", borderRadius: "8px", padding: "16px", marginTop: "2px",
                        boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                    }}
                    >
                        <div className="row">
                            <div className="col-lg-4 mt-2">
                                <material.TextField
                                    multiline
                                    variant="standard"
                                    label="Clinic Name"
                                    // InputProps={openEditCrm.data ? { readOnly: true } : null}
                                    type="text"
                                    fullWidth
                                    {...register("clinicName",)}
                                />
                            </div>
                            <div className="col-lg-4 mt-2">
                                <material.TextField
                                    // required
                                    multiline
                                    label="CRM Name"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    // InputProps={openEditCrm.data ? { readOnly: true } : null}
                                    {...register("crmName")}
                                />
                            </div>
                            <div className="col-lg-4 mt-2">
                                <material.TextField
                                    // required
                                    multiline
                                    variant="standard"
                                    label="Website"
                                    type="text"
                                    // InputProps={openEditCrm.data ? { readOnly: true } : null}
                                    fullWidth
                                    {...register("website",)}
                                />
                            </div>
                            <div className="col-lg-4 mt-2">
                                <material.TextField
                                    // required
                                    multiline
                                    variant="standard"
                                    label="Nurse Name"
                                    // InputProps={openEditCrm.data ? { readOnly: true } : null}
                                    type="text"
                                    fullWidth
                                    {...register("nurseName",)}
                                />
                            </div>
                            <div className="col-lg-4 mt-2">
                                <material.TextField
                                    // required
                                    variant="standard"
                                    label="Email"
                                    type="email"
                                    // InputProps={openEditCrm.data ? { readOnly: true } : null}
                                    fullWidth
                                    {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                                />
                                <p className='form-text text-danger'>{errors.email?.type === "required" && 'This field is required'}</p>
                                {(errors?.email?.type === "pattern") && (
                                    <p className='text-danger'>This is not a valid Email</p>
                                )}
                            </div>
                            <div className="col-lg-4 mt-2">
                                <material.TextField
                                    // required
                                    variant="standard"
                                    label="Linkedin Details"
                                    type="text"
                                    fullWidth
                                    // InputProps={openEditCrm.data ? { readOnly: true } : null}
                                    {...register("linkedin",)}
                                />
                            </div>
                            <div className="col-lg-12 mt-2">
                                <material.TextField
                                    // required
                                    multiline
                                    variant="standard"
                                    label="Address Details"
                                    type="text"
                                    fullWidth
                                    // InputProps={openEditCrm.data ? { readOnly: true } : null}
                                    {...register("address",)}
                                />
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
                                <PhoneInput
                                    required
                                    placeholder="Phone"
                                    defaultCountry="AU"
                                    inputRef={register}
                                    enableSearch={true}
                                    value={phone}
                                    control={control}
                                    international
                                    // rules={{ required: true }}
                                    onChange={(phone) => setPhone(phone)}
                                    error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                // InputProps={openEditCrm.data ? { readOnly: true } : null}
                                />
                            </div>

                            <div className="col-lg-4 mt-2">
                                <material.TextField
                                    // required
                                    variant="standard"
                                    label="FaceBook Details"
                                    type="text"
                                    fullWidth
                                    // InputProps={openEditCrm.data ? { readOnly: true } : null}
                                    {...register("faceBook",)}
                                />
                            </div>
                            <div className="col-lg-4 mt-2">
                                <material.TextField
                                    // required
                                    variant="standard"
                                    label="TikTok Details"
                                    type="text"
                                    fullWidth
                                    // InputProps={{ readOnly: true }}
                                    {...register("tiktok",)}
                                // InputProps={openEditCrm.data ? { readOnly: true } : null}
                                />
                            </div>
                            <div className="col-lg-4 mt-2">
                                <material.TextField
                                    // required
                                    variant="standard"
                                    label="Instagram Details"
                                    type="text"
                                    fullWidth
                                    // InputProps={{ readOnly: true }}
                                    {...register("instagram",)}
                                // InputProps={openEditCrm.data ? { readOnly: true } : null}
                                // readOnly={openEditCrm.data ? true : false}
                                />
                            </div>
                            <div className="col-lg-4 mt-2">
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label"> Status </material.InputLabel>
                                    <material.Select
                                        {...register("status",)}
                                        // required
                                        label="Status"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        fullWidth
                                        value={status}
                                        onChange={handleChangeStatus}
                                    // InputProps={openEditCrm.data ? { readOnly: true } : null}
                                    // readOnly={openEditCrm.data ? true : false}
                                    >
                                        <material.MenuItem value={"Interested"}>Interested</material.MenuItem>
                                        <material.MenuItem value={"Present Demo"}> Present Demo </material.MenuItem>
                                        <material.MenuItem value={"Onboarding"}> Onboarding </material.MenuItem>
                                        <material.MenuItem value={"In Transit"}> In Transit </material.MenuItem>
                                        <material.MenuItem value={"Customer"}> Customer </material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>

                            <div className="col-lg-4 mt-2">
                                <material.FormControl variant="standard" fullWidth>
                                    <material.InputLabel id="demo-simple-select-standard-label"> Interested Product </material.InputLabel>
                                    <material.Select
                                        {...register("interested ",)}
                                        // required
                                        label="Status"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        fullWidth
                                        value={interested}
                                        onChange={handleChangeInterested}
                                    // InputProps={openEditCrm.data ? { readOnly: true } : null}
                                    // readOnly={openEditCrm.data ? true : false}
                                    >
                                        <material.MenuItem value={"Devices then Place Holder for Device Name"}>Devices then Place Holder for Device Name</material.MenuItem>
                                        <material.MenuItem value={"Software and Place Holder for Tier"}> Software and Place Holder for Tier </material.MenuItem>
                                        <material.MenuItem value={"Scripting Services"}> Scripting Services </material.MenuItem>
                                        <material.MenuItem value={"Mentorship Program"}> Mentorship Program </material.MenuItem>
                                        <material.MenuItem value={"Devices Training"}> Devices Training </material.MenuItem>
                                        <material.MenuItem value={"Others"}> Others </material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                            {/* {interested === "Others" ?
                                <div className="col-lg-4 mt-2">
                                    <material.TextField
                                        // required
                                        variant="standard"
                                        label="Others Interested Product"
                                        type="text"
                                        fullWidth
                                        // InputProps={{ readOnly: true }}
                                        {...register("others",)}
                                    />
                                </div> : null} */}

                            <div className="col-lg-4 mt-2">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <material.DatePicker
                                        label="Next Follow Up Data"
                                        // format='DD-MM-YYYY'
                                        format='YYYY-MM-DD'
                                        value={nextFollowUpData}
                                        onChange={(newValue) => {
                                            setNextFollowUpData(newValue);
                                        }}
                                        slotProps={{ textField: { variant: 'standard' } }}
                                        sx={{ width: "100%" }}
                                        required={false}
                                    // InputProps={openEditCrm.data ? { readOnly: true } : null}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="col-lg-4 mt-2">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <material.DatePicker
                                        label="Email Sent Date"
                                        // format='DD-MM-YYYY'
                                        format='YYYY-MM-DD'
                                        value={emailSentDate}
                                        onChange={(newValue) => {
                                            setEmailSentDate(newValue);
                                        }}
                                        slotProps={{ textField: { variant: 'standard' } }}
                                        sx={{ width: "100%" }}
                                        // InputProps={openEditCrm.data ? { readOnly: true } : null}
                                        required={false}
                                    />
                                </LocalizationProvider>
                            </div>

                            <div className="col-lg-4 mt-2">
                                <material.TextField
                                    // required
                                    variant="standard"
                                    label="Notes"
                                    type="text"
                                    fullWidth
                                    // InputProps={openEditCrm.data ? { readOnly: true } : null}
                                    // InputProps={{ readOnly: true }}
                                    {...register("notes",)}
                                />
                            </div>


                            <div className="col-lg-4 mt-3">
                                <Link
                                    to="#"
                                    onClick={handleOpenNewTabForSentMail}
                                    style={{ textDecoration: "none", color: "#1976d2" }}
                                >
                                    View Send Mail
                                </Link>
                                {/* <Link
                                    to="/view-send-mail"
                                    state={{ email: openEditCrm?.data?.email }}
                                    style={{ textDecoration: "none", color: "#1976d2" }}
                                >
                                    View Send Mail
                                </Link> */}
                            </div>
                            <div className="col-lg-4 mt-3">
                                <Link
                                    to="#"
                                    onClick={handleOpenNewTab}
                                    style={{ textDecoration: "none", color: "#1976d2" }}
                                >
                                    View Receive Mail
                                </Link>
                                {/* <Link
                                    to="/view-receive-mail"
                                    state={{ email: openEditCrm?.data?.email }}
                                    style={{ textDecoration: "none", color: "#1976d2" }}
                                >
                                    View Receive Mail
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions>
                    <div className="mt-3"> <span className="float-end py-3"><material.Button size="medium" className="me-2" onClick={cancel} variant="contained" sx={{ textTransform: "none" }} color="error" > Cancel </material.Button>
                        {openEditCrm.data ? (
                            <material.Button size="medium" className="me-2" onClick={handleSubmit(updatePlatformCrmData)} variant="contained" sx={{ textTransform: "none" }} > Update </material.Button>
                        ) : (
                            <material.Button size="medium" className="me-2" onClick={handleSubmit(savePlatformCrmData)} variant="contained" sx={{ textTransform: "none" }} > Save </material.Button>)}
                    </span>
                    </div>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

export default AddPlatFormCrm;