import React, { useEffect, useState, useRef } from 'react';
import { material } from '../../../library/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { addNewPatient, getPatientById, getPatientHistory } from '../../../services/PatientService';
import Snackbar from '../../toastrmessage/Snackbar';
import { dateAndTimeFormat, dateFormat } from '../../../date-and-time-format/DateAndTimeFormat';
import Webcam from 'react-webcam';
import SendBddForm from '../../dialog/SendBddForm';
import dayjs from 'dayjs';
import Navbar from '../../navbar/Navbar';
import AddPatientNote from '../../dialog/AddPatientNote';
import AssessmentAskDialog from '../../dialog/AssessmentAskDialog';
import { getClinicDetailsByClinicId } from '../../../services/TreatmentPlanService';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

var patientNoteData = [];
var uploadImage = [];
var allImages = [];
const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
    facingMode: FACING_MODE_ENVIRONMENT
};

var patientDetails;

function AddPatient(props) {

    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [date, setDate] = useState(dayjs(new Date()));
    const [selectGender, setSelectGender] = useState('');
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [facingMode, setFacingMode] = React.useState(FACING_MODE_ENVIRONMENT);
    const [isWebcamActive, setIsWebcamActive] = useState(true);
    const [openSendBddForm, setOpenSendBddForm] = useState({ "action": false, "data": {} });
    const [image, setImage] = useState([]);
    const [close, setClose] = useState(true);
    const [stream, setStream] = useState(null);
    const videoRef = useRef(null);
    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const navigate = useNavigate();
    const location = useLocation();
    const [openPatientNote, setOpenPatientNote] = useState({ action: false, note: null });
    const [openAssessmentAskDialog, setOpenAssessmentAskDialog] = useState(false);
    const [getPatientData, setGetPatientData] = useState({});
    const [paymentOption, setPaymentOption] = useState([]);
    const [selectPaymentOption, setSelectPaymentOption] = useState("");
    const [phone, setPhone] = useState("");

    const patientData = location.state ? location.state.patientData : "";
    const { register, handleSubmit, reset, control, formState: { errors, isValid } } = useForm({
        mode: "onTouched"
    });
    const [isLoading, setIsLoading] = useState(true);

    if (patientData) {
        localStorage.setItem("menuName", "Edit Patient Details")
    } else {
        localStorage.setItem("menuName", "Add Patient")
    };

    useEffect(() => {
        getClinicByClinicId();
        if (patientData) {
            getPatientDetails()
        } else {
            setIsLoading(false)
        }
    }, []);

    const getPatientDetails = (patientId) => {
        setOpenSnackBar({
            "action": true,
            "type": "info",
            "message": "Please wait....",
        })
        getPatientById(patientData.patientId)
            .then((resp) => {
                setSelectGender(resp.data.data ? resp.data.data.gender : selectGender);
                setDateOfBirth(dayjs(resp.data.data.dateOfBirth));
                setDate(dayjs(resp.data.data ? resp.data.data.dateOfEntry : new Date()));
                setPhone(resp.data.data?.phoneNumber)
                reset(resp.data.data);
                setSelectPaymentOption(resp.data.data.patientPreferredPaymentChoice);
                patientDetails = resp.data.data;
                setIsLoading(false)
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const getClinicByClinicId = () => {
        getClinicDetailsByClinicId(clinicDetails.clinicId)
            .then((resp) => {
                let clinicData = resp.data.data;
                let data = clinicData.paymentOptionsForPatient.split(",")
                setPaymentOption(data)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const goBack = () => {
        navigate("/patient-list");
    };

    const changeGender = (event) => {
        setSelectGender(event.target.value)
    };

    const addPhoto = () => {
        setClose(false)
        openCamera();
        setImage("")
    };

    async function openCamera() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            setStream(stream);
            videoRef.current.srcObject = stream;
        } catch (error) {
            // console.error(error);
        }
        setIsWebcamActive(true)
    };

    function closeCamera() {
        if (stream) {
            stream.getTracks().forEach((track) => track.stop());
            setStream(null);
        }
        setIsWebcamActive(false)
    };

    function capturePhoto() {
        const imageSrc = videoRef.current.getScreenshot();
        const timestamp = new Date().toLocaleString();
        const imageWithTimestamp = addTimestampToImage(imageSrc, timestamp);
        // console.log(imageWithTimestamp)
        uploadPhoto(imageSrc);
        closeCamera();
    };

    const addTimestampToImage = (imageSrc, timestamp) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 640;
        canvas.height = 480
        const image = new Image();
        image.src = imageSrc;
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        ctx.font = '20px Arial';
        ctx.fillStyle = 'white';
        ctx.fillText(timestamp, 10, 30);
        const imageWithTimestamp = canvas.toDataURL('image/jpeg');
        return imageWithTimestamp;
    };

    async function uploadPhoto(photo) {
        setImage(photo)
        allImages.push({ "image": photo });
        uploadImage.push(photo);
    };

    const switchCamera = React.useCallback(() => {
        setFacingMode(
            prevState =>
                prevState === FACING_MODE_USER
                    ? FACING_MODE_ENVIRONMENT
                    : FACING_MODE_USER
        );
    }, []);

    function toggleCamera() {
        if (stream) {
            closeCamera();
        } else {
            openCamera();
        }
    };

    const addPatient = async (fromData) => {
        let obj = {
            "clinicId": clinicDetails.clinicId,
            "orgId": userDetails.orgId,
            "title": "",
            "firstName": fromData.firstName,
            "lastName": fromData.lastName,
            "dateOfBirth": dateFormat(dateOfBirth.$d),
            "gender": selectGender,
            "medicareNumber": fromData.medicareNumber,
            "medicareNumberReference": fromData.medicareNumberReference,
            "medicareExpiry": fromData.medicareExpiry,
            "address": fromData.address,
            "email": fromData.email,
            "phoneNumber": phone,
            "dateOfEntry": dateAndTimeFormat(date.$d),
            "history": patientNoteData,
            "patientImages": uploadImage,
            patientPreferredPaymentChoice: fromData.patientPreferredPaymentChoice
        }
        await addNewPatient(obj)
            .then((resp) => {
                localStorage.setItem("patientId", resp.data.data.patientId);
                setGetPatientData(resp.data.data);
                reset();
                uploadImage = [];
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                setOpenPatientNote({ action: true, note: null })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const updatePatientData = async (fromData) => {
        let obj = {
            "clinicId": clinicDetails.clinicId,
            "orgId": userDetails.orgId,
            "title": "",
            patientId: patientData.patientId,
            "firstName": fromData.firstName,
            "lastName": fromData.lastName,
            "dateOfBirth": dateFormat(dateOfBirth.$d),
            "gender": selectGender,
            "medicareNumber": fromData.medicareNumber,
            "medicareNumberReference": fromData.medicareNumberReference,
            "medicareExpiry": fromData.medicareExpiry,
            "address": fromData.address,
            "email": fromData.email,
            "phoneNumber": phone,
            "dateOfEntry": dateAndTimeFormat(date.$d),
            "history": patientNoteData,
            "patientImages": fromData?.patientImages?.length ? fromData.patientImages.concat(uploadImage) : uploadImage,
            patientPreferredPaymentChoice: fromData.patientPreferredPaymentChoice
        }
        await addNewPatient(obj)
            .then((resp) => {
                localStorage.setItem("patientId", resp.data.data.patientId);
                setGetPatientData(resp.data.data);
                reset()
                uploadImage = [];
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                getPatientHistory(patientData.patientId)
                    .then((res) => {
                        let filterData = res.data.data.filter((ele) => ele.noteDefinition === "DefaultNotes");
                        if (!filterData.length) {
                            setOpenPatientNote({ action: true, note: null, patientData: resp.data })
                        } else {
                            setOpenAssessmentAskDialog({ action: true, patient: null })
                        }
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            setOpenSnackBar({
                                "action": true,
                                "type": "error",
                                "message": error.response.data.data.info,
                            })
                        } else {
                            setOpenSnackBar({
                                "action": true,
                                "type": "error",
                                "message": error.response.data.messages,
                            })
                        }
                    })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const handleChange = (event) => {
        setSelectPaymentOption(event.target.value)
    };

    const openTreatmentPlan = () => {
        navigate("/patient-list/edit-patient/treatment-plan", { state: { patientData } })
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div hidden={isLoading}>
                <div className='row'>
                    <div className='col-6'>
                    </div>
                    <div className='col-6'>
                        <span className="float-end">
                            <material.Button variant="contained" sx={{ textTransform: "none" }} className='me-2' onClick={addPhoto} startIcon={<material.AddAPhotoIcon />}>Add Photo</material.Button>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={goBack} startIcon={<material.ReplyIcon />}>Back</material.Button>
                        </span>
                    </div>
                </div>
                <material.Paper className='p-4 pb-5 mt-2' elevation={3}>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <material.DatePicker
                                    label="Date Of Entry"
                                    format='DD-MM-YYYY'
                                    value={date}
                                    onChange={(newValue) => {
                                        setDate(newValue);
                                    }}
                                    slotProps={{ textField: { variant: 'standard' } }}
                                    sx={{ width: "100%" }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                error={errors.firstName?.type === "required"}
                                required
                                label="First Name"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                {...register("firstName", { required: true, minLength: 3 })}
                            />
                            <p className='form-text text-danger'>{errors.firstName?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                error={errors.lastName?.type === "required"}
                                required
                                label="Last Name"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                {...register("lastName", { required: true, minLength: 3 })}
                            />
                            <p className='form-text text-danger'>{errors.lastName?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <material.DatePicker
                                    required
                                    format='DD-MM-YYYY'
                                    label="Date of Birth"
                                    value={dateOfBirth}
                                    onChange={(newValue) => {
                                        setDateOfBirth(newValue)
                                    }}
                                    sx={{ width: "100%" }}
                                    slotProps={{ textField: { variant: 'standard' } }}
                                />
                            </LocalizationProvider> */}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="dateOfBirth"
                                    control={control}
                                    defaultValue={null}
                                    render={({ field }) => (
                                        <material.DatePicker
                                            label="Date of Birth"
                                            format='DD-MM-YYYY'
                                            value={dateOfBirth}
                                            onChange={(newValue) => {
                                                setDateOfBirth(newValue);
                                                field.onChange(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <material.TextField
                                                    {...params}
                                                    variant="standard"
                                                    color="success"
                                                    {...field}
                                                />
                                            )}
                                            slotProps={{ textField: { variant: 'standard' } }}
                                            sx={{ width: "100%" }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.FormControl fullWidth variant="standard">
                                <material.InputLabel id="demo-simple-select-label">Gender</material.InputLabel>
                                <material.Select
                                    {...register("gender", { required: true })}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectGender}
                                    onChange={changeGender}
                                >
                                    <material.MenuItem value={"MALE"}>Male</material.MenuItem>
                                    <material.MenuItem value={"FEMALE"}>Female</material.MenuItem>
                                    <material.MenuItem value={"OTHER"}>Other</material.MenuItem>
                                </material.Select>
                            </material.FormControl>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                error={errors.medicareNumber?.type === "required"}
                                label="Medicare Number"
                                variant="standard"
                                type="number"
                                size="small"
                                fullWidth
                                {...register("medicareNumber", { minLength: 10, maxLength: 10 })}
                            />
                            {(errors?.medicareNumber?.type === "minLength" || errors?.medicareNumber?.type === "maxLength") && (
                                <p className='text-danger'>This field only contain 10 digits</p>
                            )}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                error={errors.medicareNumberReference?.type === "required"}
                                label="Medicare Reference Number"
                                variant="standard"
                                type="number"
                                size="small"
                                fullWidth
                                {...register("medicareNumberReference", { minLength: 1, maxLength: 1 })}
                            />
                            {(errors?.medicareNumberReference?.type === "minLength" || errors?.medicareNumberReference?.type === "maxLength") && (
                                <p className='text-danger'>This field only contain 1 digits</p>
                            )}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            {/* <material.TextField
                            error={errors.medicareExpiry?.type === "required"}
                            required
                            label="Medicare Expiry"
                            variant="standard"
                            type="number"
                            size="small"
                            fullWidth
                            InputProps={{ readOnly: patientData ? true : false }}
                            inputProps={{ style: { textTransform: 'capitalize' } }
                            {...register("medicareExpiry", { required: true })}
                        />
                        <p className='form-text text-danger'>{errors.medicareExpiry?.type === "required" && 'This field is required'}</p> */}
                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <material.DatePicker
                                    views={['year', 'month']}
                                    label="Medicare Expiry"
                                    value={medicareExpiryDate}
                                    onChange={(newValue) => {
                                        setMedicareExpiryDate(newValue)
                                    }}
                                    slotProps={{ textField: { variant: 'standard', color: "success" } }}
                                    sx={{ width: "100%" }}
                                />
                            </LocalizationProvider> */}
                            <material.TextField
                                error={errors.medicareNumberReference?.type === "required"}
                                label="Medicare Expiry"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                {...register("medicareExpiry")}
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                // error={errors.email?.type === "required"}
                                required
                                label="Email ID"
                                variant="standard"
                                type="email"
                                size="small"
                                fullWidth
                                {...register("email", { pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                            />
                            <p className='form-text text-danger'>{errors.email?.type === "required" && 'This field is required'}</p>
                            {(errors?.email?.type === "pattern") && (
                                <p className='text-danger'>This is not a valid Email</p>
                            )}
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <PhoneInput
                                required
                                placeholder="Phone"
                                defaultCountry="AU"
                                inputRef={register}
                                enableSearch={true}
                                value={phone}
                                control={control}
                                international
                                rules={{ required: true }}
                                onChange={(phone) => setPhone(phone)}
                                error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.FormControl fullWidth variant="standard">
                                <material.InputLabel id="demo-simple-select-label">Patient Preferred Payment Options</material.InputLabel>
                                <material.Select
                                    {...register("patientPreferredPaymentChoice", { required: true })}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectPaymentOption}
                                    onChange={handleChange}
                                >
                                    {paymentOption.map((item, k) => (
                                        <material.MenuItem value={item} key={k}>{item}</material.MenuItem>
                                    ))}
                                </material.Select>
                            </material.FormControl>
                        </div>
                        <div className="col-lg-9 col-md-6 col-sm-12">
                            <material.TextField
                                error={errors.address?.type === "required"}
                                required
                                label="Address"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                {...register("address", { required: true })}
                            />
                        </div>
                        <div className='col-6 py-2' hidden={close}>
                            {image ? (
                                <>
                                    <span className='me-5'>
                                        {allImages.length ? allImages.map((ele, i) => (
                                            <img
                                                key={i}
                                                src={ele.image}
                                                height={100} width="25%"
                                                style={{ cursor: "pointer", margin: "5px" }}
                                            />
                                        )) : ""}
                                    </span>
                                </>
                            ) : (
                                <span>
                                    {isWebcamActive ? (
                                        <Webcam
                                            height={300}
                                            width="100%"
                                            screenshotFormat="image/jpeg"
                                            ref={videoRef}
                                            videoConstraints={{
                                                ...videoConstraints,
                                                facingMode
                                            }}
                                        />
                                    ) : (
                                        <div style={{ height: "300px", width: "100%" }}></div>
                                    )}
                                    <span className='d-flex justify-content-center mt-2'>
                                        <material.Button sx={{ textTransform: "none", mr: 1 }} size='small' variant="contained" onClick={switchCamera} startIcon={<material.CameraswitchIcon />} >Switch camera</material.Button>
                                        <material.Button sx={{ textTransform: "none" }} size='small' variant="contained" onClick={capturePhoto} startIcon={<material.CameraAltIcon />} >Take photo</material.Button>
                                        <material.Button sx={{ textTransform: "none" }} size='small' variant="contained" onClick={toggleCamera} className='ms-2'>
                                            {stream ? 'Close camera' : 'Open camera'}
                                        </material.Button>
                                    </span>
                                </span>
                            )}
                        </div>
                    </div>
                    <div className='mt-5'>
                        <div className='col-lg-12 col-md-12 col-sm-12 mt-5'>
                            <span className='float-end'>
                                {patientData ? (
                                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={handleSubmit(updatePatientData)}>Update</material.Button>
                                ) : (
                                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={handleSubmit(addPatient)} disabled={!isValid}>Save</material.Button>
                                )}
                            </span>
                        </div>
                    </div>
                </material.Paper>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <SendBddForm
                openSendBddForm={openSendBddForm}
                setOpenSendBddForm={setOpenSendBddForm}
            />
            <AddPatientNote
                openPatientNote={openPatientNote}
                setOpenPatientNote={setOpenPatientNote}
                patientData={patientDetails}
                callFrom="Add Patient Component"
            />
            <AssessmentAskDialog
                openAssessmentAskDialog={openAssessmentAskDialog}
                setOpenAssessmentAskDialog={setOpenAssessmentAskDialog}
                openPatientNote={openPatientNote}
                setOpenPatientNote={setOpenPatientNote}
                patientData={getPatientData}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(AddPatient);