import React, { useState } from 'react';
import "../ConsentForm.css";
import SignaturePad from 'react-signature-canvas';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { material } from '../../../../library/material';
import { saveConsentFormOpen, saveConsentForm } from '../../../../services/ConsentFormService';
import Snackbar from '../../../toastrmessage/Snackbar';
import { socket } from '../../../../socket/Socket';

var sigPad = {};

function Threads(props) {

    const theme = useTheme();
    const { blankTreatmentData, openConsentForm, setGetConsentFormThreadsData, consentData, callForm, clinicDetails } = props;
    const navigate = useNavigate();
    const [refNo, setRefNo] = useState('');
    const [saveConsentData, setSaveConsentData] = useState(false);
    const [questions, setQuestions] = useState({
        aw_q1: "",
        aw_q2: "",
        aw_q3: "",
        aw_q4: "",
        aw_q5: "",
        aw_q6: "",
        aw_q7: "",
        aw_q8: "",
        aw_q9: "",
        aw_q10: "",
        aw_q11: "",
    });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    const getPatientSignature = () => {
        // getSignature(sigPad.getTrimmedCanvas().toDataURL('image/png'))
    };

    const selectQuestions = (qNumber, value) => {
        setQuestions(prevValues => ({
            ...prevValues,
            [qNumber]: value,
        }))
    };
    const isValid = Object.values(questions).some(value => value === "");

    const saveConsent = async (data) => {
        if (openConsentForm.action === false) {
            let obj = {
                patientId: blankTreatmentData.patientId,
                treatmentPlanRequestId: blankTreatmentData.treatmentPlanRequestId,
                concent_type: "Threads",
                concent_data: { ...questions, patientSignature: sigPad.getTrimmedCanvas().toDataURL('image/png') }
            }
            await saveConsentForm(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data.messages,
                    })
                    if (callForm === "primary-user") {
                        setTimeout(() => {
                            setGetConsentFormThreadsData("save")
                        }, 2000)
                    } else {
                        socket.emit("new-concent-added", "Threads Consent Form Submit successfully");
                        // navigate('/kiosk-menu')
                        setSaveConsentData(true);
                    }
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                })
        } else {
            let obj = {
                refNo: refNo,
                concent_type: "Threads",
                concent_data: { ...questions, patientSignature: sigPad.getTrimmedCanvas().toDataURL('image/png') }
            }
            await saveConsentFormOpen(obj)
                .then((resp) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "success",
                        "message": resp.data,
                    })
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data,
                    })
                })
        };
    };

    const clear = () => {
        sigPad.clear()
    };

    return (
        <div className={callForm === "secondary-user" ? "consent-form-body" : 'content'} id='form-section' hidden={saveConsentData}>
            <material.Paper elevation={3} sx={{
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
                [theme.breakpoints.up('sm')]: {
                    width: '100%',
                },
                [theme.breakpoints.up('md')]: {
                    width: '70%',
                },
                [theme.breakpoints.up('lg')]: {
                    width: '70%',
                },
                mt: 2, m: 2, p: 3, pb: 10
            }}>
                <div className='mt-2 fw-bold'>
                    <span className='d-flex justify-content-center'>Consent Form for Threads Treatments</span>
                </div>
                <div className='mt-5 ms-5'>
                    <div hidden={openConsentForm.action === false || consentData} className='mb-3'>
                        <material.TextField
                            type='text'
                            variant="standard"
                            sx={{ width: "30%", ml: 1, mr: 1, mt: -1 }}
                            name='refNo'
                            label="Reference No"
                            onChange={e => setRefNo(e.target.value)}
                        />
                    </div>
                    <h5>1. Procedure Information:</h5>
                    <div className='ms-5'>
                        <span>
                            I acknowledge that I have been informed about the “Threads” procedure, its purpose, and potential benefits. “Threads” are used to lift and tighten sagging skin tissues by inducing collagen production and supporting the skin structure.
                        </span>
                        {consentData ? (
                            <span className='ms-2'>{consentData.aw_q1}</span>
                        ) : (
                            <span>
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q1", event.target.value)}
                                        value={questions.aw_q1}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </span>
                        )}
                    </div>
                    <h5>2. Risk :</h5>
                    <div className='ms-5'>
                        <span>
                            I understand that there are potential risks and side effects associated with the “Thread” procedure, which include but are not limited to:
                        </span>
                        {consentData ? (
                            <span className='ms-2'>{consentData.aw_q2}</span>
                        ) : (
                            <span>
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q2", event.target.value)}
                                        value={questions.aw_q2}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            </span>
                        )}
                    </div>
                    <ul className='ms-3'>
                        <li>Temporary redness, swelling, or discomfort at the insertion sites.</li>
                        <li>Bruising or minor bleeding at the insertion sites.</li>
                        <li>Infection.</li>
                        <li>Thread extrusion or migration.</li>
                        <li>Asymmetry or irregularities in the skin.</li>
                        <li>Numbness or tingling in the treated area.</li>
                        <li>Scarring.</li>
                        <li>Incomplete or unsatisfactory results.</li>
                    </ul>
                    <h5>3. Contraindications :</h5>
                    <div className='ms-5'>
                        <span>
                            Please indicate YES or NO for each:
                        </span>
                        <ul className='ms-3'>
                            <li>Are you pregnant or breastfeeding?
                                {consentData ? (
                                    <span className='ms-2'>{consentData.aw_q3}</span>
                                ) : (
                                    <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                        <material.RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={event => selectQuestions("aw_q3", event.target.value)}
                                            value={questions.aw_q3}
                                        >
                                            <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                            <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                        </material.RadioGroup>
                                    </material.FormControl>
                                )}
                            </li>
                            <li>Do you have any known allergies to materials used in  “Threads”?
                                {consentData ? (
                                    <span className='ms-2'>{consentData.aw_q4}</span>
                                ) : (
                                    <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                        <material.RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={event => selectQuestions("aw_q4", event.target.value)}
                                            value={questions.aw_q4}
                                        >
                                            <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                            <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                        </material.RadioGroup>
                                    </material.FormControl>
                                )}
                            </li>
                            <li>Are you currently taking any medications that affect blood clotting (e.g., blood thinners)?
                                {consentData ? (
                                    <span className='ms-2'>{consentData.aw_q5}</span>
                                ) : (
                                    <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                        <material.RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={event => selectQuestions("aw_q5", event.target.value)}
                                            value={questions.aw_q5}
                                        >
                                            <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                            <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                        </material.RadioGroup>
                                    </material.FormControl>
                                )}
                            </li>
                            <li>Do you have a history of keloids or hypertrophic scarring?
                                {consentData ? (
                                    <span className='ms-2'>{consentData.aw_q6}</span>
                                ) : (
                                    <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                        <material.RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={event => selectQuestions("aw_q6", event.target.value)}
                                            value={questions.aw_q6}
                                        >
                                            <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                            <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                        </material.RadioGroup>
                                    </material.FormControl>
                                )}
                            </li>
                            <li>Do you have any active skin infections or inflammatory conditions in the treatment area?
                                {consentData ? (
                                    <span className='ms-2'>{consentData.aw_q7}</span>
                                ) : (
                                    <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                        <material.RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={event => selectQuestions("aw_q7", event.target.value)}
                                            value={questions.aw_q7}
                                        >
                                            <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                            <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                        </material.RadioGroup>
                                    </material.FormControl>
                                )}
                            </li>
                            <li>Have you recently undergone any facial treatments or surgeries?
                                {consentData ? (
                                    <span className='ms-2'>{consentData.aw_q8}</span>
                                ) : (
                                    <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                        <material.RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={event => selectQuestions("aw_q8", event.target.value)}
                                            value={questions.aw_q8}
                                        >
                                            <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                            <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                        </material.RadioGroup>
                                    </material.FormControl>
                                )}
                            </li>
                            <li>Do you have any autoimmune diseases?
                                {consentData ? (
                                    <span className='ms-2'>{consentData.aw_q9}</span>
                                ) : (
                                    <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                        <material.RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={event => selectQuestions("aw_q9", event.target.value)}
                                            value={questions.aw_q9}
                                        >
                                            <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                            <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                        </material.RadioGroup>
                                    </material.FormControl>
                                )}
                            </li>
                        </ul>
                    </div>
                    <h5>4. Post-Procedure Care:</h5>
                    <div className='ms-5'>
                        <span>
                            I confirm that I have taken the following pre-procedure care:
                            {consentData ? (
                                <span className='ms-2'>{consentData.aw_q10}</span>
                            ) : (
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q10", event.target.value)}
                                        value={questions.aw_q10}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            )}
                        </span>
                        <ul className='ms-3'>
                            <li>Avoid alcohol and smoking for at least 24 hours before the procedure.</li>
                            <li>Inform the practitioner of all medications and supplements currently in use.</li>
                            <li>Avoid taking anti-inflammatory drugs (e.g., ibuprofen, aspirin) for at least 3 days before the procedure, unless otherwise directed by a healthcare provider.</li>
                            <li>Follow any specific instructions provided by the clinic.</li>
                        </ul>
                    </div>
                    <h5>5. Post-Procedure Care:</h5>
                    <div className='ms-5'>
                        <span>
                            I understand that I need to follow the provided aftercare advice:
                            {consentData ? (
                                <span className='ms-2'>{consentData.aw_q11}</span>
                            ) : (
                                <material.FormControl sx={{ marginTop: -1, ml: 2 }}>
                                    <material.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={event => selectQuestions("aw_q11", event.target.value)}
                                        value={questions.aw_q11}
                                    >
                                        <material.FormControlLabel value="YES" control={<material.Radio color="secondary" />} label="YES" />
                                        <material.FormControlLabel value="NO" control={<material.Radio color="secondary" />} label="NO" />
                                    </material.RadioGroup>
                                </material.FormControl>
                            )}
                        </span>
                        <ul className='ms-3'>
                            <li>Keep the treatment area clean and dry.</li>
                            <li>Avoid strenuous activities, heavy lifting, and facial massages for at least 1 week after the procedure.</li>
                            <li>Sleep on your back with your head elevated for a few days post-procedure to minimize swelling.</li>
                            <li>Apply ice packs to the treated area to reduce swelling if recommended by the practitioner.</li>
                            <li>Monitor the area for signs of infection or unusual reactions and contact the clinic if any occur.</li>
                            <li>Follow any additional instructions provided by the practitioner.</li>
                        </ul>
                    </div>
                    <h5>6. Acknowledgement and Consent:</h5>
                    <div className='ms-5'>
                        <span>
                            By signing below, I acknowledge that I understand the risks, benefits, and instructions associated with the “Thread” procedure. I have been given the opportunity to ask questions, and all of my questions have been answered to my satisfaction. I hereby give my consent to undergo the  thread procedure at <span style={{ fontWeight: "bold" }}>{clinicDetails.clinicName}</span>.
                        </span>
                    </div>
                    <div className='mt-5 d-flex'>
                        <span>Patient Signature</span>
                        {consentData ? (
                            <span className='ms-3'>
                                <img
                                    src={consentData.patientSignature}
                                />
                            </span>
                        ) : (
                            <>
                                <span className='sigBox ms-3'>
                                    <SignaturePad penColor='black'
                                        canvasProps={{ width: 400, height: 100, className: 'sigPad' }}
                                        ref={(ref) => { sigPad = ref }}
                                        onEnd={getPatientSignature}
                                    />
                                </span>
                                <span className='ms-2'>
                                    <material.Button size='small' sx={{ textTransform: "none" }} onClick={clear}>Clear</material.Button>
                                </span>
                            </>
                        )}
                    </div>
                    <span className='float-end mt-3 pe-3'>
                        <material.Button hidden={consentData} variant="contained" sx={{ textTransform: "none" }} onClick={saveConsent} disabled={isValid}>Save Consent Form</material.Button>
                    </span>
                </div>
                <Snackbar
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                />
            </material.Paper>
        </div>
    );
}

export default Threads;