import React, { useEffect, useState } from 'react';
import { material } from '../../library/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment/moment';
import { dateFormat, localDateTimeFormat } from '../../date-and-time-format/DateAndTimeFormat';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateInjector, updateUser } from '../../services/UserManagementService';
import dayjs from 'dayjs';
import Snackbar from '../toastrmessage/Snackbar';
import { connect } from 'react-redux';
import Navbar from '../navbar/Navbar';
import { getUserRolePermission } from '../../services/manage-roles-service/ManageRolesService';

const injectorPermission = ["INJECTOR"];

function EditUserMangement(props) {

    const { userLoginData, clinicData } = props;
    const userDetails = userLoginData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [dateOfBirth, setDateOfBirth] = useState(dayjs(new Date()));
    const [date, setDate] = useState(dayjs(new Date()));
    const [selectGender, setSelectGender] = useState('');
    const [isDoctor, setIsDoctor] = React.useState("");
    const [selectRole, setSelectRole] = useState("");
    const [roleId, setRoleId] = useState(null);
    const [userRoles, setUserRoles] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const location = useLocation();
    const userData = location.state ? location.state.userData : "";
    const payloadData = location.state ? location.state.payload : "";
    localStorage.setItem("menuName", "Edit User Management");
    const { register, handleSubmit, reset, setValue, control, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
        defaultValues: userData
    });
    const navigate = useNavigate();

    useEffect(() => {
        getUserRolePermissionData();
        setDateOfBirth(userData.dateOfBirth ? dayjs(userData.dateOfBirth) : dayjs(new Date()));
        setDate(userData.createdAt ? dayjs(userData.createdAt) : dayjs(new Date()));
        setSelectGender(userData.gender ? userData.gender : selectGender);
        setIsDoctor(userData.isDoctor ? userData.isDoctor ? "Yes" : "No" : isDoctor);
        setSelectRole(userData.role.roleName);
        setRoleId(userData.role.id);
    }, []);

    const getUserRolePermissionData = () => {
        const payload = {
            orgId: payloadData.callFrom === "Clinic Level" ? payloadData.orgId : userDetails.orgId,
            clinicId: payloadData.callFrom === "Clinic Level" ? payloadData.clinicId : clinicDetails.clinicId
        }
        getUserRolePermission(payload)
            .then((resp) => {
                setUserRoles(resp.data.data);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const goBack = () => {
        navigate("/user_management")
    };

    const changeGender = (event) => {
        setSelectGender(event.target.value)
    };

    const updateUserDetails = async (formData) => {
        let obj = {
            userId: userData.userId,
            title: formData.title,
            firstName: formData.firstName,
            lastName: formData.lastName,
            dob: dateFormat(dateOfBirth.$d),
            gender: formData.gender,
            AHPRA_Reg_ID: formData.AHPRA_Reg_ID,
            address: formData.address,
            email: formData.email,
            phone: formData.phone,
            timeStamp: moment(date.$d).format("YYYY-MM-DDTHH:mm:ss"),
            isDoctor: isDoctor === "Yes" ? true : false,
            prescriberGovtId: formData.prescriberGovtId,
            roleId: roleId,
            orgId: formData.orgId,
            clinicId: formData.clinicId,
        }
        await updateUser(obj)
            .then((res) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": res.data.messages
                })
                navigate("/user_management")
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const handleChangeRoles = (event) => {
        setSelectRole(event.target.value);
        const getRoleId = userRoles.filter((item) => item.roleName === event.target.value).map((item) => item.id)[0];
        setRoleId(getRoleId);
    };

    return (
        <div className='container-fluid' style={{ marginTop: "6%" }}>
            <Navbar />
            <div>
                <div className='row'>
                    <div className='col-6'>
                    </div>
                    <div className='col-6'>
                        <span className="float-end">
                            <material.Button variant="contained" sx={{ textTransform: "none" }} className='ms-2' onClick={goBack} startIcon={<material.ReplyIcon />}>Back</material.Button>
                        </span>
                    </div>
                </div>
                <material.Paper className='p-4 mt-2' elevation={1}>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                error={errors.firstName?.type === "required"}
                                required
                                label="First Name"
                                id="standard-error"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                InputProps={{ readOnly: userData.readOnly }}
                                inputProps={{ style: { textTransform: 'capitalize' } }}
                                {...register("firstName", { required: true, minLength: 3 })}
                            />
                            <p className='form-text text-danger'>{errors.firstName?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                error={errors.lastName?.type === "required"}
                                required
                                label="Last Name"
                                id="standard-error"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                InputProps={{ readOnly: userData.readOnly }}
                                inputProps={{ style: { textTransform: 'capitalize' } }}
                                {...register("lastName", { required: true, minLength: 3 })}
                            />
                            <p className='form-text text-danger'>{errors.lastName?.type === "required" && 'This field is required'}</p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <material.DesktopDatePicker
                                    format='DD-MM-YYYY'
                                    label="Date of Birth"
                                    value={dateOfBirth}
                                    onChange={(newValue) => {
                                        setDateOfBirth(newValue)
                                    }}
                                    sx={{ width: "100%" }}
                                    slotProps={{ textField: { variant: 'standard' } }}
                                />
                            </LocalizationProvider>
                        </div>
                        {userData?.gender ? (
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.FormControl fullWidth variant='standard'>
                                    <material.InputLabel id="demo-simple-select-label">Gender</material.InputLabel>
                                    <material.Select
                                        {...register("gender", { required: true })}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectGender}
                                        onChange={changeGender}
                                    >
                                        <material.MenuItem value="MALE">MALE</material.MenuItem>
                                        <material.MenuItem value="FEMALE">FEMALE</material.MenuItem>
                                        <material.MenuItem value="TRANSGENDER">TRANSGENDER</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        ) : null}
                        {userData.role.permissions.some(item => injectorPermission.includes(item.permissionName)) ? (
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.AHPRA_Reg_ID?.type === "required"}
                                    label="AHPRA ID"
                                    id="standard-error"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    InputProps={{ readOnly: userData.readOnly }}
                                    {...register("AHPRA_Reg_ID")}
                                />
                                <p className='form-text text-danger'>{errors.AHPRA_Reg_ID?.type === "required" && 'This field is required'}</p>
                            </div>
                        ) : null}
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <material.TextField
                                error={errors.email?.type === "required"}
                                required
                                label="Email ID"
                                id="standard-error"
                                variant="standard"
                                type="email"
                                size="small"
                                fullWidth
                                InputProps={{ readOnly: userData.readOnly }}
                                {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                            />
                            <p className='form-text text-danger'>{errors.email?.type === "required" && 'This field is required'}</p>
                            {(errors?.email?.type === "pattern") && (
                                <p className='text-danger'>This is not a valid Email</p>
                            )}
                        </div>
                        {userData?.phone ? (
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.phone?.type === "required"}
                                    required
                                    label="Phone Number"
                                    id="standard-error"
                                    variant="standard"
                                    type="number"
                                    size="small"
                                    fullWidth
                                    InputProps={{ readOnly: userData.readOnly }}
                                    {...register("phone", { required: true, minLength: 9 })}
                                />
                                <p className='form-text text-danger'>{errors.phone?.type === "required" && 'This field is required'}</p>
                                {(errors?.phone?.type === "minLength") && (
                                    <p className='text-danger'>This field minimum contain 9 digits</p>
                                )}
                            </div>
                        ) : null}
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <material.DateTimePicker
                                    label="Date Of Entry"
                                    format='DD-MM-YYYY'
                                    value={date}
                                    onChange={(newValue) => {
                                        setDate(newValue);
                                    }}
                                    slotProps={{ textField: { variant: 'standard' } }}
                                    sx={{ width: "100%" }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="col-lg-3">
                            <material.FormControl fullWidth variant='standard'>
                                <material.InputLabel id="demo-simple-select-label">Role</material.InputLabel>
                                <material.Select
                                    {...register("role", { required: true })}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectRole}
                                    onChange={handleChangeRoles}
                                >
                                    {userRoles.map((item, index) => (
                                        <material.MenuItem value={item.roleName} key={index}>{item.roleName}</material.MenuItem>
                                    ))}
                                </material.Select>
                            </material.FormControl>
                        </div>
                        {isDoctor == "Yes" ? (
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.prescriberGovtId?.type === "required"}
                                    label="Prescriber Id"
                                    id="standard-error"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    required
                                    {...register("prescriberGovtId", { required: true })}
                                />
                                <p className='form-text text-danger'>{errors.prescriberGovtId?.type === "required" && 'This field is required'}</p>
                            </div>
                        ) : null}
                        {userData.role.permissions.some(item => injectorPermission.includes(item.permissionName)) ? (
                            <div className="col-lg-9 col-md-6 col-sm-12">
                                <material.TextField
                                    error={errors.address?.type === "required"}
                                    required
                                    label="Address"
                                    id="standard-error"
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    InputProps={{ readOnly: userData.readOnly }}
                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                    {...register("address", { required: true })}
                                />
                                <p className='form-text text-danger'>{errors.address?.type === "required" && 'This field is required'}</p>
                            </div>
                        ) : null}
                        {userData.role.permissions.some(item => injectorPermission.includes(item.permissionName)) ? (
                            <div className="col-lg-3 col-md-3 col-sm-6">
                                <material.FormControl fullWidth variant='standard'>
                                    <material.InputLabel id="demo-simple-select-label">Doctor</material.InputLabel>
                                    <material.Select
                                        {...register("isDoctor")}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={isDoctor}
                                        onChange={(event) => setIsDoctor(event.target.value)}
                                    >
                                        <material.MenuItem value="Yes">Yes</material.MenuItem>
                                        <material.MenuItem value="No">No</material.MenuItem>
                                    </material.Select>
                                </material.FormControl>
                            </div>
                        ) : null}
                        <div className='col-lg-12 col-md-12 col-sm-12 mt-5'>
                            <span className='float-end' hidden={userData.readOnly}>
                                <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" onClick={handleSubmit(updateUserDetails)}>
                                    Update
                                </material.Button>
                            </span>
                        </div>
                    </div>
                </material.Paper>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userLoginData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(EditUserMangement);