import React, { useState, useEffect } from 'react';
import { material } from '../../library/material';
import { getPatientNote } from '../../services/PatientService';
import { getPatientHistory } from '../../services/PatientService';
import { sendBddForm } from '../../services/BddFormService';
import Snackbar from '../toastrmessage/Snackbar';
import { getBddFormDataByFormId } from '../../services/BddFormService';
import { changeDateFormate } from '../../date-and-time-format/DateAndTimeFormat';
import { getPatientPastProcedureList } from '../../services/PatientService';
import { connect } from 'react-redux';
import AddPatientNote from './AddPatientNote';
import PostSessionNote from './PostSessionNote';
import { StyledTableCell } from '../../shared/TableHeaderStyle';

function ViewPatientProfile(props) {

    const { openPatientProfile, setOpenPatientProfile, userData } = props;
    let userDetails = userData.authReducer.data;
    const [value, setValue] = React.useState("General Notes");
    const [history, setHistory] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        if (value === "Injector Notes") {
            getInjectorNotesByPatientId()
        } else if (value === "Dermal Therapists Notes") {
            getDermalTherapistsNotesByPatientId()
        }
    }, [value])
    const getInjectorNotesByPatientId = async () => {
        let payload = {
            patientId: openPatientProfile.patientData?.patientId,
            typeOfNotes: "INJECTOR_NOTES"
        }
        await getPatientNote(payload)
            .then((resp) => {
                setHistory(resp.data.data)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };
    const getDermalTherapistsNotesByPatientId = async () => {
        let payload = {
            patientId: openPatientProfile.patientData?.patientId,
            typeOfNotes: "DERMAL_THERAPISTS_NOTES"
        }
        await getPatientNote(payload)
            .then((resp) => {
                setHistory(resp.data.data)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const close = () => {
        setOpenPatientProfile({ action: false, patientData: null })
    };

    return (
        <div>
            <material.Dialog fullWidth maxWidth="lg" open={openPatientProfile.action} hideBackdrop >
                <material.DialogTitle className='d-flex justify-content-between'>Patient Profile
                    <span>
                        <material.IconButton color='error' title='Close' onClick={close}><material.CloseIcon /></material.IconButton>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    <div className='mt-3'>
                        <material.Box sx={{ bgcolor: 'background.paper' }}>
                            <material.TabContext
                                value={value}
                            >
                                <material.AppBar position="static" sx={{ backgroundColor: "primary" }}>
                                    <material.TabList
                                        onChange={handleChange}
                                        indicatorColor="secondary"
                                        textColor="inherit"
                                        variant="fullWidth"
                                        aria-label="full width tabs example"
                                    >
                                        {/* <material.Tab sx={{ textTransform: "none" }} label="Patient Details" value="Patient Details" /> */}
                                        <material.Tab sx={{ textTransform: "none" }} label="Patient Assessment" value="General Notes" />
                                        <material.Tab sx={{ textTransform: "none" }} label="Injector Notes" value="Injector Notes" />
                                        <material.Tab sx={{ textTransform: "none" }} label="Dermal Therapists Notes" value="Dermal Therapists Notes" />
                                        <material.Tab sx={{ textTransform: "none" }} label="Patient Past Procedure" value="Patient Past Procedure" />
                                        <material.Tab sx={{ textTransform: "none" }} label="BDD Details" value="BDD Details" />
                                    </material.TabList>
                                </material.AppBar>
                                {/* <material.TabPanel value="Patient Details">
                                    <PatientDetails />
                                </material.TabPanel> */}
                                <material.TabPanel value="General Notes">
                                    <GeneralNotes
                                        patientData={openPatientProfile.patientData}
                                    />
                                </material.TabPanel>
                                <material.TabPanel value="Injector Notes" >
                                    <InjectorNotes
                                        patientData={openPatientProfile.patientData}
                                        history={history}
                                    />
                                </material.TabPanel>
                                <material.TabPanel value="Dermal Therapists Notes">
                                    <DermalTherapistsNotes
                                        patientData={openPatientProfile.patientData}
                                        history={history}
                                    />
                                </material.TabPanel>
                                <material.TabPanel value="Patient Past Procedure">
                                    <PastProcedureList
                                        patientData={openPatientProfile.patientData}
                                    />
                                </material.TabPanel>
                                <material.TabPanel value="BDD Details">
                                    <ViewBddForm
                                        patientData={openPatientProfile.patientData}
                                    />
                                </material.TabPanel>
                            </material.TabContext>
                        </material.Box>
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

const GeneralNotes = (props) => {

    const { patientData } = props;
    const [history, setHistory] = useState([]);
    const [openPatientNote, setOpenPatientNote] = useState({ action: false, note: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getPatientHistoryByPatientId()
    }, []);

    const getPatientHistoryByPatientId = async () => {
        await getPatientHistory(patientData.patientId)
            .then((resp) => {
                setHistory(resp.data.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data.messages,
                })
            })
    };

    const viewDefaultNote = (note) => {
        setOpenPatientNote({ action: true, note: note })
    };

    return (
        <div>
            <div className='mt-5'>
                <div>
                    {history?.length ? history.map((ele, i) => (
                        <span key={i}>
                            <ul className='fw-bold text-align-justify'>
                                {ele.noteDefinition === "DefaultNotes" ? (
                                    <li className='ms-5 fw-normal'>{ele.user} | {changeDateFormate(ele.dateOfEntry)} : <material.Link onClick={() => viewDefaultNote(ele.history)} style={{ cursor: "pointer" }}>View Default Note</material.Link></li>
                                ) : ele.noteDefinition === "CustomNotes" ? (
                                    <li className='ms-5 fw-normal'>{ele.user} | {changeDateFormate(ele.dateOfEntry)} | {Object.keys(ele.history)} : {Object.values(ele.history)}</li>
                                ) : null}
                            </ul>
                        </span>
                    )) : null}
                </div>
                <Snackbar
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                />
                <AddPatientNote
                    openPatientNote={openPatientNote}
                    setOpenPatientNote={setOpenPatientNote}
                    patientData={patientData}
                />
            </div>
        </div>
    )
};

const InjectorNotes = (props) => {

    const { patientData, getInjectorNotesByPatientId, history } = props;


    return (
        <div>
            <div>
                {history.length ? history.map((ele, k) => (
                    <span key={k}>
                        <ul className='fw-bold text-align-justify'>
                            <li className='ms-5 fw-normal'>{ele.name} | {changeDateFormate(ele.dateOfEntry)}: {ele.notes}</li>
                        </ul>
                    </span>
                )) : (
                    <div>
                        <span className='d-flex justify-content-center'>No data found</span>
                    </div>
                )}
            </div>
        </div>
    )
};

const DermalTherapistsNotes = (props) => {

    const { patientData, getDermalTherapistsNotesByPatientId, history } = props;
    const [addPostSessionNots, setAddPostSessionNots] = useState({ action: false, patientData: null, note: null });

    const viewPostSessionNote = (note) => {
        setAddPostSessionNots({ action: true, patientData: null, note: note })
    };

    return (
        <div>
            <div>
                {history.length ? history.map((ele, k) => (
                    <span key={k}>
                        <ul className='fw-bold text-align-justify'>
                            {ele?.notes ? (
                                <li className='ms-5 fw-normal'>{ele.name} | {changeDateFormate(ele.dateOfEntry)} : {ele.notes}</li>
                            ) : (
                                <li className='ms-5 fw-normal'>{ele.name} | {changeDateFormate(ele.dateOfEntry)} | <material.Link onClick={() => viewPostSessionNote(ele)} style={{ cursor: "pointer" }}>View Post Session Note</material.Link></li>
                            )}
                        </ul>
                    </span>
                )) : (
                    <div>
                        <span className='d-flex justify-content-center'>No data found</span>
                    </div>
                )}
            </div>
            <PostSessionNote
                addPostSessionNots={addPostSessionNots}
                setAddPostSessionNots={setAddPostSessionNots}
            />
        </div>
    )
};

const PastProcedureList = (props) => {
    const { patientData } = props;
    const [pastProcedureList, setPastProcedureList] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getPatientsPastProcedureData()
    }, []);

    const getPatientsPastProcedureData = async () => {
        await getPatientPastProcedureList(patientData.patientId)
            .then((resp) => {
                setPastProcedureList(resp.data.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data.messages,
                })
            })
    };

    return (
        <div className='ms-5'>
            <material.Accordion>
                <material.AccordionSummary
                    expandIcon={<material.ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <material.Typography>Skin Treatment</material.Typography>
                </material.AccordionSummary>
                <material.AccordionDetails>
                    <material.TableContainer>
                        <material.Table>
                            <material.TableHead >
                                <material.TableRow>
                                    <StyledTableCell>Treatment</StyledTableCell>
                                    <StyledTableCell>Area</StyledTableCell>
                                    <StyledTableCell>Date</StyledTableCell>
                                </material.TableRow>
                            </material.TableHead>
                            <material.TableBody>
                                {pastProcedureList.length ? pastProcedureList?.map((treatment, i) => (
                                    treatment.skinTreatment?.map((row, k) => (
                                        <material.TableRow
                                            key={k}
                                            sx={{
                                                '&:last-child td, &:last-child th': { border: 0 }
                                            }}
                                        >
                                            <material.TableCell sx={{ pt: 3, pb: 3 }} size='small' component="th" scope="row">{row.treatment}</material.TableCell>
                                            <material.TableCell size='small'>{row.area}</material.TableCell>
                                            <material.TableCell size='small'>{changeDateFormate(row.date)}</material.TableCell>
                                        </material.TableRow>
                                    ))
                                )) : (
                                    <material.TableRow >
                                        <material.TableCell colSpan={6}>
                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                        </material.TableCell>
                                    </material.TableRow>
                                )}
                            </material.TableBody>
                        </material.Table>
                    </material.TableContainer>
                </material.AccordionDetails>
            </material.Accordion>
            <material.Accordion sx={{ mt: 2 }}>
                <material.AccordionSummary
                    expandIcon={<material.ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <material.Typography>Cosmetic Injectable</material.Typography>
                </material.AccordionSummary>
                <material.AccordionDetails>
                    <material.TableContainer>
                        <material.Table>
                            <material.TableHead >
                                <material.TableRow>
                                    <StyledTableCell>Treatment</StyledTableCell>
                                    <StyledTableCell>Area</StyledTableCell>
                                    <StyledTableCell>Date</StyledTableCell>
                                    <StyledTableCell>Product</StyledTableCell>
                                    <StyledTableCell>Quantity</StyledTableCell>
                                </material.TableRow>
                            </material.TableHead>
                            <material.TableBody>
                                {pastProcedureList.length ? pastProcedureList?.map((treatment, i) => (
                                    treatment?.cosmeticInjectable?.map((row, k) => (
                                        <material.TableRow
                                            key={k}
                                            sx={{
                                                '&:last-child td, &:last-child th': { border: 0 }
                                            }}
                                        >
                                            <material.TableCell sx={{ pt: 3, pb: 3 }} size='small' component="th" scope="row">{row.treatment}</material.TableCell>
                                            <material.TableCell size='small'>{row.area}</material.TableCell>
                                            <material.TableCell size='small'>{row.date}</material.TableCell>
                                            <material.TableCell size='small'>{row.product}</material.TableCell>
                                            <material.TableCell size='small'>{row.quantity}</material.TableCell>
                                        </material.TableRow>
                                    ))
                                )) : (
                                    <material.TableRow >
                                        <material.TableCell colSpan={6}>
                                            <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                        </material.TableCell>
                                    </material.TableRow>
                                )}
                            </material.TableBody>
                        </material.Table>
                    </material.TableContainer>
                </material.AccordionDetails>
            </material.Accordion>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
};

const ViewBddForm = (props) => {

    const { patientData } = props;
    const [dbbFormData, setDbbFormData] = useState({});
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });

    useEffect(() => {
        getBddFormData()
    }, []);

    const getBddFormData = async () => {
        if (patientData?.bdd_form_id) {
            await getBddFormDataByFormId(patientData?.bdd_form_id)
                .then((resp) => {
                    setDbbFormData(resp.data)
                })
                .catch((error) => {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                })
        }
    };

    const sendBdd = async () => {
        let obj = {
            "email": patientData.email,
            "patientId": patientData.patientId,
            "clinicId": patientData.clinicId
        }
        await sendBddForm(obj)
            .then((res) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": res.data.messages,
                })
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data.messages,
                })
            })
    };

    return (
        <div>
            <div className='content mt-3 p-4'>
                <material.Paper elevation={7} sx={{ p: 4, width: "75%" }}>
                    <span className='d-flex justify-content-between'>
                        <material.Typography variant="h5">BDD Form</material.Typography>
                        <material.Button hidden={patientData?.bdd_form_id} variant="contained" sx={{ textTransform: "none" }} startIcon={<material.SendIcon />} onClick={sendBdd}>Send BDD</material.Button>
                    </span>
                    {patientData?.bdd_form_id ? (
                        <div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='fw-bold'>
                                                <td className='col-4'>Patient Name</td>
                                                <td className='col-8'>{dbbFormData.patientName}</td>
                                            </tr>
                                            <tr className='fw-bold'>
                                                <td className='col-4'>Patient Email</td>
                                                <td className='col-8'>{dbbFormData.userEmail}</td>
                                            </tr>
                                            <tr className='fw-bold'>
                                                <td className='col-4'>Date of Birth</td>
                                                <td className='col-8'>{dbbFormData.dateOfBirth}</td>
                                            </tr>
                                            <tr className='fw-bold'>
                                                <td className='col-4'>Clinic Name</td>
                                                <td className='col-8'>{dbbFormData.clinicName}</td>
                                            </tr>
                                            <tr className='fw-bold'>
                                                <td className='col-4'>Exp Date</td>
                                                <td className='col-8'>{dbbFormData.exp_date}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <material.Typography>
                                    The below questions will assess underlying psychological conditions such as (BDD) body dysmorphic disorder.
                                    Please speak to your practitioner if you require further information regarding BDD.
                                </material.Typography>
                                <material.Typography sx={{ mt: 2 }}>
                                    If there are indications of significant underlying psychological issues which may make
                                    you an unsuitable candidate for the cosmetic procedure, you may be referred for evaluation to a
                                    psychologist, psychiatrist or general practitioner who works independently of the Healthcare professional who will perform the procedure or the medical practitioner who will prescribe the cosmetic injectable.
                                </material.Typography>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='col-11'> 1- Are you worried about the way you look, and do you think about your appearance a lot and wish you could think about it less</td>
                                                <td className='col-1 fw-bold'>{dbbFormData.q1}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-11'>2- How has it, the way you feel about it, affected your daily life activities, such as school, work, or other activities?</td>
                                                <td className='col-1 fw-bold'>{dbbFormData.q2}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-11'>3- Are there things you avoid because of how you look, such as gatherings, going out etc</td>
                                                <td className='col-1 fw-bold'>{dbbFormData.q3}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-11'>Do you spend more than 2-3 hours a day thinking about how you look</td>
                                                <td className='col-1 fw-bold'>{dbbFormData.q4}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-11'>5- If you could not have a cosmetic treatment today, would this affect your life adversely?</td>
                                                <td className='col-1 fw-bold'>{dbbFormData.q5}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-10'>Assessed By</td>
                                                <td className='col-2 fw-bold'>{dbbFormData.assessed_by}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-10'>Assessment Result</td>
                                                <td className='col-2 fw-bold'>{dbbFormData.assessment_result}</td>
                                            </tr>
                                            <tr>
                                                <td className='col-10'>Assessment Note</td>
                                                <td className='col-2 fw-bold'>{dbbFormData.assessment_note}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <span className='d-flex justify-content-center mt-3 text-danger'>No BDD</span>
                        </div>
                    )}
                </material.Paper>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(ViewPatientProfile);