import { useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Navbar from '../../navbar/Navbar';
import Snackbar from '../../toastrmessage/Snackbar';
import { material } from '../../../library/material';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { changeDateAndTimeFormate } from '../../../date-and-time-format/DateAndTimeFormat';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import { getLockTreatmentPlan, lockTreatmentPlan } from '../../../services/PrescriberService';

function LockTreatmentList(props) {

    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    localStorage.setItem("menuName", "Lock Treatment List");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [lockTreatmentPlanData, setLockTreatmentPlanData] = useState([]);

    useEffect(() => {
        getLockTreatmentPlanList()
    }, []);

    const getLockTreatmentPlanList = () => {
        setIsLoading(true);
        getLockTreatmentPlan()
            .then((resp) => {
                setLockTreatmentPlanData(resp.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const unlockedTreatment = (rowData) => {
        const payload = {
            locked: false,
        }
        lockTreatmentPlan(rowData.treatmentPlanRequestId, payload)
            .then((resp) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "success",
                    "message": resp.data.messages,
                })
                getLockTreatmentPlanList();
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    return (
        <material.Grid spacing={2} className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 10
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 10
                },
            }}
        >
            <Navbar />
            <div className='row'>
                <div className='col-12 mt-3'>
                    <material.Paper sx={{ width: "100%", overflow: 'hidden', p: 3 }}>
                        <material.TableContainer sx={{ maxHeight: 500 }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell>Patient Name</StyledTableCell>
                                        <StyledTableCell>Clinic Name</StyledTableCell>
                                        <StyledTableCell>Injector Name</StyledTableCell>
                                        <StyledTableCell>Treatment</StyledTableCell>
                                        <StyledTableCell>Request Generate Time (utc)</StyledTableCell>
                                        <StyledTableCell>Action</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={10}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={10} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {lockTreatmentPlanData.length ? lockTreatmentPlanData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                <material.TableRow
                                                    key={i}
                                                >
                                                    <material.TableCell sx={{ pt: 2, pb: 2 }} size='small'>{row.patientName}</material.TableCell>
                                                    <material.TableCell size='small'>{row.clinicName}</material.TableCell>
                                                    <material.TableCell size='small'>{row.injectorName}</material.TableCell>
                                                    <material.TableCell size='small'>
                                                        {row.treatmentPlan.map((treatment, k) => (
                                                            <span className='d-flex flex-wrap' key={k}>
                                                                {treatment.treatmentName}, {treatment.treatmentArea}, {treatment.product}, {treatment.qty}
                                                            </span>
                                                        ))}
                                                    </material.TableCell>
                                                    <material.TableCell size='small'>{changeDateAndTimeFormate(row.putToPending)}</material.TableCell>
                                                    <material.TableCell>
                                                        <material.IconButton title='Unlock' onClick={() => unlockedTreatment(row)}>
                                                            <material.LockOpenIcon color='info' />
                                                        </material.IconButton>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={10}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>)}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                        <material.TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={lockTreatmentPlanData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </material.Paper>
                </div>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </material.Grid>
    );
}

export default LockTreatmentList;