import React, { useEffect, useRef, useState } from 'react';
import { material } from '../../../library/material';
import Snackbar from '../../toastrmessage/Snackbar';
import { useNavigate } from 'react-router-dom';
import { getAllHtmlTemplate } from '../../../services/PlatformCrmService';

function ViewCrmTemplateList(props) {

    const { openViewTemplateList, setOpenViewTemplateList, clinicDetails, userDetails } = props;
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [selectIndex, setSelectIndex] = useState(null);
    const [templateList, setTemplateList] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const iframeRef = useRef(null);
    const navigate = useNavigate();
    useEffect(() => {
        if (openViewTemplateList) {
            getHtmlTemplateList()
        }
    }, [openViewTemplateList == true]);

    useEffect(() => {
        if (iframeRef.current && selectedTemplate) {
            const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
            iframeDoc.open();
            iframeDoc.write(selectedTemplate.emailTemplate);
            iframeDoc.close();
        }
    }, [selectedTemplate]);

    const getHtmlTemplateList = (searchValue) => {
        const payload = {
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId,
            search: searchValue ? searchValue : ""
        }
        getAllHtmlTemplate(payload)
            .then((resp) => {
                console.log(resp.data)
                setTemplateList(resp.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data,
                })
            })
    };

    const handleClose = () => {
        setSelectIndex(null);
        setSelectedTemplate(null);
        setOpenViewTemplateList(false);
    };

    const searchList = (value) => {
        getHtmlTemplateList(value)
    };

    const selectTemplateName = (templateData, ind) => {
        setSelectIndex(ind);
        setSelectedTemplate(templateData)
    };

    const editTemplate = (templateData) => {
        navigate('/edit_crm_template', { state: { templateData: templateData } })
    };

    return (
        <div>
            <material.Dialog open={openViewTemplateList} fullWidth maxWidth="xl">
                <material.DialogTitle>View Existing CRM Templates
                    <span className='float-end'>
                        <material.IconButton title='Close' color='error' onClick={handleClose}>
                            <material.CloseIcon sx={{ cursor: "pointer" }} />
                        </material.IconButton>
                    </span>
                </material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                        <div className="col-4">
                            <material.Paper elevation={3} sx={{ p: 2, height: "90vh", overflow: "auto" }}>
                                <div className='d-flex flex-row space-between mt-2'>
                                    <span className='me-3'>
                                        <material.Typography variant='h6'>Template List</material.Typography>
                                    </span>
                                    <span>
                                        <material.TextField
                                            fullWidth
                                            variant="standard"
                                            label="Search List"
                                            onChange={(e) => searchList(e.target.value)}
                                            sx={{ mt: -3, width: "40ch" }}
                                        />
                                    </span>
                                </div>
                                {templateList.length ? templateList.map((name, k) => (
                                    <material.List component="nav" aria-label="secondary mailbox folder" key={k}
                                    >
                                        <material.ListItemButton
                                            selected={selectIndex == k}
                                            onClick={() => selectTemplateName(name, k)}
                                        >
                                            <material.ListItemText primary={name.emailTemplateName} />
                                        </material.ListItemButton>
                                    </material.List>
                                )) : null}
                            </material.Paper>
                        </div>
                        <div className="col-8">
                            <material.Paper elevation={3} sx={{ p: 2 }}>
                                <span>
                                    <material.Typography variant='h6'>Template</material.Typography>
                                </span>
                                <span className='float-end' hidden={selectedTemplate == null}>
                                    <material.Button variant="contained" sx={{ textTransform: "none", mb: 1 }} size='small' onClick={() => editTemplate(selectedTemplate)}>Edit CRM Template</material.Button>
                                </span>
                                {selectedTemplate ? (
                                    <div>
                                        <iframe
                                            ref={iframeRef}
                                            title="Invoice Viewer"
                                            style={{
                                                width: "100%",
                                                height: "70vh",
                                                border: "1px solid #ccc",
                                                borderRadius: "8px",
                                                backgroundColor: "#f9f9f9",
                                            }}
                                        ></iframe>
                                    </div>
                                ) : (
                                    <p>Select an template to view its details.</p>
                                )}
                            </material.Paper>
                        </div>
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

export default ViewCrmTemplateList;