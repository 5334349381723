import axios from "axios";
import { rootApiUrl } from "../../environment/Environment";


export const registerByInviteWithClinic = async (payload) => {
    return await axios.post(`${rootApiUrl}/register-by-invite-with-clinic`, payload);
};

export const getSubmittedForms = async (payload) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/admin/get-submitted-forms`, { params: { ...payload } });
};

export const getSubmittedFormById = async (formId) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/admin/get-submitted-form-by-id`, { params: { formId: formId } });
};

export const updateChecklist = async (payload) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/admin/update-form`, payload);
};

export const saveAgreement = async (payload) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/admin/save-agreement`, payload);
};

export const saveAgreementDetails = async (payload) => {
    return await axios.post(`${rootApiUrl}/save-agreement-details`, payload);
};

export const getAgreementUrl = async (formId) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/admin/get-agreement-url`, { params: { formId: formId } });
};

export const getFormDetailsClientSide = async (formId) => {
    return await axios.get(`${rootApiUrl}/get-form-details-client-side`, { params: { formId: formId } });
};

export const getSignedAgreementClientSide = async (formId) => {
    return await axios.get(`${rootApiUrl}/get-signed-agreement-client-side`, { params: { formId: formId } });
};

export const saveOnboardingDocument = async (payload) => {
    return await axios.post(`${rootApiUrl}/save-onboarding-document`, payload);
};

export const getDocumentList = async (formId) => {
    return await axios.get(`${rootApiUrl}/get-document-list`, { params: { formId: formId } });
};

export const getSignedDocumentLink = async (key) => {
    return await axios.get(`${rootApiUrl}/get-signed-document-link`, { params: { key: key } });
};

export const saveProtocolDocument = async (payload) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/admin/save-protocol-document`, payload);
};

export const viewProtocolDocument = async (inSent) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/admin/view-protocol-document`, { params: inSent });
};

export const deleteDocumentFormSendFolder = async (key) => {
    return await axios.put(`${rootApiUrl}/api/v0.2/admin/delete-document-from-send-folder`, null, { params: key });
};

export const saveProtocolDocumentToSendFolder = async (key) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/admin/save-protocol-document-to-send-folder`, { params: key });
};