import axios from "axios";
import { rootApiUrl } from "../environment/Environment";



export const getFolderList = async (orgId) => {
    return await axios.get(`${rootApiUrl}/get-folder-list/${orgId}`);
};

export const saveDocument = async (data) => {
    return await axios.post(`${rootApiUrl}/api/v0.2/docs/save-document`, data);
};

export const getFileList = async (orgId, folderName) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/docs/get-file-list/${orgId}/${folderName}`);
};

export const downloadDocument = async (orgId, folderName, fileName) => {
    return await axios(`${rootApiUrl}/download/${orgId}/${folderName}/${fileName}`, {
        method: 'GET',
        responseType: 'blob'
    });
};

export const uploadTreatmentDocument = async (data) => {
    return await axios.post(`${rootApiUrl}/upload-treatment-document`, data);
};

export const getDocumentDetails = async (orgId, clinicId) => {
    return await axios.get(`${rootApiUrl}/api/v0.2/docs/get-document-details`, { params: { orgId: orgId, clinicId: clinicId } });
};