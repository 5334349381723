import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  changeScheduleStatus,
  deleteAppointmentById,
  getAllScheduleByClinicId,
  getInjectorListByClinicId,
  saveSchedule,
  getScheduleMaster,
  checkInjectorAvailability,
} from "../../../services/AppointmentService";
import { material } from "../../../library/material";
import AdminPermissionDialog from "../../dialog/AdminPermissionDialog";
import ViewTodayScheduleData from "../../dialog/ViewTodayScheduleData";
import { Scheduler } from "@aldabil/react-scheduler";
import {
  getAllPAtients,
} from "../../../services/PatientService";
import { useForm } from "react-hook-form";
import ViewRoomSchedule from "../../dialog/ViewRoomSchedule";
import ViewMachineSchedule from "../../dialog/ViewMachineSchedule";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment, { duration } from "moment";
import Snackbar from "../../toastrmessage/Snackbar";
import Navbar from "../../navbar/Navbar";
import { useNavigate } from "react-router-dom";
import AddBreakHourDialog from "./AddBreakHourDialog";
import './Appointment.css';

var allInjectorDetails = [];
var allPatientDetails = [];


function AppointmentSchedule(props) {
  const { userData, clinicData } = props;
  const clinicDetails = clinicData.clinicReducer.data;
  const userDetails = userData.authReducer.data;
  const [appointmentScheduleData, setAppointmentScheduleData] = useState([]);
  const [injectorData, setInjectorData] = useState([]);
  const [openPermissionDialog, setOpenPermissionDialog] = useState({
    action: false,
    data: null,
    callFrom: "",
  });
  const [openViewSchedule, setOpenViewSchedule] = useState({
    action: false,
    injectorData: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [mode, setMode] = useState("default");
  const calendarRef = useRef(null);
  const [openSnackBar, setOpenSnackBar] = useState({
    action: false,
    type: "",
    message: "",
  });
  const [openAddBreakDialog, setOpenAddBreakDialog] = useState(false);
  const navigate = useNavigate();

  localStorage.setItem("menuName", "Appointment Schedule");

  useEffect(() => {
    getAllScheduleData();
    getAllInjectorByClinic();
  }, []);

  const getAllScheduleData = async () => {
    await getAllScheduleByClinicId(clinicDetails.clinicId)
      .then((resp) => {
        let array = [];
        for (const iterator of resp.data.data) {
          if (new Date(iterator.start) > new Date(iterator.end)) {
            continue;
          }
          array.push({
            ...iterator,
            start: new Date(iterator.start),
            end: new Date(iterator.end),
            admin_id: iterator.InjectorId,
            title:
              iterator.rescheduledCounter == 1
                ? iterator.title + " " + "(Rescheduled)"
                : iterator.title,
            color:
              iterator.rescheduledCounter == 1
                ? "magenta"
                : iterator.bookingStatus === "NOT CONFIRMED"
                  ? "Red"
                  : iterator.bookingStatus === "CONFIRMED"
                    ? "green"
                    : iterator.bookingStatus === "ARRIVED"
                      ? "orange"
                      : iterator.bookingStatus === "CHECKOUT"
                        ? "yellow"
                        : iterator.bookingStatus === "NO SHOW"
                          ? "violet"
                          : "blue",
          });
        }
        setAppointmentScheduleData(array);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response.data.data.info,
          })
      } else {
          setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response.data.messages,
          })
      }
      });
  };

  const getAllInjectorByClinic = async () => {
    await getInjectorListByClinicId(clinicDetails.clinicId).then((resp) => {
      let data = resp.data?.data?.map((ele) => {
        return {
          ...ele,
          admin_id: ele.userId,
          title: ele.fullName,
          color: "blue",
        };
      });
      setInjectorData(data);
      setIsLoading(false);
    })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response.data.data.info,
          })
      } else {
          setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response.data.messages,
          })
      }
      })
  };

  const viewTodaySchedule = () => {
    setOpenViewSchedule({ action: true, injectorData: injectorData });
  };

  const changeBookingStatus = (scheduleData) => {
    setOpenSnackBar({
      action: true,
      type: "info",
      message: "Please wait.....",
    });
    changeScheduleStatus(scheduleData)
      .then((resp) => {
        setOpenSnackBar({
          action: true,
          type: "success",
          message: resp.data.messages,
        });
        getAllScheduleData();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response.data.data.info,
          })
        } else {
          setOpenSnackBar({
            "action": true,
            "type": "error",
            "message": error.response.data.messages,
          })
        }
      });
  };

  const CustomEditor = ({ scheduler }) => {
    const event = scheduler.edited;
    const [injectorData, setInjectorData] = useState([]);
    const [patientsName, setPatientsName] = useState([]);
    const {
      register,
      handleSubmit,
      reset,
      resetField,
      setValue,
      formState: { errors, isValid },
    } = useForm({
      mode: "onTouched",
    });
    const [addNewPatient, setAddNewPatient] = useState(false);
    const [openRoomBookedSchedule, setOpenRoomBookedSchedule] = useState({
      action: false,
      data: null,
    });
    const [openBookedMachineSchedule, setOpenBookedMachineSchedule] = useState({
      action: false,
      data: null,
    });
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectTimeDuration, setSelectTimeDuration] = useState("");
    const [patientId, setPatientId] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [injectorAvailability, setInjectorAvailability] = useState(null);

    useEffect(() => {
      getAllInjectorByClinic();
      getAllPatientData();
      setStartDate(dayjs(scheduler.state?.start?.value));
      checkingInjectorAvailability();
      if (event) {
        reset(event);
        setValue(event?.InjectorId);
        setValue(event?.PatientName);
        setEndDate(dayjs(event.end));
        setSelectTimeDuration(event?.duration);
        setValue("EndTime", moment(event?.end).format("HH:mm"));
      }
    }, []);

    useEffect(() => {
      setValue("RoomName", openRoomBookedSchedule.data?.roomName);
      setValue("RoomNumber", openRoomBookedSchedule.data?.roomNumber);
    }, [openRoomBookedSchedule.data]);

    useEffect(() => {
      setValue("MachineName", openBookedMachineSchedule.data?.machineName);
      setValue("MachineNumber", openBookedMachineSchedule.data?.machineNumber);
    }, [openBookedMachineSchedule.data]);

    const getDialogData = (data) => {
      setValue("title", data.treatmentName);
      setSelectTimeDuration(data.duration);
      getDuration(data.duration);
      setValue("duration", data.duration);
      setValue("Purpose", data.instructions);
    };

    const checkingInjectorAvailability = async () => {
      const payload = {
        "orgId": userDetails.orgId,
        "clinicId": clinicDetails.clinicId,
        "startTime": moment(scheduler.state?.start?.value).format("YYYY-MM-DD HH:mm"),
        "userId": scheduler.admin_id
      }
      await checkInjectorAvailability(payload)
        .then((resp) => {
          if (resp.data.data.allowed) {
            setInjectorAvailability(true)
          } else {
            setInjectorAvailability(false)
            scheduler.close();
            // setOpenSnackBar({
            //   action: true,
            //   type: "error",
            //   message: resp.data.message,
            // });
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response.data.data.info,
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response.data.messages,
            })
          }
        })
    };

    const getAllInjectorByClinic = async () => {
      await getInjectorListByClinicId(clinicDetails.clinicId)
        .then((resp) => {
          allInjectorDetails = resp.data.data;
          let injector = resp.data?.data?.map((ele) => ele.name);
          setInjectorData(injector);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response.data.data.info,
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response.data.messages,
            })
          }
        });
    };

    const getAllPatientData = async () => {
      await getAllPAtients(clinicDetails.clinicId).then((resp) => {
        allPatientDetails = resp.data.data;
        let patientName = resp.data?.data?.map((ele) => ele.name);
        setPatientsName(patientName);
      })
        .catch((error) => {
          if (error.response.status === 401) {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response.data.data.info,
            })
          } else {
            setOpenSnackBar({
              "action": true,
              "type": "error",
              "message": error.response.data.messages,
            })
          }
        });
    };

    const newPatient = () => {
      setAddNewPatient(true);
    };

    const selectPatient = (e, value) => {
      let data = allPatientDetails.filter((ele) => ele.name === value);
      setPatientId(data[0].patientId);
      // reset(data[0])
      setValue("Email", data[0].email);
      setValue("PhoneNumber", data[0].phoneNumber);
    };

    const showRoomBookedSlot = () => {
      setOpenRoomBookedSchedule({ action: true });
    };

    const showBookedMachineSlot = () => {
      setOpenBookedMachineSchedule({ action: true });
    };

    const prePopulateEmail = () => {
      setOpenDialog(true);
    };

    const handleChangeTimeDuration = (event) => {
      setSelectTimeDuration(event.target.value);
      getDuration(event.target.value);
    };

    const getDuration = (duration) => {
      const timeArray = duration.split(" ");
      const hoursIndex = timeArray.indexOf("hour");
      const minutesIndex = timeArray.indexOf("min");
      let hours;
      let minutes;
      if (hoursIndex > 0 && minutesIndex < 0) {
        hours = Number(timeArray[hoursIndex - 1]);
        const time = hours * 60;
        let getTimeDuration = calculateNextTime(
          moment(startDate.$d).format("HH:mm"),
          time
        );
        setValue("EndTime", getTimeDuration);
      } else if (minutesIndex > 0 && hoursIndex < 0) {
        minutes = Number(timeArray[minutesIndex - 1]);
        const time = minutes;
        let getTimeDuration = calculateNextTime(
          moment(startDate.$d).format("HH:mm"),
          time
        );
        setValue("EndTime", getTimeDuration);
      } else if (hoursIndex > 0 && minutesIndex > 0) {
        hours = Number(timeArray[hoursIndex - 1]);
        minutes = Number(timeArray[minutesIndex - 1]);
        const time = hours * 60 + minutes;
        let getTimeDuration = calculateNextTime(
          moment(startDate.$d).format("HH:mm"),
          time
        );
        setValue("EndTime", getTimeDuration);
      }
    };

    const calculateNextTime = (givenTime, duration) => {
      const [givenHours, givenMinutes] = givenTime.split(":").map(Number);
      const givenDate = new Date();
      givenDate.setHours(givenHours);
      givenDate.setMinutes(givenMinutes);
      givenDate.setTime(givenDate.getTime() + duration * 60000);
      const nextTime = `${String(givenDate.getHours()).padStart(
        2,
        "0"
      )}:${String(givenDate.getMinutes()).padStart(2, "0")}`;
      return moment(nextTime, "HH:mm").format("HH:mm");
    };

    const saveAppointment = async (formData) => {
      try {
        setOpenSnackBar({
          action: true,
          type: "info",
          message: "Please wait.....",
        });
        scheduler.loading(true);
        const added_updated_event = await new Promise((res) => {
          res({
            Id: event ? formData.Id : "",
            InjectorId: scheduler.admin_id,
            ClinicId: clinicDetails.clinicId,
            title: formData.title,
            PatientName: formData.PatientName,
            Email: formData.Email,
            PhoneNumber: formData.PhoneNumber,
            start: moment(startDate.$d).format("YYYY-MM-DD HH:mm"),
            end:
              moment(startDate.$d).format("YYYY-MM-DD") +
              " " +
              moment(formData.EndTime, "HH:mm A").format("HH:mm"),
            MachineName: formData.MachineName,
            MachineNumber: formData.MachineNumber,
            MachineId: openBookedMachineSchedule.data?.machineId,
            Purpose: formData.Purpose,
            RoomName: formData.RoomName,
            RoomNumber: formData.RoomNumber,
            RoomId: openRoomBookedSchedule.data?.roomId,
            startTime: moment(startDate.$d).utc(),
            duration: formData.duration,
            patientId: event ? formData.patientId : patientId,
            rescheduledCounter: event ? event.rescheduledCounter : 0,
          });
        });
        await saveSchedule(added_updated_event)
          .then((resp) => {
            setOpenSnackBar({
              action: true,
              type: "success",
              message: resp.data.messages,
            });
            // scheduler.onConfirm(added_updated_event, event ? "edit" : "create");
            scheduler.close();
            getAllScheduleData();
            setAddNewPatient(false);
            reset({
              subject: "",
              patientName: "",
              email: "",
              phoneNumber: "",
              machineNumber: "",
              machineName: "",
              roomName: "",
              roomNumber: "",
              purpose: "",
              EndTime: "",
            });
            setSelectTimeDuration("");
          })
          .catch((error) => {
            if (error.response.status === 401) {
              setOpenSnackBar({
                "action": true,
                "type": "error",
                "message": error.response.data.data.info,
              })
            } else {
              setOpenSnackBar({
                "action": true,
                "type": "error",
                "message": error.response.data.messages,
              })
            }
          });
      } finally {
        scheduler.loading(false);
      }
    };
    return injectorAvailability ? (
      <div>
        <div style={{ padding: "1rem" }}>
          <material.DialogTitle>
            <material.Typography variant="h5" sx={{ ml: -2.5 }}>
              {event
                ? "View Appointment Schedule"
                : "Create Appointment Schedule"}
            </material.Typography>
          </material.DialogTitle>
          <div className="row">
            <div>
              <material.Typography>Patient Details</material.Typography>
              {event ? null : (
                <span className="float-end" style={{ marginTop: -25 }}>
                  <material.Button
                    variant="contained"
                    sx={{ textTransform: "none" }}
                    onClick={newPatient}
                    hidden={addNewPatient}
                  >
                    New Patient
                  </material.Button>
                </span>
              )}
            </div>
            {addNewPatient ? (
              <>
                <div className="col-6">
                  <material.TextField
                    error={errors.patientName?.type === "required"}
                    {...register("PatientName", { required: true })}
                    required
                    id="patientName"
                    label="Patient Name"
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                  <p className="form-text text-danger">
                    {errors.patientName?.type === "required" &&
                      "This field is required"}
                  </p>
                </div>
                <div className="col-6">
                  <material.TextField
                    error={errors.email?.type === "required"}
                    {...register("Email", { required: true })}
                    required
                    id="email"
                    label="Email"
                    type="email"
                    fullWidth
                    variant="standard"
                  />
                  <p className="form-text text-danger">
                    {errors.email?.type === "required" &&
                      "This field is required"}
                  </p>
                </div>
                <div className="col-5">
                  <material.TextField
                    error={errors.phoneNumber?.type === "required"}
                    {...register("PhoneNumber", { required: true })}
                    required
                    id="phoneNumber"
                    label="Phone Number"
                    type="number"
                    fullWidth
                    variant="standard"
                  />
                  <p className="form-text text-danger">
                    {errors.phoneNumber?.type === "required" &&
                      "This field is required"}
                  </p>
                </div>
              </>
            ) : (
              <>
                {event ? (
                  <div className="col-6">
                    <material.TextField
                      error={errors.patientName?.type === "required"}
                      {...register("PatientName", { required: true })}
                      required
                      id="patientName"
                      label="Patient Name"
                      type="text"
                      fullWidth
                      InputProps={{ readOnly: true }}
                      variant="standard"
                    />
                    <p className="form-text text-danger">
                      {errors.patientName?.type === "required" &&
                        "This field is required"}
                    </p>
                  </div>
                ) : (
                  <div className="col-6">
                    <material.Autocomplete
                      id="patientName"
                      fullWidth
                      onChange={selectPatient}
                      options={patientsName}
                      renderInput={(params) => (
                        <material.TextField
                          {...params}
                          required
                          variant="standard"
                          label="Select Patient"
                          {...register("PatientName", { required: true })}
                        />
                      )}
                    />
                    <p className="form-text text-danger">
                      {errors.patientName?.type === "required" &&
                        "This field is required"}
                    </p>
                  </div>
                )}
                <div className="col-6">
                  <material.TextField
                    error={errors.email?.type === "required"}
                    {...register("Email", { required: true })}
                    required
                    id="email"
                    label="Email"
                    type="email"
                    fullWidth
                    variant="standard"
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <p className="form-text text-danger">
                    {errors.email?.type === "required" &&
                      "This field is required"}
                  </p>
                </div>
                <div className="col-5">
                  <material.TextField
                    error={errors.phoneNumber?.type === "required"}
                    {...register("PhoneNumber", { required: true })}
                    required
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ readOnly: true }}
                    id="phoneNumber"
                    label="Phone Number"
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                  <p className="form-text text-danger">
                    {errors.phoneNumber?.type === "required" &&
                      "This field is required"}
                  </p>
                </div>
              </>
            )}
            <div className="col-5">
              <material.TextField
                error={errors.title?.type === "required"}
                {...register("title")}
                id="subject"
                label="Email Subject"
                type="text"
                fullWidth
                variant="standard"
                multiline
                InputLabelProps={{ shrink: true }}
              // rows={2}
              />
              <p className="form-text text-danger">
                {errors.title?.type === "required" && "This field is required"}
              </p>
            </div>
            <div className="col-2">
              <material.Button
                variant="contained"
                size="small"
                sx={{ textTransform: "none" }}
                onClick={prePopulateEmail}
              >
                Pre-populate Email
              </material.Button>
            </div>
            <material.Typography>Calender</material.Typography>
            <div className="col-4">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <material.DateTimePicker
                  label="Start"
                  format="DD-MM-YYYY, HH:mm"
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  sx={{ width: "100%" }}
                  slotProps={{ textField: { variant: "standard" } }}
                />
              </LocalizationProvider>
            </div>
            <div className="col-4">
              <material.FormControl variant="standard" fullWidth>
                <material.InputLabel
                  id="demo-simple-select-standard-label"
                  required
                >
                  Time Duration
                </material.InputLabel>
                <material.Select
                  {...register("duration", { required: true })}
                  required
                  label="Unit"
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  fullWidth
                  value={selectTimeDuration}
                  onChange={handleChangeTimeDuration}
                >
                  <material.MenuItem value={"15 min"}>15 min</material.MenuItem>
                  <material.MenuItem value={"30 min"}>30 min</material.MenuItem>
                  <material.MenuItem value={"45 min"}>45 min</material.MenuItem>
                  <material.MenuItem value={"1 hour"}>1 hour</material.MenuItem>
                  <material.MenuItem value={"1 hour 15 min"}>
                    1 hour 15 min
                  </material.MenuItem>
                  <material.MenuItem value={"1 hour 30 min"}>
                    1 hour 30 min
                  </material.MenuItem>
                  <material.MenuItem value={"1 hour 45 min"}>
                    1 hour 45 min
                  </material.MenuItem>
                  <material.MenuItem value={"2 hour"}>2 hour</material.MenuItem>
                </material.Select>
              </material.FormControl>
            </div>
            <div className="col-4">
              <material.TextField
                error={errors.EndTime?.type === "required"}
                {...register("EndTime", { required: true })}
                id="roomNumber"
                label="End Time"
                type="text"
                InputProps={{ readOnly: true }}
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div>
              <material.Typography>Configure Room</material.Typography>
              {event ? null : (
                <span className="float-end" style={{ marginTop: -25 }}>
                  <material.Button
                    variant="contained"
                    sx={{ textTransform: "none" }}
                    onClick={showRoomBookedSlot}
                  >
                    Show Booked Room Slot
                  </material.Button>
                </span>
              )}
            </div>
            <div className="col-6">
              <material.TextField
                error={errors.roomNumber?.type === "required"}
                {...register("RoomNumber")}
                id="roomNumber"
                label="Room Number"
                type="number"
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
              <p className="form-text text-danger">
                {errors.roomNumber?.type === "required" &&
                  "This field is required"}
              </p>
            </div>
            <div className="col-6">
              <material.TextField
                error={errors.roomName?.type === "required"}
                {...register("RoomName")}
                id="roomName"
                label="Room Name"
                type="text"
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
              <p className="form-text text-danger">
                {errors.roomName?.type === "required" &&
                  "This field is required"}
              </p>
            </div>
            <div>
              <material.Typography>Configure Machine</material.Typography>
              {event ? null : (
                <span className="float-end" style={{ marginTop: -25 }}>
                  <material.Button
                    variant="contained"
                    sx={{ textTransform: "none" }}
                    onClick={showBookedMachineSlot}
                  >
                    Show Booked Machine Slot
                  </material.Button>
                </span>
              )}
            </div>
            <div className="col-6">
              <material.TextField
                {...register("MachineNumber")}
                id="machineNumber"
                label="Machine Number"
                type="number"
                fullWidth
                variant="standard"
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="col-6">
              <material.TextField
                {...register("MachineName")}
                id="machineName"
                label="Machine Name"
                type="text"
                fullWidth
                variant="standard"
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div>
              <material.TextField
                error={errors.Purpose?.type === "required"}
                {...register("Purpose")}
                multiline
                id="purpose"
                label="Instructions for Patient"
                type="text"
                fullWidth
                variant="standard"
                rows={3}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>
        </div>
        <material.DialogActions>
          <material.Button
            variant="contained"
            color="error"
            sx={{ textTransform: "none", mr: 1 }}
            onClick={scheduler.close}
          >
            Cancel
          </material.Button>
          <material.Button
            variant="contained"
            sx={{ textTransform: "none", mr: 1 }}
            onClick={handleSubmit(saveAppointment)}
          >
            Confirm
          </material.Button>
        </material.DialogActions>
        <ViewRoomSchedule
          openRoomBookedSchedule={openRoomBookedSchedule}
          setOpenRoomBookedSchedule={setOpenRoomBookedSchedule}
        />
        <ViewMachineSchedule
          openBookedMachineSchedule={openBookedMachineSchedule}
          setOpenBookedMachineSchedule={setOpenBookedMachineSchedule}
        />
        <PrePopulateEmailDialog
          userDetails={userDetails}
          clinicDetails={clinicDetails}
          setOpenDialog={setOpenDialog}
          openDialog={openDialog}
          getDialogData={getDialogData}
        />
      </div>
    ) : ""
    // scheduler.close();
  };

  const deleteAppointment = async (id) => {
    await deleteAppointmentById(id)
      .then((resp) => {
        setOpenSnackBar({
          action: true,
          type: "success",
          message: resp.data,
        });
        getAllScheduleData();
      })
      .catch((error) => {
        setOpenSnackBar({
          action: true,
          type: "error",
          message: error.response?.data,
        });
      });
  };

  const viewPatientData = (patientData) => {
    navigate("/appointment-schedule/view-patient-details", {
      state: { patientData },
    });
  };

  const addBreakHour = () => {
    setOpenAddBreakDialog(true);
  };

  return (
    <div className="container-fluid" style={{ marginTop: "6%" }}>
      <Navbar />
      <div>
        <div className="row">
          <div className="col-6"></div>
          <div className="col-6">
            <span className="float-end">
              <material.Button
                variant="contained"
                sx={{ textTransform: "none", mr: 1 }}
                size="small"
                onClick={addBreakHour}
                startIcon={<material.AddIcon />}
              >
                Add Break Hour
              </material.Button>
              <material.Button
                variant="contained"
                sx={{ textTransform: "none" }}
                size="small"
                onClick={viewTodaySchedule}
                startIcon={<material.VisibilityIcon />}
              >
                View Today Schedule
              </material.Button>
            </span>
          </div>
        </div>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
            }}
          >
            <material.Stack sx={{ color: "grey.500" }}>
              <material.CircularProgress color="secondary" />
            </material.Stack>
          </div>
        ) : (
          <div>
            <div style={{ textAlign: "center", marginBottom: "10px" }}>
              <span>View Mode: </span>
              <material.Button
                color={mode === "default" ? "primary" : "inherit"}
                variant={mode === "default" ? "contained" : "text"}
                size="small"
                onClick={() => {
                  setMode("default");
                  calendarRef.current?.scheduler?.handleState(
                    "default",
                    "resourceViewMode"
                  );
                }}
              >
                Default
              </material.Button>
              <material.Button
                color={mode === "tabs" ? "primary" : "inherit"}
                variant={mode === "tabs" ? "contained" : "text"}
                size="small"
                onClick={() => {
                  setMode("tabs");
                  calendarRef.current?.scheduler?.handleState(
                    "tabs",
                    "resourceViewMode"
                  );
                }}
              >
                User Schedule
              </material.Button>
            </div>
            <div>
              <Scheduler
                view={"week"}
                ref={calendarRef}
                week={{
                  weekDays: [0, 1, 2, 3, 4, 5, 6],
                  startHour: 9,
                  endHour: 20,
                  step: 30,
                  weekStartOn: 1,
                }}
                day={{
                  startHour: 9,
                  endHour: 20,
                  step: 30,
                }}
                events={appointmentScheduleData}
                resources={injectorData}
                deletable={true}
                resourceFields={{
                  idField: "admin_id",
                  textField: "title",
                  avatarField: "title",
                }}
                onDelete={deleteAppointment}
                customEditor={(scheduler) => (
                  <CustomEditor scheduler={scheduler} />
                )}
                viewerExtraComponent={(fields, event) => {
                  return (
                    <div>
                      {fields.map((field, i) => {
                        if (field.name === "admin_id") {
                          const admin = field.options.find(
                            (fe) => fe.id === event.admin_id
                          );
                          return (
                            <material.Typography
                              key={i}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                textTransform: "capitalize",
                              }}
                              color="textSecondary"
                              variant="caption"
                              noWrap
                            >
                              <material.PersonIcon /> {admin.text}
                            </material.Typography>
                          );
                        } else {
                          return "";
                        }
                      })}
                      <div className="d-flex flex-row">
                        <div>
                          <material.Typography
                            style={{
                              display: "flex",
                              alignItems: "center",
                              textTransform: "capitalize",
                            }}
                            variant="caption"
                            noWrap
                          >
                            <material.PersonIcon sx={{ mr: 1 }} />
                            {event.PatientName}
                          </material.Typography>
                        </div>
                        {event.patientId !== null ? (
                          <span>
                            <material.IconButton
                              title="Open Profile"
                              sx={{ mt: -2 }}
                              aria-label="delete"
                              size="large"
                              onClick={() => viewPatientData(event)}
                            >
                              <material.PersonIcon color="info" />
                            </material.IconButton>
                          </span>
                        ) : null}
                      </div>
                      <div>
                        <material.Typography
                          style={{
                            display: "flex",
                            alignItems: "center",
                            textTransform: "capitalize",
                          }}
                          variant="caption"
                          noWrap
                        >
                          <material.CallIcon sx={{ mr: 1 }} />{" "}
                          {event.PhoneNumber}
                        </material.Typography>
                      </div>
                      <div>
                        <material.Typography
                          style={{
                            display: "flex",
                            alignItems: "center",
                            textTransform: "capitalize",
                          }}
                          variant="caption"
                          noWrap
                        >
                          <material.EmailIcon sx={{ mr: 1 }} /> {event.Email}
                        </material.Typography>
                      </div>
                      <div>
                        <material.Typography
                          style={{
                            display: "flex",
                            alignItems: "center",
                            textTransform: "capitalize",
                          }}
                          variant="caption"
                          noWrap
                        >
                          <material.InventoryIcon sx={{ mr: 1 }} />{" "}
                          {event.appointmentType}
                        </material.Typography>
                      </div>
                      {event.patientId !== null ? (
                        <div>
                          <material.Button
                            variant={
                              event.bookingStatus === "NOT CONFIRMED"
                                ? "contained"
                                : "outlined"
                            }
                            color={
                              event.bookingStatus === "NOT CONFIRMED"
                                ? "success"
                                : "info"
                            }
                            sx={{ textTransform: "none", m: 1 }}
                            size="small"
                            onClick={() =>
                              changeBookingStatus({
                                ...event,
                                bookingStatus: "NOT CONFIRMED",
                              })
                            }
                          >
                            Not Confirmed
                          </material.Button>
                          <material.Button
                            variant={
                              event.bookingStatus === "CONFIRMED"
                                ? "contained"
                                : "outlined"
                            }
                            color={
                              event.bookingStatus === "CONFIRMED"
                                ? "success"
                                : "info"
                            }
                            sx={{ textTransform: "none", m: 1 }}
                            size="small"
                            onClick={() =>
                              changeBookingStatus({
                                ...event,
                                bookingStatus: "CONFIRMED",
                              })
                            }
                          >
                            Confirmed
                          </material.Button>
                          <material.Button
                            variant={
                              event.bookingStatus === "ARRIVED"
                                ? "contained"
                                : "outlined"
                            }
                            color={
                              event.bookingStatus === "ARRIVED"
                                ? "success"
                                : "info"
                            }
                            sx={{ textTransform: "none", m: 1 }}
                            size="small"
                            onClick={() =>
                              changeBookingStatus({
                                ...event,
                                bookingStatus: "ARRIVED",
                              })
                            }
                          >
                            Arrived
                          </material.Button>
                          <material.Button
                            variant={
                              event.bookingStatus === "CHECKOUT"
                                ? "contained"
                                : "outlined"
                            }
                            color={
                              event.bookingStatus === "CHECKOUT"
                                ? "success"
                                : "info"
                            }
                            sx={{ textTransform: "none", m: 1 }}
                            size="small"
                            onClick={() =>
                              changeBookingStatus({
                                ...event,
                                bookingStatus: "CHECKOUT",
                              })
                            }
                          >
                            Checkout
                          </material.Button>
                          <material.Button
                            variant={
                              event.bookingStatus === "NO SHOW"
                                ? "contained"
                                : "outlined"
                            }
                            color={
                              event.bookingStatus === "NO SHOW"
                                ? "success"
                                : "info"
                            }
                            sx={{ textTransform: "none", m: 1 }}
                            size="small"
                            onClick={() =>
                              changeBookingStatus({
                                ...event,
                                bookingStatus: "NO SHOW",
                              })
                            }
                          >
                            No Show
                          </material.Button>
                        </div>
                      ) : null}
                    </div>
                  );
                }}
              />
            </div>
            <AdminPermissionDialog
              openPermissionDialog={openPermissionDialog}
              setOpenPermissionDialog={setOpenPermissionDialog}
              getAllInjectorByClinic={getAllInjectorByClinic}
              getAllScheduleData={getAllScheduleData}
            />
          </div>
        )}
      </div>
      <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
      <ViewTodayScheduleData
        openViewSchedule={openViewSchedule}
        setOpenViewSchedule={setOpenViewSchedule}
      />
      <AddBreakHourDialog
        openAddBreakDialog={openAddBreakDialog}
        setOpenAddBreakDialog={setOpenAddBreakDialog}
        getAllScheduleData={getAllScheduleData}
        getAllInjectorByClinicId={getAllInjectorByClinic}
      />
    </div>
  );
}

var allTreatmentList;

const PrePopulateEmailDialog = (props) => {
  const {
    setOpenDialog,
    openDialog,
    userDetails,
    clinicDetails,
    getDialogData,
  } = props;
  const [treatmentName, setTreatmentName] = useState([]);
  const [selectTreatmentName, setSelectTreatmentName] = useState("");
  const [tagName, setTagName] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState({
    action: false,
    type: "",
    message: "",
  });

  const {
    register,
    handleSubmit,
    resetField,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: "onTouched",
  });

  useEffect(() => {
    if (openDialog) {
      // getAllTreatmentList();
      getMasterDataForScheduler();
    }
  }, [openDialog === true]);

  const getMasterDataForScheduler = () => {
    setOpenSnackBar({
      action: true,
      type: "info",
      message: "Please Wait....!",
    });
    getScheduleMaster(clinicDetails.clinicId)
      .then((resp) => {
        allTreatmentList = resp.data.data;
        let treatment = resp.data?.data?.map((res) => res.treatmentName);
        setTreatmentName(treatment);
        setOpenSnackBar({
          action: true,
          type: "success",
          message: resp.data.messages,
        });
      })
      .catch((error) => {
        setOpenSnackBar({
          action: true,
          type: "error",
          message: error.response.data.messages,
        });
      });
  };

  const selectTreatment = (e, value) => {
    reset({ treatmentArea: "" });
    let treatmentArea = allTreatmentList?.filter(
      (ele) => ele.treatmentName === value
    );
    setValue("instructions", treatmentArea[0].instructions);
    setValue("duration", treatmentArea[0].duration);
    // setAreaOption(treatmentArea?.length ? [treatmentArea[0].instructions] : []);
    setSelectTreatmentName(value);
  };

  const selectArea = async (e, value) => {
    setTagName(selectTreatmentName + "-" + value);
  };

  const close = () => {
    setOpenDialog(false);
    reset({ treatmentArea: "", instructions: "", duration: "" });
    setTagName("");
  };

  const save = (formData) => {
    getDialogData(formData);
    setOpenDialog(false);
    reset({ treatmentArea: "", instructions: "", duration: "" });
    setTagName("");
  };

  return (
    <div>
      <material.Dialog open={openDialog} hideBackdrop fullWidth maxWidth="sm">
        <material.DialogTitle>Pre-populate Email Subject</material.DialogTitle>
        <material.DialogContent>
          <div>
            <div>
              <material.Autocomplete
                className="mt-3"
                id="treatment"
                onChange={selectTreatment}
                options={treatmentName}
                renderInput={(params) => (
                  <material.TextField
                    {...params}
                    variant="standard"
                    label="Treatment"
                    {...register("treatmentName", { required: true })}
                  />
                )}
              />
              <material.TextField
                id="area"
                className="mt-3"
                variant="standard"
                label="Instruction"
                {...register("instructions")}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
              <material.TextField
                id="area"
                className="mt-3"
                variant="standard"
                label="Duration"
                {...register("duration", { required: true })}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
              {/* <material.Autocomplete
                                id="area"
                                className='mt-3'
                                onChange={selectArea}
                                options={areaOption}
                                renderInput={(params) => <material.TextField {...params} variant="standard" label="Instruction"
                                    {...register("instruction", { required: true })}
                                />}
                            /> */}
            </div>
          </div>
        </material.DialogContent>
        <material.DialogActions>
          <material.Button
            variant="contained"
            color="error"
            sx={{ textTransform: "none" }}
            startIcon={<material.CloseIcon />}
            onClick={close}
          >
            Cancel
          </material.Button>
          <material.Button
            variant="contained"
            sx={{ textTransform: "none" }}
            startIcon={<material.DoneIcon />}
            disabled={!isValid}
            onClick={handleSubmit(save)}
          >
            Save
          </material.Button>
        </material.DialogActions>
      </material.Dialog>
      <Snackbar openSnackBar={openSnackBar} setOpenSnackBar={setOpenSnackBar} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state,
    clinicData: state,
  };
};

export default connect(mapStateToProps)(AppointmentSchedule);
