import React, { useEffect, useState } from 'react'
import { material } from '../../../library/material';
import { useTheme } from '@mui/material';
import Navbar from '../../navbar/Navbar';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import { useNavigate } from 'react-router-dom';
import Snackbar from '../../toastrmessage/Snackbar';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import moment from 'moment';
import { connect } from 'react-redux';
import { getAllCrmData } from '../../../services/PlatformCrmService';
import AddPlatFormCrmDetails from './AddPlatFormCrmDetails';
import AddPlatFormCrm from './AddPlatFormCrm';
import ViewSentMailData from './ViewSentMailData';
import ViewReceiveMailData from './ViewReceiveMailData';



function PlatFormCrmDetails(props) {

    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const theme = useTheme();
    // const navigate = useNavigate();
    const [openAddCrm, setOpenAddCrm] = useState(false);
    const [openViewSentEmail, setOpenViewSentEmail] = useState(false);
    const [openViewReceiveEmail, setOpenViewReceiveEmail] = useState(false);
    const [allCrm, setAllCrm] = useState([]);
    const [openEditCrm, setOpenEditCrm] = useState({ action: false, data: null });
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const [filteredCrm, setFilteredCrm] = useState([]); // Filtered data for display
    const [searchTerm, setSearchTerm] = useState(""); // Search term input
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getCrmData();
    }, []);

    // navigate("/platform-crm-details")

    const getCrmData = () => {
        const param = {
            // clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId
        }
        getAllCrmData(param)
            .then((resp) => {
                setAllCrm(resp.data.data)
                setFilteredCrm(resp.data.data);
            })
            .catch((error) => {
                console.log(error)
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data.data,
                })
            })
    };


    const addCrm = () => {
        setOpenAddCrm(true);
    };

    const addCrmNormalData = () => {
        setOpenEditCrm({ action: true, data: null });
    };

    const editCrmData = (rowData) => {
        setOpenEditCrm({ action: true, data: rowData });
    };
    const viewSentEmail = () => {
        setOpenViewSentEmail(true);
    };
    const viewReceiveEmail = () => {
        setOpenViewReceiveEmail(true);
    };

    const filterByField = (searchValue) => {
        setSearchTerm(searchValue);
        if (!searchValue.trim()) {
            setFilteredCrm(allCrm);
            return;
        }

        const lowerSearchValue = searchValue.toLowerCase();

        // Filter data based on relevant fields
        const filteredData = allCrm.filter((row) => {
            return (
                row.interested?.toLowerCase().includes(lowerSearchValue) ||
                row.others?.toLowerCase().includes(lowerSearchValue) ||
                row.clinicName?.toLowerCase().includes(lowerSearchValue) ||
                row.crmName?.toLowerCase().includes(lowerSearchValue) ||
                row.website?.toLowerCase().includes(lowerSearchValue) ||
                row.nurseName?.toLowerCase().includes(lowerSearchValue) ||
                row.email?.toLowerCase().includes(lowerSearchValue) ||
                row.linkedin?.toLowerCase().includes(lowerSearchValue) ||
                row.address?.toLowerCase().includes(lowerSearchValue) ||
                row.phoneNumber?.toLowerCase().includes(lowerSearchValue) ||
                row.faceBook?.toLowerCase().includes(lowerSearchValue) ||
                row.tiktok?.toLowerCase().includes(lowerSearchValue) ||
                row.instagram?.toLowerCase().includes(lowerSearchValue) ||
                row.status?.toLowerCase().includes(lowerSearchValue) ||
                row.notes?.toLowerCase().includes(lowerSearchValue) ||
                row.nextFollowUpDate?.toLowerCase().includes(lowerSearchValue) ||
                row.emailSentDate?.toLowerCase().includes(lowerSearchValue)
            );
        });

        setFilteredCrm(filteredData); // Update filtered data
    };

    return (
        <material.Grid className='container-fluid'
            sx={{ [theme.breakpoints.down('sm')]: { mt: 10 }, [theme.breakpoints.up('sm')]: { mt: 10 }, [theme.breakpoints.up('md')]: { mt: 12 }, [theme.breakpoints.up('lg')]: { mt: 12 }, }} >
            <Navbar />
            <material.Grid container spacing={2}>
                <material.Grid item xs={12} sm={6} md={6} lg={6}>
                    {/* <material.Stack direction='row' spacing={1}>
                        <material.Autocomplete
                            id="campaignName"
                            onChange={selectCampaign}
                            options={allCampaignName}
                            getOptionLabel={(option) => option.campaignName}
                            renderInput={(params) => <material.TextField {...params} variant="standard" label="Select CRM Campaign"
                                sx={{
                                    mt: -2, [theme.breakpoints.down('sm')]: { width: "15ch" }, [theme.breakpoints.up('sm')]: { width: "15ch" },
                                    [theme.breakpoints.up('md')]: { width: "20ch" }, [theme.breakpoints.up('lg')]: { width: "30ch" },
                                }} />}
                        />
                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' onClick={startEmailSending}>Start Email Sending</material.Button>
                    </material.Stack> */}
                    <material.TextField
                        fullWidth
                        variant="standard"
                        label="Filter By All Field"
                        type='text'
                        sx={{ width: "40ch" }}
                        value={searchTerm}
                        onChange={(e) => filterByField(e.target.value)}
                    />
                </material.Grid>
                <material.Grid item xs={12} sm={6} md={6} lg={6}>
                    <material.Stack direction='row' spacing={1} justifyContent='flex-end'>
                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' onClick={viewSentEmail}>View Sent Email</material.Button>
                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' onClick={viewReceiveEmail}>View Receive Email</material.Button>
                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' onClick={addCrmNormalData}>Add CRM Data</material.Button>
                        <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} size='small' onClick={addCrm}>Add CRM Excel Data</material.Button>
                    </material.Stack>
                </material.Grid>
                <material.Grid item xs={12}>
                    <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <material.TableContainer sx={{ maxHeight: "70vh" }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell>CRM Name</StyledTableCell>
                                        <StyledTableCell>Clinic Name</StyledTableCell>
                                        <StyledTableCell>Website</StyledTableCell>
                                        <StyledTableCell>Nurse Name</StyledTableCell>
                                        <StyledTableCell>Email</StyledTableCell>
                                        <StyledTableCell>Linkedin</StyledTableCell>
                                        <StyledTableCell>Address</StyledTableCell>
                                        <StyledTableCell>Mobile Number</StyledTableCell>
                                        <StyledTableCell>FaceBook</StyledTableCell>
                                        <StyledTableCell>TikTok</StyledTableCell>
                                        <StyledTableCell>Instagram</StyledTableCell>
                                        <StyledTableCell>Interested Product</StyledTableCell>
                                        {/* <StyledTableCell>Others Interested Product</StyledTableCell> */}
                                        <StyledTableCell>Status</StyledTableCell>
                                        <StyledTableCell>Next Follow Up Data</StyledTableCell>
                                        <StyledTableCell>Email Sent Date</StyledTableCell>
                                        <StyledTableCell>Notes</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {filteredCrm.length ? filteredCrm.map((row, k) => (
                                        <material.TableRow key={k}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } }}
                                            onClick={() => editCrmData(row)}
                                        >
                                            <material.TableCell>{row.crmName}</material.TableCell>
                                            <material.TableCell>{row.clinicName ? row.clinicName : ""}</material.TableCell>
                                            <material.TableCell><a href={row.website ? row.website : ""} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}>{row.website ? row.website : ""}</a></material.TableCell>
                                            <material.TableCell>{row.nurseName ? row.nurseName : ""}</material.TableCell>
                                            <material.TableCell>{row.email ? row.email : ""}</material.TableCell>
                                            <material.TableCell><a href={row.linkedin ? row.linkedin : ""} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}> {row.linkedin ? row.linkedin : ""}</a></material.TableCell>
                                            <material.TableCell>{row.address ? row.address : ""}</material.TableCell>
                                            <material.TableCell>{row.phoneNumber ? row.phoneNumber : ""}</material.TableCell>
                                            <material.TableCell> <a href={row.faceBook ? row.faceBook : ""} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}>{row.faceBook ? row.faceBook : ""}</a></material.TableCell>
                                            <material.TableCell><a href={row.tiktok ? row.tiktok : ""} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}>{row.tiktok ? row.tiktok : ""}</a></material.TableCell>
                                            <material.TableCell><a href={row.instagram ? row.instagram : ""} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}>{row.instagram ? row.instagram : ""}</a></material.TableCell>
                                            <material.TableCell>{row.interested ? row.interested : ""}</material.TableCell>
                                            {/* <material.TableCell>{row.others ? row.others : ""}</material.TableCell> */}
                                            <material.TableCell>{row.status ? row.status : ""}</material.TableCell>
                                            <material.TableCell>{row.nextFollowUpDate ? moment(row.nextFollowUpDate).format('YYYY-MM-DD') : ""}</material.TableCell>
                                            <material.TableCell>{row.emailSentDate ? moment(row.emailSentDate).format('YYYY-MM-DD') : ""}</material.TableCell>
                                            <material.TableCell>{row.notes ? row.notes : ""}</material.TableCell>
                                        </material.TableRow>
                                    )) : (
                                        <material.TableRow>
                                            <material.TableCell colSpan={18}>
                                                <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                            </material.TableCell>
                                        </material.TableRow>
                                    )}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                    </material.Paper>
                </material.Grid>
            </material.Grid>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
            <AddPlatFormCrmDetails
                openAddCrm={openAddCrm}
                setOpenAddCrm={setOpenAddCrm}
                getCrmData={getCrmData}
                clinicDetails={clinicDetails}
                userDetails={userDetails}
            />
            <AddPlatFormCrm
                openEditCrm={openEditCrm}
                setOpenEditCrm={setOpenEditCrm}
                getCrmData={getCrmData}
                clinicDetails={clinicDetails}
                userDetails={userDetails}
            />
            <ViewSentMailData
                openViewSentEmail={openViewSentEmail}
                setOpenViewSentEmail={setOpenViewSentEmail}
            />
            <ViewReceiveMailData
                openViewReceiveEmail={openViewReceiveEmail}
                setOpenViewReceiveEmail={setOpenViewReceiveEmail}
            />
        </material.Grid>
    )

}

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};
export default connect(mapStateToProps)(PlatFormCrmDetails);
