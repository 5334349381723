import React, { useEffect, useState } from 'react';
import { material } from '../../../../library/material';
import { getAllPAtients, getPatientCreditList } from '../../../../services/PatientService';
import { useForm } from 'react-hook-form';
import Snackbar from '../../../toastrmessage/Snackbar';
import { savePackageInvoice } from '../../../../services/PackageConfigurationService';
import { getUsersList } from '../../../../services/UserManagementService';

function GenerateInvoice(props) {

    const { openGenerateInvoiceDialog, setOpenGenerateInvoiceDialog, userDetails, clinicDetails } = props;
    const { register, handleSubmit, getValues, control, watch, setValue, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [patientCreditList, setPatientCreditList] = useState(0);
    const [getCreditValue, setCreditValue] = useState(null);
    const [patientsData, setPatientsData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });
    const [patientId, setPatientId] = useState(null);
    const [userList, setSUserList] = useState([]);
    const [getSalesAccount, setGetSalesAccount] = useState(null);

    useEffect(() => {
        if (openGenerateInvoiceDialog.action) {
            getAllPatientListByClinicId();
            getAllUserList();
            setValue("totalPayableAmount", openGenerateInvoiceDialog.rowData?.totalPayableAmount);
            setValue('discount', 0);
            setValue('eft', 0);
            setValue('giftCard', 0);
            setValue('cash', 0);
            setValue('patientCredit', 0);
        }
    }, [openGenerateInvoiceDialog.action == true]);

    const onChangeEft = watch('eft');
    const onChangeGiftCard = watch('giftCard');
    const onChangeCash = watch('cash');
    const onChangeTotalAmount = watch('totalPayableAmount');
    const onChangeDiscount = watch('discount');
    const onChangeTotalCreditAmount = watch('patientCredit');

    useEffect(() => {
        totalCalculation();
    }, [onChangeEft, onChangeGiftCard, onChangeCash, onChangeDiscount, onChangeTotalAmount, onChangeTotalCreditAmount, openGenerateInvoiceDialog.action === true]);

    const totalCalculation = () => {
        let eftValue = getValues('eft');
        let giftCardValue = getValues('giftCard');
        let cashValue = getValues('cash');
        let totalAmount = getValues('totalPayableAmount');
        let discountValue = getValues('discount');
        let totalCreditAmount = getValues('patientCredit');
        let totalValue = parseFloat(totalAmount) - (parseFloat(eftValue) + parseFloat(giftCardValue) + parseFloat(cashValue) + parseFloat(totalCreditAmount));
        setValue("remainingAmount", parseFloat(totalValue) - parseFloat(discountValue));
        setCreditValue(totalCreditAmount);
    };

    const getAllPatientListByClinicId = async () => {
        await getAllPAtients(clinicDetails.clinicId)
            .then((res) => {
                setPatientsData(res.data.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data.messages,
                })
            })
    };

    const selectPatient = (e, value) => {
        getPatientCreditListData(value.patientId);
        setPatientId(value.patientId);
    };

    const getPatientCreditListData = (patientId) => {
        let payload = {
            patientId: patientId
        }
        getPatientCreditList(payload)
            .then((resp) => {
                if (resp.data.data.length) {
                    if (resp.data.data[0].balance < 0) {
                        setOpenSnackBar({
                            "action": true,
                            "type": "error",
                            "message": "Customer has an outstanding balance. Please check customer credit.",
                        })
                        setPatientCreditList(0);
                    } else {
                        setPatientCreditList(resp.data.data[0].balance);
                    }
                } else {
                    setPatientCreditList(0);
                }
            })
            .catch((error) => {
                setOpenSnackBar({
                    "action": true,
                    "type": "error",
                    "message": error.response.data.messages,
                })
            })
    };

    const getAllUserList = () => {
        const payload = {
            orgId: userDetails.orgId,
            clinicId: clinicDetails.clinicId
        }
        getUsersList(payload)
            .then((resp) => {
                const activeUser = resp.data.data.filter((user) => user.active === true);
                setSUserList(activeUser)
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data.messages,
                });
            })
    };

    const selectUser = (e, value) => {
        setGetSalesAccount(value.userId);
    };

    const generateInvoice = (formData) => {
        const payload = {
            clinicId: clinicDetails.clinicId,
            orgId: userDetails.orgId,
            packageId: openGenerateInvoiceDialog.rowData.id,
            patientId: patientId,
            generatedBy: getSalesAccount,
            ...formData
        }
        if (formData.remainingAmount <= 0) {
            savePackageInvoice(payload)
                .then((resp) => {
                    setOpenSnackBar({
                        action: true,
                        type: "success",
                        message: resp.data.messages,
                    });
                    setGetSalesAccount(null);
                    setPatientId(null);
                    reset({ discount: "", cash: 0, giftCard: 0, eft: 0, remainingAmount: "", generatedBy: "", patientName: "" });
                    setOpenGenerateInvoiceDialog({ action: false, rowData: null });
                })
                .catch((error) => {
                    setOpenSnackBar({
                        action: true,
                        type: "error",
                        message: error.response.data.messages,
                    });
                })
        } else {
            setOpenSnackBar({
                action: true,
                type: "error",
                message: "",
            });
        }
    };

    const cancel = () => {
        setGetSalesAccount(null);
        setPatientId(null);
        reset({ discount: "", cash: 0, giftCard: 0, eft: 0, remainingAmount: "", generatedBy: "", patientName: "" });
        setOpenGenerateInvoiceDialog({ action: false, rowData: null });
    };

    return (
        <div>
            <material.Dialog open={openGenerateInvoiceDialog.action} maxWidth="lg" fullWidth>
                <material.DialogTitle>Generate Invoice</material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                        <div className='col-3'>
                            <material.Autocomplete
                                id="name"
                                margin="dense"
                                onChange={selectPatient}
                                options={patientsData}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <material.TextField {...params} required variant="standard" label="Patient Name"
                                    {...register('patientName', { required: true })}
                                />}
                            />
                        </div>
                        <div className="col-3">
                            <material.TextField
                                required
                                margin="dense"
                                label="Total Price"
                                variant="standard"
                                type="text"
                                size="small"
                                fullWidth
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                {...register("totalPayableAmount", { required: true })}
                            />
                        </div>
                        <div className="col-3">
                            <material.TextField
                                error={errors.discount?.type === "required"}
                                required
                                margin="dense"
                                label="Discount"
                                variant="standard"
                                type="number"
                                size="small"
                                fullWidth
                                {...register("discount", { required: true })}
                            />
                        </div>
                        <div className="col-3">
                            <material.Autocomplete
                                id="generatedBy"
                                margin="dense"
                                onChange={selectUser}
                                options={userList}
                                getOptionLabel={(option) => option.firstName + " " + option.lastName}
                                renderInput={(params) => <material.TextField {...params} required variant="standard" label="Sales Account"
                                    {...register("generatedBy", { required: true })}
                                />}
                            />
                        </div>
                        <div className='col-3'>
                            <material.TextField
                                {...register("giftCard", { required: true })}
                                margin="dense"
                                required
                                fullWidth
                                type='number'
                                label="Gift Card"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div className='col-3'>
                            <material.TextField
                                {...register("eft", { required: true })}
                                margin="dense"
                                required
                                fullWidth
                                type='number'
                                label="EFT"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div className='col-3'>
                            <material.TextField
                                {...register("cash", { required: true })}
                                margin="dense"
                                required
                                fullWidth
                                type='number'
                                label="Cash"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div className='col-3'>
                            <material.TextField
                                {...register("remainingAmount", { required: true })}
                                margin="dense"
                                fullWidth
                                type='number'
                                label="Remaining Amount"
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                            />
                        </div>
                        <div className='col-3'>
                            <material.TextField
                                {...register("patientCredit", { required: true })}
                                margin="dense"
                                fullWidth
                                type='number'
                                label="Credit"
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                                disabled={patientCreditList === 0}
                            />
                            {(getCreditValue > patientCreditList) && (
                                <h6 className="text-danger">Maximum credit available {patientCreditList}</h6>
                            )}
                            <h6>Available Balance : {patientCreditList}</h6>
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions sx={{ m: 2 }}>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" color="error" onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" color="primary" onClick={handleSubmit(generateInvoice)} disabled={!isValid} startIcon={<material.DoneIcon />}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default GenerateInvoice;