import React, { useEffect } from 'react';
import { material } from '../../library/material';
import { logoutAction } from '../../store/action/Action';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { socket } from '../../socket/Socket';
import { styled } from "@mui/material/styles";
import { connect } from 'react-redux';


const AppBar = styled(material.MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}));

const initialState = {
    isLoggedIn: false,
    data: {
        email: "",
        orgId: "",
        refreshToken: "",
        role: "",
        token: "",
        userId: ""
    },
};

function KioskNavbar(props) {

    const { user, authLogout, clinicData } = props;
    const userDetails = user.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [choseOption, setChoseOption] = React.useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${userDetails.token}`;
        socket.emit('kiosk-info', userDetails);
        socket.on("logout", (res) => {
            logOut()
        });
    }, []);

    const handleMenu = (event) => {
        setChoseOption(event.currentTarget);
    };

    async function logOut() {
        authLogout(initialState, navigate)
        socket.disconnect()
    };

    const handleClose = () => {
        setChoseOption(null);
    };

    return (
        <div>
            <material.Box>
                <AppBar position="fixed"
                    style={{ backgroundColor: "#fff" }}
                >
                    <material.Toolbar>
                        {/* <material.Typography sx={{ color: "black", marginTop: "-2px !important", mr: 2 }} className="nav-content">{localStorage.getItem("menuName")}</material.Typography> */}
                        <material.Typography sx={{ flexGrow: 1 }}></material.Typography>
                        <material.IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <material.Tooltip>
                                <material.Avatar />
                            </material.Tooltip>
                        </material.IconButton>
                        <material.Menu
                            id="menu-appbar"
                            anchorEl={choseOption}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(choseOption)}
                            onClose={handleClose}
                        >
                            <material.MenuItem className="nav-content">Name: {userDetails.name}</material.MenuItem>
                            <material.MenuItem className="nav-content">Email: {userDetails.email}</material.MenuItem>
                            <material.MenuItem className="nav-content">Roles: {userDetails.role?.roleName}</material.MenuItem>
                            <material.MenuItem onClick={logOut} className="nav-content"><material.LogoutIcon sx={{ mr: 2 }} />Logout</material.MenuItem>
                        </material.Menu>
                    </material.Toolbar>
                </AppBar>
            </material.Box>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state,
        patientData: state,
        clinicData: state
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        authLogout: (initialState, navigate) => {
            dispatch(logoutAction(initialState, navigate))
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(KioskNavbar);