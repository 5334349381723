import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllPAtients, getAllPatientByClinicId } from '../../../services/PatientService';
import { connect } from 'react-redux';
import moment from 'moment';
import { changeDateFormate } from '../../../date-and-time-format/DateAndTimeFormat';
import Navbar from '../../navbar/Navbar';
import { StyledTableCell } from '../../../shared/TableHeaderStyle';
import { socket } from '../../../socket/Socket';
import Snackbar from '../../toastrmessage/Snackbar';
import { useTheme } from '@mui/material';

var allPatientData;
const patientEditPermission = ["PATIENT_EDIT"];

function PatientManagement(props) {

    const { userData, clinicData } = props;
    const userDetails = userData.authReducer.data;
    const clinicDetails = clinicData.clinicReducer.data;
    const [patientsData, setPatientsData] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [openSnackBar, setOpenSnackBar] = useState({
        "action": false,
        "type": "",
        "message": "",
    });
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    localStorage.setItem("menuName", "Patient List")

    useEffect(() => {
        getAllPatientListByClinicId()
    }, []);

    const getAllPatientListByClinicId = async (paginationData) => {
        const payload = {
            limit: paginationData ? paginationData.limit : 10,
            page: paginationData ? paginationData.page : 0,
            clinicId: clinicDetails.clinicId,
            search: paginationData ? paginationData.search : ""
        }
        setIsLoading(true)
        await getAllPatientByClinicId(payload)
            .then((res) => {
                allPatientData = res.data.data
                setPatientsData(res.data.data.patients);
                setPage(Number(res.data.data.currentPage));
                setRowsPerPage(res.data.data.limit);
                setTotalElements(res.data.data.totalItems);
                setIsLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const addPatient = (patientData) => {
        // if (userDetails.role.includes("INJECTOR")) {
        //     navigate("/patient-list/add-patient")
        // } else {
        //     navigate("/user_management/patient-list/add-patient")
        // }
        navigate("/patient-list/add-patient")
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const payload = {
            limit: rowsPerPage,
            page: newPage,
        }
        getAllPatientListByClinicId(payload)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        const payload = {
            limit: event.target.value,
            page: page
        }
        getAllPatientListByClinicId(payload)
    };

    const searchPatient = (value) => {
        const payload = {
            // limit: 100,
            // page: 0,
            search: value
        }
        getAllPatientListByClinicId(payload)
    };

    const filterByPhone = (value) => {
        setPage(1);
        const filteredRows = patientsData.filter((row) => {
            return row.phoneNumber
                .toString()
                .toLowerCase()
                .trim()
                .includes(value.toString().toLowerCase().trim())
        })
        if (value.trim().toString().length < 1) {
            setPatientsData(allPatientData);
        } else {
            setPatientsData(filteredRows);
        }
    };

    const showPatientData = (patientData) => {
        navigate("/patient-list/view-patient-details", { state: { patientData } })
    };

    const addPatientUsingKiosk = () => {
        socket.emit("add-patient", clinicDetails);
    };

    socket.on("no-secondary", (resp) => {
        if (resp) {
            setOpenSnackBar({
                "action": true,
                "type": "warning",
                "message": "Connection lost or No Secondary found. Please Login into Secondary to use this function",
            })
        };
    });

    return (
        <material.Grid spacing={2} className='container-fluid'
            sx={{
                [theme.breakpoints.down('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('sm')]: {
                    mt: 10
                },
                [theme.breakpoints.up('md')]: {
                    mt: 10
                },
                [theme.breakpoints.up('lg')]: {
                    mt: 10
                },
            }}
        >
            <Navbar getAllPatientListByClinicId={getAllPatientListByClinicId} />
            <div className="row">
                <div className="col-lg-3 col-md-5 col-sm-12">
                    <span style={{ marginLeft: 5 }}>
                        <material.TextField
                            fullWidth
                            variant="standard"
                            label="Search Patient"
                            onChange={(e) => searchPatient(e.target.value)}
                            sx={{ mt: -2 }}
                        />
                    </span>
                </div>
                <div className='col-lg-9 col-md-7 col-sm-12'>
                    {userDetails.role.permissions.some(item => patientEditPermission.includes(item.permissionName)) ? (
                        <span className="float-end">
                            <material.Button variant="contained" sx={{ textTransform: "none", mr: 1 }} onClick={() => addPatientUsingKiosk({ "readOnly": false, "callFrom": "add" })} startIcon={<material.AddIcon />}>Add-Patient Using Secondary</material.Button>
                            <material.Button variant="contained" sx={{ textTransform: "none" }} onClick={() => addPatient({ "readOnly": false, "callFrom": "add" })} startIcon={<material.AddIcon />}> Add-Patient</material.Button>
                        </span>
                    ) : null}
                </div>
                <div className="col-12 mt-3">
                    <material.Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <material.TableContainer sx={{ maxHeight: "70vh" }}>
                            <material.Table stickyHeader aria-label="sticky table">
                                <material.TableHead >
                                    <material.TableRow>
                                        <StyledTableCell>Name</StyledTableCell>
                                        <StyledTableCell>Date of Birth</StyledTableCell>
                                        <StyledTableCell>Age</StyledTableCell>
                                        <StyledTableCell>Email</StyledTableCell>
                                        <StyledTableCell>Phone No</StyledTableCell>
                                        <StyledTableCell>Gender</StyledTableCell>
                                        <StyledTableCell>Status</StyledTableCell>
                                    </material.TableRow>
                                </material.TableHead>
                                <material.TableBody>
                                    {isLoading ? (
                                        <material.TableRow >
                                            <material.TableCell colSpan={10}>
                                                <SkeletonTheme baseColor="#bbdefb" highlightColor="#c6ff00" enableAnimation="true" inline="true" width="100% " height="30px">
                                                    <Skeleton count={10} />
                                                </SkeletonTheme>
                                            </material.TableCell>
                                        </material.TableRow>
                                    ) : (
                                        <>
                                            {patientsData.length ? patientsData.map((row, i) => (
                                                <material.TableRow
                                                    key={i}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } }}
                                                    onClick={() => showPatientData(row)}
                                                >
                                                    <material.TableCell sx={{ textTransform: "capitalize" }} size='small' component="th" scope="row">{row.name} </material.TableCell>
                                                    <material.TableCell size='small'>{row.dateOfBirth ? changeDateFormate(row.dateOfBirth) : ""}</material.TableCell>
                                                    <material.TableCell size='small'>{moment().diff(row.dateOfBirth, "years")}</material.TableCell>
                                                    <material.TableCell size='small'>{row.email}</material.TableCell>
                                                    <material.TableCell size='small'>{row.phoneNumber}</material.TableCell>
                                                    <material.TableCell size='small'>{row.gender}</material.TableCell>
                                                    <material.TableCell size='small'>{row.active ? (<p style={{ color: "green", fontWeight: "bold" }}>active</p>) : (<p style={{ color: "red", fontWeight: "bold" }}>De-active</p>)}</material.TableCell>
                                                </material.TableRow>
                                            )) : (
                                                <material.TableRow >
                                                    <material.TableCell colSpan={10}>
                                                        <h6 className='d-flex justify-content-center text-danger fw-bold'>No data found</h6>
                                                    </material.TableCell>
                                                </material.TableRow>
                                            )}
                                        </>)}
                                </material.TableBody>
                            </material.Table>
                        </material.TableContainer>
                        <hr />
                        <material.TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={totalElements}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </material.Paper>
                </div>
            </div>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </material.Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        userData: state,
        clinicData: state
    };
};

export default connect(mapStateToProps)(PatientManagement);