import React, { useEffect, useState } from 'react';
import { material } from '../../../../library/material';
import { StyledTableCell } from '../../../../shared/TableHeaderStyle';
import { getCurrentOffers } from '../../../../services/ConfigureService';
import Snackbar from '../../../toastrmessage/Snackbar';
import { Slide } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ViewCurrentOfferList(props) {

    const { userDetails, clinicDetails, openViewCurrentOfferList, setOpenViewCurrentOfferList } = props;
    const [getCurrentOfferData, setGetCurrentOfferData] = useState([]);

    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });

    useEffect(() => {
        if (openViewCurrentOfferList) {
            getCurrentOfferList()
        }
    }, [openViewCurrentOfferList === true]);

    const viewCurrentOfferDetails = (rowData) => {
        const newTab = window.open("", "_blank")
        newTab.document.write(rowData.offerDescription)
    };

    const getCurrentOfferList = () => {
        const payload = {
            orgId: userDetails.orgId,
            clinicId: clinicDetails.clinicId
        }
        getCurrentOffers(payload)
            .then((resp) => {
                setGetCurrentOfferData(resp.data.data)
            })
            .catch((error) => {
                setOpenSnackBar({
                    action: true,
                    type: "error",
                    message: error.response.data.messages,
                });
            })
    };

    const handleClose = () => {
        setOpenViewCurrentOfferList(false);
    };

    return (
        <div>
            <material.Dialog open={openViewCurrentOfferList}
                fullScreen
                PaperProps={{
                    sx: {
                        backgroundColor: '#fff',
                    },
                }}
                TransitionComponent={Transition}
            >
                <material.AppBar sx={{
                    position: 'relative',
                }}>
                    <material.Toolbar>
                        <material.IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <material.CloseIcon />
                        </material.IconButton>
                        <material.Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Current Offer List
                        </material.Typography>
                    </material.Toolbar>
                </material.AppBar>
                <material.DialogContent>
                    <div className="row">
                        <div className="col-12 mt-3">
                            <material.TableContainer>
                                <material.Table>
                                    <material.TableHead>
                                        <material.TableRow>
                                            <StyledTableCell>Treatment Name</StyledTableCell>
                                            <StyledTableCell>Amount</StyledTableCell>
                                            <StyledTableCell>Name</StyledTableCell>
                                        </material.TableRow>
                                    </material.TableHead>
                                    <material.TableBody>
                                        {getCurrentOfferData.length ? getCurrentOfferData.map((item, k) => (
                                            <material.TableRow key={k}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", ":hover": { backgroundColor: "lightgray" } }}
                                                onClick={() => viewCurrentOfferDetails(item)}
                                            >
                                                <material.TableCell>{item.forTreatment}</material.TableCell>
                                                <material.TableCell>{item.offerAmount}</material.TableCell>
                                                <material.TableCell>{item.offerName}</material.TableCell>
                                            </material.TableRow>
                                        )) : (
                                            <material.TableRow>
                                                <material.TableCell colSpan={10}>
                                                    <h6 className="d-flex justify-content-center text-danger fw-bold">
                                                        No data found
                                                    </h6>
                                                </material.TableCell>
                                            </material.TableRow>
                                        )}
                                    </material.TableBody>
                                </material.Table>
                            </material.TableContainer>
                        </div>
                    </div>
                </material.DialogContent>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default ViewCurrentOfferList;