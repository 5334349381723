import axios from "axios";
import { rootApiUrl } from "../environment/Environment";

export const addProductToCart = async (productToCart) => {
  return axios.post(`${rootApiUrl}/api/v0.2/cart/add-product-to-cart`, productToCart);
};

export const getPlatformProductList = async (payload) => {
  return axios.get(`${rootApiUrl}/api/v0.2/cart/get-platform-product-list`, {params:{...payload}});
};
