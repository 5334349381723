import React, { useEffect, useState } from 'react';
import { material } from '../../../library/material';
import Snackbar from '../../toastrmessage/Snackbar';
import { Controller, useForm } from 'react-hook-form';
import { getOtherInHandStocksByClinic } from '../../../services/OtherStockService';
import { debounce } from 'lodash';
import { saveOrUpdatePackageDetails } from '../../../services/PackageConfigurationService';

const complementary = ["Yes", "No"];

function AddProductPackage(props) {

    const { clinicDetails, openAddProductPackage, setOpenAddProductPackage, packageId, getSingleClinicPackages, userDetails, setPackageId } = props;
    const { register, handleSubmit, resetField, control, watch, setValue, reset, formState: { errors, isValid } } = useForm({
        mode: "onTouched",
    });
    const [productNameOptions, setProductNameOptions] = useState([]);
    const [getProducts, setGetProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState({
        action: false,
        type: "",
        message: "",
    });

    useEffect(() => {
        const calculateTotalCost = debounce(() => {
            selectedProduct.forEach((product) => {
                const costPerUnit = watch(`${product}_perUnitCost`);
                const quantity = watch(`${product}_quantity`);
                const discountPercentage = watch(`${product}_discountPercentage`);
                const discount = watch(`${product}_discount`);
                const totalCost = watch(`${product}_totalCost`);
                if (costPerUnit && quantity) {
                    const totalCost = parseFloat(costPerUnit) * parseInt(quantity);
                    setValue(`${product}_totalCost`, totalCost.toFixed(2));
                }
                if (totalCost && discountPercentage) {
                    const discount = parseFloat(totalCost) * parseInt(discountPercentage) / 100;
                    setValue(`${product}_discount`, discount.toFixed(2));
                }
                if (discount && totalCost) {
                    const payableAmount = parseFloat(totalCost) - parseInt(discount)
                    setValue(`${product}_totalPayableAmount`, payableAmount.toFixed(2));
                }
            });
        }, 300);

        calculateTotalCost();
        return () => {
            calculateTotalCost.cancel();
        };
    }, [
        selectedProduct,
        ...selectedProduct.map(product => watch(`${product}_perUnitCost`)),
        ...selectedProduct.map(product => watch(`${product}_quantity`)),
        ...selectedProduct.map(product => watch(`${product}_totalCost`)),
        ...selectedProduct.map(product => watch(`${product}_discountPercentage`)),
        ...selectedProduct.map(product => watch(`${product}_discount`)),
        ...selectedProduct.map(product => watch(`${product}_totalPayableAmount`)),
    ]);

    useEffect(() => {
        if (openAddProductPackage.action) {
            getProductName()
        }
    }, [openAddProductPackage.action === true]);

    const getProductName = () => {
        getOtherInHandStocksByClinic(clinicDetails.clinicId)
            .then((res) => {
                setGetProducts(res.data);
                const productName = res.data?.data?.map((ele) => ele.product);
                setProductNameOptions(productName);
            }).catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            });
    };

    const saveProduct = (formData) => {
        const array = [];
        for (const element of formData.treatmentName) {
            let temp = {}
            temp.clinicId = clinicDetails.clinicId;
            temp.orgId = userDetails.orgId;
            temp.treatment_product_service = "PRODUCT";
            temp.packageId = packageId;
            temp.treatmentProductName = element;
            temp.treatmentProductArea = formData[element + "_instruction"];
            temp.numberOfSession_quantityOfProduct = formData[element + "_quantity"];
            temp.perSessionCost = formData[element + "_perUnitCost"];
            temp.discountPercentage = formData[element + "_discountPercentage"];
            temp.total = formData[element + "_totalCost"];
            temp.discount = formData[element + "_discount"];
            temp.totalPayableAmount = formData[element + "_totalPayableAmount"];
            temp.isComplementary = formData[element + "_isComplementary"] == "Yes" ? true : false;
            array.push(temp);
        }
        saveOrUpdatePackageDetails(array)
            .then((resp) => {
                setOpenSnackBar({
                    action: true,
                    type: "success",
                    message: resp.data.messages,
                });
                reset();
                setSelectedProduct([]);
                setOpenAddProductPackage({ action: false, productData: null });
                getSingleClinicPackages(resp.data.data.newAssignedId);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.data.info,
                    })
                } else {
                    setOpenSnackBar({
                        "action": true,
                        "type": "error",
                        "message": error.response.data.messages,
                    })
                }
            })
    };

    const cancel = () => {
        reset();
        setSelectedProduct([])
        setOpenAddProductPackage({ action: false, productData: null });
    };

    return (
        <div>
            <material.Dialog open={openAddProductPackage.action} maxWidth="lg" fullWidth>
                <material.DialogTitle>Add Product Plan</material.DialogTitle>
                <material.DialogContent>
                    <div className="row">
                        <div className="col-12">
                            <Controller
                                name="treatmentName"
                                control={control}
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <material.Autocomplete
                                        multiple
                                        disableCloseOnSelect
                                        {...field}
                                        value={field.value || []}
                                        options={productNameOptions}
                                        renderInput={(params) => (
                                            <material.TextField
                                                required
                                                margin="dense"
                                                {...params}
                                                variant="standard"
                                                label="Product Name"
                                                fullWidth
                                            />
                                        )}
                                        onChange={(_, data) => {
                                            field.onChange(data);
                                            setSelectedProduct(data);
                                            const price = data?.map(product => {
                                                const productDetails = getProducts?.filter((ele) => ele.product === product)[0];
                                                setValue(`${product}_perUnitCost`, productDetails?.perUnitPrice);
                                            })
                                            data.forEach(name => {
                                                setValue(`${name}_product`, name);
                                                setValue(`${name}_instruction`, "");
                                                setValue(`${name}_quantity`, "");
                                                // setValue(`${name}_perUnitCost`, "");
                                                setValue(`${name}_totalCost`, "");
                                                setValue(`${name}_discountPercentage`, "");
                                                setValue(`${name}_discount`, "");
                                                setValue(`${name}_totalPayableAmount`, "");
                                            })
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-12">
                            {selectedProduct.length ? selectedProduct.map((productName, ind) => (
                                <div className="row border border-2 p-2 my-2 rounded-2 mt-2" key={ind} style={{ boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" }}>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <material.TextField
                                            {...register(`${productName}_product`, { required: true })}
                                            required
                                            margin="dense"
                                            id="name"
                                            label="Product Name"
                                            type="text"
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            variant="standard"
                                        />
                                    </div>
                                    <div className="col-lg-8 col-md-6 col-sm-12">
                                        <material.TextField
                                            {...register(`${productName}_instruction`)}
                                            margin="dense"
                                            id="name"
                                            label="Instruction"
                                            type="text"
                                            fullWidth
                                            multiline
                                            rows={2}
                                            variant="standard"
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-4">
                                        <Controller
                                            name={`${productName}_isComplementary`}
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <material.Autocomplete
                                                    {...field}
                                                    options={complementary}
                                                    renderInput={(params) => (
                                                        <material.TextField
                                                            required
                                                            margin="dense"
                                                            {...params}
                                                            variant="standard"
                                                            label="Is Complementary"
                                                            fullWidth
                                                        />
                                                    )}
                                                    onChange={(event, data) => {
                                                        field.onChange(data);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-4">
                                        <material.TextField
                                            {...register(`${productName}_quantity`, { required: true })}
                                            required
                                            margin="dense"
                                            id="name"
                                            label="Quantity of Product"
                                            type="number"
                                            fullWidth
                                            variant="standard"
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-4">
                                        <material.TextField
                                            {...register(`${productName}_perUnitCost`, { required: true })}
                                            required
                                            margin="dense"
                                            id="name"
                                            label="Per Unit Cost"
                                            type="text"
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            InputLabelProps={{ shrink: true }}
                                            variant="standard"
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-4">
                                        <material.TextField
                                            {...register(`${productName}_totalCost`, { required: true })}
                                            required
                                            margin="dense"
                                            id="name"
                                            label="Total Cost"
                                            type="number"
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            InputLabelProps={{ shrink: true }}
                                            variant="standard"
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-4">
                                        <material.TextField
                                            {...register(`${productName}_discountPercentage`, { required: true })}
                                            required
                                            margin="dense"
                                            id="name"
                                            label="Discount Percentage"
                                            type="number"
                                            fullWidth
                                            variant="standard"
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-4">
                                        <material.TextField
                                            {...register(`${productName}_discount`, { required: true })}
                                            required
                                            margin="dense"
                                            id="name"
                                            label="Discount"
                                            type="number"
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            InputLabelProps={{ shrink: true }}
                                            variant="standard"
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-4">
                                        <material.TextField
                                            {...register(`${productName}_totalPayableAmount`, { required: true })}
                                            required
                                            margin="dense"
                                            id="name"
                                            label="Total Payable Amount"
                                            type="number"
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            InputLabelProps={{ shrink: true }}
                                            variant="standard"
                                        />
                                    </div>
                                </div>
                            )) : null}
                        </div>
                    </div>
                </material.DialogContent>
                <material.DialogActions sx={{ m: 2 }}>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" color="error" onClick={cancel} startIcon={<material.CloseIcon />}>Cancel</material.Button>
                    <material.Button variant="contained" sx={{ textTransform: "none" }} size="medium" color="primary" onClick={handleSubmit(saveProduct)} startIcon={<material.DoneIcon />}>Save</material.Button>
                </material.DialogActions>
            </material.Dialog>
            <Snackbar
                openSnackBar={openSnackBar}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    );
}

export default AddProductPackage;